import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { routeConfig } from 'configs/route';
import { removeStoredAccessToken } from 'shared/utils/authToken';
import { updateCurrentUserAction } from 'reducers/user';
import { addCompanyModalAction } from 'reducers/modalLot';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.resolve()
      .then(() => dispatch(addCompanyModalAction()))
      .then((company) => {
        /** Assign user to the company */
        return dispatch(
          updateCurrentUserAction({
            companyId: company._id
          })
        );
      })
      .then(() => removeStoredAccessToken())
      .then(() => {
        /* Show confirmation modal */
        dispatch(push(routeConfig.SIGNUP_CONFIRMATION));
      })
      .catch(() => {
        /** Silence is gold  */
        dispatch(push(routeConfig.LOGOUT));
      });
  }, [dispatch]);

  return null;
};
