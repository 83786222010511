import React, { useEffect } from 'react';
import { Spin, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';

import { routeConfig } from 'configs/route';
import { confirmSignup } from 'services/sourcing';
import { dispatchService } from 'shared/utils/service';
import { buildPath } from 'shared/utils';

export default () => {
  const dispatch = useDispatch();
  const { token, type } = useParams();

  useEffect(() => {
    const loginUrl = buildPath(routeConfig.SOURCING_LOGIN, { type });
    return dispatchService(
      () => confirmSignup(type, token),
      (_) => {
        notification.success({ message: "You're all signed up!" });
        dispatch(push(loginUrl));
      },
      (_) => {
        notification.error({ message: 'Token is not valid' });
        dispatch(push(loginUrl));
      }
    );
  }, [dispatch, token]);

  return <Spin />;
};
