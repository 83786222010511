import toast from 'shared/utils/toast';
import { createAction, createReducer } from 'redux-act';
import {
  fetchMoveType as fetchMoveTypeData,
  createMoveType as createMoveTypeData
} from 'services/moveType';

const initialState = {
  fetchLoading: false,
  createLoading: false,
  data: [],
  error: false,
  createError: false
};

const fetchMoveType = createAction('fetch move type');
const fetchMoveTypeSuccess = createAction('fetch move type success');
const fetchEdiLogError = createAction('fetch move type error');
const createMoveType = createAction('create move type');
const createMoveTypeSuccess = createAction('create move type success');
const createMoveTypeError = createAction('create move type error');

export default createReducer(
  {
    [fetchMoveType]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchMoveTypeSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchEdiLogError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      fetchLoading: false
    }),
    [createMoveType]: (state) => ({
      ...state,
      createLoading: true
    }),
    [createMoveTypeSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      createLoading: false
    }),
    [createMoveTypeError]: (state) => ({
      ...state,
      createError: true
    })
  },
  initialState
);

export const fetchMoveTypeAction = (type, companyId) => {
  return async (dispatch) => {
    dispatch(fetchMoveType());
    try {
      const data = await fetchMoveTypeData(type, companyId);
      dispatch(fetchMoveTypeSuccess(data.data));
    } catch (error) {
      dispatch(fetchEdiLogError('Fetching error'));
      toast.error(`Error occurred when fetching Move Type/Load Type`);
    }
  };
};

export const createMoveTypeAction = (data) => {
  return async (dispatch) => {
    dispatch(createMoveType());
    try {
      const res = await createMoveTypeData(data);
      if (res) {
        const refetchData = await fetchMoveTypeData(data.type, data.companyId);
        dispatch(createMoveTypeSuccess(refetchData.data));
        toast.success("You've added new Move Type/Load Type");
      }
    } catch (error) {
      dispatch(fetchEdiLogError('Fetching error'));
      toast.error(`Error occurred when create Move Type/Load Type`);
    }
  };
};
