import {
  getFeatureFields,
  getRateFields,
  createRateCard,
  updateRateCard,
  getRateCardType,
  validateRateCardData,
  updateRateCardGroup,
  validateFormulaRate
} from 'services/contract';
import { createAction, createReducer } from 'redux-act';

const initialState = {
  featureFields: [],
  isFetchingFeatureFields: false,
  rateFields: [],
  isFetchingRateFields: false,
  selectedFeatureFields: [],
  selectedRateFields: [],
  isWorking: false,
  rateCardColumns: [
    { title: 'Feature Fields', children: [] },
    { title: 'Rate Fields', children: [] }
  ],
  rateCardData: [],
  rateCardType: [],
  selectedRateCardType: {},
  isFetchingRateCardType: false,
  isDisableButtons: false,
  rateCardCurrentStep: 0,
  canMoveNextStep: true,
  isValidatingRateCard: false,
  validationRateCardData: {},
  validationRateErrors: null,
  formulaRateList: []
};

const getFeatureFieldsStartAction = createAction('getFeatureFields_start');
const getFeatureFieldsSuccessAction = createAction('getFeatureFields_success');
const getFeatureFieldsErrorAction = createAction('getFeatureFields_error');

const getRateFieldsStartAction = createAction('getRateFields_start');
const getRateFieldsSuccessAction = createAction('getRateFields_success');
const getRateFieldsErrorAction = createAction('getRateFields_error');

const selectFeaturesStartAction = createAction('selectFeatureFields_start');
const selectFeaturesSuccessAction = createAction('selectFeatureFields_success');

const selectRatesStartAction = createAction('selectRateFields_start');
const selectRatesSuccessAction = createAction('selectRateFields_success');

const createRateCardStartAction = createAction('create rate card start');
const createRateCardSuccessAction = createAction('create rate card success');
const createRateCardErrorAction = createAction('create rate card error');

const getRateCardTypeStartAction = createAction('getRateCardType_start');
const getRateCardTypeSuccessAction = createAction('getRateCardType_success');
const getRateCardTypeErrorAction = createAction('getRateCardType_error');

const validateRateCardStartAction = createAction('validateRateCard_start');
const validateRateCardSuccessAction = createAction('validateRateCard_success');
const validateRateCardErrorAction = createAction('validateRateCard_error');

const updateRateCardDataSuccessAction = createAction(
  'updateRateCardData_success'
);

const resetRateCardSuccessAction = createAction('resetRateCard_success');

const getAllFieldsRateCardSuccessAction = createAction(
  'getAllFieldsRateCard_success'
);

export const loadRateCardAction = createAction('load rate card data');
export const updateStepButtonStatusAction = createAction(
  'updateStepStatus_success'
);
export const setRateCardCurrentStepAction = createAction('rateCardCurrentStep');
export const setCanMoveNextStepAction = createAction('canMoveNextStep');

export const saveRateFormulaAction = createAction('save formula rate');
export const updateRateFormulaAction = createAction('update formula rate');

export default createReducer(
  {
    [getFeatureFieldsStartAction]: (state) => ({
      ...state,
      isFetchingFeatureFields: true
    }),
    [getFeatureFieldsSuccessAction]: (state, featureFields) => ({
      ...state,
      isFetchingFeatureFields: false,
      featureFields
    }),
    [getFeatureFieldsErrorAction]: (state) => ({
      ...state,
      isFetchingFeatureFields: false
    }),
    [selectFeaturesStartAction]: (state) => ({
      ...state,
      isWorking: true
    }),
    [selectFeaturesSuccessAction]: (state, selectedFeatureFields) => {
      const featureFields = {
        ...state.rateCardColumns[0],
        children: selectedFeatureFields.map((feature, index) => ({
          title: `${feature.fieldName ? feature.fieldName : feature}`,
          dataIndex: `${feature.fieldName ? feature.fieldName : feature}`,
          key: `${feature.fieldName ? feature.fieldName : feature}-${index}`,
          componentType: feature.fieldComponentType,
          width: 250
        }))
      };
      return {
        ...state,
        isWorking: false,
        selectedFeatureFields,
        rateCardColumns: [featureFields, state.rateCardColumns[1]]
      };
    },
    [getRateFieldsStartAction]: (state) => ({
      ...state,
      isFetchingRateFields: true
    }),
    [getRateFieldsSuccessAction]: (state, rateFields) => ({
      ...state,
      isFetchingRateFields: false,
      rateFields
    }),
    [getRateFieldsErrorAction]: (state) => ({
      ...state,
      isFetchingRateFields: false
    }),
    [selectRatesStartAction]: (state) => ({
      ...state,
      isWorking: true
    }),
    [selectRatesSuccessAction]: (state, selectedRateFields) => {
      const rateFields = {
        ...state.rateCardColumns[1],
        children: selectedRateFields.map((rate, index) => ({
          title: `${rate}`,
          dataIndex: `${rate}`,
          key: `${rate}-${index}`,
          width: 250
        }))
      };
      return {
        ...state,
        isWorking: false,
        selectedRateFields,
        rateCardColumns: [state.rateCardColumns[0], rateFields]
      };
    },
    [loadRateCardAction]: (state, rateCardData) => ({
      ...state,
      rateCardData
    }),
    [createRateCardStartAction]: (state) => ({
      ...state,
      isWorking: true
    }),
    [createRateCardSuccessAction]: (state) => ({
      ...state,
      isWorking: false
    }),
    [createRateCardErrorAction]: (state) => ({
      ...state,
      isWorking: false
    }),
    [updateRateCardDataSuccessAction]: (state, rateCardData) => ({
      ...state,
      rateCardData
    }),
    [getRateCardTypeStartAction]: (state) => ({
      ...state,
      isFetchingRateCardType: true
    }),
    [getRateCardTypeSuccessAction]: (state, rateCardType) => ({
      ...state,
      isFetchingRateCardType: false,
      rateCardType
    }),
    [getRateCardTypeErrorAction]: (state) => ({
      ...state,
      isFetchingRateCardType: false
    }),
    [getAllFieldsRateCardSuccessAction]: (state, rateCardTypeId) => {
      const foundRateCardType = state.rateCardType.find(
        (rateCard) => rateCard._id === rateCardTypeId
      );
      if (foundRateCardType) {
        return {
          ...state,
          featureFields: foundRateCardType.feature.sort(
            (a, b) => a.fieldOrder - b.fieldOrder
          ),
          rateFields: foundRateCardType.rate.sort(
            (a, b) => a.fieldOrder - b.fieldOrder
          ),
          selectedRateCardType: foundRateCardType
        };
      }
      return {
        ...state
      };
    },
    [resetRateCardSuccessAction]: (state) => ({
      ...initialState,
      rateCardType: state.rateCardType
    }),
    [updateStepButtonStatusAction]: (state, status) => ({
      ...state,
      isDisableButtons: status
    }),
    [setRateCardCurrentStepAction]: (state, step) => ({
      ...state,
      rateCardCurrentStep: step
    }),
    [setCanMoveNextStepAction]: (state, status) => ({
      ...state,
      canMoveNextStep: status
    }),
    [validateRateCardStartAction]: (state) => ({
      ...state,
      isValidatingRateCard: true
    }),
    [validateRateCardSuccessAction]: (state, data) => {
      return {
        ...state,
        isValidatingRateCard: false,
        validationRateCardData: data.rateCards,
        validationRateErrors: data.errors
      };
    },
    [validateRateCardErrorAction]: (state) => ({
      ...state,
      isValidatingRateCard: false
    }),
    [saveRateFormulaAction]: (state, formula) => ({
      ...state,
      formulaRateList: state.formulaRateList.concat(formula)
    }),
    [updateRateFormulaAction]: (state, formulaRateList) => ({
      ...state,
      formulaRateList
    })
  },
  initialState
);

export const getFeatureFieldsAction = () => {
  return async (dispatch, getState) => {
    try {
      if (getState().rateCard.featureFields.length > 0)
        return Promise.resolve();
      dispatch(getFeatureFieldsStartAction());
      const response = await getFeatureFields();
      dispatch(getFeatureFieldsSuccessAction(response.data));
    } catch (error) {
      dispatch(getFeatureFieldsErrorAction());
    }
  };
};

export const selectedFeatureFieldsAction = (fields) => {
  return async (dispatch) => {
    dispatch(selectFeaturesSuccessAction(fields));
    return Promise.resolve();
  };
};

export const getRateFieldsAction = () => {
  return async (dispatch, getState) => {
    try {
      if (getState().rateCard.rateFields.length > 0) return Promise.resolve();
      dispatch(getRateFieldsStartAction());
      const response = await getRateFields();
      dispatch(getRateFieldsSuccessAction(response.data));
    } catch (error) {
      dispatch(getRateFieldsErrorAction());
    }
  };
};

export const selectedRateFieldsAction = (fields) => {
  return async (dispatch) => {
    dispatch(selectRatesSuccessAction(fields));
    return Promise.resolve();
  };
};

export const createRateCardAction = (contractId, data) => {
  return async (dispatch) => {
    dispatch(createRateCardStartAction());
    try {
      await createRateCard(contractId, data);
      //TODO: Need add response to redux state
      dispatch(createRateCardSuccessAction());
      return Promise.resolve();
    } catch (err) {
      dispatch(createRateCardErrorAction());
    }
  };
};

export const updateRateCardAction = (contractId, data) => {
  return async () => {
    await updateRateCard(contractId, data);
  };
};

export const updateRateCardDataAction = (data) => {
  return async (dispatch) => {
    dispatch(updateRateCardDataSuccessAction(data));
    return Promise.resolve();
  };
};

export const resetRateCardAction = () => {
  return async (dispatch) => {
    dispatch(resetRateCardSuccessAction());
    return Promise.resolve();
  };
};

export const getRateCardTypeAction = () => {
  return async (dispatch, getState) => {
    if (getState().rateCard.rateCardType.length > 0) return Promise.resolve();
    dispatch(getRateCardTypeStartAction());
    try {
      const response = await getRateCardType();
      dispatch(getRateCardTypeSuccessAction(response.data));
      return Promise.resolve();
    } catch (err) {
      dispatch(getRateCardTypeErrorAction());
    }
  };
};

export const getAllFieldsRateCardAction = (rateCardTypeId) => {
  return async (dispatch) => {
    dispatch(getAllFieldsRateCardSuccessAction(rateCardTypeId));
    return Promise.resolve();
  };
};

export const validateRateCardAction = (contractId, data) => {
  return async (dispatch) => {
    try {
      dispatch(validateRateCardStartAction());
      const response = await validateRateCardData(contractId, data);
      let errors = [];
      response.data.rateCards.forEach((rate, index) => {
        if (rate.errorFields.length > 0) {
          errors.push({ index, errorFields: rate.errorFields });
        }
      });
      dispatch(
        validateRateCardSuccessAction({ rateCards: response.data, errors })
      );
    } catch (error) {
      dispatch(validateRateCardErrorAction());
    }
  };
};

export const updateRateCardGroupAction = (contractId, rateGroupId, data) => {
  return async () => {
    const response = await updateRateCardGroup(contractId, rateGroupId, data);
    return response.data[0];
  };
};

export const validateFormulaRateAction = (formula) => {
  return async () => {
    const response = await validateFormulaRate(formula);
    return response;
  };
};
