export default {
  common: {
    "My Company": "Mi EMPRESA",
    "Build My Network": "Crear Mi Network",
    "AI Models": "Modelos AI",
    "DataHub": "DataHub",
    "My Orders": "Mis Ordenes",
    "Contracts": "Contratos",
    "Precision Block Asset Service Management": "Gestión de servicios de armado bloques ",
    "Expedite Your Inbound CTN": "Priorización de contenedores entrantes",
    "Expedite Your Inbound Container": "Priorización de contenedores entrantes",
    "Terminal Activities": "Actividades en Terminal",
    "Container Block": "Bloque de contenedores",
    "Terminals": "Terminal",
    "Customer Container Tier Management": "Gestión de niveles de contenedores del cliente",
    "Container Booking Management": "Gestion de reservas de Contenedores",
    "Existing Expedited Requests": "pedidos de priorizacion existentes",
    "Control Panel": "Panel de Control",
    "Manage Equipment": "Gestion de recursos",
    "Filter by": "Filtrar por",
    "Name": "Nombre",
    "Back": "atras",
    "Columns": "Columnas",
    "Remove filter": "No filtrar",
    "New terminal": "Nuevo terminal",
    "Railway": "Via del Tren",
    "Address": "Dirección",
    "Zip/Postal Code": "Zip/Codigo Postal",
    "Hours of operation": "Horas de Operacion",
    "Lift Hours":	"Horas de Grua",
    "Edit": "Editar",
    "Layout": "Layout",
    "Schedule": "Cronograma",
    "Delete": "Borrar",
    "View operation hours": "Ver horas de operacion",
    "View lift hours": "Ver horas de gruas",
    "View rail map": "Ver plano de vias tren",
    "Closed": "Cerrar",
    "Train Schedule": "Plan del Tren",
    "Train Schedules": "Cronogramas del Tren", 
    "Inbound": "Llegada",
    "Outbound": "Salida",
    "Train#": "Tren#",
    "Status": "Estatus",
    "Planned Arrival Date": "Dia planeado de Llegada",
    "Planned Departure Date": "Dia planeado de Salida",
    "Confirm Arrival": "Llegada confirmada",
    "Load Arrival": "Llegada de Carga",
    "Train Control Panel": "Panel de control de trenes",
    "Arrived Containers": "Llegada de Contenedor",
    "Unloaded Containers": "Descarga de contenedor",
    "Container": "Contenedor",
    "Block Position": "Posicion del bloque",
    "Block Validation": "Validacion del Bloque",
    "Customer": "Cliente",
    "Container Size": "Tamaño contenedor",
    "Gross Weight": "Peso Bruto",
    "Item": "Item",
    "Prioritized": "Priorizar",
    "Revenue Prioritzation": "Priorizacion de ingresos",
    "Flag": "Bandera",
    "Toplift Driver": "Chofer Toplift",
    "Crane Operator": "Operador de Grua",
    "GPS Offload": "GPS Offload",
    "Bulk actions": "Bulk actions",
    "Select status": "Seleccionar Estatus",
    "Door change Request": "Pedido Cambio de puerta",
    "Change Time": "Cambiar Horario",
    "Change Container": "Cambiar Contenedor",
    "Lost Container": "Contenedor Perdido",
    "Move to Block": "Mover a bloque ",
    "Well Car incorrect": "Well Car incorrect",
    "Change Date at door": "Change Date at door",
    "Container still full": "Contenedor aun lleno",
    "Keep in Yard": "Mantener en Patio",
    "Seal missing": "Faltan Precintos",
    "Document Missing": "Documento faltante",
    "Wrong PO": "PO Errada",
    "Not customs cleared": "Liberacion de Aduana pendiente ",
    "Store Container in Block": "Almacenar contenedor en el bloque ",
    "Unload confirmed": "Descarga Confirmada",
    "Empty confirmed": "Vacio confirmado",
    "Offload confirmed": "Descarga confirmada",
    "Departure confirmed": "Salida confirmada ",
    "Well car confirmed": "Well car confirmed",
    "Click to Upload": "Click Actualizar",
    "Equipment": "Equipo",
    "Equipment Type": "Tipo de equipo",
    "Equipment No": "# de equipo",
    "Rail arrived": "llegada tren",
    "Unloaded": "Descarga",
    "Moved to yard": "Mover a Patio",
    "Loaded to chassis": "Cargado en Chassis ",
    "Moved to door": "Mover a Puerta ",
    "Moved from door to yard": "De Puerta a patio",
    "Loaded to train": "Cargado en tren ",
    "Rail departed": "Salida Tren ",
    "Export": "Exportacion",
    "Search": "Buscar",
    "Reset": "Reset",
    "Transit Status": "Estatus en transito ",
    "Last Event": "Ultimo Evento",
    "Offload/Load": "Descarga/ Carga",
    "Available Date": "Fecha Disponible",
    "Storage Start": "Inicio almacenamiento",
    "Rail": "Linea Tren",
    "Line": "Linea",
    "Ship": "Barco",
    "At rail yard": "En patio de tren ",
    "Rail carrier": "Operador tren",
    "Move Orders": "Orden de Movimiento",
    "Container Management": "Gestion de Contenedores",
    "Move type": "Tipo Movimiento",
    "Destination": "Destino",
    "Order Date": "Dia orden",
    "Notes": "Notas",
    "Operator": "Operador",
    "Operated Date": "Operated Date",
    "Completed Date": "Dia completado",
    "Documents": "Documentos",
    "Document Name": "Nomber Documento ",
    "Description": "Descripcion",
    "Created At": "Creado en ",
    "Created By": "Created el",
    "Links": "Vínculos",
    "Move to block to store": "Mover a patio para almacenar",
    "Move to door": "Mover a Puerta",
    "Move to door delay": "Mover a Puerta al final",
    "Prioritized to door": "Priorizar a puerta",
    "Move to rail siding": "Mover a vias tren",
    "Move to crane priority": "Mover a Grúa prioridad",
    "Move to truck": "Mover a Camion",
    "Pick-up empty chassis": "Recoger chassis vacios",
    "Unload": "Descarga",
    "Move to chassis": "Mover a Chassis",
    "Chassis confirmed": "Chassis confirmado",
    "Move to block": "Mover a patio",
    "Crane offload and store": "Desargar de Grúa y almacenar",
    "Takes empty chassis to yard": "Devolver chassis vacios a patio",
    "Unload well car": "Descargar vagon",
    "Unload chassis": "Descargar Chassis",
    "Arrived at yard": "Llegada a patio",
    "Moved to rail": "Mover a Tren",
    "Moved to crane priority": "Mover a Grúa Prioridad",
    "Moved to truck": "Mover a Camion ",
    "COMPLETED": "COMPLETADO",
    "CANCELLED": "CANCELADO",
    "CREATED": "CREADO",
    "PROCESSING": "EN PROCESO",
    "CONFIRMED": "CONFIRMADO",
    "Please select a terminal to see container block": "Por favor seleccionar un terminal para ver el patio",
    "Select a terminal": "Selecionar terminal",
    "Block Capacity": "Capacidad del patio",
    "Waiting to confirm": "Esperando Confirmación",
    "Prioritization Capacity": "Prioritization Capacity",
    "Under review": "En Revision",
    "Run Allocation": "Run Allocation",
    "Rank": "Rank",
    "Price/Day": "Precio/Dia",
    "Arriving Train": "Llegada de tren",
    "Arriving Date": "Dia de llegada"
  }
}