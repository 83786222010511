import store from 'store';
import {
  removeStoredAccessToken,
  removeStoredRefreshToken,
  getStoredRefreshToken
} from 'shared/utils/authToken';
import http from 'shared/utils/http';
import { removeLoggedCompany, removeLoggedAccount } from 'services/company';

export const login = async (email, password) => {
  const response = await http.api('netwila/user/login', 'put', {
    email,
    password
  });
  return response.data;
};

export const signup = async (firstName, lastName, email, password) => {
  const autoConfirm = true;
  const response = await http.api('/netwila/user', 'put', {
    email,
    firstName,
    lastName,
    password,
    autoConfirm
  });
  return response.data;
};

export const signup2 = async (firstName, lastName, email, password, token) => {
  const response = await http.api(
    `/netwila-core/company/verify/${token}`,
    'post',
    {
      firstName,
      lastName,
      email,
      password
    }
  );
  return response.data;
};

export const setLoggedUser = (user) => {
  store.set('user', user);
};

export const getLoggedUser = () => {
  return store.get('user');
};

export const removeLoggedUser = () => {
  store.remove('user');
};

export const logout = () => {
  // Remove access token
  removeStoredAccessToken();
  // Remove refresh token
  removeStoredRefreshToken();
  // Remove user information
  removeLoggedUser();
  // Remove logged company information
  removeLoggedCompany();
  // Remove logged account information
  removeLoggedAccount();
};

export const refreshAccessToken = async () => {
  return http.put('/netwila/user/refreshtoken', {
    RefreshToken: getStoredRefreshToken()
  });
};

export const oauthLogin = async (email, password, query) => {
  const response = await http.api('/netwila/oauth2/login?' + query, 'post', {
    username: email,
    password
  });
  return response.data;
};