import { sortBy } from 'lodash';
import { push } from 'connected-react-router';

import { routeConfig } from 'configs/route';
import {
  getCompanyType,
  getAllCompany,
  createCompany,
  getIndustrySegment,
  getCompany,
  updateCompany,
  getLoggedCompany,
  setLoggedCompany,
  setLoggedAccount,
  checkCompany,
  disableCompany,
  getCompanies,
  getEmployees,
  updateCompanyLogo,
  updateCompanyContacts,
  updateCompanyProductSegments,
  getCompanySubscription,
  getCompanyAppServices
} from 'services/company';

const initialState = {
  loading: false,
  isFetchingAllCompany: false,
  isFetchingCompanyType: false,
  isFetchingIndustrySegment: false,
  message: '',
  companyType: [],
  industrySegment: [],
  currentCompany: null,
  error: false,
  allCompany: [],
  isWorking: false,
  workingCompany: null,
  loggedCompany: null,
  carrier: [],
  isFetchingCarrier: false,
  employees: [],
  subscription: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getCompanyType_start':
      return {
        ...state,
        isFetchingCompanyType: true
      };
    case 'getCompanyType_success':
      return {
        ...state,
        companyType: action.data,
        isFetchingCompanyType: false
      };
    case 'getCompanyType_error':
      return {
        ...state,
        isFetchingCompanyType: false
      };
    case 'getIndustrySegment_start':
      return {
        ...state,
        isFetchingIndustrySegment: true
      };
    case 'getIndustrySegment_success':
      return {
        ...state,
        industrySegment: action.data,
        isFetchingIndustrySegment: false
      };
    case 'getIndustrySegment_error':
      return {
        ...state,
        industrySegment: action.data,
        isFetchingIndustrySegment: false
      };
    case 'addCompany_start':
      return {
        ...state,
        isWorking: true
      };
    case 'addCompany_success':
      return {
        ...state,
        isWorking: false,
        allCompany: state.allCompany.concat(action.data)
      };
    case 'addCompany_error':
      return {
        ...state,
        isWorking: false,
        error: true,
        message: action.message
      };

    case 'updateCompany_start':
      return {
        ...state,
        isWorking: true,
        error: false,
        message: ''
      };
    case 'updateCompany_success':
      return {
        ...state,
        isWorking: false,
        ...action.data,
        workingCompany: null
      };
    case 'updateCompany_error':
      return {
        ...state,
        isWorking: false,
        error: true,
        message: action.message,
        workingCompany: null
      };
    case 'addDocumentToCompany_success':
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          document: state.currentCompany.document.concat(action.data)
        }
      };
    case 'getCurrentCompany_start':
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      };
    case 'getCurrentCompany_success':
      return {
        ...state,
        currentCompany: action.data ? action.data : state.currentCompany,
        loggedCompany: action.data ?? state.loggedCompany,
        loading: false
      };
    case 'getCurrentCompany_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      };
    case 'getCompany_start':
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      };
    case 'getCompany_success':
      return {
        ...state,
        loading: false,
        ...action.data
      };
    case 'getCompany_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      };
    case 'getAllCompany_start':
      return {
        ...state,
        isFetchingAllCompany: true,
        error: false,
        message: ''
      };
    case 'getAllCompany_success':
      return {
        ...state,
        allCompany: action.data,
        isFetchingAllCompany: false
      };
    case 'getAllCompany_error':
      return {
        ...state,
        isFetchingAllCompany: false,
        error: true,
        message: action.message
      };
    case 'verifyLoggedCompany_success':
      return {
        ...state,
        loggedCompany: action.data
      };
    case 'checkCompany_start':
      return {
        ...state,
        loading: true
      };
    case 'checkCompany_success':
    case 'checkCompany_error':
      return {
        ...state,
        loading: false
      };
    case 'workingCompany_global':
      return {
        ...state,
        workingCompany: action.companyId // This state for knowing which company in table list is updating
      };
    case 'addCompanyLocation':
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          location: [...state.currentCompany.location, action.data]
        },
        loggedCompany: {
          ...state.loggedCompany,
          location: [...state.currentCompany.location, action.data]
        }
      };
    case 'updateCompanyLocation':
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          location: action.data
        }
      };
    case 'updateCompanyLocationById':
      return {
        ...state,
        [action.companyId]: {
          ...state[action.companyId],
          location: action.data
        }
      };
    case 'updateCurrentCompany':
      return {
        ...state,
        currentCompany: action.data,
        loggedCompany: action.data ?? state.loggedCompany
      };
    case 'addCompany':
      return {
        ...state,
        allCompany: state.allCompany.concat(action.company)
      };
    case 'getCarrierCompany_start':
      return {
        ...state,
        isFetchingCarrier: true
      };
    case 'getCarrierCompany_success':
      return {
        ...state,
        carrier: action.carrier,
        isFetchingCarrier: false
      };
    case 'getCarrierCompany_error':
      return {
        ...state,
        isFetchingCarrier: false
      };
    case 'addCompanyPartner':
      return {
        ...state,
        loggedCompany: {
          ...state.loggedCompany,
          companyLink: [state.loggedCompany.companyLink, action.data]
        }
      };
    case 'searchEmployee_success':
      return {
        ...state,
        employees: action.data
      };
    case 'getSubscription_success':
      return {
        ...state,
        subscription: action.data
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

export const getCompanyTypeAction = () => {
  return (dispatch) => {
    dispatch({ type: 'getCompanyType_start' });
    getCompanyType()
      .then((res) => {
        dispatch({ type: 'getCompanyType_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getCompanyType_error', message: err });
      });
  };
};

export const getIndustrySegmentAction = () => {
  return (dispatch) => {
    dispatch({ type: 'getIndustrySegment_start' });
    getIndustrySegment()
      .then((res) => {
        dispatch({ type: 'getIndustrySegment_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getIndustrySegment_error', message: err });
      });
  };
};

export const addNewCompanyAction = (company) => {
  return async () => {
    const response = await createCompany(company);
    return response.data[0];
  };
};

export const addCompanyStateAction = (company) => {
  return {
    type: 'addCompany',
    company
  };
};

export const updateCompanyAction = (companyId, toUpdateData) => {
  return (dispatch, getState) => {
    dispatch({ type: 'updateCompany_start', companyId });
    updateCompany({ companyId, ...toUpdateData })
      .then((res) => {
        const { currentCompany } = getState().company;
        if (currentCompany._id === companyId) {
          dispatch({
            type: 'updateCurrentCompany',
            data: res.data[0]
          });
        }
        let data = {};
        data[`${companyId}`] = res.data.length > 0 ? res.data[0] : null;
        dispatch({
          type: 'updateCompany_success',
          data
        });
      })
      .catch((err) => {
        dispatch({ type: 'updateCompany_error', data: err.message });
      });
  };
};

export const updateCompanyLogoAction = (companyId, logo) => {
  return async () => {
    await updateCompanyLogo(companyId, logo);
  };
};

export const updateCompanyContactsAction = (companyId, contacts) => {
  return async () => {
    await updateCompanyContacts(companyId, contacts);
  };
};

export const updateCompanyProductSegmentAction = (
  companyId,
  productSegments
) => {
  return async () => {
    await updateCompanyProductSegments(companyId, productSegments);
  };
};

export const addCompanyLocationAction = (location) => {
  return {
    type: 'addCompanyLocation',
    data: location
  };
};

export const updateCompanyLocationAction = (locations) => {
  return {
    type: 'updateCompanyLocation',
    data: locations
  };
};

export const updateCompanyLocationById = (companyId, locations) => {
  return (dispatch, getState) => {
    if (companyId === getState().company.currentCompany._id) {
      dispatch(updateCompanyLocationAction);
    }
    dispatch({
      type: 'updateCompanyLocationById',
      companyId,
      data: locations
    });
  };
};

export const getCompanyAction = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: 'getCompany_start' });
    getCompany(companyId)
      .then((res) => {
        let data = {};
        data[`${companyId}`] = res.data.length > 0 ? res.data[0] : null;
        dispatch({
          type: 'getCompany_success',
          data
        });
      })
      .catch((err) => {
        dispatch({ type: 'getCompany_error', data: err.message });
      });
  };
};

const isAllCompanyExisted = (state) => {
  const { allCompany } = state.company;
  if (allCompany.length === 0) {
    return false;
  }
  return true;
};

export const getAllCompanyAction = () => {
  return async (dispatch, getState) => {
    if (isAllCompanyExisted(getState())) return Promise.resolve();

    dispatch({ type: 'getAllCompany_start' });
    try {
      const resp = await getAllCompany();
      if (resp.data.length > 0) {
        dispatch({
          type: 'getAllCompany_success',
          data: sortBy(resp.data, [(o) => o.legalName])
        });
      }
    } catch (error) {
      dispatch({ type: 'getAllCompany_error', data: error.message });
    }
  };
};

const isCurrentCompanyExisted = (state) => {
  const { currentCompany } = state.company;
  if (!currentCompany) {
    return true;
  }
  return false;
};

export const getCurrentCompanyAction = (refresh = false) => {
  return async (dispatch, getState) => {
    if (!isCurrentCompanyExisted(getState()) && !refresh) {
      return Promise.resolve();
    }
    const { currentUser } = getState().user;
    if (currentUser.company) {
      const response = await getCompany(currentUser.company._id);
      const appServicesResp = await getCompanyAppServices();
      dispatch({
        type: 'getCurrentCompany_success',
        data:
          response.data.length > 0
            ? { ...response.data[0], appServices: appServicesResp.data }
            : null
      });
    } else {
      const companyId = currentUser.companyWaitList;
      if (!companyId) return Promise.resolve();

      dispatch({ type: 'getCurrentCompany_start' });
      try {
        const response = await getCompany(companyId);
        dispatch({
          type: 'getCurrentCompany_success',
          data: response.data.length > 0 ? response.data[0] : null
        });
      } catch (error) {
        dispatch({ type: 'getCurrentCompany_error', data: error.message });
      }
    }
  };
};

export const verifyCompanySelectionAction = () => {
  return async (dispatch) => {
    const loggedCompany = getLoggedCompany();
    if (loggedCompany) {
      dispatch({
        type: 'verifyLoggedCompany_success',
        data: loggedCompany
      });
      return Promise.resolve();
    }

    return dispatch(push(routeConfig.ACCOUNT_SELECTION));
  };
};

export const doCompanyCheckAction = (companyName, accountNumber) => {
  return async () => {
    try {
      const response = await checkCompany(companyName, accountNumber);
      if (!response) {
        throw new Error('The company name or account number is not valid');
      }
      const company = response.data?.[0];
      setLoggedCompany(company);
      setLoggedAccount(
        company.account.find((acc) => acc.number === accountNumber)
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const setWorkingCompanyAction = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: 'workingCompany_global', companyId });
  };
};

export const getCarrierCompanyAction = () => {
  return async (dispatch, getState) => {
    if (getState().company.carrier.length > 0) return Promise.resolve();

    dispatch({ type: 'getCarrierCompany_start' });

    try {
      if (getState().company.allCompany.length === 0) {
        await dispatch(getAllCompanyAction());
      }

      const allCompany = getState().company.allCompany;
      let filteredCompany = allCompany.filter((company) =>
        company.companyType.find((type) =>
          type.companyType.toLowerCase().includes('carrier')
        )
      );

      filteredCompany = filteredCompany.map((company) => company.legalName);
      dispatch({ type: 'getCarrierCompany_success', carrier: filteredCompany });
    } catch (error) {
      dispatch({ type: 'getCarrierCompany_error' });
    }
  };
};

export const disableCompanyAction = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: 'disableCompany_start' });
    try {
      await disableCompany({ companyId });
      dispatch({ type: 'disableCompany_success' });
    } catch (error) {
      dispatch({ type: 'disableCompany_error' });
    }
  };
};

export const addCompanyPartnerAction = (company) => {
  return {
    type: 'addCompanyPartner',
    data: company
  };
};

export const getCompaniesAction = (filter) => {
  return async () => {
    try {
      const response = await getCompanies(filter);
      return response.data;
    } catch (err) {
      return [];
    }
  };
};

export const searchEmployeesAction = (filter) => {
  return async (dispatch) => {
    const response = await getEmployees(filter);
    dispatch({
      type: 'searchEmployee_success',
      data: response.data
    });
  };
};

export const verifyCompanySubscriptionAction = () => {
  return async (dispatch) => {
    const response = await getCompanySubscription();
    dispatch({
      type: 'getSubscription_success',
      data: response.data
    });
  };
};

export const checkSurveyAction = () => {
  return async (_, getState) => {
    const { user, company } = getState();
    const currentUser = user.currentUser;
    const currentCompany = company.currentCompany;

    const SurveyError = (type) => {
      const error = new Error();
      error.name = 'SurveyError';
      error.type = type;
      return error;
    };

    if (
      currentUser?.userType.includes('seller') ||
      currentUser?.userType.includes('buyer')
    ) {
      if (!currentCompany?.completedSurvey) throw SurveyError('sourcing');
    }
    if (currentCompany.companyType?.some((t) => t.companyType === 'Vendor')) {
      if (!currentCompany?.completedSurvey) throw SurveyError('vendor');
    }
  };
};
