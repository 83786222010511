import toast from 'shared/utils/toast';
import { createReducer } from 'redux-act';
import { createActionCRUDResources } from 'shared/utils/reducerHelper';

import * as integrateWareSetupServices from 'services/integratedShipping/integrateWareSetup';

const initialState = {
  fetchLoading: false,
  createLoading: false,
  data: [],
  error: false,
  createError: false,
  message: '',
  createMessage: ''
};

const integrateWareSetupAction = createActionCRUDResources(
  'integrate ware setup'
);

export default createReducer(
  {
    [integrateWareSetupAction.fetch.start]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [integrateWareSetupAction.fetch.success]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [integrateWareSetupAction.fetch.error]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    }),
    [integrateWareSetupAction.create.start]: (state) => ({
      ...state,
      createLoading: true,
      createError: false
    }),
    [integrateWareSetupAction.create.success]: (state) => ({
      ...state,
      createLoading: false
    }),
    [integrateWareSetupAction.create.error]: (state, payload) => ({
      ...state,
      createLoading: false,
      createError: true,
      createMessage: payload
    })
  },
  initialState
);

export const fetchIntegrateWareSetupTableCreator = (companyId, param) => {
  return async (dispatch) => {
    try {
      dispatch(integrateWareSetupAction.fetch.start());
      const response = await integrateWareSetupServices.fetchIntegrateWareSetupTblData(
        companyId,
        param
      );
      dispatch(integrateWareSetupAction.fetch.success(response.data));
      return true;
    } catch (error) {
      dispatch(integrateWareSetupAction.fetch.error());
    }
    return false;
  };
};

export const createIntegrateWareSetupCreator = (companyId, data) => {
  return async (dispatch) => {
    try {
      dispatch(integrateWareSetupAction.create.start());
      const response = await integrateWareSetupServices.createIntegrateWareSetup(
        {
          ...data,
          companyId
        }
      );
      toast.success('Integrate ware setup has been created');
      dispatch(integrateWareSetupAction.create.success(response));
      return response;
    } catch (error) {
      dispatch(integrateWareSetupAction.create.error());
    }
    toast.error('Error occurred when creating Integrate ware setup');
    return false;
  };
};

export const updateIntegrateWareSetupCreator = (
  integrateWareSetupId,
  companyId,
  data
) => {
  return async (dispatch) => {
    try {
      dispatch(integrateWareSetupAction.update.start());
      const response = await integrateWareSetupServices.updateIntegrateWareSetup(
        integrateWareSetupId,
        {
          ...data,
          companyId
        }
      );
      toast.success('Integrate ware setup has been updated');
      dispatch(integrateWareSetupAction.update.success(response));
      return response;
    } catch (error) {
      dispatch(integrateWareSetupAction.update.error());
    }
    toast.error('Error occurred when updating Integrate ware setup');
    return false;
  };
};

export const deleteIntegrateWareSetupCreator = (integrateWareSetupId) => {
  return async (dispatch) => {
    try {
      dispatch(integrateWareSetupAction.delete.start());
      await integrateWareSetupServices.deleteIntegrateWareSetup(
        integrateWareSetupId
      );
      toast.success('Integrate ware setup has been deleted');
      dispatch(integrateWareSetupAction.delete.success());
      return true;
    } catch (error) {
      dispatch(integrateWareSetupAction.delete.error());
    }
    toast.error('Error occurred when deleting Integrate ware setup');
    return false;
  };
};
