import http from 'shared/utils/http';

export const getTaskLinks = (params = {}) => {
  return http.get('/integrated-shipping/task-link', params);
};

export const getTaskLink = (id) => {
  return http.get(`/integrated-shipping/task-link/${id}`);
};

export const getTasks = (filter) => {
  return http.get('/integrated-shipping/task', filter);
};

export const createTaskLink = (
  companyId,
  tasks,
  contractLinkId = undefined
) => {
  return http.post('/integrated-shipping/task-link', {
    companyId,
    tasks,
    contractLinkId
  });
};

export const deleteTaskLink = (linkId) => {
  return http.delete(`/integrated-shipping/task-link/${linkId}`);
};

export const importTaskLinks = (taskLinks) => {
  return http.post('/integrated-shipping/task-link/import', { taskLinks });
};

export const updateTask = (id, task, taskType) => {
  return http.put(`/integrated-shipping/task-link/task/${id}`, {
    task,
    taskType
  });
};

export const getSubTasks = (id) => {
  return http.get(`/integrated-shipping/task/${id}/sub-tasks`);
};

export const getFreightLinks = (params = {}) => {
  return http.get(`/netwila-core/freight-link`, params);
};

export const createFreightLink = (data) => {
  return http.post('/netwila-core/freight-link', data);
};

export const deleteFreightLink = (id) => {
  return http.delete(`/netwila-core/freight-link/${id}`);
};

export const updateFreightLink = (id, data) => {
  return http.put(`/netwila-core/freight-link/${id}`, data);
};
