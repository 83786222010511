import { createApiAction, createReducer } from 'shared/utils/redux';
import { LOCATION_CHANGE } from 'connected-react-router';

import { getModes } from 'services/mode';
import {
  getMonthlyPlanning,
  updateMonthlyPlanning
} from 'services/demand/planning/monthlyControlPlanning';
import { getRemainingCaps } from 'services/demand/planning/annualVolume';
import { getContracts } from 'services/contract';

const OCEAN_MODE = 'Ocean';
const initialState = {
  contracts: [],
  allocations: [],
  remainingCaps: 0
};

export const oceanModeSelector = (state) =>
  state.oceanIntermodal.monthlyAllocation.mode;

export const monthlyAllocationSelector = (state) =>
  state.oceanIntermodal.monthlyAllocation.allocations;

export const contractsSelector = (state) =>
  state.oceanIntermodal.monthlyAllocation.contracts;

export const remainingCapsSelector = (state) =>
  state.oceanIntermodal.monthlyAllocation.remainingCaps;

export const getOceanModeAction = createApiAction('getOceanMode', () => ({
  callAPI: () => getModes({ mode: OCEAN_MODE })
}));

export const getMonthlyPlanningAction = createApiAction(
  'getMonthlyPlanning',
  (filter) => ({
    callAPI: () => getMonthlyPlanning(filter)
  })
);

export const getContractsAction = createApiAction(
  'getContract',
  (companyId) => ({
    callAPI: () =>
      getContracts({
        parties: [companyId]
      })
  })
);

export const updateMonthlyPlanningAction = createApiAction(
  'updateMonthlyPlanning',
  (data) => ({
    callAPI: () => updateMonthlyPlanning(data)
  })
);

export const getRemainingCapsAction = createApiAction(
  'getRemainingCaps',
  (filter) => ({
    callAPI: () => getRemainingCaps(filter)
  })
);

export default createReducer(initialState, {
  [getOceanModeAction.success]: (state, { response }) => ({
    ...state,
    mode: response.data.find((mode) => mode.mode === OCEAN_MODE)
  }),
  [getMonthlyPlanningAction.success]: (state, { response }) => ({
    ...state,
    allocations: response.data
  }),
  [getContractsAction.success]: (state, { response }) => ({
    ...state,
    contracts: response.data
  }),
  [getRemainingCapsAction.success]: (state, { response }) => ({
    ...state,
    remainingCaps: response.data
  }),
  [LOCATION_CHANGE]: () => initialState
});
