import React, { useEffect } from 'react';
import { Spin, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { useQuery } from 'shared/utils/router';
import { routeConfig } from 'configs/route';
import { verifyConfirmEmail } from 'services/company';
import { dispatchService } from 'shared/utils/service';

export default () => {
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    const token = query.get('token');
    const companyId = query.get('companyId');
    const userId = query.get('userId');
    return dispatchService(
      () => verifyConfirmEmail(token, companyId, userId),
      (_) => {
        notification.success({ message: "You're all signed up!" });
        dispatch(push(routeConfig.LOGIN));
      },
      (_) => {
        notification.error({ message: 'Token is not valid' });
        dispatch(push(routeConfig.LOGIN));
      }
    );
  }, [dispatch, query]);

  return <Spin />;
};
