import styled from "styled-components";

import {  font } from "shared/utils/styles";

export const Container = styled.div`
  margin: 10px 0;
`;

export const ProgressBarGroupContainer = styled.div`
    display:flex
`;
export const ProgressBarContainer = styled.div`
    width: ${(props) => props.width};
  progress[value] {
    width: 98%;
    appearance: none;

    ::-webkit-progress-bar {
      height: 10px;
      border-radius: 20px;
      background-color: #eee;
    }

    ::-webkit-progress-value {
      height: 10px;
      border-radius: 20px;
      background-color: ${(props) => props.color};
    }
  }
`;
export const Label = styled.div`
  display: block;
  padding-bottom: 5px;
  color: black;
  text-align: left;
  ${font.size(16)}
`;
