import { createReducer, createApiAction } from 'shared/utils/redux';

import {
  createDataFlow,
  getDataFlows,
  runDataFlow
} from 'services/etl/data-flow';

const initialState = {
  dataFlows: [],
  dataFlowMeta: null,
  error: null
};

export const getDataFlowStateSelector = (state) => state.etl.dataFlow;

export const getDataFlowsSelector = (state) => {
  const { dataFlows, dataFlowMeta } = getDataFlowStateSelector(state);
  return { dataFlows, dataFlowMeta };
};

export const createDataFlowAction = createApiAction(
  'createDataFlowAction',
  (data) => ({
    callAPI: () => createDataFlow(data)
  })
);

export const getDataFlowsAction = createApiAction('getDataFlowsAction', () => ({
  callAPI: () => getDataFlows()
}));

export const runDataFlowAction = createApiAction('runDataFlowAction', (id) => ({
  callAPI: () => runDataFlow(id)
}));

export default createReducer(initialState, {
  [getDataFlowsAction.success]: (state, { response }) => ({
    ...state,
    dataFlows: response.data,
    dataFlowMeta: response.meta
  }),
  [createDataFlowAction.failure]: (state, { error }) => ({
    ...state,
    error: error?.message
  })
});
