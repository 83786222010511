import { createAction, createReducer } from 'redux-act';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading: false,
  data: [],
  error: false
};

const fetchAnualOceanCapacityAllocation = createAction(
  'fetch anual ocean capacity allocation'
);
const fetchAnualOceanCapacityAllocationSuccess = createAction(
  'fetch anual ocean capacity allocation success'
);
const fetchAnualOceanCapacityAllocationError = createAction(
  'fetch anual ocean capacity allocation error'
);

export default createReducer(
  {
    [fetchAnualOceanCapacityAllocation]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchAnualOceanCapacityAllocationSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchAnualOceanCapacityAllocationError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    })
  },
  initialState
);

export const fetchAnnualOceanCapacityAllAction = (values) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAnualOceanCapacityAllocation());
      await delay(1000);
      dispatch(fetchAnualOceanCapacityAllocationSuccess(values));
    } catch (error) {
      dispatch(fetchAnualOceanCapacityAllocationError());
    }
  };
};
