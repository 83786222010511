import http from 'shared/utils/http';

export const getDocumentType = () => {
  return http.get('/netwila/document/type');
};

export const addDocument = (data) => {
  return http.post('/netwila/document', data);
};

export const deleteDocument = (data) => {
  return http.delete('/netwila/document', data);
};

export const downloadDocument = (key) => {
  return http.get('/netwila/document', { key });
};

export const downloadDocumentById = (id) => {
  return http.get(`/netwila-core/document/${id}`);
};

export const uploadDocument = (data) => {
  return http.post('/netwila-core/document', data);
};

export const verifyDocument = (data) => {
  return http.post('/netwila-core/document/verify', data);
};
