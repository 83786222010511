import { getMenu } from 'services/dashboard';

const initialState = {
  loading: false,
  message: '',
  networkManagement: [],
  logistics: [],
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getNetworkManagement_start':
      return {
        ...state,
        message: action.message,
        error: false
      };
    case 'getNetworkManagement_success':
      return {
        ...state,
        error: false,
        networkManagement: action.data
      };
    case 'getNetworkManagement_error':
      return {
        ...state,
        message: action.message,
        error: true
      };
    case 'getLogistics_start':
      return {
        ...state,
        message: action.message,
        error: false
      };
    case 'getLogistics_success':
      return {
        ...state,
        error: false,
        logistics: action.data
      };
    case 'getLogistics_error':
      return {
        ...state,
        message: action.message,
        error: true
      };
    default:
      return state;
  }
};

export const getNetworkManagementAction = (menuId, menuTitle) => {
  return (dispatch) => {
    dispatch({ type: 'getNetworkManagement_start' });
    getMenu(menuId, menuTitle)
      .then((res) => {
        dispatch({
          type: 'getNetworkManagement_success',
          data: res.data.length === 0 ? [] : res.data[0].menuSub
        });
      })
      .catch((err) => {
        dispatch({ type: 'getNetworkManagement_error', data: err });
      });
  };
};

export const getLogisticsAction = (menuId, menuTitle) => {
  return (dispatch) => {
    dispatch({ type: 'getLogistics_start' });
    getMenu(menuId, menuTitle)
      .then((res) => {
        dispatch({
          type: 'getLogistics_success',
          data: res.data.length === 0 ? [] : res.data[0].menuSub
        });
      })
      .catch((err) => {
        dispatch({ type: 'getLogistics_error', data: err });
      });
  };
};
