import { createAction, createReducer } from 'redux-act';
import {
  createPurchaseOrder,
  getPurchaseOrder,
  getOrderStatus,
  getLoadTypes
} from 'services/orders';
import { getCountries } from 'services/location';
import LoadableComponent from 'components/LoadableComponent';
import { openModal } from 'reducers/modalLot';
import { sortByNewest } from 'shared/utils/javascript';

const initialState = {
  isLoading: false,
  po: [],
  poList: [],
  isFetchingPOList: false,
  isFetchingPODetail: false
};

const createPurchaseOrderStartAction = createAction(
  'create purchase order start'
);
const createPurchaseOrderSuccessAction = createAction(
  'create purchase order success'
);
const createPurchaseOrderErrorAction = createAction(
  'create purchase order error'
);

const getPurchaseOrderStartAction = createAction('get purchase order start');
const getPurchaseOrderSuccessAction = createAction(
  'get purchase order success'
);
const getPurchaseOrderErrorAction = createAction('get purchase order error');

const getPurchaseOrderDetailStartAction = createAction(
  'get purchase order detail start'
);
const getPurchaseOrderDetailSuccessAction = createAction(
  'get purchase order detail success'
);
const getPurchaseOrderDetailErrorAction = createAction(
  'get purchase order detail error'
);

const loadPurchaseOrderAction = createAction('load purchase order from file');
const resetPurchaseOrder = createAction('reset purchase order');

export default createReducer(
  {
    [createPurchaseOrderStartAction]: (state) => ({
      ...state,
      isLoading: true
    }),
    [createPurchaseOrderSuccessAction]: (state) => ({
      ...state,
      isLoading: false
    }),
    [createPurchaseOrderErrorAction]: (state) => ({
      ...state,
      isLoading: false
    }),
    [loadPurchaseOrderAction]: (state, po) => ({
      ...state,
      po
    }),
    [getPurchaseOrderStartAction]: (state) => ({
      ...state,
      isFetchingPOList: true
    }),
    [getPurchaseOrderSuccessAction]: (state, poList) => ({
      ...state,
      isFetchingPOList: false,
      poList: sortByNewest(poList, 'updatedAt')
    }),
    [getPurchaseOrderErrorAction]: (state) => ({
      ...state,
      isFetchingPOList: false
    }),
    [getPurchaseOrderDetailStartAction]: (state) => ({
      ...state,
      isFetchingPODetail: true
    }),
    [getPurchaseOrderDetailSuccessAction]: (state, detail) => ({
      ...state,
      isFetchingPODetail: false,
      [detail._id]: { ...detail }
    }),
    [getPurchaseOrderDetailErrorAction]: (state) => ({
      ...state,
      isFetchingPODetail: false
    }),
    [resetPurchaseOrder]: (state) => ({
      ...state,
      po: []
    })
  },
  initialState
);

export const createPurchaseOrderAction = (purchaseOrders) => {
  return async (dispatch) => {
    try {
      dispatch(createPurchaseOrderStartAction());
      const response = await createPurchaseOrder(purchaseOrders);
      dispatch(createPurchaseOrderSuccessAction());
      return response.data;
    } catch (err) {
      dispatch(createPurchaseOrderErrorAction());
    }
  };
};

export const getPurchaseOrderAction = (filter = {}) => {
  return async (dispatch) => {
    try {
      dispatch(getPurchaseOrderStartAction());
      const response = await getPurchaseOrder(filter);
      dispatch(getPurchaseOrderSuccessAction(response.data));
    } catch (error) {
      dispatch(getPurchaseOrderErrorAction());
    }
  };
};

export const getPurchaseOrderDetailAction = (poId) => {
  return async (dispatch) => {
    try {
      dispatch(getPurchaseOrderDetailStartAction());
      const response = await getPurchaseOrder({ itemPOId: poId });
      dispatch(getPurchaseOrderDetailSuccessAction(response.data[0]));
    } catch (error) {
      dispatch(getPurchaseOrderDetailErrorAction());
    }
  };
};

export const openPOExplorerAction =
  (selectedPO, filterConds) => async (dispatch) => {
    const Modal = LoadableComponent(() =>
      import('pages/Orders/PurchaseOrder/POExplorer')
    );
    return dispatch(openModal(Modal, { selectedPO, filterConds }));
  };

export const getCountriesAction = () => async () => {
  const response = await getCountries();
  return response.data;
};

export const getLoadTypesAction = () => async () => {
  const response = await getLoadTypes();
  return response.data;
};

export const getOrderStatusAction = () => async () => {
  const response = await getOrderStatus();
  return response.data;
};

export { loadPurchaseOrderAction, resetPurchaseOrder };
