/* eslint-disable no-unused-vars */
import {
  getDocumentType,
  addDocument,
  deleteDocument,
  downloadDocument
} from 'services/document';
import { saveAs } from 'file-saver';

const initialState = {
  loading: false,
  isWorking: false,
  message: '',
  documentType: [],
  document: [],
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getDocumentType_success':
      return {
        ...state,
        documentType: action.data,
        loading: false
      };
    case 'uploadDocument_start':
      return {
        ...state,
        isWorking: true
      };
    case 'uploadDocument_success':
      return {
        ...state,
        document: state.document.concat(action.data),
        isWorking: false
      };
    case 'uploadDocument_error':
      return {
        ...state,
        isWorking: false
      };
    default:
      return state;
  }
};

export const getDocumentTypeAction = () => {
  return (dispatch) => {
    dispatch({ type: 'getDocumentType_start' });
    getDocumentType()
      .then((res) => {
        dispatch({ type: 'getDocumentType_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getDocumentType_error', message: err.message });
      });
  };
};

export const uploadDocumentAction = (document, dispatchType, sideEffect) => {
  return (dispatch) => {
    dispatch({ type: 'uploadDocument_start' });
    addDocument(document)
      .then((res) => {
        dispatch({
          type: 'uploadDocument_success',
          data: res.data
        });
        sideEffect();
        dispatch({ type: dispatchType, data: res.data });
        return true;
      })
      .catch((err) => {
        dispatch({ type: 'uploadDocument_error', data: err.message });
      });
    return false;
  };
};

export const addDocumentAction = (document) => {
  return async () => {
    const response = await addDocument(document);
    return response.data;
  };
};

export const deleteDocumentAction = (data) => {
  return () => {
    deleteDocument(data)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };
};

export const getDownloadUrlAction = (document) => {
  return async () => {
    const url = await downloadDocument(document.document);
    return url;
  };
};

export const downloadDocumentAction = (document) => {
  return async () => {
    const response = await downloadDocument(document.document);
    saveAs(response, document.name);
  };
};
