import React from 'react';
import { Checkbox, Row, Popover, Input } from 'antd';

import { PopoverArea, PopoverHeader, ExpandBtn } from './Styles';
import Icon from '../Icon';
import { uniqueId } from 'lodash';

const PopoverFilter = ({
  columns,
  hiddenColumns,
  searchColumnNameTxt,
  setSearchColumnNameTxt,
  onChangeSelectedColumns
}) => {
  return (
    <Popover
      trigger='click'
      style={{ width: 250, height: 400 }}
      placement='bottomLeft'
      content={() => (
        <PopoverArea>
          {columns
            .filter((col) => {
              if (searchColumnNameTxt === '') return true;
              return (
                col.title
                  .toLowerCase()
                  .indexOf(searchColumnNameTxt.toLowerCase()) !== -1
              );
            })
            .map((c) => {
              if (!c.title) return null;

              return (
                <Row key={uniqueId('key')}>
                  <Checkbox
                    onChange={(e) =>
                      onChangeSelectedColumns(c.key, e.target.checked)
                    }
                    checked={!hiddenColumns[c.key]}
                    style={{ fontSize: 14 }}
                    value={c.dataIndex}
                    key={c.dataIndex}
                  >
                    {c.title}
                  </Checkbox>
                </Row>
              );
            })}
        </PopoverArea>
      )}
      title={() => (
        <PopoverHeader>
          <Input.Search
            value={searchColumnNameTxt}
            placeholder='Search Column Name'
            onChange={(event) => {
              setSearchColumnNameTxt(event.target.value);
            }}
          />
        </PopoverHeader>
      )}
    >
      <ExpandBtn type='link'>
        <Icon top={2} type='plus' /> Expand Sheet
      </ExpandBtn>
    </Popover>
  );
};

export default PopoverFilter;
