import { fetchMappedEdi } from 'services/edi/edi';
import { createApiAction, createReducer } from 'shared/utils/redux';

const initialState = {
  fetchLoading: false,
  error: false,
  data: [],
  meta: {}
};

export const ediMappingSelector = (state) => ({
  data: state.edi.edi.data,
  meta: state.edi.edi.meta
});

export const fetchMappedEdiAction = createApiAction(
  'fetchMappedEdiAction',
  (companyId) => ({
    callAPI: () => fetchMappedEdi(companyId)
  })
);

export default createReducer(initialState, {
  [fetchMappedEdiAction.request]: (state) => ({
    ...state,
    fetchLoading: true
  }),
  [fetchMappedEdiAction.success]: (state, { response }) => ({
    ...state,
    data: response.data,
    meta: response.meta,
    fetchLoading: false,
    error: false
  }),
  [fetchMappedEdiAction.failure]: (state) => ({
    ...state,
    fetchLoading: false,
    data: [],
    error: true
  })
});
