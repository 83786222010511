import { createActionResources, createReducer } from 'redux-waiters';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  data: [],
  error: false
};

export const fetchTotalProgramCapacityAction = createActionResources(
  'fetch total program capacity'
);

export default createReducer(
  {
    [fetchTotalProgramCapacityAction.start]: (state) => ({
      ...state,
      error: false
    }),
    [fetchTotalProgramCapacityAction.success]: (state, payload) => ({
      ...state,
      data: payload || [],
      error: false
    }),
    [fetchTotalProgramCapacityAction.error]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: []
    })
  },
  initialState
);

export const fetchTotalProgramCapacityCreator = () =>
  fetchTotalProgramCapacityAction.waiterAction(async (dispatch) => {
    try {
      dispatch(fetchTotalProgramCapacityAction.start());
      await delay(1000);
      dispatch(fetchTotalProgramCapacityAction.success());
    } catch (error) {
      dispatch(fetchTotalProgramCapacityAction.error(error));
    }
  });
