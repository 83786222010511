import styled from 'styled-components';
import { Layout } from 'antd';

import { color } from 'shared/utils/styles';

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  justify-content: center;
  background-color: ${color.primary};
`;
