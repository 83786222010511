import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from 'store';

import fr from './locale/fr';
import en from './locale/en';
import es from './locale/es';

const lang = store.get('lang') || 'en';

const resources = { en, fr, es };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    defaultNS: 'common',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
