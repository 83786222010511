import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

import { StyledSearchInput, SearchButton, StyledForm } from './Styles';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  invalid: PropTypes.bool,
  isWorking: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  invalid: false,
  isWorking: false,
  onClick: () => {},
  onChange: () => {}
};

const SearchInput = forwardRef(
  ({ className, onChange, onClick, isWorking, ...inputProps }, ref) => {
    const [enteredValue, setEnteredValue] = useState('');
    const handleChange = (value) => {
      setEnteredValue(value);
      onChange(value);
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      onClick(enteredValue);
    };
    return (
      <StyledSearchInput className={className}>
        <StyledForm onSubmit={handleSubmit}>
          <Input {...inputProps} onChange={handleChange} ref={ref} />
          <SearchButton
            icon='search'
            variant='primary'
            isWorking={isWorking}
            type='submit'
          >
            Search
          </SearchButton>
        </StyledForm>
      </StyledSearchInput>
    );
  }
);

SearchInput.propTypes = propTypes;
SearchInput.defaultProps = defaultProps;

export default SearchInput;
