import { getMessages , getSelectedMessage, getModules, getSubModules , createMessage, updateMessage} from 'services/criticalMessages';

const initialState = {
  data: [],
  message: '',
  fetchAllMessages: false,
  fetchMessage: false,
  priorities: [
    {
      priority_id: 'P451',
      priority_name: 'High Priority'
    },
    {
      priority_id: 'P452',
      priority_name: 'Low Priority'
    },
    {
      priority_id: 'P453',
      priority_name: 'Announcements'
    }
  ],
  skip: 5,
  limit: 20,
  total: 60,
  search: '',
  filter: {
    type: 'All'
  },
  modules: [],
  subModules: [],
  selectedMessage: {},
  created: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getMessages_start':
      return {
        ...state,
        fetchAllMessages: action.data
      };
      case 'getMessages_succes':
      return {
        ...state,
        data: action.data,
        fetchAllMessages: false
      };
    case 'getMessages_error':
      return {
        ...state,
        data: [],
        fetchAllMessages: false
      };
    case 'getModules_start':
      return {
        ...state,
        modules: action.data
      };
    case 'getSubModules_start':
      return {
        ...state,
        subModules: action.data
      };
    case 'createMessage':
      return {
        ...state,
        message: action.data,
        created: true
      };
    case 'filterMessageByStatus':
      return {
        ...state,
        filter: {
          status: action.data
        }
      };
    case 'filterMessageByType':
      return {
        ...state,
        filter: {
          ...state.filter, // keep filter status condition
          type: action.data
        }
      };
      case 'filterMessageBySearch':
      return {
        ...state,
         // keep filter status condition
          search: action.data
      };
    case 'selectedMessage_start':
      return {
        ...state,
        fetchMessage: action.data
      };
    case 'selectedMessage_success':
      return {
        ...state,
        selectedMessage: action.data,
        fetchMessage: false
      };
    case 'priorityType':
      return {
        ...state
      };
    case 'setCreateFalse':
      return {
        ...state,
        created: false
      };
    default:
      return state;
  }
};

export const getMessagesAction = (filter) => {
  return (dispatch) => {
    dispatch({ type: 'getMessages_start', data: true });
    getMessages(filter)
      .then((res) => {
        dispatch({ type: 'getMessages_succes', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getMessages_error', data: err });
      });
  };
};

export const getModulesAction = () => {
  return (dispatch) => {
    getModules()
      .then((res) => {
        dispatch({ type: 'getModules_start', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getModules_error', data: err });
      });
  };
};

export const getSubModulesAction = (module) => {
  return (dispatch) => {
    getSubModules(module)
      .then((res) => {
        dispatch({ type: 'getSubModules_start', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getSubModules_error', data: err });
      });
  };
};

export const createMessageAction = (message) => {
  return (dispatch) => {
    createMessage(message)
      .then((res) => {
        dispatch({ type: 'createMessage', data: res.meta.message });
        dispatch({ type: 'selectedMessage_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'createMessage', data: err });
      });
  };
};

export const filterMessageByStatusAction = (status) => {
  return (dispatch) =>
    dispatch({ type: 'filterMessageByStatus', data: status });
};

export const filterMessageByTypeAction = (type) => {
  return (dispatch) => dispatch({ type: 'filterMessageByType', data: type });
};

export const filterMessageBySearchAction = (type) => {
  return (dispatch) => dispatch({ type: 'filterMessageBySearch', data: type });
};

export const selectMessageAction = (message) => {
  return (dispatch) => {
    dispatch({ type: 'selectedMessage_start', data: true });
    getSelectedMessage(message)
      .then((res) => {
        dispatch({ type: 'selectedMessage_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'selectedMessageError', data: err });
      });
  };
};

export const updateMessageAction = (id , message) => {
  return (dispatch) => {
    updateMessage(id, message)
      .then((res) => {
        dispatch({ type: 'updateMessage', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'updateMessage', data: err });
      });
  };
};

export const getPriorityAction = () => {
  return (dispatch) => dispatch({ type: 'priorityType'});
};

export const setCreatedAction = () => {
  return (dispatch) => dispatch({ type: 'setCreateFalse'});
};
