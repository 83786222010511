import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from 'shared/components';

export const LogoLink = styled(NavLink)`
  display: block;
  position: relative;
  left: 0;
  text-align: center;
  transition: left 0.1s;
  border-bottom: solid white 1px;
  height: 64px;
`;

export const StyledLogo = styled(Icon)`
  font-size: 20px;
  color: #fff;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white;
  height: 64px;
`;

export const Bottom = styled.div`
  background-color: #1f4370;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
