/* eslint-disable no-unused-vars */
import http from 'shared/utils/http';

const NOTIFICATIONS = [
  /*
  {
    id: 1,
    actor: {
      id: 1,
      profile: {
        displayName: 'John Doe',
        link: ''
      }
    },
    entity: {
      id: 1,
      type: 1, // create new contract,
      description: 'Bob'
    },
    notifiers: [
      {
        id: 2,
        profile: {
          displayName: '',
          link: ''
        }
      },
      {
        id: 2,
        profile: {
          displayName: '',
          link: ''
        }
      }
    ]
  }
  */
];

export const getNotificationCount = () => {
  // TODO: Call backend api to get notification count
  return Promise.resolve({
    data: 1
  });
};

export const getNotifications = () => {
  return http.get('/netwila-core/noti');
};

export const markAllAsRead = () => {
  return http.post('/netwila-core/noti/read');
};
