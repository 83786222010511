import { combineReducers } from 'redux';

import intermodalYardPlanningReducer from './intermodalYardPlanning';
import automotiveYardPlanningReducer from './AutomotiveYardPlanning';
import automotiveYardTaskReducer from './automotiveYardTask';
import invoiceReducer from './invoice';

export default () =>
  combineReducers({
    intermodalYardPlanning: intermodalYardPlanningReducer,
    automotiveYardPlanning: automotiveYardPlanningReducer,
    automotiveYardTask: automotiveYardTaskReducer,
    invoice: invoiceReducer
  });
