import {
  getLocation,
  addNewLocation,
  updateLocation,
  getCountries,
  getLocationType,
  getLatLongByAddress,
  findLocation,
  searchLocations2
} from 'services/location';

import { uniq } from 'lodash';

const initialState = {
  loading: false,
  message: '',
  allLocations: [],
  countries: [],
  states: [],
  locationType: [],
  filteredLocation: null,
  existedLocations: [],
  isFetchingFilteredLocation: false,
  error: false,
  basePort: [],
  isFetchingBasePort: false
};

export const getLocationSelector = (state) => state.location;
export const getLocationTypeSelector = (state) => {
  return getLocationSelector(state).locationType;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getLocation_success':
      return {
        ...state,
        allLocations: action.data,
        loading: false
      };

    case 'getLocationsByCityAndState_start':
      return {
        ...state,
        isFetchingFilteredLocation: true
      };

    case 'getLocationsByCityAndState_success':
      return {
        ...state,
        isFetchingFilteredLocation: false,
        filteredLocation: action.filteredLocation,
        existedLocations: action.existedLocations
      };

    case 'getLocationsByCityAndState_error':
      return {
        ...state,
        isFetchingFilteredLocation: false
      };

    case 'addNewLocation_start':
      return {
        ...state,
        loading: true
      };

    case 'addNewLocation_success':
      return {
        ...state,
        loading: false,
        allLocations: state.allLocations.concat(action.data)
      };
    case 'addNewLocation_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      };
    case 'getCountries_start':
      return {
        ...state,
        loading: true
      };
    case 'getCountries_success':
      return {
        ...state,
        countries: action.data,
        loading: false
      };
    case 'getLocationType_success':
      return {
        ...state,
        locationType: action.data,
        loading: false
      };
    case 'getStatesByCountry':
      return {
        ...state,
        states:
          state.countries.find(
            (country) =>
              country._id === action.data || country.name === action.data
          )?.state ?? []
      };
    case 'getBasePort_start':
      return {
        ...state,
        isFetchingBasePort: true
      };
    case 'getBasePort_success':
      return {
        ...state,
        basePort: action.data,
        isFetchingBasePort: false
      };
    case 'getBasePort_error':
      return {
        ...state,
        isFetchingBasePort: false
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

const shouldFetchCountries = (state) => {
  if (state.location.countries.length > 0) return false;
  if (state.location.loading) return false;
  return true;
};

export const getLocationAction = () => {
  return (dispatch) => {
    dispatch({ type: 'getLocation_start' });
    getLocation()
      .then((res) => {
        dispatch({ type: 'getLocation_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getLocation_error', message: err.message });
      });
  };
};

export const updateLocationAction = (location) => {
  return (dispatch) => {
    dispatch({ type: 'updateLocation_start' });
    updateLocation(location)
      .then((res) => {
        dispatch({ type: 'updateLocation_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'updateLocation_error', message: err.message });
      });
  };
};

export const getCountriesAction = (defaultCountryCode) => {
  return async (dispatch, getState) => {
    if (!shouldFetchCountries(getState())) return;
    dispatch({ type: 'getCountries_start' });
    return getCountries()
      .then((res) => {
        dispatch({ type: 'getCountries_success', data: res.data });
        if (defaultCountryCode) {
          dispatch({
            type: 'getStatesByCountry',
            data: defaultCountryCode
          });
        }
      })
      .catch((err) => {
        dispatch({ type: 'getLocation_error', message: err.message });
      });
  };
};

const shouldFetchLocationType = (state) => {
  if (state.location.locationType.length > 0) return false;
  if (state.location.loading) return false;
  return true;
};

export const getLocationTypeAction = () => {
  return async (dispatch, getState) => {
    if (!shouldFetchLocationType(getState())) return;
    dispatch({ type: 'getLocationType_start' });
    getLocationType()
      .then((res) => {
        dispatch({ type: 'getLocationType_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getLocationType_error', message: err.message });
      });
  };
};

export const getStatesByCountryAction = (code) => {
  return {
    type: 'getStatesByCountry',
    data: code
  };
};

export const addNewLocationAction = (location) => {
  return async () => {
    const response = await addNewLocation(location);
    return response.data;
  };
};

export const getAllLocationsByCityAndStateAction = () => {
  return async (dispatch, getState) => {
    if (getState().location.filteredLocation) return Promise.resolve();
    dispatch({ type: 'getLocationsByCityAndState_start' });

    try {
      const resp = await getLocation();
      const locations = resp.data.map((location) => ({
        city: location?.city,
        postCode: location?.postCode,
        state: location?.state?.state,
        country: location?.country?.name
      }));
      const existedLocations = uniq(locations).filter(
        (location) =>
          location.city &&
          location.state &&
          location.postCode &&
          location.country
      );
      let state = [];
      let city = [];
      let postCode = [];
      let country = [];

      existedLocations.forEach((location) => {
        if (!state.includes(location.state)) state.push(location.state);
        if (!city.includes(location.city)) city.push(location.city);
        if (!postCode.includes(location.postCode))
          postCode.push(location.postCode);
        if (!country.includes(location.country)) country.push(location.country);
      });
      dispatch({
        type: 'getLocationsByCityAndState_success',
        filteredLocation: { state, city, postCode, country },
        existedLocations
      });
    } catch (error) {
      dispatch({ type: 'getAllLocationsByCityAndState_error' });
    }
  };
};

export const getBasePortAction = (filter) => {
  return (dispatch, getState) => {
    const location = getLocationSelector(getState());
    if (location.isFetchingBasePort) return;
    dispatch({ type: 'getBasePort_start' });
    searchLocations2(filter)
      .then((res) => {
        dispatch({ type: 'getBasePort_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getBasePort_error', message: err.message });
      });
  };
};

export const getLocationsAction = (filter) => {
  return async () => {
    try {
      const response = await getLocation(filter);
      return response.data;
    } catch (err) {
      return [];
    }
  };
};

export const getLatLong = async (address) => {
  try {
    const response = await getLatLongByAddress(address);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const searchLocations = async (data) => {
  try {
    const response = await findLocation(data);
    return response.data;
  } catch (err) {
    return err;
  }
};
