import React, { useState, useMemo, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';

import {
  StyledTable,
  Thead,
  HeaderTbl,
  TablePaginationToolbar
} from './Styles';

import { Button } from 'shared/components';
import PopoverFilter from '../PopoverFilter';

const selectAllCheckbox = {
  title: 'Select All',
  dataIndex: 'selectAll',
  key: 'selectAll'
};

const TableFilter = ({
  defaultColumns,
  data,
  localStorageKey,
  scroll = {
    x: 5000,
    y: null
  },
  hasHeaderColor = true,
  pagination = false,
  rowKey,
  loading = false,
  actionColumnsLeft = [],
  actionColumnsRight = [],
  numItems = 10,
  totalItems = 99999,
  customPagination = null,
  ...restProps
}) => {
  const [searchColumnNameTxt, setSearchColumnNameTxt] = useState('');
  const [hiddenColumns, setHiddenColumns] = useState(() => {
    try {
      const arrCol = JSON.parse(localStorage.getItem(localStorageKey)) || {};

      return arrCol;
    } catch (e) {
      return {};
    }
  });

  const tableId = useRef(uniqueId('table-'));

  const columns = useMemo(() => {
    if (hasHeaderColor) {
      return [
        ...actionColumnsLeft,
        ...defaultColumns
          .filter((col) => !hiddenColumns[col.key])
          .map(({ bgColor, color, title, children = [], ...colProps }) => ({
            ...colProps,
            title: (
              <Thead
                className={children.length ? 'thead-parent' : ''}
                color={color}
                bgColor={bgColor}
              >
                {title}
              </Thead>
            ),
            children: children.map((columnChildren) => ({
              ...columnChildren,
              title: (
                <Thead color={color} bgColor={columnChildren.bgColor}>
                  {columnChildren.title}
                </Thead>
              )
            }))
          })),
        ...actionColumnsRight
      ];
    }
    return defaultColumns.filter((col) => !hiddenColumns[col.key]);
  }, [
    hiddenColumns,
    defaultColumns,
    hasHeaderColor,
    actionColumnsLeft,
    actionColumnsRight
  ]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(hiddenColumns));
  }, [hiddenColumns, localStorageKey]);

  useEffect(() => {
    const tableBody = document.querySelector(
      `#${tableId.current} div.ant-table-container > div`
    );

    const handler = ({ code }) => {
      if (code === 'ArrowRight' || code === 'ArrowLeft') {
        tableBody.scrollBy(code === 'ArrowRight' ? 40 : -40, 0);
      }
    };

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, []);

  const onChangeSelectedColumns = (columnKey, checked) => {
    if (columnKey === 'selectAll') {
      if (checked) {
        setHiddenColumns({});
      } else {
        setHiddenColumns({
          selectAll: true,
          ...defaultColumns.reduce(
            (allHiddencolumns, column) => ({
              [column.key]: true,
              ...allHiddencolumns
            }),
            {}
          )
        });
      }
    } else {
      const newHiddenColumns = {
        ...hiddenColumns,
        [columnKey]: !checked,
        selectAll: false
      };
      const hiddenColumnsLength = Object.values(newHiddenColumns).filter(
        (isHidden) => !!isHidden
      ).length;

      setHiddenColumns((oldCols) => ({
        ...oldCols,
        [columnKey]: !checked,
        selectAll: !!hiddenColumnsLength
      }));
    }
  };
  return (
    <StyledTable
      id={tableId.current}
      bordered={hasHeaderColor}
      size='small'
      columns={columns}
      dataSource={data}
      scroll={scroll}
      pagination={pagination}
      rowKey={rowKey}
      loading={loading}
      hasHeaderColor={hasHeaderColor}
      title={() => (
        <HeaderTbl>
          <TablePaginationToolbar>
            {!customPagination ? (
              <>
                Show {numItems} items of {totalItems}
                <Button icon='chevron-left' iconSize={14} />
                <Button icon='chevron-right' iconSize={14} />
              </>
            ) : (
              customPagination()
            )}
          </TablePaginationToolbar>
          <PopoverFilter
            columns={[selectAllCheckbox, ...defaultColumns]}
            hiddenColumns={hiddenColumns}
            searchColumnNameTxt={searchColumnNameTxt}
            setSearchColumnNameTxt={setSearchColumnNameTxt}
            onChangeSelectedColumns={onChangeSelectedColumns}
          />
        </HeaderTbl>
      )}
      {...restProps}
    />
  );
};

export default TableFilter;
