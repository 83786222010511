import { LOCATION_CHANGE } from 'connected-react-router';

import { createReducer, createApiAction } from 'shared/utils/redux';

import {
  getDataTables,
  updateDataTable,
  importDataTable
} from 'services/etl/data-table';

const initialState = {
  dataTables: [],
  dataTableMeta: {},
  error: null
};

export const getDataTableStateSelector = (state) => state.etl.dataTable;

export const getDataTablesSelector = (state) => {
  const { dataTables, dataTableMeta } = getDataTableStateSelector(state);
  return { dataTables, dataTableMeta };
};

export const getDataTableSelector = (id) => (state) => {
  const { dataTables } = getDataTableStateSelector(state);
  return dataTables.find((table) => {
    return table.id === id;
  });
};

export const getDataTablesAction = createApiAction(
  'getDataTablesAction',
  ({ refresh, ...params }) => ({
    shouldCallAPI: (state) => {
      if (refresh) return true;
      const { dataTables } = getDataTableStateSelector(state);
      return dataTables.length === 0;
    },
    callAPI: () => getDataTables(params)
  })
);

export const updateDataTableAction = createApiAction(
  'updateDataTableAction',
  ({ id, ...data }) => ({
    callAPI: () => updateDataTable(id, data)
  })
);

export const importDataTableAction = createApiAction(
  'importDataTableAction',
  (data) => ({
    callAPI: () => importDataTable(data)
  })
);

export default createReducer(initialState, {
  [getDataTablesAction.success]: (state, { response }) => ({
    ...state,
    dataTables: response.data,
    dataTableMeta: response.meta
  }),
  [importDataTableAction.failure]: (state, { error }) => ({
    ...state,
    error: error.message
  }),
  [LOCATION_CHANGE]: () => initialState
});
