import React from 'react';
import { Popover, List, Badge, Typography } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';

import { dispatchService } from 'shared/utils';
import { getNotifications, markAllAsRead } from 'services/notification';

export default () => {
  const [notis, setNotis] = React.useState({
    unread: 0,
    notifications: []
  });

  const markAllAsReadAction = () => {
    dispatchService(markAllAsRead, () => {
      setNotis({
        ...notis,
        unread: 0
      });
    });
  };

  React.useEffect(
    () =>
      dispatchService(getNotifications, (data) => {
        if (!data) return;
        setNotis(data);
      }),
    []
  );

  return (
    <Popover
      placement='bottom'
      trigger='click'
      title={<b>Notification</b>}
      content={
        <List
          size='small'
          dataSource={notis.notifications}
          locale={{
            emptyText: 'You do not have any notification'
          }}
          renderItem={(item) => <List.Item>{item.msg}</List.Item>}
          footer={
            <div style={{ textAlign: 'center' }}>
              <Typography.Link onClick={markAllAsReadAction}>
                Mark all as read
              </Typography.Link>
            </div>
          }
        />
      }
    >
      <Badge dot={notis.unread > 0}>
        <NotificationOutlined style={{ fontSize: 16 }} />
      </Badge>
    </Popover>
  );
};
