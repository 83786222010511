import React from 'react';
import { Button } from 'antd';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const ButtonLink = ({ to, children, ...props }) => {
  const dispatch = useDispatch();
  const goTo = () => {
    dispatch(push(to));
  };

  return (
    <Button onClick={goTo} {...props}>
      {children}
    </Button>
  );
};

export default ButtonLink;
