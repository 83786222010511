import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { getFileUrl } from 'shared/utils/fileHelper';
import { AvatarContainer, Image, Letter } from './Styles';
import withUpload from 'hocs/withUpload';
import { updateCompanyLogoAction } from 'reducers/company/companyManagement';

const propTypes = {
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.oneOf(['circle', 'square'])
};

const defaultProps = {
  className: undefined,
  avatarUrl: null,
  name: '',
  size: 24,
  variant: 'circle'
};

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA'
];

const getColorFromName = (name) =>
  colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

const Avatar = ({
  className,
  avatarUrl,
  name,
  size,
  variant,
  ...otherProps
}) => {
  const [avatar, setAvatar] = useState(avatarUrl);
  const dispatch = useDispatch();
  const loggedCompany = useSelector((state) => state.company.loggedCompany);

  const CompanyAvatarUpload = withUpload({
    data: {
      where: 'company',
      whereId: loggedCompany._id,
      name: 'logo'
    },
    mimeTypes: ['image/jpeg', 'image/png'],
    onSuccess: async ({ _id, _, document }) => {
      await dispatch(updateCompanyLogoAction(loggedCompany._id, document));
      setTimeout(() => {
        setAvatar(getFileUrl(document));
      }, 2000);
    }
  });

  return (
    <AvatarContainer>
      {!avatar ? (
        <Letter
          className={className}
          size={size}
          color={getColorFromName(name)}
          variant={variant}
          {...otherProps}
        >
          <span>{name.charAt(0)}</span>
        </Letter>
      ) : (
        <Image
          className={className}
          size={size}
          avatarUrl={avatar}
          variant={variant}
          {...otherProps}
        />
      )}
      <CompanyAvatarUpload>
        <EditOutlined
          style={{
            width: 16,
            height: 16,
            position: 'absolute',
            top: 0,
            right: 0,
            cursor: 'pointer'
          }}
        />
      </CompanyAvatarUpload>
    </AvatarContainer>
  );
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
