import { createReducer, createAction } from 'redux-act';

import {
  createBlockSetup,
  updateBlockSetup,
  getBlocks,
  getDetail
} from 'services/demand/intermodalYardPlanning/blockMatrix';

// List
const fetchBlocksActionStart = createAction('start fetch blocks list');
const fetchBlocksActionSuccess = createAction('fetch blocks list success');
const fetchBlocksActionError = createAction('fetch blocks list error');
// Detail
const fetchDetailDataActionStart = createAction('start fetch detail data');
const fetchDetailDataActionSuccess = createAction('fetch detail data success');
const fetchDetailDataActionError = createAction('fetch detail data error');

const initialState = {
  isFetching: false,
  blocks: [],
  blocksMeta: {},
  error: false,
  message: ''
};

export default createReducer(
  {
    // List
    [fetchBlocksActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchBlocksActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      blocks: data,
      blocksMeta: meta
    }),
    [fetchBlocksActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),
    // Detail
    [fetchDetailDataActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchDetailDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      [`${payload._id}`]: payload
    }),
    [fetchDetailDataActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    })
  },
  initialState
);

export const createBlockSetupAction = (data) => {
  return async () => {
    return createBlockSetup(data);
  };
};

export const updateBlockSetupAction = (id, data) => {
  return async () => {
    return updateBlockSetup(id, data);
  };
};

export const fetchBlocksAction = (params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBlocksActionStart());
      const resp = await getBlocks(params);
      if (resp) {
        dispatch(
          fetchBlocksActionSuccess({ data: resp.data, meta: resp.meta })
        );
      }
    } catch (error) {
      dispatch(fetchBlocksActionError('Failed to fetch data'));
    }
  };
};

export const fetchBlockDetailAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDetailDataActionStart());
      const resp = await getDetail(id);
      if (resp.data) {
        dispatch(fetchDetailDataActionSuccess(resp.data));
      }
    } catch (error) {
      dispatch(fetchDetailDataActionError('Failed to fetch data'));
    }
  };
};
