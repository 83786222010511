import http from 'shared/utils/http';

export const createDataFlow = (data) => {
  return http.post('/netwila-core/etl/data-flow', data);
};

export const getDataFlows = () => {
  return http.get('/netwila-core/etl/data-flow');
};

export const getDownloadOutputLink = (s3Uri) => {
  return http.get('/netwila-core/etl/download-output', { s3Uri });
};

export const runDataFlow = (id) => {
  return http.get(`/netwila-core/etl/data-flow/${id}/run`);
};
