import http from 'shared/utils/http';

export const getInvoiceLine = (params) => {
  return http.get('/netwila-core/invoice/line-detail', params);
};

export const getInvoice = (id) => {
  return http.get(`/netwila-core/invoice/${id}`);
};

export const createInvoice = (params) => {
  return http.post('/netwila-core/invoice', params);
};

export const updateInvoice = ({ id, ...params }) => {
  return http.put(`/netwila-core/invoice/${id}`, params);
};

export const updateInvoiceLineDetail = (id, data) => {
  return http.put(`/netwila-core/invoice/${id}/line-detail`, data);
};

export const publishInvoice = ({ id, itemId }) => {
  return http.put(`/netwila-core/invoice/${id}/publish`, { itemId });
};

export const cancelInvoice = ({ id }) => {
  return http.put(`/netwila-core/invoice/${id}/cancel`);
};

export const getInvoices = (params = {}) => {
  return http.get('/netwila-core/invoice', params);
};

export const deletInvoice = (id) => {
  return http.delete(`/netwila-core/invoice/${id}`);
};

export const getLineDetail = (id, itemId) => {
  return http.get(`/netwila-core/invoice/${id}/line-detail`, { itemId });
};

export const exportInvoice = (id) => {
  return http.get(`/netwila-core/invoice/export/${id}`);
};

export const exportInvoice2Csv = (id) => {
  return http.get(`/netwila-core/invoice/export/${id}/csv`);
};

export const exportInvoice2Excel = (id) => {
  return http.get(`/netwila-core/invoice/export/${id}/excel`);
};

export const getRates = (params) => {
  return http.get('/netwila-core/invoice-rate', params);
};

export const updateRate = (id, data) => {
  return http.put(`/netwila-core/invoice-rate/${id}`, data);
};

export const createRate = (data) => {
  return http.post('/netwila-core/invoice-rate', data);
};

export const deleteRate = (id) => {
  return http.delete(`/netwila-core/invoice-rate/${id}`);
};

export const syncRate = (id) => {
  return http.post(`/netwila-core/invoice/${id}/update-rate`);
};
