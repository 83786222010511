import http from 'shared/utils/http';

export const fetchHSCode = async (companyId = '0') => {
  const response = await http.api('api/hscode/data', 'get', {
    companyId
  });
  return response.data;
};

export const createHSCode = async (data) => {
  const response = await http.api('api/hscode/data', 'post', {
    ...data
  });
  return response.data;
};

export const deleteHSCode = async (id) => {
  const response = await http.delete(`api/hscode/data/${id}`);
  return response;
};

export const fetchHazardousCode = async (companyId = '0') => {
  const response = await http.api('api/hazardouscode/data', 'get', {
    companyId
  });
  return response.data;
};

export const createHazardousCode = async (data) => {
  const response = await http.api('api/hazardouscode/data', 'post', {
    ...data
  });
  return response.data;
};

export const deleteHazardousCode = async (id) => {
  const response = await http.delete(`api/hazardouscode/data/${id}`);
  return response;
};
