import http from 'shared/utils/http';

export const getRailDeliveryTransit = (param) => {
  return http.get('/api/dray/transit/', param);
};

export const createRailDeliveryTransit = (data) => {
  return http.post('/api/dray/transit/', data);
};

export const updateRailDeliveryTransit = (data) => {
  return http.put(`/api/dray/transit/${data.id}`, data);
};
