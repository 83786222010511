/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { loginAction } from 'reducers/authentication';
import { routeConfig } from 'configs/route';
import { Form } from 'shared/components';
import { useParams } from 'react-router-dom';

import { Tabs, Row, Col } from 'antd';
import ANworkLogo from 'assets/images/anwork-logo.svg';
import NetwilaLogo from 'assets/images/netwila-logo.svg';

import {
  StyledLayout,
  Heading,
  FormHeading,
  FormElement,
  Actions,
  ActionButton,
  Tip,
  LinkItem,
  Error,
  SmallTip
} from './Styles';

const NetwilaLogin = ({ login, auth, app }) => {
  const { vendor } = useParams();

  const handleLogin = (values) => {
    login(values.email, values.password, app);
  };

  return (
    <Form
      initialValues={{
        email: '',
        password: ''
      }}
      validations={{
        email: [Form.is.required(), Form.is.email()],
        password: Form.is.required()
      }}
      onSubmit={handleLogin}
    >
      <FormElement>
        <Heading>
          {vendor ? (
            <FormHeading>Vendor Login</FormHeading>
          ) : (
            <FormHeading>Login Access</FormHeading>
          )}
          {/* <FormSecondHeading href={routeConfig.SIGNUP}>
            Sign Up to {app === 'netwila' ? 'Netwila' : 'ANwork'}
          </FormSecondHeading> */}
        </Heading>
        <Form.Field.Input
          name='email'
          label='Email'
          type='email'
          placeholder='Enter Email Address'
        />
        <Form.Field.Input
          name='password'
          label='Password'
          type='password'
          placeholder='Enter Password'
        />
        {auth.error && <Error>{auth.message}</Error>}
        <Actions>
          <ActionButton
            type='submit'
            variant='primary'
            isWorking={auth.loading}
          >
            Login
          </ActionButton>
        </Actions>
        <Tip style={{ justifyContent: 'space-between' }}>
          {/* <SmallTip>
            New to Netwila?
            <LinkItem to={routeConfig.SIGNUP}>Sign Up</LinkItem>
          </SmallTip> */}
          {vendor && (
            <SmallTip>
              New to Netwila?
              <LinkItem to={routeConfig.VENDOR_SIGNUP}>Sign Up</LinkItem>
            </SmallTip>
          )}
          <LinkItem to={routeConfig.FORGOT_PASSWORD}>Forgot Password</LinkItem>
        </Tip>
      </FormElement>
    </Form>
  );
};

const Login = ({ login, auth }) => {
  const [app, setApp] = useState('netwila');

  return (
    <StyledLayout>
      <Row justify='center' align='middle'>
        <Col flex='400px'>
          <NetwilaLogin login={login} auth={auth} app={app} />
          {/* <Tabs type='card' onChange={setApp}>
            <Tabs.TabPane
              tab={
                <img
                  style={{ width: 70 }}
                  alt='Netwila Login'
                  src={NetwilaLogo}
                />
              }
              key='netwila'
            >
              <NetwilaLogin login={login} auth={auth} app={app} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <img
                  style={{ width: 70 }}
                  alt='ANwork Login'
                  src={ANworkLogo}
                />
              }
              key='anwork'
            >
              <NetwilaLogin login={login} auth={auth} app={app} />
            </Tabs.TabPane>
          </Tabs> */}
        </Col>
      </Row>
    </StyledLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, app) => {
      dispatch(loginAction(email, password, app));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
