import { createAction, createReducer } from 'redux-act';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading: false,
  data: [],
  error: false
};

const fetchBulkVessel = createAction('fetch bulk vessel');
const fetchBulkVesselSuccess = createAction('fetch bulk vessel success');
const fetchBulkVesselError = createAction('fetch bulk vessel error');

export default createReducer(
  {
    [fetchBulkVessel]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchBulkVesselSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchBulkVesselError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    })
  },
  initialState
);

export const fetchBulkVesselAction = (values) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBulkVessel());
      await delay(1000);
      dispatch(fetchBulkVesselSuccess(values));
    } catch (error) {
      dispatch(fetchBulkVesselError());
    }
  };
};
