import toast from 'shared/utils/toast';
import { createAction, createReducer } from 'redux-act';
import * as equipmentServices from 'services/equipmentServices';

const initialState = {
  createLoading: false,
  uploadLoading: false,
  fetchLoading: false,
  deleteLoading: false,
  data: [],
  error: false,
  createError: false,
  updateError: false,
  deleteError: false,
  currentEquipmentType: undefined,
  currentEquipment: {}
};

const fetchEquipment = createAction('fetch equipment');
const fetchEquipmentSuccess = createAction('fetch equipment success');
const fetchEquipmentError = createAction('fetch equipment error');

const createEquipment = createAction('create equipment');
const createEquipmentSuccess = createAction('create equipment success');
const createEquipmentError = createAction('create equipment error');

const updateEquipment = createAction('update equipment');
const updateEquipmentSuccess = createAction('update equipment success');
const updateEquipmentError = createAction('update equipment error');

const getEquipment = createAction('get equipment');
const getEquipmentSuccess = createAction('get equipment success');
const getEquipmentError = createAction('get equipment error');

const deleteEquipment = createAction('delete equipment');
const deleteEquipmentSuccess = createAction('delete equipment success');
const deleteEquipmentError = createAction('delete equipment error');

export const updateEquipmentTypeAction = createAction('update equipment type');

export default createReducer({
  [fetchEquipment]: (state) => ({
    ...state,
    fetchLoading: true,
    currentEquipment: {},
    error: false
  }),
  [fetchEquipmentSuccess]: (state, payload) => ({
    ...state,
    data: payload,
    fetchLoading: false,
    error: false
  }),
  [fetchEquipmentError]: (state, payload) => ({
    ...state,
    message: payload,
    error: true,
    data: [],
    fetchLoading: false
  }),
  [createEquipment]: state => ({
    ...state,
    createLoading: true,
    createError: true
  }),
  [createEquipmentSuccess]: (state, payload) => ({
    ...state,
    data: [...state.data, payload],
    createLoading: false,
    createError: false
  }),
  [createEquipmentError]: (state, payload) => ({
    ...state,
    createLoading: false,
    createError: true,
    message: payload
  }),
  [updateEquipment]: state => ({
    ...state,
    updateLoading: true,
    updateError: true
  }),
  [updateEquipmentSuccess]: (state, payload) => ({
    ...state,
    data: [...state.data, payload],
    currentEquipment: {},
    updateLoading: false,
    updateError: false
  }),
  [updateEquipmentError]: (state, payload) => ({
    ...state,
    updateLoading: false,
    updateError: true,
    currentEquipment: {},
    message: payload
  }),
  [getEquipment]: (state) => ({
    ...state,
    fetchLoading: true,
    error: false,
    currentEquipment: {}
  }),
  [getEquipmentSuccess]: (state, payload) => ({
    ...state,
    fetchLoading: false,
    currentEquipment: payload,
    error: false
  }),
  [getEquipmentError]: (state, payload) => ({
    ...state,
    fetchLoading: false,
    message: payload,
    currentEquipment: {},
    error: true
  }),
  [deleteEquipment]: (state) => ({
    ...state,
    deleteLoading: true,
    deleteError: false
  }),
  [deleteEquipmentSuccess]: (state) => ({
    ...state,
    deleteLoading: false
  }),
  [deleteEquipmentError]: (state, payload) => ({
    ...state,
    deleteLoading: false,
    message: payload,
    deleteError: true
  }),
  [updateEquipmentTypeAction]: (state, type) => ({
    ...state,
    currentEquipmentType: type
  })
}, initialState);

export const fetchEquipmentDataAction = (equipmentType, companyId) => {
  return async (dispatch) => {
    dispatch(fetchEquipment());
    try {
      const data = await equipmentServices.fetchEquipmentTblData(equipmentType, companyId);
      dispatch(fetchEquipmentSuccess(data.data));
    } catch (error) {
      dispatch(fetchEquipmentError('Fetching error'));
      toast.error(
        `Error occurred when fetching equipment ${equipmentType}`
      );
    }
  };
};


export const createEquipmentAction = (equipmentType, data) => {
  return async (dispatch) => {
    dispatch(createEquipment());
    try {
      const respone = await equipmentServices.createEquipment(equipmentType, data);
      dispatch(createEquipmentSuccess(respone.data));
      toast.success(
        'Equipment has been created'
      );
      return true;
    } catch (error) {
      dispatch(createEquipmentError('Creating error'));
      toast.error(
        `Error occurred when creating equipment ${equipmentType}`
      );
      return false;
    }
  };
};

export const updateEquipmentAction = (equipmentType, equipmentId, data) => {
  return async (dispatch) => {
    dispatch(updateEquipment());
    try {
      const respone = await equipmentServices.updateEquipment(equipmentType, equipmentId, data);
      dispatch(updateEquipmentSuccess(respone.data));
      toast.success(
        'Equipment has been updated'
      );
      return true;
    } catch (error) {
      dispatch(updateEquipmentError('Updating error'));
      toast.error(
        `Error occurred when updating equipment ${equipmentType}`
      );
      return false;
    }
  };
};

export const getEquipmentAction = (equipmentType, equipmentId) => {
  return async (dispatch) => {
    dispatch(getEquipment());
    try {
      const respone = await equipmentServices.getEquipment(equipmentType, equipmentId);
      dispatch(getEquipmentSuccess(respone.data));
    } catch (error) {
      dispatch(getEquipmentError('Getting error'));
    }
  };
};

export const deleteEquipmentAction = (equipmentType, equipmentId) => {
  return async (dispatch) => {
    dispatch(deleteEquipment());
    try {
      const respone = await equipmentServices.deleteEquipment(equipmentType, equipmentId);
      dispatch(deleteEquipmentSuccess(respone.data));
      return true;
    } catch (error) {
      dispatch(deleteEquipmentError('Deleting error'));
      return false;
    }
  };
};