import http from 'shared/utils/http';

/**
 *
 * @param {Object} filter
 * @example filter = { status: 'draft', type: 'tarrif' }
 */
export const getMessages = (filter) => {
  return http.get(`notifications/critical-message?${filter}`);
};

export const getSelectedMessage = (filter) => {
  return http.get(`notifications/critical-message/${filter}`);
};

export const getModules = () => {
  return http.get(`notifications/module`);
};

export const getSubModules = (module) => {
  return http.get(`notifications/sub-module/?module_id=${module}`);
};

export const createMessage = (message) => {
  return http.post(`notifications/critical-message`, message);
};
/**
 *
 * @param {Number} id
 * @param {Object} message
 */
export const updateMessage = (id , data) => {
  return http.put(`notifications/critical-message/${id}`, data);
};
