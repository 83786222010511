import { createReducer, createAction } from 'redux-act';
import toast from 'shared/utils/toast';

import {
  fetchData,
  createData,
  deleteDataById,
  updateDataById
} from 'services/integratedShipping/inboundDeliveryReceivingBookingSite';

const initialState = {
  isFetching: true,
  data: [],
  isUpdate: false,
  error: false,
  message: ''
};

const fetchDataActionStart = createAction(
  'start fetch inbound delivery receiving list'
);
const fetchDataActionSuccess = createAction(
  'fetch inbound delivery receiving list success'
);
const fetchDataActionError = createAction(
  'fetch inbound delivery receiving list error'
);

const createUpdateDataActionStart = createAction(
  'start create/update inbound delivery receiving'
);
const createUpdateDataActionSuccess = createAction(
  'create/update inbound delivery receiving success'
);
const createUpdateDataActionError = createAction(
  'create/update inbound delivery receiving error'
);

export default createReducer(
  {
    [fetchDataActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      data: payload.data
    }),
    [fetchDataActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [createUpdateDataActionStart]: (state) => ({
      ...state,
      isUpdate: true
    }),
    [createUpdateDataActionSuccess]: (state) => ({
      ...state,
      isUpdate: false
    }),
    [createUpdateDataActionError]: (state) => ({
      ...state,
      isUpdate: false
    })
  },
  initialState
);

export const fetchInboundDeliveryReceivingList = (companyId, filter) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchData(companyId, filter);
      dispatch(fetchDataActionSuccess({ data: res.data }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const createInboundDeliveryReceivingAction = (companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await createData(companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Inbound Delivery Receiving has been created');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when creating Inbound Delivery Receiving');
    }
  };
};

export const updateInboundDeliveryReceivingAction = (id, companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await updateDataById(id, companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Inbound Delivery Receiving has been updated');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when updating Inbound Delivery Receiving');
    }
  };
};

export const deleteInboundDeliveryReceivingAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await deleteDataById(id);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Inbound Delivery Receiving has been deleted');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when deleting Inbound Delivery Receiving');
    }
  };
};
