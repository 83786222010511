/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { push } from 'connected-react-router';
import {
  login,
  signup2,
  logout,
  refreshAccessToken
} from 'services/authentication';
import { getCurrentUser } from 'services/user';
import {
  storeAccessToken,
  storeRefreshToken,
  extractAccessToken,
  updateAuthTime,
  getAuthTime
  // removeStoredAccessToken
} from 'shared/utils/authToken';
import { routeConfig } from 'configs/route';

const initialState = {
  loading: false,
  message: '',
  data: {},
  error: false
};

export const logoutAction = () => {
  return (dispatch) => {
    logout();
    dispatch(push(routeConfig.LOGIN));
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'login_start':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'login_success':
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data
      };
    case 'login_error':
      return {
        ...state,
        message: action.message,
        error: true,
        loading: false
      };
    case 'signup_start':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'signup_success':
      return {
        ...state,
        loading: false,
        error: false
      };
    case 'signup_error':
      return {
        ...state,
        message: action.message,
        error: true,
        loading: false
      };
    default:
      return state;
  }
};

export const loginAction = (email, password, app) => {
  return async (dispatch) => {
    dispatch({ type: 'login_start' });
    try {
      const loggedInUser = await login(email, password);
      storeAccessToken(loggedInUser.accessToken);
      storeRefreshToken(loggedInUser.RefreshToken);
      dispatch({
        type: 'login_success',
        message: 'Successfully Login',
        data: { loggedInUser }
      });
      // Get user information
      const response = await getCurrentUser();
      const user = response.data[0];
      if (!user) throw new Error('Login error');
      if (!user.company) {
        return dispatch(push(routeConfig.COMPANY_SIGNUP));
      }
      if (user.company.pendingStatus === true) {
        return dispatch(push(routeConfig.SIGNUP_CONFIRMATION));
      }
      dispatch(push(routeConfig.ACCOUNT_SELECTION, { app }));
    } catch (error) {
      dispatch({
        type: 'login_error',
        message: error.data.message
      });
    }
  };
};

export const signupAction = (firstName, lastName, email, password, token) => {
  return async (dispatch) => {
    dispatch({ type: 'signup_start' });
    try {
      const signedUpUser = await signup2(
        firstName,
        lastName,
        email,
        password,
        token
      );
      dispatch({
        type: 'signup_success',
        message: 'Successfully Login',
        data: { signedUpUser }
      });
      return signedUpUser;
    } catch (error) {
      dispatch({
        type: 'signup_error',
        message: error.message.join('<br/>')
      });
    }
  };
};

const ACCESS_TOKEN_LIFETIME = 3600; // 1 hour
const ACCESS_TOKEN_REFRESH_THRESHOLD = 1800; // 30 minutes
const refreshTokenAction = async () => {
  try {
    const response = await refreshAccessToken();
    storeAccessToken(response.accessToken);
    setTimeout(refreshTokenAction, ACCESS_TOKEN_REFRESH_THRESHOLD * 1000);
  } catch (err) {
    /**/
  }
};

/**
 * Whether token is expired
 * @param {Integer} authTime Authentication time
 */
const isAccessTokenExpired = () => {
  const now = Math.round(Date.now() / 1000);
  const authTime = getAuthTime();
  return authTime + ACCESS_TOKEN_LIFETIME < now;
};

const AuthError = (message) => {
  const error = new Error(message);
  error.name = 'AuthError';
  return error;
};

export const verifyAccessTokenAction = () => {
  return async (dispatch) => {
    // Current timestamp in UTC
    const now = Math.round(Date.now() / 1000);
    const accessToken = extractAccessToken();

    /**
     * Token validation is not needed since http client has already handled
     * unauthorized requests
     */
    if (isAccessTokenExpired()) {
      throw AuthError('Token is expired');
    }

    // Force to refresh if the access tokens have a minimum
    // remaining validity of 30 minutes
    if (accessToken.auth_time + ACCESS_TOKEN_REFRESH_THRESHOLD < now) {
      await refreshTokenAction();
    } else {
      // Automatically refresh token if remaining validity is 30 minutes
      const timeout =
        accessToken.auth_time + ACCESS_TOKEN_REFRESH_THRESHOLD - now;
      setTimeout(refreshTokenAction, timeout * 1000);
    }
  };
};
