import http from 'shared/utils/http';

export const getWorkflows = (params) => {
  return http.get('/edi/edi-workflow/workflow', params);
};

export const getWorkflow = (id) => {
  return http.get(`/edi/edi-workflow/workflow/${id}`);
};

export const addWorkflow = (data) => {
  return http.post('/edi/edi-workflow/workflow', data);
};

export const getWorkflowconfigs = () => {
  return http.get('/edi/edi-workflow/configs');
};

export const getWorkflowconfig = (id) => {
  return http.get(`/edi/edi-workflow/config/${id}`);
};

export const addWorkflowConfig = (data) => {
  return http.post('/edi/edi-workflow/config', data);
};
