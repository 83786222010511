import { useLocation } from 'react-router-dom';

/**
 * Build full url for routing
 * @param {String} path
 * @param {Object} params
 * @param {String} host For a custom host
 * @example buildPath('/path/to/:id', { id: 1 }) should be /path/to/1
 * @example build('/path/to/:id/:name', { id: 1, name: 'alice' }) should be /path/to/1/alice
 */
export const buildPath = (path, params, query = {}, host) => {
  Object.keys(params).forEach((key) => {
    path = path.replace(`:${key}`, params[key]);
  });

  const queryString = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&');

  if (host !== undefined) {
    path = `${host}${path}?${queryString}`;
  }

  return `${path}?${queryString}`;
};

/**
 * How to use:
 * Example ULR: http://abc.com?key=value
 * const query = useQuery();
 * query.get('key')
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
