import store from 'store';

export const DEFAULT_COL_WIDTH = 100;
export const DEFAULT_ITEM_PER_PAGE = 10;

export const calculateTableWidth = (columns) => {
  const tblWidth = columns
    // .filter((column) => !column.fixed)
    .reduce((width, column) => width + (column.width ?? DEFAULT_COL_WIDTH), 0);
  return `${tblWidth}px`;
};

export const getDefaultColumns = (columns) => {
  return columns.filter((column) => column.default).map((column) => column.key);
};

export const getTableName = (name) => {
  return `tbl${name}`;
};

export const getSettingColumns = (tblName) => {
  if (!tblName) return;
  return store.get(getTableName(tblName));
};

export const updateSettingColumns = (tblName, columns) => {
  store.set(getTableName(tblName), columns);
};
