import { createActionResources, createReducer } from 'redux-waiters';
import * as locationServices from 'services/location';

export const fetchLocationTypeAction = createActionResources(
  'fetch location type'
);

const initialState = {
  data: []
};

export default createReducer(
  {
    [fetchLocationTypeAction.success]: (state, payload) => {
      return {
        ...state,
        data: payload
      };
    }
  },
  initialState
);

export const fetchLocationTypeCreator = () =>
  fetchLocationTypeAction.waiterAction(async (dispatch) => {
    try {
      dispatch(fetchLocationTypeAction.start());
      const locations = await locationServices.getLocationType();
      dispatch(fetchLocationTypeAction.success(locations.data));
    } catch (err) {
      dispatch(fetchLocationTypeAction.error(err));
    }
  });
