import { combineReducers } from 'redux';
import integratedContainerSetupReducer from './integratedContainerSetup';
import totalSmartReducer from './totalSmart';
import smartInModeReducer from './smartInMode';
import smartOutModeReducer from './smartOutMode';

export default combineReducers({
  integratedContainerSetup: integratedContainerSetupReducer,
  totalSmart: totalSmartReducer,
  smartInMode: smartInModeReducer,
  smartOutMode: smartOutModeReducer
});
