import styled from 'styled-components';

export const ProgressStyle = styled.div`
  width: 100%;
`;

export const StepStyleContainer = styled.div`
  display: flex;
  padding-top: ${(props) => props.paddingTop};
`;

export const StepStyle = styled.div`
  width: ${(props) => props.width};
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.bgColor};
  margin-right: 2px;
  position: relative;
`;

export const StepProgressStyle = styled.div`
  width: ${(props) => props.width};
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.strokeColor};
`;

export const InfoStyle = styled.div`
  color: #666666;
  font-size: 12px;
  margin-top: 4px;
`;

export const StepInfoStyle = styled.div`
  color: #666666;
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  top: -24px;
  left: 0;
`;
