/* eslint-disable no-unused-vars */
import { getRateCardType, findMatchedRate } from 'services/contract';
import { createOrderRail } from 'services/oderRailServices';
import { createAction, createReducer } from 'redux-act';

import { getModes } from 'services/mode';

const RAIL_MODE = 'Rail';
const initialState = {
  selectedShipperCountry: null,
  selectedConsigneeCountry: null
};

const selectShipperCountryAction = createAction('select shipper country');
const selectConsigneeCountryAction = createAction('select shipper country');
const updateRailOrderEntryAction = createAction('update order entry action');
const fetchRailModeSuccessAction = createAction(
  'fetch rail equipment type success'
);

export default createReducer(
  {
    [selectShipperCountryAction]: (state, selectedShipperCountry) => ({
      ...state,
      selectedShipperCountry
    }),
    [selectConsigneeCountryAction]: (state, selectedConsigneeCountry) => ({
      ...state,
      selectedConsigneeCountry
    }),
    [updateRailOrderEntryAction]: (state, data) => ({
      ...state,
      ...data
    }),
    [fetchRailModeSuccessAction]: (state, mode) => ({
      ...state,
      mode
    })
  },
  initialState
);

export const getRailModesAction = () => async (dispatch) => {
  try {
    const response = await getModes({ mode: RAIL_MODE });
    dispatch(
      fetchRailModeSuccessAction(
        response.data.find((mode) => mode.mode === RAIL_MODE)
      )
    );
  } catch (err) {
    /*SL*/
  }
};

export const createOrderRailAction = (data) => {
  return async () => {
    const response = await createOrderRail(data);
    return response.data;
  };
};

const rateMatch = (data) => ({
  [`Door-to-Door`]: {
    [`Rail Carrier`]: data.carrier.legalName,
    [`Origin Country`]: data.originCountry.name,
    [`Origin City`]: data.shipperLocation.city,
    [`Destination Country`]: data.destinationCountry.name,
    [`Destination City`]: data.consigneeLocation.city,
    [`Equipment Type`]: data.equipmentType
    // [`Service`]: data.shippingType
    // [`Temperature Type`]: data
  },
  [`Ramp-to-Ramp`]: {
    [`Rail Carrier`]: data.carrier.legalName,
    [`Origin Rail Terminal`]: data.originRailTerminal.name,
    [`Destination Rail Terminal`]: data.interlineRail
      ? data.destinationInterlineRailTerminal.name
      : data.destinationRailTerminal.name,
    [`Equipment Type`]: data.equipmentType
    // [`Service`]: data.shippingType
    // [`Temperature Type`]: data
  },
  [`Door-to-Ramp`]: {
    [`Rail Carrier`]: data.carrier.legalName,
    [`Origin Country`]: data.originCountry.name,
    [`Origin City`]: data.shipperLocation.city,
    [`Destination Rail Terminal`]: data.interlineRail
      ? data.destinationInterlineRailTerminal.name
      : data.destinationRailTerminal.name,
    [`Equipment Type`]: data.equipmentType
    // [`Service`]: data.shippingType,
    // [`Temperature Type`]: data
  },
  [`Ramp-to-Door`]: {
    [`Rail Carrier`]: data.carrier.legalName,
    [`Origin Rail Terminal`]: data.originRailTerminal,
    [`Destination Country`]: data.destinationCountry.name,
    [`Destination City`]: data.consigneeLocation.city,
    [`Equipment Type`]: data.equipmentType
    // [`Service`]: data.shippingType
    // [`Temperature Type`]: data
  }
});
export const findMatchedRateAction = (data) => async () => {
  const rateMatchFilter = rateMatch(data);
  // Gets rate card type 120 is rail rate card
  const rateCardTypes = await getRateCardType({ code: '120' });
  if (rateCardTypes.length === 0) return false;
  const rateCardType = rateCardTypes.data[0];

  const fieldsForMatching = rateMatchFilter[`${data.moveType?.label}`];
  // map field to value
  const mapData = rateCardType.feature
    .filter((field) => Object.keys(fieldsForMatching).includes(field.fieldName))
    .map((field) => {
      return {
        feature: field._id,
        value: fieldsForMatching[field.fieldName]
      };
    });

  const rate = await findMatchedRate({
    contractId: data.contract._id,
    feature: [...mapData]
  });
  return rate.data;
};

export {
  selectConsigneeCountryAction,
  selectShipperCountryAction,
  updateRailOrderEntryAction
};
