/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Dropdown, Button, Avatar, Drawer, Divider, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Icon } from 'shared/components';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useModuleMatch } from 'shared/hooks/useModuleMatch';
import { dispatchService } from 'shared/utils/service';
import { getFileUrl } from 'shared/utils/fileHelper';
import { topbarConfig } from 'configs/menu';
import { routeConfig } from 'configs/route';
import { getInstructions } from 'services/instruction';
import Notification from '../Notification';

const UserMenu = () => {
  return (
    <Menu>
      <Menu.Item key='1'>
        <Link to={routeConfig.SETTINGS_PROFILE}>Profile</Link>
      </Menu.Item>
      <Menu.Item key='2'>
        <Link to='/settings'>Settings</Link>
      </Menu.Item>
      <Menu.Item key='3'>
        <Link to='/logout'>Logout</Link>
      </Menu.Item>
    </Menu>
  );
};

const Topbar = () => {
  const { t } = useTranslation();
  const { moduleName } = useModuleMatch();
  const { currentUser } = useSelector((state) => state.user);
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [instruction, setInstruction] = useState();

  const showHelp = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const hasPermission = (menuPermission) => {
    return currentUser.permissions.some((permission) => {
      const [access] = permission.split('.');
      const [menuAccess] = menuPermission.split('.');
      return access === menuAccess;
    });
  };

  const getUserMenus = (menus) => {
    return menus.filter((menu) => {
      /**
       * - Menu will be displayed if function was not configured
       * - Menu will be displayed if logged user if right permissions
       */
      const hasMenuAccess =
        currentUser.isSystemAdmin ||
        !menu.permission ||
        hasPermission(menu.permission);
      /**
       * - Menu will be displayed if additional condition was not configured
       * - Menu will be displayed if additional condition return true
       */
      const hasAdditionalAccess =
        !menu.additionalCondition || menu.additionalCondition?.(currentUser);
      if (hasMenuAccess && hasAdditionalAccess) {
        return true;
      }

      return false;
    });
  };

  useEffect(() => {
    if (visible) {
      dispatchService(
        () => getInstructions({ route: location.pathname }),
        (data) => {
          setInstruction(data[0]);
        }
      );
    }
  }, [visible]);

  return (
    <Menu
      mode='horizontal'
      selectedKeys={[moduleName]}
      selectable={false}
      className='topbar__menu'
    >
      {topbarConfig
        .map(({ module, menus }) => ({
          module,
          menus: getUserMenus(menus)
        }))
        .map(({ module, menus }) => {
          return menus.map((menu) => (
            <Menu.Item key={module}>
              <Link to={menu.to}>{t(menu.title)}</Link>
            </Menu.Item>
          ));
        })}
      <Menu.Item key='help'>
        <InfoCircleOutlined onClick={showHelp} />
        <Drawer
          title={instruction?.title || 'Netwila'}
          placement='right'
          onClose={onClose}
          visible={visible}
        >
          <div
            dangerouslySetInnerHTML={{ __html: instruction?.content || '...' }}
          />
          <Divider />
          <h3>Learn More</h3>
          <Space direction='vertical'>
            {instruction?.links.map((l) => (
              <a key={l.url} href={l.url} target='__blank'>
                {l.title}
              </a>
            ))}
          </Space>
        </Drawer>
      </Menu.Item>
      <Menu.Item key={'noti'}>
        <Notification />
      </Menu.Item>
      <Menu.Item key='profile' className='topbar__user-profile'>
        <Dropdown overlay={<UserMenu />}>
          <Button style={{ padding: '2px 5px' }}>
            <Avatar
              src={currentUser.avatar && getFileUrl(currentUser.avatar)}
              size={28}
              shape='square'
            />{' '}
            {currentUser.firstName} {currentUser.lastName}
            <Icon size={14} type='chevron-down' />
          </Button>
        </Dropdown>
      </Menu.Item>
    </Menu>
  );
};

export default Topbar;
