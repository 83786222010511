import { csvParser } from 'shared/utils/parser';
import toast from 'shared/utils/toast';
import { isEqual } from 'lodash';

import LoadableComponent from 'components/LoadableComponent';
import { openModal } from 'reducers/modalLot';
import {
  createSmartModeList,
  fetchSmartModeTblData,
  deleteSmartMode,
  updateSmartMode
} from 'services/masterSchedule/smartMode';
import {
  getTaskLinks,
  getTaskLink,
  getTasks,
  createTaskLink,
  deleteTaskLink,
  importTaskLinks
} from 'services/integratedShipping/smartMode';

const initialState = {
  fetchLoading: false,
  loading: false,
  isWorking: false,
  isValidCSVFile: false,
  isShowModalUploadCSV: false,
  csvData: [],
  message: '',
  error: false,
  data: [],
  taskLinks: [],
  taskLink: null,
  tasks: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'fetching_dashboard_smart_mode':
      return {
        ...state,
        fetchLoading: true,
        error: false
      };
    case 'fetching_dashboard_smart_mode_success':
      return {
        ...state,
        data: action.data,
        fetchLoading: false,
        error: false
      };
    case 'fetching_dashboard_smart_mode_error':
      return {
        ...state,
        message: action.message,
        error: true,
        fetchLoading: false
      };
    case 'uploadCSV_start':
      return {
        ...state,
        isWorking: true
      };
    case 'uploadCSV_success':
      return {
        ...state,
        isWorking: false,
        isValidCSVFile: true,
        csvData: action.data,
        isShowModalUploadCSV: true
      };
    case 'uploadCSV_error':
      return {
        ...state,
        isWorking: false
      };
    case 'create_smartMode_start':
      return {
        ...state,
        loading: true,
        fetchLoading: true
      };
    case 'create_smartMode_success':
      return {
        ...state,
        csvData: [],
        data: action.data,
        fetchLoading: false,
        loading: false
      };
    case 'create_smartMode_error':
      return {
        ...state,
        isWorking: false
      };
    case 'set_showModal':
      return {
        ...state,
        isShowModalUploadCSV: true
      };
    case 'set_closeModal':
      return {
        ...state,
        isShowModalUploadCSV: false,
        csvData: []
      };
    case 'delete_smart_mode_start':
    case 'update_smart_mode_start':
      return {
        ...state,
        fetchLoading: true
      };
    case 'delete_smart_mode_error':
    case 'update_smart_mode_error':
      return {
        ...state,
        fetchLoading: false
      };
    case 'getTaskLinksSucccessAction':
      return {
        ...state,
        taskLinks: action.data
      };
    case 'getTaskLinkSucccessAction':
      return {
        ...state,
        taskLink: action.data
      };
    case 'getTasksSucccessAction':
      return {
        ...state,
        tasks: action.data
      };
    case 'closeTaskExplorerAction':
      return {
        ...state,
        tasks: []
      };
    default:
      return state;
  }
};

export const uploadCSVAction = (csvFile, companyId, columnMapping) => {
  return async (dispatch) => {
    dispatch({ type: 'uploadCSV_start' });
    try {
      return await csvParser.parse(csvFile, {
        header: true,
        transformHeader: (header) => {
          return columnMapping[header];
        },
        complete: ({ data, meta }) => {
          if (isEqual(meta.fields, Object.values(columnMapping))) {
            const formattedData = data
              .filter((record) => Object.keys(record).length > 1)
              .map((record) => {
                return {
                  ...record,
                  companyId
                };
              });
            dispatch({ type: 'uploadCSV_success', data: formattedData });
            return true;
          }
          toast.error('File .csv must follow formatted form');
          return false;
        }
      });
    } catch (e) {
      toast.error('File .csv must follow formatted form');
      return false;
    }
  };
};

export const createSmartModeFromCSVAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: 'create_smartMode_start' });
    try {
      const res = await createSmartModeList(data);
      if (res) {
        const refetchData = await fetchSmartModeTblData(data[0]['companyId']);
        dispatch({ type: 'create_smartMode_success', data: refetchData.data });
        toast.success('Upload CSV successful ');
      }
    } catch (e) {
      dispatch({ type: 'create_smartMode_error' });
    }
  };
};

export const fetchDataAction = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: 'fetching_dashboard_smart_mode' });
    try {
      const data = await fetchSmartModeTblData(companyId);
      dispatch({
        type: 'fetching_dashboard_smart_mode_success',
        data: data.data
      });
    } catch (error) {
      toast.error('Fetching data error!');
      dispatch({
        type: 'fetching_dashboard_smart_mode_error',
        message: 'Fetching error'
      });
    }
  };
};

export const setShowModalAction = (isShow) => {
  return (dispatch) => {
    if (isShow) {
      dispatch({ type: 'set_showModal' });
    } else {
      dispatch({ type: 'set_closeModal' });
    }
  };
};

export const deleteDataAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: 'delete_smart_mode_start' });
    try {
      await deleteSmartMode(id);
      toast.success('Smart mode has been deleted');
    } catch (error) {
      toast.error('Delete data error!');
      dispatch({
        type: 'delete_smart_mode_error',
        message: 'Error occurred when deleting smart mode'
      });
    }
  };
};

export const updateDataAction = (id, companyId, data) => {
  return async (dispatch) => {
    dispatch({ type: 'update_smart_mode_start' });
    try {
      await updateSmartMode(id, companyId, data);
      toast.success('Smart mode has been updated');
    } catch (error) {
      toast.error('Error occurred when update smart mode');
      dispatch({
        type: 'update_smart_mode_error',
        message: 'Error occurred when update smart mode'
      });
    }
  };
};

export const getTaskLinksAction = (params = {}) => async (dispatch) => {
  const response = await getTaskLinks(params);
  dispatch({ type: 'getTaskLinksSucccessAction', data: response.data });
};

export const getTaskLinkAction = (id) => async (dispatch) => {
  const response = await getTaskLink(id);
  dispatch({ type: 'getTaskLinkSucccessAction', data: response.data });
};

export const getTasksAction = (filter) => async (dispatch) => {
  const response = await getTasks(filter);
  dispatch({ type: 'getTasksSucccessAction', data: response.data });
};

export const openTaskExplorerAction = (filterConds) => async (dispatch) => {
  const Modal = LoadableComponent(() =>
    import('pages/Orders/OrderManagement/OrderCreate/TaskExplorerModal')
  );
  return dispatch(openModal(Modal, { filterConds }));
};

export const closeTaskExplorerAction = () => ({
  type: 'closeTaskExplorerAction'
});

export const createTaskLinkAction = (companyId, tasks) => async () => {
  const response = await createTaskLink(companyId, tasks);
  return response.data;
};

export const deleteTaskLinkAction = (linkId) => async () => {
  const response = await deleteTaskLink(linkId);
  return response.data;
};

export const importTaskLinksAction = (taskLinks) => async () => {
  const response = await importTaskLinks(taskLinks);
  return response.data;
};
