import http from 'shared/utils/http';

export const getInstructions = (params = {}) => {
  return http.get('/netwila-core/instructions', params);
};

export const getInstruction = (id) => {
  return http.get(`/netwila-core/instructions/${id}`);
};

export const createInstruction = (data) => {
  return http.post('/netwila-core/instructions', data);
};

export const updateInstruction = ({ id, ...data }) => {
  return http.put(`/netwila-core/instructions/${id}`, data);
};

export const deleteInstruction = (id) => {
  return http.delete(`/netwila-core/instructions/${id}`);
};
