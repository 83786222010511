import {
  createOrder,
  fetchCompanyType,
  loginOrder,
  fetchOrderData
} from '../services/orderEntry';

const initialState = {
  //region Get data from form login order
  isLoginSuccess: false,
  isLoginLoading: false,
  formLoginValues: {},
  //endregion
  companyTypes: [],
  createLoading: false,
  fetchLoading: true,
  fetchOrderLoading: true,
  error: false,
  createError: false,
  message: '',
  orderData: {},
  companies: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    //region Get company for form value
    //TODO add query with group division
    case 'fetching_company_group_start':
      return {
        ...state,
        fetchLoading: true,
        error: false
      };
    case 'fetching_company_group_success':
      return {
        ...state,
        companies: action.data,
        fetchLoading: false,
        error: false
      };
    case 'fetching_company_group_error':
      return {
        ...state,
        error: true,
        message: action.message
      };
    //endregion

    //region Login handler
    case 'login_order_start':
      return {
        ...state,
        isLoginLoading: true
      };
    case 'login_order_success':
      return {
        ...state,
        isLoginLoading: false,
        isLoginSuccess: true,
        formLoginValues: action.data
      };
    case 'login_order_error':
      return {
        ...state,
        isLoginLoading: false,
        error: true,
        message: action.message
      };
    //endregion

    //region Fetch company type  base on shippingMode
    case 'fetching_company_type_start':
      return {
        ...state,
        fetchLoading: true,
        error: false
      };
    case 'fetching_company_type_success':
      return {
        ...state,
        companyTypes: action.data,
        fetchLoading: false,
        error: false
      };
    case 'fetching_company_type_error':
      return {
        ...state,
        error: true,
        message: 'Failed fetching data'
      };
    //endregion

    case 'creating_order_start':
      return {
        ...state,
        createLoading: true,
        createError: false
      };
    case 'creating_order_success':
      return {
        ...state,
        createLoading: false,
        createError: true,
        message: action.message
      };
    case 'creating_order_error':
      return {
        ...state,
        message: action.message,
        createLoading: false,
        fetchLoading: false
      };
    case 'cancel_form':
      return initialState;

    case 'fetching_order_start':
      return {
        ...state,
        fetchOrderLoading: true,
        error: false
      };
    case 'fetching_order_success_update_login_access':
      return {
        ...state,
        error: false,
        fetchOrderLoading: false,
        orderData: action.data.data,
        formLoginValues: action.data.formLoginValues
      };
    case 'fetching_order_error':
      return {
        ...state,
        fetchOrderLoading: false,
        error: true,
        message: action.message
      };
    case 'clear_order_information':
      return {
        ...state,
        formLoginValues: {},
        orderData: {},
        isLoginSuccess: false
      };
    default:
      return state;
  }
};

export const loginOrderAction = (formValues) => {
  return async (dispatch) => {
    dispatch({ type: 'login_order_start' });
    setTimeout(async () => {
      try {
        const data = await loginOrder(formValues);

        //TODO handle for main API (this is temporary logic for logic running)
        if (data.message === 'success') {
          const {
            shippingMode,
            shippingModeDetail,
            company,
            userName
          } = formValues;

          dispatch({
            type: 'login_order_success',
            data: {
              shippingMode,
              shippingModeDetail,
              company,
              userName
            }
          });
        } else {
          dispatch({
            type: 'login_order_error',
            data: data.message
          });
        }
      } catch (e) {
        dispatch({
          type: 'login_order_error',
          data: e.message
        });
      }
    }, 2000);
  };
};

export const fetchCompanyTypeAction = () => {
  return async (dispatch) => {
    dispatch({ type: 'fetching_company_type_start' });
    try {
      const companyTypes = await fetchCompanyType();

      dispatch({
        type: 'fetching_company_type_success',
        data: companyTypes.data
      });
    } catch (error) {
      dispatch({
        type: 'fetching_company_type_error',
        message: 'Fetching error'
      });
    }
  };
};

export const createOrderAction = (data, shippingMode) => {
  return async (dispatch) => {
    dispatch({ type: 'creating_order_start' });
    try {
      //TODO add companyId to body
      const response = await createOrder(data, shippingMode);
      dispatch({
        type: 'creating_order_success',
        data: response.data
      });
      return true;
    } catch (error) {
      dispatch({
        type: 'creating_order_error',
        message: 'Creating error'
      });
      return false;
    }
  };
};

export const handleCancelFormAction = () => ({
  type: 'cancel_form'
});

export const fetchOrderAction = ({ id }, shippingMode) => {
  return async (dispatch) => {
    dispatch({ type: 'fetching_order_start' });
    try {
      const { data } = await fetchOrderData(id, shippingMode);
      dispatch({
        type: 'fetching_order_success_update_login_access',
        data: {
          data,
          formLoginValues: {
            shippingMode,
            shippingModeDetail: data.shippingModeDetail,
            company: JSON.stringify(data.company),
            disabled: true
          }
        }
      });
      return true;
    } catch (error) {
      dispatch({
        type: 'fetching_order_error',
        message: 'Fetching order data error'
      });
      return false;
    }
  };
};

export const clearOrderInfo = () => ({ type: 'clear_order_information' });
