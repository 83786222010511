import styled, { css } from 'styled-components';

import { color, mixin } from 'shared/utils/styles';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  ${(props) =>
    props.bordered &&
    css`
      thead.ant-table-thead tr th {
        background: #ffffff;
        font-weight: bold;
        height: 50px;
        ${props.hasHeaderColor &&
        css`
          padding: 0 !important;
        `}
      }
    `}

  div.ant-table-body {
    margin-right: 9px;
  }

  .ant-table-body,
  .ant-table-content {
    ${mixin.customScrollbar}
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  }

  .ant-table-tbody > tr > td {
    color: #999999;
    &:last-child {
      border-right-color: transparent !important;
    }
  }

  .ant-table-pagination {
    display: none;
  }

  .ant-table.ant-table-bordered .ant-table-container {
    border: none !important;
  }

  .ant-table-title {
    border: none !important;
    padding: 0 !important;
  }

  .ant-table-tbody .ant-form-item-explain {
    font-size: 12px;
  }
`;

export const Thead = styled.div`
  padding: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || '#000'};
  letter-spacing: 0.1px;
  background-color: ${(props) => props.bgColor || '#ffffff'};

  &.thead-parent {
    text-transform: uppercase;
    justify-content: center;
  }
`;

export const HeaderTbl = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bolder;
  align-items: center;
`;

export const TablePaginationToolbar = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${color.textLight};
  font-weight: 500;

  button:first-child {
    margin-left: 5px;
  }
`;
