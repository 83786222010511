import styled from 'styled-components';
import Button from '../Button';

export const StyledSearchInput = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const SearchButton = styled(Button)`
  height: 100%;
  position: absolute;
  right: 0;
  div {
    display: none;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: flex-start;
`;
