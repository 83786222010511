import * as ediLogServices from 'services/edi/ediLog';
import { createApiAction, createReducer } from 'shared/utils/redux';

const initialState = {
  fetchLoading: false,
  data: [],
  error: false
};

export const fetchEdiLogDataAction = createApiAction(
  'fetchEdiLogDataAction',
  (params) => ({
    callAPI: () => ediLogServices.fetchEdiLogTblData(params)
  })
);

export default createReducer(initialState, {
  [fetchEdiLogDataAction.request]: (state) => ({
    ...state,
    fetchLoading: true,
    currentEquipment: {},
    error: false
  }),
  [fetchEdiLogDataAction.success]: (state, { response }) => ({
    ...state,
    data: response.data,
    meta: response.meta,
    fetchLoading: false,
    error: false
  }),
  [fetchEdiLogDataAction.failure]: (state) => ({
    ...state,
    error: true,
    data: [],
    fetchLoading: false
  })
});
