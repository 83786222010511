import { createAction, createReducer } from 'redux-act';
import { push, LOCATION_CHANGE } from 'connected-react-router';

import { routeConfig } from 'configs/route';
import { getModes } from 'services/mode';
import {
  getWaveSetups,
  createWaveSetup
} from 'services/integratedShipping/waveSetup';

const RAIL_MODE = 'Rail';
const initialState = {
  railMode: null,
  waveSetups: [],
  errors: []
};

const getRailModesSuccessAction = createAction('get rail modes success');
const getRailModesErrorAction = createAction('get rail modes error');

const getWaveSetupsSuccessAction = createAction('get wave setups success');
const getWaveSetupsErrorAction = createAction('get wave setups error');

const createWaveSetupErrorAction = createAction('create wave setup error');

export default createReducer(
  {
    [getRailModesSuccessAction]: (state, railMode) => ({
      ...state,
      railMode
    }),
    [getRailModesErrorAction]: (state) => ({
      ...state,
      railMode: null
    }),
    [getWaveSetupsSuccessAction]: (state, waveSetups) => ({
      ...state,
      waveSetups
    }),
    [getWaveSetupsErrorAction]: (state) => ({
      ...state,
      waveSetups: []
    }),
    [createWaveSetupErrorAction]: (state, errors) => ({
      ...state,
      errors
    }),
    [LOCATION_CHANGE]: () => initialState
  },
  initialState
);

export const getRailModeAction = () => async (dispatch) => {
  try {
    const response = await getModes({ mode: RAIL_MODE });
    const railMode = response.data.find((mode) => mode.mode === RAIL_MODE);
    dispatch(getRailModesSuccessAction(railMode));
  } catch (err) {
    dispatch(getRailModesErrorAction());
  }
};

export const getWaveSetupsAction = () => async (dispatch) => {
  try {
    const response = await getWaveSetups();
    dispatch(getWaveSetupsSuccessAction(response.data));
  } catch (err) {
    dispatch(getWaveSetupsErrorAction());
  }
};

export const createWaveSetupAction = (data) => async (dispatch) => {
  try {
    await createWaveSetup(data);
    dispatch(push(routeConfig.INTEGRATED_SHIPPING_WAVE_SETUP));
  } catch (err) {
    dispatch(createWaveSetupErrorAction(err.message));
  }
};
