/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Form,
  Alert,
  Input,
  Select,
  Radio,
  Space,
  Tooltip,
  Modal,
  Descriptions
} from 'antd';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';

import {
  SignUpPage,
  FormElement,
  FormHeading,
  Agreement,
  Actions,
  ActionButton
} from './Styles';

import CountrySelect from 'components/Commons/CountrySelect';
import { registerVendor } from 'services/vendor';
import { dispatchService } from 'shared/utils';
import { routeConfig } from 'configs/route';

export default () => {
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const [state, setState] = useState([]);
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState();

  const onFinish = (data) => {
    dispatchService(
      () =>
        registerVendor({
          ...data,
          country: countryCode
        }),
      () => {
        dispatch(push(routeConfig.VENDOR_SIGNUP_CONFIRM));
      },
      (err) => setError(err)
    );
  };

  const onCountryChange = (_, country) => {
    setState(country.state);
    setCountryCode(country.code);
  };

  const showVendorTypeInfo = () => {
    Modal.info({
      title: <b>Vendor Type</b>,
      width: 800,
      closable: true,
      content: (
        <Descriptions layout='horizontal' bordered column={1}>
          <Descriptions.Item label='Direct'>
            PO placed as a direct shipment to another company for agreed volume
            for a prearranged sale
          </Descriptions.Item>
          <Descriptions.Item label='Indirect'>
            The volume not dedicated to one company or is purchased in advance
            based on a forecast without a firm PO
          </Descriptions.Item>
        </Descriptions>
      )
    });
  };

  return (
    <SignUpPage>
      <FormElement>
        <FormHeading>Join as new vendor</FormHeading>
        {error && <Alert type='error' message={error} />}
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item label='Vendor Type'>
            <Space>
              <Form.Item name='vendorType' initialValue={'direct'}>
                <Radio.Group
                  optionType='button'
                  buttonStyle='solid'
                  options={[
                    {
                      label: 'Direct Vendor',
                      value: 'direct'
                    },
                    {
                      label: 'Indirect Vendor',
                      value: 'indirect'
                    }
                  ]}
                />
              </Form.Item>
              <Tooltip title='Need help?'>
                <QuestionCircleOutlined onClick={showVendorTypeInfo} />
              </Tooltip>
            </Space>
          </Form.Item>
          <Form.Item
            label='Your Company Name'
            name='companyName'
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'country'}
            label='Country'
            rules={[
              {
                required: true
              }
            ]}
          >
            <CountrySelect onChange={onCountryChange} />
          </Form.Item>
          <Form.Item
            name={'state'}
            label='State/Province'
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              options={state.map((s) => ({
                label: s.state,
                value: s.code
              }))}
            />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input type='email' />
          </Form.Item>
          <Form.Item>
            <Form.Item
              label='First Name'
              name='firstName'
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Last Name'
              name='lastName'
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px'
              }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input type='password' />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
              })
            ]}
          >
            <Input type='password' />
          </Form.Item>
          <Agreement>
            By signing up means that you agreeing to the Netwila{' '}
            <a
              href='https://app.netwila.ca/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              term &amp; conditions
            </a>{' '}
            and{' '}
            <a
              href='https://app.netwila.ca/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              privacy policy
            </a>
          </Agreement>
          <Actions>
            <ActionButton type='submit' variant='primary'>
              Sign Up
            </ActionButton>
          </Actions>
        </Form>
      </FormElement>
    </SignUpPage>
  );
};
