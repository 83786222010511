import styled from 'styled-components';
import { Divider, Table } from 'antd';

import { color, mixin } from 'shared/utils/styles';
import { Button } from 'shared/components';

export const StyledTable = styled(Table)`
  width: 100%;
  height: 100%;

  thead.ant-table-thead tr th {
    font-weight: bold;
    padding: 0 !important;
    letter-spacing: 0.2px;
    color: ${color.textBlack};
    background: #ffffff;
  }

  .ant-table-body,
  .ant-table-content {
    ${mixin.customScrollbar}
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  }

  .ant-table-tbody > tr > td {
    color: #999999;
    &:last-child {
      border-right-color: transparent;
    }
  }

  .ant-table-pagination {
    display: none;
  }

  .ant-table.ant-table-bordered .ant-table-container {
    border: none !important;
  }

  .ant-table-title {
    border: none !important;
    padding: 0 !important;
  }

  th.ant-table-cell.ant-table-cell-fix-right-first {
    right: 8px !important;
  }
`;

export const TableGroupFilterContainer = styled.div`
  ${StyledTable}:not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const TableToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Expand = styled(Button)`
  text-align: center;
  font-weight: bolder !important;
  > div {
    font-weight: bolder !important;
  }
`;

export const PopoverHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopoverContent = styled.div`
  overflow-y: auto;
  width: 250px;
  max-height: 400px;
  ${mixin.customScrollbar}
`;

export const StyledDivider = styled(Divider)`
  margin: 7px 0 !important;
`;

export const Thead = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor || '#ffffff'};

  &.parent-header {
    height: 60px;
    justify-content: center;
    text-transform: uppercase;
  }

  &.child-header {
    height: 50px;
  }
`;
