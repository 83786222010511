import styled from 'styled-components';
import { color, font } from 'shared/utils/styles';
import { Icon, Input } from 'shared/components';

export const QuickAccess = styled.div`
  padding: 25px 35px 60px;
`;

export const SearchInputCont = styled.div`
  position: relative;
  padding-right: 30px;
  margin-bottom: 40px;
`;

export const SearchInputDebounced = styled(Input)`
  height: 40px;
  input {
    padding: 0 0 0 32px;
    border: none;
    border-bottom: 2px solid ${color.primary};
    background: #fff;
    ${font.size(21)}
    &:focus,
    &:hover {
      box-shadow: none;
      border: none;
      border-bottom: 2px solid ${color.primary};
      background: #fff;
    }
  }
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 0;
  color: ${color.textMedium};
`;

export const SectionTitle = styled.div`
  text-transform: uppercase;
  color: ${color.textMedium};
  font-weight: bold;
  ${font.size(11.5)}
  padding-bottom: 15px;
`;

export const Item = styled.div`
  padding: 5px;
  &:hover {
    background: ${color.backgroundLight};
  }
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  text-transform: capitalize;
  color: ${color.textMedium};
  ${font.size(11.5)}
`;
