/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Button, Radio, message } from 'antd';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useParams, Link } from 'react-router-dom';

import { routeConfig } from 'configs/route';
import { StyledLayout, Heading, FormHeading, FormElement } from './Styles';
import { dispatchService, buildPath, capitalize } from 'shared/utils';
import { sellerLogin } from 'services/sourcing';
import { getCurrentUser } from 'services/user';
import { setLoggedCompany, setLoggedAccount } from 'services/company';
import { storeAccessToken, storeRefreshToken } from 'shared/utils/authToken';

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (data) => {
    dispatchService(
      () => sellerLogin(data),
      (authResp) => {
        storeAccessToken(authResp.accessToken);
        storeRefreshToken(authResp.refreshToken);
        dispatchService(
          () => getCurrentUser(),
          (resp) => {
            const user = resp[0];
            if (!user) throw new Error('Login error');
            if (!user.company) {
              return dispatch(
                push(buildPath(routeConfig.SOURCING_ACCOUNT_TYPE))
              );
            }
            if (user.company.pendingStatus === true) {
              return dispatch(push(routeConfig.SOURCING_SIGNUP_CONFIRM));
            }
            setLoggedCompany(user.company);
            setLoggedAccount(user.account[0].number);
            if (data.loginSite === 'netwila') return dispatch(push('/'));
            const params = new URLSearchParams(window.location.search);
            window.location = `${
              process.env.REACT_APP_API_BASE_URL
            }/sourcing/auth/login?token=${
              authResp.accessToken
            }&${params.toString()}`;
          },
          (err) => message.error(err)
        );
      },
      (err) => message.error(err)
    );
  };

  return (
    <StyledLayout>
      <Row justify='center' align='middle'>
        <Col flex='400px'>
          <FormElement>
            <Heading>
              <FormHeading>Netwila Login</FormHeading>
            </Heading>
            <Form form={form} onFinish={onFinish} layout='vertical'>
              <Form.Item
                label='Email'
                name='email'
                placeholder='Enter Email Address'
                rules={[
                  {
                    required: true,
                    type: 'email'
                  }
                ]}
              >
                <Input type='email' />
              </Form.Item>
              <Form.Item
                name='password'
                label='Password'
                type='password'
                placeholder='Enter Password'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input type='password' />
              </Form.Item>
              <Form.Item
                label='Login site'
                initialValue={'storefront'}
                name='loginSite'
              >
                <Radio.Group
                  optionType='button'
                  buttonStyle='solid'
                  options={[
                    {
                      label: 'NSCM Global Sourcing',
                      value: 'storefront'
                    },
                    {
                      label: 'Netwila Logistics',
                      value: 'netwila'
                    }
                  ]}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType='submit'
                  style={{ width: '100%' }}
                  type='primary'
                >
                  Login
                </Button>
              </Form.Item>
              <Form.Item>
                Don&apos;t have an account yet?{' '}
                <Link to='/sourcing/type'>Sign Up</Link>
              </Form.Item>
            </Form>
          </FormElement>
        </Col>
      </Row>
    </StyledLayout>
  );
};

export default Login;
