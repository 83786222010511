import { createAction, createReducer } from 'redux-act';

import {
  getUserPolicy,
  getUnattachedPolicy,
  addUserPolicy,
  deleteUserPolicy,
  getPolicies,
  getCompanyPolicies,
  updateCompanyPolicies,
  deleteCompanyPolicy
} from 'services/uaa/policy';

const initialState = {
  userPolicy: {},
  unattachedPolicies: [],
  companyPolicy: []
};

const getUserPolicySuccessAction = createAction('getUserPolicySuccessAction');
const getUnattachedPolicySuccessAction = createAction(
  'getUnattachedPolicySuccessAction'
);
const getPoliciesSuccessAction = createAction('getPoliciesSuccessAction');
const getCompanyPoliciesSuccessAction = createAction(
  'getCompanyPoliciesSuccessAction'
);

export default createReducer(
  {
    [getUserPolicySuccessAction]: (state, userPolicy) => ({
      ...state,
      userPolicy
    }),
    [getUnattachedPolicySuccessAction]: (state, unattachedPolicies) => ({
      ...state,
      unattachedPolicies
    }),
    [getPoliciesSuccessAction]: (state, policies) => ({
      ...state,
      policies
    }),
    [getCompanyPoliciesSuccessAction]: (state, companyPolicy) => ({
      ...state,
      companyPolicy
    })
  },
  initialState
);

export const getPoliciesAction = (params) => async (dispatch) => {
  const response = await getPolicies(params);
  dispatch(getPoliciesSuccessAction(response.data));
};

export const getUserPolicyAction = (userId) => async (dispatch) => {
  const response = await getUserPolicy(userId);
  dispatch(getUserPolicySuccessAction(response.data));
};

export const getUnattachedPolicyAction = (userId) => async (dispatch) => {
  const response = await getUnattachedPolicy(userId);
  dispatch(getUnattachedPolicySuccessAction(response.data));
};

export const addUserPolicyAction = (userId, account, policies) => async (
  dispatch
) => {
  const userPolicies = {
    userId,
    permissions: policies.map((policy) => ({ policy: policy.id, account }))
  };
  await addUserPolicy(userPolicies);
  dispatch(getUserPolicyAction(userId));
};

export const deleteUserPolicyAction = (userId, policyId) => async (
  dispatch
) => {
  await deleteUserPolicy(userId, policyId);
  dispatch(getUserPolicyAction(userId));
};

export const getCompanyPolicyAction = (companyId, params = {}) => async (
  dispatch
) => {
  const response = await getCompanyPolicies(companyId, params);
  dispatch(getCompanyPoliciesSuccessAction(response.data));
};

export const updateCompanyPolicyAction = (companyId, policies) => async (
  dispatch
) => {
  await updateCompanyPolicies(companyId, policies);
  dispatch(getCompanyPolicyAction(companyId));
};

export const deleteCompanyPolicyAction = (policyId) => async () => {
  await deleteCompanyPolicy(policyId);
};
