import http from 'shared/utils/http';

export const createBlockSetup = (data) => {
  return http.post(`/netwila/container/block`, data);
};

export const updateBlockSetup = (id, data) => {
  return http.put(`/netwila/container/block/${id}`, data);
};

export const getBlocks = (params = {}) => {
  return http.get(`/netwila/container/block?limit=10`, params);
};

export const getDetail = (id) => {
  return http.get(`/netwila/container/block/${id}`);
};

export const deleteBlock = (id) => {
  return http.delete(`/netwila/container/block/${id}`);
};

export const deleteBlockSlot = (id) => {
  return http.delete(`/netwila/container/blockslot/${id}`);
};

export const checkSlotsAvailability = (id) => {
  return http.get(`/netwila/container/checkavailability/${id}`);
};
