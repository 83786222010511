import { createAction, createReducer } from 'redux-act';
import {
  validateImportedTasks,
  createImportedTasks,
  getTasks,
  updateTask,
  getTaskTypes,
  getTask,
  updateOpsCost,
  createTruckDriverTask,
  updateTask2,
  deleteTask
} from 'services/task';
import {
  CONTRACT_MODES,
  CONTRACT_MODE_ROAD,
  CONTRACT_MODE_SMART_OCEAN,
  CONTRACT_MODE_SMART_RAIL
} from 'configs/app';

const getInboundOceanTasksSuccessAction = createAction(
  'get inbound ocean tasks'
);
const getInlandRailTasksSuccessAction = createAction('get inland rail tasks');
const getOutboundRailTasksSuccessAction = createAction(
  'get outbound rail tasks'
);
const getOutboundOceanTasksSuccessAction = createAction(
  'get outbound ocean tasks'
);

const loadInboundOceanTasksAction = createAction('load inbound ocean tasks');
const saveInboundOceanTasksAction = createAction('save inbound ocean tasks');

const loadInlandRailTasksAction = createAction('load inland rail tasks');
const saveInlandRailTasksAction = createAction('save inland rail tasks');

const loadMatchbackTasksAction = createAction('load matchback tasks');
const saveMatchbackTasksAction = createAction('save matchback tasks');

const loadOutboundOceanTasksAction = createAction('load inland rail tasks');
const saveOutboundOceanTasksAction = createAction('save inland rail tasks');

const loadRepairTrackingTasksAction = createAction(
  'load repair tracking tasks'
);
const resetTasksAction = createAction('reset tasks');
const updateInlandRailTaskAction = createAction('updateInlandRailTaskAction');
const updateMatchBackTaskAction = createAction('updateMatchBackTaskAction');
const updateOutboundOceanTaskAction = createAction(
  'updateOutboundOceanTaskAction'
);
const getTaskTypesSuccessAction = createAction('getTaskTypesSuccessAction');
const getRoadTasksSuccessAction = createAction('getRoadTasksSuccessAction');
const getTaskSuccessAction = createAction('getTaskSuccessAction');
const getTasksSuccessAction = createAction('getTasksSuccessAction');

const initialState = {
  inboundOceanTasks: [],
  savedInboundOceanTasks: [],
  inlandRailTasks: [],
  savedInlandRailTasks: [],
  matchbackTasks: [],
  savedMatchbackTasks: [],
  outboundOceanTasks: [],
  savedOutboundOceanTasks: [],
  repairTrackingTasks: [],
  roadTasks: {
    data: [],
    meta: {}
  },
  types: [],
  taskList: {
    data: [],
    meta: {}
  },
  tasks: {}
};

const getUpdateTaskList = (data, newItem) => {
  const newData = [...data];
  const index = newData.findIndex((item) => newItem.key === item.key);
  if (index > -1) {
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...newItem });
  } else {
    newData.push(newItem);
  }

  return newData;
};

export default createReducer(
  {
    [loadInboundOceanTasksAction]: (state, inboundOceanTasks) => ({
      ...state,
      inboundOceanTasks
    }),
    [saveInboundOceanTasksAction]: (state, savedInboundOceanTasks) => ({
      ...state,
      savedInboundOceanTasks
    }),
    [loadInlandRailTasksAction]: (state, inlandRailTasks) => ({
      ...state,
      inlandRailTasks
    }),
    [saveInlandRailTasksAction]: (state, savedInlandRailTasks) => ({
      ...state,
      savedInlandRailTasks
    }),
    [loadMatchbackTasksAction]: (state, matchbackTasks) => ({
      ...state,
      matchbackTasks
    }),
    [saveMatchbackTasksAction]: (state, savedMatchbackTasks) => ({
      ...state,
      savedMatchbackTasks
    }),
    [loadOutboundOceanTasksAction]: (state, outboundOceanTasks) => ({
      ...state,
      outboundOceanTasks
    }),
    [saveOutboundOceanTasksAction]: (state, savedOutboundOceanTasks) => ({
      ...state,
      savedOutboundOceanTasks
    }),
    [loadRepairTrackingTasksAction]: (state, repairTrackingTasks) => ({
      ...state,
      repairTrackingTasks
    }),
    [getInboundOceanTasksSuccessAction]: (state, inboundOceanTasks) => ({
      ...state,
      inboundOceanTasks
    }),

    [getInlandRailTasksSuccessAction]: (state, inlandRailTasks) => ({
      ...state,
      inlandRailTasks
    }),
    [getOutboundRailTasksSuccessAction]: (state, matchbackTasks) => ({
      ...state,
      matchbackTasks
    }),
    [getOutboundOceanTasksSuccessAction]: (state, outboundOceanTasks) => ({
      ...state,
      outboundOceanTasks
    }),
    [updateInlandRailTaskAction]: (state, data) => ({
      ...state,
      inlandRailTasks: getUpdateTaskList(state.inlandRailTasks, data)
    }),
    [updateMatchBackTaskAction]: (state, data) => ({
      ...state,
      matchbackTasks: getUpdateTaskList(state.matchbackTasks, data)
    }),
    [updateOutboundOceanTaskAction]: (state, data) => ({
      ...state,
      outboundOceanTasks: getUpdateTaskList(state.outboundOceanTasks, data)
    }),
    [getTaskTypesSuccessAction]: (state, types) => ({
      ...state,
      types
    }),
    [getRoadTasksSuccessAction]: (state, { data, meta }) => ({
      ...state,
      roadTasks: { data, meta }
    }),
    [getTaskSuccessAction]: (state, task) => ({
      ...state,
      tasks: {
        ...state.tasks,
        [task.id]: task
      }
    }),
    [getTasksSuccessAction]: (state, { data, meta }) => ({
      ...state,
      taskList: {
        data,
        meta
      }
    }),
    [resetTasksAction]: () => ({
      ...initialState
    })
  },
  initialState
);

export const validateImportedTasksAction = (data) => {
  return async () => {
    const response = await validateImportedTasks(data);
    return response;
  };
};

export const createImportedTasksAction = (data) => {
  return async () => {
    const response = await createImportedTasks(data);
    return response;
  };
};

// For inbound ocean tasks
export const getInboundOceanTasksAction = () => async (dispatch) => {
  const response = await getTasks({
    mode: CONTRACT_MODES[CONTRACT_MODE_SMART_OCEAN]
  });
  dispatch(getInboundOceanTasksSuccessAction(response.data));
};

// For inland rail tasks
export const getInlandRailTasksAction = () => async (dispatch) => {
  const response = await getTasks({
    mode: CONTRACT_MODES[CONTRACT_MODE_SMART_RAIL]
  });
  dispatch(getInlandRailTasksSuccessAction(response.data));
};

// For matchback tasks
export const getOutboundRailTasksAction = () => async (dispatch) => {
  const response = await getTasks({
    mode: CONTRACT_MODES[CONTRACT_MODE_SMART_RAIL]
  });
  dispatch(getOutboundRailTasksSuccessAction(response.data));
};

// For outbound ocean tasks
export const getOutboundOceanTasksAction = () => async (dispatch) => {
  const response = await getTasks({
    mode: CONTRACT_MODES[CONTRACT_MODE_SMART_OCEAN]
  });
  dispatch(getOutboundOceanTasksSuccessAction(response.data));
};

export const updateTaskAction = (task) => async () => {
  const response = await updateTask(task);
  return response.data;
};

export const getTaskTypesAction = () => async (dispatch) => {
  const response = await getTaskTypes();
  dispatch(getTaskTypesSuccessAction(response.data));
};

export const getRoadTasksAction = () => async (dispatch) => {
  const response = await getTasks({
    mode: CONTRACT_MODES[CONTRACT_MODE_ROAD]
  });
  dispatch(getRoadTasksSuccessAction(response));
};

export const getTasksAction = () => async (dispatch) => {
  const response = await getTasks();
  dispatch(getTasksSuccessAction(response));
};

export const getTaskAction = (id) => async (dispatch) => {
  const response = await getTask(id);
  dispatch(getTaskSuccessAction(response.data));
};

export const updateTaskCostAction = (id, cost) => async (dispatch) => {
  await updateOpsCost(id, cost);
  dispatch(getRoadTasksAction());
};

export const createTruckDriverTaskAction = (data) => async () => {
  await createTruckDriverTask(data);
};

export const updateTask2Action = (id, data) => async () => {
  await updateTask2(id, data);
};

export const deleteTaskAction = (id) => async () => {
  await deleteTask(id);
};

export {
  loadInboundOceanTasksAction,
  saveInboundOceanTasksAction,
  loadInlandRailTasksAction,
  saveInlandRailTasksAction,
  loadMatchbackTasksAction,
  saveMatchbackTasksAction,
  loadOutboundOceanTasksAction,
  loadRepairTrackingTasksAction,
  saveOutboundOceanTasksAction,
  resetTasksAction,
  updateInlandRailTaskAction,
  updateMatchBackTaskAction,
  updateOutboundOceanTaskAction
};

export const roadTasksSelector = (state) => state.companyTask.roadTasks;
export const taskSelector = (id) => (state) => state.companyTask.tasks[id];
export const tasksSelector = (state) => state.companyTask.taskList;
