import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider } from 'antd';
import LoadableComponent from 'components/LoadableComponent';
import { EditableCell, Icon } from 'shared/components';
import {
  getCompanyAction,
  getCurrentCompanyAction,
  getCompanyTypeAction
} from 'reducers/company/companyManagement';
import { openModal } from 'reducers/modalLot';
import { StyledCompanyDropdown, StyledSelect, StyledAction } from './Styles';

const { Option } = StyledSelect;

const defaultMapper = (v) => ({ _id: v._id, name: v.legalName });

export default React.forwardRef(
  (
    {
      name,
      queryValue,
      mapper = defaultMapper,
      showActionBtn = true,
      formProps
    },
    ref
  ) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { currentCompany, companyType } = useSelector(
      (state) => state.company
    );
    const dispatch = useDispatch();
    const form = formProps || useContext(EditableCell.EditableContext);

    useEffect(() => {
      if (currentCompany?.companyLink && currentCompany.companyLink?.length) {
        const companiesTemp = currentCompany.companyLink
          .filter(
            (c) =>
              !!c.companyType.find((type) => type.companyType === queryValue)
          )
          .map(mapper);
        setData(companiesTemp);
      }
    }, [currentCompany, queryValue, mapper]);

    useEffect(() => {
      if (!companyType.length) {
        dispatch(getCompanyTypeAction());
      }
    }, [dispatch, companyType]);

    const onSelectChange = (value) => {
      try {
        if (!value) {
          form.resetFields([name]);
        } else {
          form.setFieldsValue({ [name]: value });
        }
      } catch (e) {
        // console.log(e);
      }
    };

    const onAddCompanyPartner = async () => {
      const companyPartnerModal = LoadableComponent(() =>
        import('components/Commons/AddCompanyPartnerModal')
      );
      const currentCompanyType = companyType.find(
        (c) => c.companyType === queryValue
      );

      const companyPartner = await dispatch(
        openModal(companyPartnerModal, {
          currentCompany,
          defaultCompanyType: currentCompanyType
        })
      );

      if (companyPartner) {
        await dispatch(getCompanyAction(currentCompany._id));
        //set new company to current value
        const { newCompany } = companyPartner;
        if (
          newCompany &&
          !!newCompany.companyType.find((c) => c.companyType === queryValue)
        ) {
          form.setFieldsValue({
            [name]: JSON.stringify({
              id: newCompany._id,
              name: newCompany.legalName
            })
          });
        }
      }
    };

    const refetchData = async () => {
      setLoading(true);
      await dispatch(getCurrentCompanyAction(true));
      setLoading(false);
    };

    return (
      <StyledCompanyDropdown>
        <StyledSelect
          loading={loading}
          filterOption
          optionFilterProp='children'
          showSearch
          allowClear
          onChange={onSelectChange}
          defaultValue={form.getFieldValue(name)}
          ref={ref}
        >
          {data.length &&
            data.map((company) => (
              <Option
                value={JSON.stringify({ _id: company._id, name: company.name })}
                key={company._id}
              >
                {company.name}
              </Option>
            ))}
        </StyledSelect>
        {showActionBtn ? (
          <StyledAction>
            <Button
              type='link'
              size='small'
              onClick={refetchData}
              className={loading ? 'rotate' : ''}
            >
              <Icon size={13} type='reload' />
            </Button>
            <Divider type='vertical' />
            <Button onClick={onAddCompanyPartner} size='small' type='link'>
              Add
            </Button>
          </StyledAction>
        ) : null}
      </StyledCompanyDropdown>
    );
  }
);
