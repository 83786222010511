import toast from 'shared/utils/toast';
import { createReducer } from 'redux-act';
import { createActionCRUDResources } from 'shared/utils/reducerHelper';
import * as integratedContainerSetupServices from 'services/masterSchedule/integratedContainerSetup';

const initialState = {
  fetchLoading: false,
  createLoading: false,
  data: [],
  error: false,
  createError: false,
  message: '',
  createMessage: ''
};

const integratedContainerSetupAction = createActionCRUDResources(
  'integrated container setup'
);

export default createReducer(
  {
    [integratedContainerSetupAction.fetch.start]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [integratedContainerSetupAction.fetch.success]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [integratedContainerSetupAction.fetch.error]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    }),
    [integratedContainerSetupAction.create.start]: (state) => ({
      ...state,
      createLoading: true,
      createError: false
    }),
    [integratedContainerSetupAction.create.success]: (state) => ({
      ...state,
      createLoading: false
    }),
    [integratedContainerSetupAction.create.error]: (state, payload) => ({
      ...state,
      createLoading: false,
      createError: true,
      createMessage: payload
    })
  },
  initialState
);

export const fetchIntegratedContainerSetupTableCreator = (companyId, param) => {
  return async (dispatch) => {
    try {
      dispatch(integratedContainerSetupAction.fetch.start());
      const response = await integratedContainerSetupServices.fetchIntegratedContainerSetupTblData(
        companyId,
        param
      );
      dispatch(integratedContainerSetupAction.fetch.success(response.data));
      return true;
    } catch (error) {
      dispatch(integratedContainerSetupAction.fetch.error());
    }
    return false;
  };
};

export const createIntegratedContainerSetupCreator = (companyId, data) => {
  return async (dispatch) => {
    try {
      dispatch(integratedContainerSetupAction.create.start());
      const response = await integratedContainerSetupServices.createIntegratedContainerSetup(
        {
          ...data,
          companyId
        }
      );
      toast.success('Integrated container setup has been created');
      dispatch(integratedContainerSetupAction.create.success(response));
      return response;
    } catch (error) {
      dispatch(integratedContainerSetupAction.create.error());
    }
    toast.error('Error occurred when creating Integrated container setup');
    return false;
  };
};

export const updateIntegratedContainerSetupCreator = (
  integratedContainerSetupId,
  companyId,
  data
) => {
  return async (dispatch) => {
    try {
      dispatch(integratedContainerSetupAction.update.start());
      const response = await integratedContainerSetupServices.updateIntegratedContainerSetup(
        integratedContainerSetupId,
        {
          ...data,
          companyId
        }
      );
      toast.success('Integrated container setup has been updated');
      dispatch(integratedContainerSetupAction.update.success(response));
      return response;
    } catch (error) {
      dispatch(integratedContainerSetupAction.update.error());
    }
    toast.error('Error occurred when updating Integrated container setup');
    return false;
  };
};

export const deleteIntegratedContainerSetupCreator = (
  integratedContainerSetupId
) => {
  return async (dispatch) => {
    try {
      dispatch(integratedContainerSetupAction.delete.start());
      await integratedContainerSetupServices.deleteIntegratedContainerSetup(
        integratedContainerSetupId
      );
      toast.success('Integrated container setup has been deleted');
      dispatch(integratedContainerSetupAction.delete.success());
      return true;
    } catch (error) {
      dispatch(integratedContainerSetupAction.delete.error());
    }
    toast.error('Error occurred when deleting Integrated container setup');
    return false;
  };
};
