import { createReducer, createAction } from 'redux-act';
import toast from 'shared/utils/toast';

import {
  fetchData,
  createData,
  deleteDataById,
  updateDataById
} from 'services/integratedShipping/smartInMode';

const initialState = {
  isFetching: true,
  data: [],
  isUpdate: false,
  error: false,
  message: ''
};

const fetchDataActionStart = createAction('start fetch smart in mode list');
const fetchDataActionSuccess = createAction('fetch smart in mode list success');
const fetchDataActionError = createAction('fetch smart in mode list error');

const createUpdateDataActionStart = createAction(
  'start create/update smart in mode'
);
const createUpdateDataActionSucess = createAction(
  'create/update smart in mode success'
);
const createUpdateDataActionError = createAction(
  'create/update smart in mode error'
);

export default createReducer(
  {
    [fetchDataActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      data: payload.data
    }),
    [fetchDataActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [createUpdateDataActionStart]: (state) => ({
      ...state,
      isUpdate: true
    }),
    [createUpdateDataActionSucess]: (state) => ({
      ...state,
      isUpdate: false
    }),
    [createUpdateDataActionError]: (state) => ({
      ...state,
      isUpdate: false
    })
  },
  initialState
);

export const fetchSmartInModeList = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchData(companyId);
      dispatch(fetchDataActionSuccess({ data: res.data }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const createSmartInModeAction = (companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await createData(companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Smart in mode has been created');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when creating smart in mode');
    }
  };
};

export const updateSmartInModeAction = (id, companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await updateDataById(id, companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Smart in mode has been updated');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when updating smart in mode');
    }
  };
};

export const deleteSmartInModeAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await deleteDataById(id);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Smart in mode has been deleted');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when deleting smart in mode');
    }
  };
};
