import http from 'shared/utils/http';

export const fetchData = (companyId, param = {}) => {
  return http.get(`/api/integrated-shipping/smart-out-mode`, {
    companyId,
    ...param
  });
};

export const createData = (companyId, data) => {
  return http.post(`/api/integrated-shipping/smart-out-mode`, {
    companyId,
    ...data
  });
};

export const updateDataById = (id, companyId, data) => {
  return http.put(`/api/integrated-shipping/smart-out-mode/${id}`, {
    companyId,
    ...data
  });
};

export const deleteDataById = (id) => {
  return http.delete(`/api/integrated-shipping/smart-out-mode/${id}`);
};
