import * as React from 'react';
import { Result } from 'antd';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <Result title='Please press home' extra={<a href='/'>Home</a>} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
