import styled from 'styled-components';
import { Table, Popover } from 'antd';

export const StyledTable = styled(Table)`
  .ant-table-title {
    padding: 8px;
  }
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 12px 0;
  }
`;

export const ColumnFilterHeader = styled.div`
  padding: 8px 0;
`;

export const ColumnFilterContent = styled.div`
  min-height: 100px;
  max-height: 250px;
  width: 300px;
  overflow-y: scroll;
  margin: 0 -16px;
  border-bottom: 1px solid #f0f0f0;

  .ant-checkbox-wrapper {
    display: block;
    padding: 5px 16px;

    & + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    &:hover {
      background-color: #defbff;
    }

    .ant-checkbox {
      display: inline-block;
    }
  }
`;

export const ColumnFilterFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;
