import http from 'shared/utils/http';

export const validateImportedTasks = (data) => {
  return http.put('/netwila/task/import', data);
};

export const createImportedTasks = (data) => {
  return http.post('/netwila/task/import', data);
};

export const getTasks = (filter) => {
  return http.get('/netwila-core/task', filter);
};

export const updateTask = (task) => {
  return http.post('/netwila/task', task);
};

export const getTaskTypes = () => {
  return http.get('/netwila/task/type');
};

export const getTask = (id) => {
  return http.get(`/netwila-core/task/${id}`);
};

export const updateOpsCost = (id, cost) => {
  return http.post('/netwila-core/task/cost', { taskId: id, cost });
};

export const createTruckDriverTask = (data) => {
  return http.post('/netwila-core/task/truck-driver', data);
};

export const updateTask2 = (id, data) => {
  return http.put(`/netwila-core/task/${id}`, data);
};

export const deleteTask = (id) => {
  return http.delete(`/netwila-core/task/${id}`);
};

export const getOrderTasks = (orderNumber) => {
  return http.get(`/netwila-core/task/order/${orderNumber}`);
};

export const createRoadTask = (data) => {
  return http.post('/netwila-core/task/road', data);
};

export const createAutoTask = (data) => {
  return http.post('/netwila-core/task/automotive', data);
};

export const createRailTask = (data) => {
  return http.post('/netwila-core/task/rail', data);
};

export const updateRailTask = (data) => {
  return http.put('/netwila-core/task/rail', data);
};

export const updateRoadTask = (data) => {
  return http.put('/netwila-core/task/road', data);
};

export const updateOceanTask = (data) => {
  return http.put('/netwila-core/task/ocean', data);
};

export const createDrayageTask = (data) => {
  return http.post('/netwila-core/task/drayage', data);
};

export const createOceanTask = (data) => {
  return http.post('/netwila-core/task/ocean', data);
};

export const updateDrayageTask = (data) => {
  return http.put('/netwila-core/task/drayage', data);
};

export const addTaskActivity = (data) => {
  return http.post('/netwila-core/task/activity', data);
};

export const getTaskActivity = (id, params) => {
  return http.get(`/netwila-core/task/${id}/activity`, params);
};

export const addTaskDocument = (id, data) => {
  return http.put(`/netwila-core/task/${id}/documents`, data);
};

export const deleteTaskDocument = (id, docId) => {
  return http.delete(`/netwila-core/task/${id}/documents`, { docId });
};

export const getEquipmentTracking = (taskId) => {
  return http.get(`/asset/automotive/tracking`, { taskId });
};

export const getDrayages = (filter) => {
  return http.get('/netwila-core/task/drayages', filter);
};

export const updateTaskOrder = (data) => {
  return http.post('/netwila-core/task/task-order', data);
};
