import React from 'react';
import { Layout, Row, Col, Card } from 'antd';

import ButtonLink from 'shared/components/ButtonLink';
import Logo from 'assets/images/nscmglobal.png';
// eslint-disable-next-line no-unused-vars
import BuyerBgImg from 'assets/images/search_products.jpg';
// eslint-disable-next-line no-unused-vars
import SellerBgImg from 'assets/images/purchase_order.jpg';

import { routeConfig } from 'configs/route';

const { Header, Content } = Layout;

export default () => {
  return (
    <Layout style={{ background: '#fff' }}>
      <Header>
        <div className='logo'>
          <img src={Logo} alt='' />
        </div>
      </Header>
      <Content>
        <div
          className='site-layout-content'
          style={{ backgroundColor: '#fff' }}
        >
          <Row justify='center' style={{ marginTop: 32 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <h2
                style={{ fontSize: 22, color: '#111111', fontWeight: 'normal' }}
              >
                Are you a Buyer or Seller?
              </h2>
            </Col>
          </Row>
          <Row gutter={16} justify='center' style={{ marginTop: 16 }}>
            <Col span={6}>
              <Card
                title={<b>Source Products</b>}
                style={{
                  backgroundImage: `url(${BuyerBgImg})`,
                  color: '#fff',
                  borderRadius: 10
                }}
                headStyle={{
                  textAlign: 'center',
                  border: 'none',
                  color: '#fff',
                  backgroundColor: 'rgba(0, 0, 0, .5)',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  marginBottom: 0
                }}
                bodyStyle={{
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, .5)',
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10
                }}
              >
                <p>
                  Become a NSCM Global Buyer and into network of suppliers
                  across China, Vietnam, India and more...
                </p>
                <ButtonLink
                  type='primary'
                  to={`${routeConfig.SOURCING}/buyer/signup`}
                >
                  Buyer
                </ButtonLink>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                title={<b>Sell Products</b>}
                style={{
                  backgroundImage: `url(${SellerBgImg})`,
                  color: '#fff',
                  borderRadius: 10
                }}
                headStyle={{
                  textAlign: 'center',
                  border: 'none',
                  color: '#fff',
                  backgroundColor: 'rgba(0, 0, 0, .5)',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  marginBottom: 0
                }}
                bodyStyle={{
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, .5)',
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10
                }}
              >
                <p>
                  Become a NSCM Global Buyer and into network of suppliers
                  across China, Vietnam, India and more...
                </p>
                <ButtonLink
                  type='primary'
                  to={`${routeConfig.SOURCING}/seller/signup`}
                >
                  Seller
                </ButtonLink>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};
