import React from 'react';
import { Divider, Button, Select, DatePicker, Form, Input } from 'antd';

import { FilterActionWrapper, StyledFormInputs } from './Styles';

import LocationDropdown from 'shared/components/CommonFields/LocationDropdown';
import CompanyDropdown from 'shared/components/CommonFields/CompanyDropdown';

const FormFilter = ({ form, fields, onFinish }) => {
  const resetFilter = () => {
    form.resetFields();
    onFinish();
  };

  const renderFields = () => {
    return fields.map(({ name, label, type }) => {
      if (type === 'select') {
        return (
          <Form.Item key={name} name={name} label={label}>
            <Select>
              {Array.from({ length: 5 }).map((_, idx) => (
                <Select.Option key={idx}>{`${name} ${idx + 1}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }
      if (type === 'date') {
        return (
          <Form.Item key={name} name={name} label={label}>
            <DatePicker />
          </Form.Item>
        );
      }
      return (
        <Form.Item key={name} name={name} label={label}>
          <Input />
        </Form.Item>
      );
    });
  };

  const renderDefaultFields = () => (
    <>
      <Form.Item key='location' name='location' label='Location'>
        <LocationDropdown name='location' formProps={form} />
      </Form.Item>
      <Form.Item
        key='steamShipLine'
        name='steamShipLine'
        label='Steamship Line'
      >
        <CompanyDropdown
          name='steamShipLine'
          queryValue='Ocean Carrier'
          showActionBtn={false}
          formProps={form}
        />
      </Form.Item>
    </>
  );

  const renderFieldItems = () => {
    if (fields && fields.length) {
      return renderFields();
    }
    return renderDefaultFields();
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <StyledFormInputs col={!fields ? 2 : 0}>
          {renderFieldItems()}
        </StyledFormInputs>
        <FilterActionWrapper>
          <Button danger type='primary' onClick={resetFilter}>
            Reset
          </Button>
          <Button htmlType='submit' type='primary'>
            Filter
          </Button>
        </FilterActionWrapper>
      </Form>

      <Divider />
    </>
  );
};

export default FormFilter;
