import http from 'shared/utils/http';

import { getLoggedCompany } from 'services/company';

export const fetchOceanOderTblData = async (filter) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/api/order/oceanorder', 'get', {
    ...filter,
    companyId: loggedCompany._id
  });

  return response.data;
};

export const createOrderOcean = async (data) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/api/order/oceanorder', 'post', {
    ...data,
    companyId: loggedCompany._id
  });
  /**
   * TODO: get columns define from server
   * How to handle for error
   */

  return response.data;
};
