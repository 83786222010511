import http from 'shared/utils/http';

/***********Auto Yard Setup Service *****************/

export const createAutoYardSetup = (companyId, data) => {
  return http.post(`/asset/auto-yard-setup`, {
    companyId,
    ...data
  });
};

export const updateAutoYardSetup = (id, companyId, data) => {
  return http.put(`/asset/auto-yard-setup/${id}`, {
    companyId,
    ...data
  });
};

export const getAutoYardSetup = (companyId, params = {}) => {
  return http.get(`/asset/auto-yard-setup`, {
    companyId,
    ...params
  });
};

export const getAutoYardSetupDetail = (id) => {
  return http.get(`/asset/auto-yard-setup/detail/${id}`);
};

export const deleteAutoYardSetup = (id) => {
  return http.delete(`/asset/auto-yard-setup/${id}`);
};

export const findAutoYardAreas = (company, params = {}) => {
  return http.get(`/asset/auto-yard-setup/find-yard-area`, {
    customer: company,
    ...params
  });
};

/***********Auto Yard Management Service *****************/

export const createAutoYardManagement = (companyId, data) => {
  return http.post(`/asset/auto-yard-management`, {
    companyId,
    ...data
  });
};

export const updateAutoYardManagement = (id, companyId, data) => {
  return http.put(`/asset/auto-yard-management/${id}`, {
    companyId,
    ...data
  });
};

export const getAutoYardManagement = (companyId, params = {}) => {
  return http.get(`/asset/auto-yard-management`, {
    companyId,
    ...params
  });
};

export const getAutoYardManagementDetail = (id) => {
  return http.get(`/asset/auto-yard-management/detail/${id}`);
};

export const deleteAutoYardManagement = (id) => {
  return http.delete(`/asset/auto-yard-management/${id}`);
};

/***********Inbound From Yard *****************/

export const createInboundFromYard = (companyId, data) => {
  return http.post(`/asset/inbound-from-yard`, {
    companyId,
    ...data
  });
};

export const updateInboundFromYard = (id, companyId, data) => {
  return http.put(`/asset/inbound-from-yard/${id}`, {
    companyId,
    ...data
  });
};

export const getInboundFromYard = (companyId, params = {}) => {
  return http.get(`/asset/inbound-from-yard`, {
    companyId,
    ...params
  });
};

export const getInboundFromYardDetail = (id) => {
  return http.get(`/asset/inbound-from-yard/detail/${id}`);
};

export const deleteInboundFromYard = (id) => {
  return http.delete(`/asset/inbound-from-yard/${id}`);
};

export const exportInboundFromYard = (companyId, params = {}) => {
  return http.get(`/asset/inbound-from-yard/export`, {
    companyId,
    ...params
  });
};

/***********Outbound From Yard *****************/

export const createOutboundFromYard = (companyId, data) => {
  return http.post(`/asset/outbound-from-yard`, {
    companyId,
    ...data
  });
};

export const getOutboundFromYard = (companyId, params = {}) => {
  return http.get(`/asset/outbound-from-yard`, {
    companyId,
    ...params
  });
};

export const deleteOutboundFromYard = (id) => {
  return http.delete(`/asset/outbound-from-yard/${id}`);
};

/***********Daily Report *****************/
export const generateDailyReport = (companyId, data) => {
  return http.post(`/asset/daily-report/generate`, {
    companyId,
    ...data
  });
};

export const getDailyReport = (companyId, params = {}) => {
  return http.get(`/asset/daily-report/`, {
    companyId,
    ...params
  });
};

export const updateDailyReport = (id, companyId, data) => {
  return http.put(`/asset/daily-report/${id}`, {
    companyId,
    ...data
  });
};

export const getYardAreas = (yardAddress, filter) => {
  return http.get('/asset/auto-yard-setup/yard-area/', {
    yardAddress,
    ...filter
  });
};

export const getTasks = (params = {}) => {
  return http.get('/asset/automotive-yard/tasks', params);
};

export const assignWorker = (params = {}) => {
  return http.post('/asset/automotive-yard/assign-worker', params);
};

export const updateTaskStatus = (params) => {
  return http.put('/asset/automotive-yard/tasks', params);
};

export const getInventory = (id) => {
  return http.get(`/asset/auto-yard-management/inventory/${id}`);
};

export const updateInventory = (id, data) => {
  return http.put(`/asset/auto-yard-management/inventory/${id}`, data);
};

export const deleteInventory = (id) => {
  return http.delete(`/asset/auto-yard-management/inventory/${id}`);
};

export const exportInventory = (params) => {
  return http.post(`/asset/auto-yard-management/export`, params);
};

export const getDownloadHistory = (params = {}) => {
  return http.get(`/asset/auto-yard-management/export`, params);
};

export const getDownloadLink = (id) => {
  return http.get(`/asset/auto-yard-management/export/download`, { id });
};

export const getDownloadInventoryVarianceLink = (date) => {
  return http.get(
    `/asset/auto-yard-management/export/daily-inventory-variance`,
    { date }
  );
};

export const getDownloadOutgateVarianceLink = (date) => {
  return http.get(`/asset/auto-yard-management/export/daily-outgate-variance`, {
    date
  });
};
