import styled from 'styled-components';

import { font } from 'shared/utils/styles';

export const RadioGroupContainer = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
  border-radius: 4px;
  appearance: none !important;
  background: white;
  border: 1px solid black;
`;

export const StyledRadioButton = styled.button`
  display: block;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 18px;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.1s;
  text-align: left;
  appearance: none !important;
  width: 100%;
  ${font.size(14)}
  background: ${(props) => (props.background ? props.background : '#ffffff')};
  color: ${(props) => (props.selected ? '#1e4a71' : '#000000')};
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 45px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-bottom: 10px;
  }
  
`;

export const CheckBox = styled.span`
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  background: ${(props) => (props.checked ? '#1e4a71' : props.background)};
  cursor: pointer;
`;
