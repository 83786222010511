import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import 'i18n/i18n';

import ErrorBoundary from './components/Error/ErrorBoundary';
import App from './App';
import store, { history, persistor } from './stores';
import GlobalStyle from './shared/utils/globalStyle';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <GlobalStyle />
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
