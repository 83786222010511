/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  QuickAccess,
  SearchInputCont,
  SearchInputDebounced,
  SearchIcon,
  SectionTitle,
  Item,
  ItemTitle
} from './Styles';

const CONTENTS = [
  {
    id: 1,
    title: 'User Profile',
    link: '/settings/profile'
  },
  {
    id: 2,
    title: 'Company Details',
    link: '/company/company-details'
  },
  {
    id: 3,
    title: 'Asset Management',
    link: '/asset-management'
  },
  {
    id: 4,
    title: 'Demand Management',
    link: '/demand-management'
  },
  {
    id: 5,
    title: 'Ocean Management',
    link: '/ocean-management'
  },
  {
    id: 6,
    title: 'Bulk Shipping',
    link: '/bulk-shipping'
  },
  {
    id: 7,
    title: 'EDI',
    link: '/edi'
  }
];

const QuickAccessModal = ({ modal }) => {
  const renderLinkItem = (item) => (
    <Link key={item.id} to={item.link} onClick={modal.close}>
      <Item>
        <ItemTitle>{item.title}</ItemTitle>
      </Item>
    </Link>
  );
  return (
    <QuickAccess>
      {/* <SearchInputCont>
        <SearchInputDebounced autoFocus placeholder='Search ...' />
        <SearchIcon type='search' size={22} />
      </SearchInputCont> */}
      <SectionTitle>Modules</SectionTitle>
      {CONTENTS.map(renderLinkItem)}
    </QuickAccess>
  );
};

export default QuickAccessModal;
