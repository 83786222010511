import { getLanguage } from 'services/language';

const initialState = {
  loading: false,
  message: '',
  data: [],
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getLanguage_success':
      return {
        ...state,
        data: action.data,
        loading: false
      };

    default:
      return state;
  }
};

export const shouldFetchLanguages = (state) => {
  const { data, loading } = state.language;

  if (data.length === 0 && !loading) {
    return true;
  }

  return false;
};

export const getLanguageAction = () => {
  return (dispatch) => {
    dispatch({ type: 'getLanguage_start' });
    getLanguage()
      .then((res) => {
        dispatch({ type: 'getLanguage_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getLanguage_error', message: err.message });
      });
  };
};

export const fetchLanguageIfNeeded = () => async (dispatch, getState) => {
  if (!shouldFetchLanguages(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'getLanguage_start' });
    const response = await getLanguage();
    dispatch({ type: 'getLanguage_success', data: response.data });
  } catch (err) {
    dispatch({ type: 'getLanguage_error' });
  }
};
