import { LOCATION_CHANGE } from 'connected-react-router';
import {
  createReducer,
  createApiAction,
  createAction
} from 'shared/utils/redux';
import {
  getInvoiceLine,
  createInvoice,
  updateInvoice,
  publishInvoice,
  cancelInvoice,
  getInvoices,
  getInvoice,
  deletInvoice,
  getLineDetail,
  getRates,
  createRate,
  updateRate,
  deleteRate
} from 'services/asset/invoice';

const initialState = {
  error: null,
  isLoading: false,
  invoice: {
    lineItems: []
  },
  invoices: [],
  invoiceMeta: {},
  lineDetail: [],
  rates: [],
  rateMeta: {}
};

export const getInvoiceStateSelector = (state) => state.asset.invoice;
export const getLoadingSelector = (state) => {
  const { isLoading } = getInvoiceStateSelector(state);
  return isLoading;
};
export const getErrorSelector = (state) => {
  const { error } = getInvoiceStateSelector(state);
  return error;
};
export const getInvoiceLineSelector = (state) => {
  const { invoice } = getInvoiceStateSelector(state);
  return invoice?.lineItems ?? [];
};
export const getInvoiceSelector = (state) => {
  const { invoice } = getInvoiceStateSelector(state);
  return invoice;
};
export const getInvoicesSelector = (state) => {
  const { invoices, invoiceMeta } = getInvoiceStateSelector(state);
  return {
    invoices,
    invoiceMeta
  };
};
export const getLineDetailSelector = (state) => {
  const { lineDetail } = getInvoiceStateSelector(state);
  return lineDetail;
};
export const getRatesSelector = (state) => {
  const { rates, rateMeta } = getInvoiceStateSelector(state);
  return { rates, rateMeta };
};

export const createInvoiceRateAction = createApiAction(
  'createInvoiceRateAction',
  (data) => ({
    callAPI: () => createRate(data)
  })
);

export const updateInvoiceRataAction = createApiAction(
  'updateInvoiceRataAction',
  ({ id, ...data }) => ({
    callAPI: () => updateRate(id, data)
  })
);

export const deleteInvoiceRataAction = createApiAction(
  'deleteInvoiceRataAction',
  (id) => ({
    callAPI: () => deleteRate(id)
  })
);

export const getInvoiceLineAction = createApiAction(
  'getInvoiceLineAction',
  (params) => ({
    shouldCallAPI: (state) => {
      const lines = getInvoiceLineSelector(state);
      const line = lines.find((el) => el.lineId === params.lineId);
      if (!line) return true;
      return false;
    },
    callAPI: () => getInvoiceLine(params)
  })
);

export const createInvoiceAction = createApiAction(
  'createInvoiceAction',
  (params) => ({
    callAPI: () => createInvoice(params)
  })
);

export const updateInvoiceAction = createApiAction(
  'updateInvoiceAction',
  (params) => ({
    callAPI: () => updateInvoice(params)
  })
);

export const publishInvoiceAction = createApiAction(
  'publishInvoiceAction',
  (params) => ({
    callAPI: () => publishInvoice(params)
  })
);

export const cancelInvoiceAction = createApiAction(
  'cancelInvoiceAction',
  (params) => ({
    callAPI: () => cancelInvoice(params)
  })
);

export const getInvoicesAction = createApiAction(
  'getInvoicesAction',
  (params) => ({
    callAPI: () => getInvoices(params)
  })
);

export const getInvoiceAction = createApiAction('getInvoiceAction', (id) => ({
  callAPI: () => getInvoice(id)
}));

export const deleteInvoiceAction = createApiAction(
  'deleteInvoiceAction',
  (id) => ({
    callAPI: () => deletInvoice(id)
  })
);

export const removeInvoiceLineAction = createAction('removeInvoiceLineAction');

export const getLineDetailAction = createApiAction(
  'getLineDetailAction',
  ({ id, itemId }) => ({
    callAPI: () => getLineDetail(id, itemId)
  })
);

export const getRatesAction = createApiAction('getRatesAction', (customer) => ({
  callAPI: () => getRates(customer)
}));

const updateLineItems = (lineItems, line) => {
  const idx = lineItems.findIndex((element) => element.lineId === line.lineId);
  if (idx === -1) {
    // Add new item
    return [...lineItems, line];
  }
  // Update existing item
  return [...lineItems.slice(0, idx), line, ...lineItems.slice(idx + 1)];
};

export default createReducer(initialState, {
  [getInvoiceLineAction.request]: (state) => ({
    ...state,
    isLoading: true,
    error: null
  }),
  [getInvoiceLineAction.failure]: (state, { error }) => ({
    ...state,
    isLoading: false,
    error
  }),
  [getInvoiceLineAction.success]: (state, { response }) => ({
    ...state,
    isLoading: false,
    error: null,
    invoice: response.data
      ? {
          ...state.invoice,
          lineItems: updateLineItems(state.invoice.lineItems, response.data)
        }
      : state.invoice
  }),
  [removeInvoiceLineAction]: (state, { payload }) => ({
    ...state,
    invoice: {
      ...state.invoice,
      lineItems: state.invoice.lineItems.filter(
        (line) => line.lineId !== payload
      )
    }
  }),
  [createInvoiceAction.success]: (state, { response }) => ({
    ...state,
    invoice: response.data
  }),
  [getInvoicesAction.success]: (state, { response }) => ({
    ...state,
    invoices: response.data,
    invoiceMeta: response.meta
  }),
  [getInvoiceAction.success]: (state, { response }) => ({
    ...state,
    invoice: response.data
  }),
  [getLineDetailAction.success]: (state, { response }) => ({
    ...state,
    lineDetail: response.data
  }),
  [getRatesAction.success]: (state, { response }) => ({
    ...state,
    rates: response.data,
    rateMeta: response.meta
  }),
  [LOCATION_CHANGE]: () => initialState
});
