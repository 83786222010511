import styled, { css } from 'styled-components';

import { font, color } from 'shared/utils/styles';

export const AvatarContainer = styled.div`
  position: relative;
`;

export const Image = styled.div`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 100%;
  background-image: url('${(props) => props.avatarUrl}');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${color.backgroundLight};
`;

const circle = css`
  border-radius: 100%;
`;

const square = css`
  border-radius: 4px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
`;

const avatarVariants = {
  circle,
  square
};

export const Letter = styled.div`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  ${(props) => avatarVariants[props.variant]};
  text-transform: uppercase;
  color: #fff;
  background: ${(props) => props.color};
  position: relative;
  ${font.medium}
  ${(props) => font.size(Math.round(props.size / 1.7))}
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;
