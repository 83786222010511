import toast from 'shared/utils/toast';
import {
  getRailDeliveryTransit,
  createRailDeliveryTransit,
  updateRailDeliveryTransit
} from 'services/railDeliveryTransit';

const initialState = {
  loading: true,
  error: false,
  message: '',
  data: [],
  submitSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'fetching_data':
      return {
        ...state,
        loading: true
      };
    case 'fetching_data_success':
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case 'fetching_data_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      };

    case 'create_data_success':
    case 'update_data_success':
      return {
        ...state,
        loading: false,
        submitSuccess: true
      };
    case 'create_data_error':
    case 'update_data_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
        submitSuccess: false
      };

    default:
      return state;
  }
};

export const fetchRailDeliveryTransitAction = (param) => {
  return async (dispatch) => {
    try {
      const { data } = await getRailDeliveryTransit(param);
      dispatch({ type: 'fetching_data_success', data });
      toast.message();
    } catch (e) {
      dispatch({ type: 'fetching_data_error', message: e.message });
    }
  };
};

export const createRailDeliveryTransitAction = (param) => {
  return async (dispatch) => {
    try {
      const { data } = await createRailDeliveryTransit(param);
      dispatch({ type: 'create_data_success', data });
      toast.success('Rail Delivery Transit has been created');
    } catch (e) {
      dispatch({ type: 'create_data_error', message: e.message });
      toast.error('Error occurred when creating');
    }
  };
};

export const updateRailDeliveryTransitAction = (param) => {
  return async (dispatch) => {
    try {
      const { data } = await updateRailDeliveryTransit(param);
      dispatch({ type: 'update_data_success', data });
      toast.success('Rail Delivery Transit has been updated');
    } catch (e) {
      dispatch({ type: 'update_data_error', message: e.message });
      toast.error('Error occurred when updating');
    }
  };
};
