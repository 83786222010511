import { createAction, createReducer } from 'redux-act';

import { getModes, getTemperatureType } from 'services/mode';
import {
  updateLoadBoard,
  getLoadBoard,
  getPrivateLoadBoard,
  getLoadBoardDetail,
  acceptBid,
  rejectBid,
  deleteLoadBoard,
  bidLoadBoard
} from 'services/road';

const ROAD_MODE = 'Road';
const initialState = {
  roadMode: null,
  loadBoard: [],
  temperatureType: [],
  editingLoadBoard: null,
  bidError: null
};
const getRoadModeSuccessAction = createAction('getRoadModeSuccessAction');
const getLoadBoardSuccessAction = createAction('getLoadBoardSuccessAction');
const getLoadBoardDetailSuccessAction = createAction(
  'getLoadBoardDetailSuccessAction'
);
const getTemperatureTypeSuccessAction = createAction(
  'getTemperatureTypeSuccessAction'
);
const bidLoadBoardErrorAction = createAction('bidLoadBoardErrorAction');

export default createReducer(
  {
    [getRoadModeSuccessAction]: (state, roadMode) => ({
      ...state,
      roadMode
    }),
    [getLoadBoardSuccessAction]: (state, loadBoard) => ({
      ...state,
      loadBoard
    }),
    [getTemperatureTypeSuccessAction]: (state, temperatureType) => ({
      ...state,
      temperatureType
    }),
    [getLoadBoardDetailSuccessAction]: (state, loadBoard) => ({
      ...state,
      editingLoadBoard: loadBoard
    }),
    [bidLoadBoardErrorAction]: (state, bidError) => ({
      ...state,
      bidError
    })
  },
  initialState
);

export const getRoadModeAction = () => async (dispatch) => {
  try {
    const response = await getModes({ mode: ROAD_MODE });
    const roadMode = response.data.find((mode) => mode.mode === ROAD_MODE);
    dispatch(getRoadModeSuccessAction(roadMode));
  } catch (err) {
    return [];
  }
};

export const updateLoadBoardAction = (loadBoard) => async () => {
  const response = await updateLoadBoard(loadBoard);
  return response.data;
};

/**
 * Gets public load board
 * @param {} filter
 */
export const getLoadBoardAction =
  (filter = {}) =>
  async (dispatch) => {
    const response = await getLoadBoard(filter);
    dispatch(getLoadBoardSuccessAction(response.data));
  };

export const getPrivateLoadBoardAction =
  (filter = {}) =>
  async (dispatch) => {
    const response = await getPrivateLoadBoard(filter);
    dispatch(getLoadBoardSuccessAction(response.data));
  };

export const getLoadBoardDetailAction = (id) => async (dispatch) => {
  const response = await getLoadBoardDetail(id);
  dispatch(getLoadBoardDetailSuccessAction(response.data));
};

export const acceptLoadBoardAction = (loadBoardId, bidAccepted) => async () => {
  const response = await acceptBid(loadBoardId, bidAccepted);
  return response.data;
};

export const getTemperatureTypeAction = () => async (dispatch) => {
  const response = await getTemperatureType();
  dispatch(getTemperatureTypeSuccessAction(response.data));
};

export const rejectLoadBoardAction = (loadBoardId, bidAccepted) => async () => {
  const response = await rejectBid(loadBoardId, bidAccepted);
  return response.data;
};

export const deleteLoadBoardAction = (loadBoardId) => async () => {
  const response = await deleteLoadBoard(loadBoardId);
  return response.data;
};

export const bidLoadBoardAction = (loadBoardId, bid) => async (dispatch) => {
  try {
    await bidLoadBoard(loadBoardId, bid);
    dispatch(getLoadBoardAction());
  } catch (err) {
    dispatch(bidLoadBoardErrorAction(err.message));
  }
};
