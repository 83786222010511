import http from 'shared/utils/http';

export const registerVendor = (data) => {
  return http.post('/sourcing/seller', data);
};

export const registerBuyer = (data) => {
  return http.post('/sourcing/buyer', data);
};

export const confirmSignup = (type, token) => {
  return http.get(`/sourcing/${type}/verify/${token}`);
};

export const sellerLogin = (data) => {
  return http.post('/sourcing/seller/login', data);
};

export const uploadSrcFile = (type, data) => {
  return http.post(`/sourcing/survey/upload/${type}`, data);
};

export const getSurvey = () => {
  return http.get('/sourcing/survey');
};

export const updateSurvey = (data) => {
  return http.put('/sourcing/survey', data);
};

export const submitSurvey = (data) => {
  return http.post('/sourcing/survey', data);
};

export const makeSeller = (data) => {
  return http.put('/sourcing/seller/make', data);
};
export const makeBuyer = (data) => {
  return http.put('/sourcing/buyer/make', data);
};

export const approveProduct = (id) => {
  return http.put(`/sourcing/product/${id}/approve`);
};

export const getShippingQuoteRequests = (params) => {
  return http.get(`/sourcing/shipping-calculator`, params);
};

export const initShippingQuote = (id) => {
  return http.post(`/sourcing/shipping-calculator/quote/${id}`);
};

export const sendShippingQuote = (id) => {
  return http.post(`/sourcing/shipping-calculator/quote/${id}/send`);
};

export const getShopifyWebhooks = () => {
  return http.get('/sourcing/webhook/shopify');
};

export const subscribeShopifyWebhook = (data) => {
  return http.post('/sourcing/webhook/shopify/subscribe', data);
};

export const updateShopifyWebhook = (data) => {
  return http.put('/sourcing/webhook/shopify/subscribe', data);
};

export const deleteShopifyWebhook = (id) => {
  return http.delete(`/sourcing/webhook/shopify/${id}`);
};

export const getDraftOrders = (params) => {
  return http.get('/sourcing/api/order', params);
};

export const getDraftOrder = (id) => {
  return http.get(`/sourcing/api/order/${id}`);
};

export const updateDraftOrder = (id, data) => {
  return http.put(`/sourcing/api/order/${id}`, data);
};

export const completeDraftOrder = (id) => {
  return http.post(`/sourcing/api/order/${id}/complete`);
};

export const confirmDraftOrder = (id) => {
  return http.post(`/sourcing/api/order/${id}/confirm`);
};
