import React, { useState, useEffect, useMemo } from 'react';
import { Popover, Input, Checkbox } from 'antd';

import {
  TableGroupFilterContainer,
  TableToolbar,
  Expand,
  PopoverHeader,
  PopoverContent,
  StyledDivider,
  StyledTable
} from './Styles';
import { Icon } from 'shared/components';
import * as helpers from './helpers';

const selectAllCheckbox = {
  title: 'Select All',
  dataIndex: 'selectAll',
  key: 'selectAll'
};
const TableGroupFilter = ({
  columnGroup,
  localStorageKey = '',
  children,
  filter = true,
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [colConfig, setColConfig] = useState(() => {
    return JSON.parse(localStorage.getItem(localStorageKey)) || {};
  });

  const filteredColumnGroup = useMemo(() => {
    return helpers.filteredColumnGroup(columnGroup, colConfig);
  }, [colConfig, columnGroup]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(colConfig));
  }, [colConfig, localStorageKey]);

  const columnsFilter = useMemo(() => {
    return columnGroup.reduce((columns, group) => {
      return {
        ...columns,
        ...group.reduce((subColumns, column) => {
          if (column.children) {
            const cols = column.children.reduce(
              (childrenCol, child) => ({ ...childrenCol, [child.key]: true }),
              {}
            );
            return { ...subColumns, ...cols };
          }
          return { ...subColumns, [column.key]: true };
        }, {})
      };
    }, {});
  }, [columnGroup]);

  const handleCheckboxChange = (key) => ({ target }) => {
    if (key === 'selectAll') {
      if (target.checked) {
        setColConfig({
          selectAll: true,
          ...Object.keys(columnsFilter).reduce(
            (columns, cKey) => ({ ...columns, [cKey]: true }),
            {}
          )
        });
      } else {
        setColConfig(
          Object.keys(columnsFilter).reduce(
            (columns, cKey) => ({ ...columns, [cKey]: false }),
            {}
          )
        );
      }
    } else {
      const columnsLength = Object.keys(columnsFilter).length;
      const needToSelectAll =
        Object.values({
          ...colConfig,
          [key]: target.checked,
          selectAll: false
        }).filter((isHidden) => !!isHidden).length === columnsLength;

      setColConfig((prev) => ({
        ...prev,
        [key]: target.checked,
        selectAll: needToSelectAll
      }));
    }
  };

  const renderPopoverHeader = () => (
    <PopoverHeader>
      <Input.Search
        value={search}
        placeholder='Search Column Name'
        onChange={({ target }) => setSearch(target.value)}
      />
    </PopoverHeader>
  );

  const renderCheckbox = ({ key, title }, checked) => (
    <div key={key}>
      <Checkbox
        checked={checked}
        style={{ fontSize: 14 }}
        onChange={handleCheckboxChange(key)}
      >
        {title}
      </Checkbox>
    </div>
  );

  const renderPopoverContent = () => (
    <PopoverContent>
      {renderCheckbox(selectAllCheckbox, colConfig['selectAll'])}
      <StyledDivider />
      {columnGroup.map((columns, idx) => (
        <React.Fragment key={idx}>
          {columns.map((col) => {
            if (col.children) {
              return col.children.reduce((acc, cur) => {
                if (cur.title.toLowerCase().includes(search.toLowerCase())) {
                  acc.push(renderCheckbox(cur, colConfig[cur.key] ?? true));
                }
                return acc;
              }, []);
            }
            return col.title.toLowerCase().includes(search.toLowerCase())
              ? renderCheckbox(col, colConfig[col.key] ?? true)
              : null;
          })}
          {columnGroup.length > idx + 1 && <StyledDivider />}
        </React.Fragment>
      ))}
    </PopoverContent>
  );

  return (
    <TableGroupFilterContainer>
      {filter && (
        <TableToolbar>
          <div />
          <Popover
            trigger='click'
            style={{ width: 250, height: 400 }}
            placement='bottomLeft'
            content={renderPopoverContent()}
            title={renderPopoverHeader()}
          >
            <Expand type='link'>
              <Icon top={2} type='plus' /> Expand Sheet
            </Expand>
          </Popover>
        </TableToolbar>
      )}
      {props.render
        ? props.render(filteredColumnGroup)
        : children(filteredColumnGroup)}
    </TableGroupFilterContainer>
  );
};

TableGroupFilter.StyledTable = StyledTable;

export default TableGroupFilter;
