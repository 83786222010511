import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Upload from '../Upload';

import {
  StyledUploadButton,
  StyledButton,
  FieldError,
  FieldLabel,
  FieldTip
} from './Styles';

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  name: PropTypes.string,
  label: PropTypes.string,
  tip: PropTypes.string,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  isWorking: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  variant: 'secondary',
  name: undefined,
  label: undefined,
  tip: undefined,
  error: undefined,
  invalid: false,
  isWorking: false,
  isHideUploadButton: false,
  onClick: () => {},
  onChange: () => {}
};

const UploadButton = forwardRef(
  (
    {
      className,
      onChange,
      onClick,
      isWorking,
      variant,
      name,
      label,
      tip,
      error,
      isHideUploadButton,
      ...inputProps
    },
    ref
  ) => {
    const [uploadedDocument, setUploadedDocument] = useState('');
    const handleChange = (file) => {
      const data = {};
      data[`${name}`] = file;

      setUploadedDocument(data);
      onChange(file);
    };
    const handleUpload = () => {
      onClick(uploadedDocument);
    };
    return (
      <>
        {label && <FieldLabel>{label}</FieldLabel>}
        <StyledUploadButton className={className}>
          <Upload
            {...inputProps}
            variant={variant}
            onChange={handleChange}
            ref={ref}
          />
          {!isHideUploadButton && (
            <StyledButton
              variant='primary'
              buttonSize={variant}
              isWorking={isWorking}
              onClick={handleUpload}
            >
              Upload
            </StyledButton>
          )}
        </StyledUploadButton>
        {tip && <FieldTip>{tip}</FieldTip>}
        {error && <FieldError>{error}</FieldError>}
      </>
    );
  }
);

UploadButton.propTypes = propTypes;
UploadButton.defaultProps = defaultProps;

export default UploadButton;
