import { createAction, createReducer } from 'redux-act';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading: false,
  data: [],
  error: false
};

const fetchBulkCarUnload = createAction('fetch bulk carunload');
const fetchBulkCarUnloadSuccess = createAction('fetch bulk carunload success');
const fetchBulkCarUnloadError = createAction('fetch bulk carunload error');

export default createReducer(
  {
    [fetchBulkCarUnload]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchBulkCarUnloadSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchBulkCarUnloadError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    })
  },
  initialState
);

export const fetchBulkCarUnloadAction = (values) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBulkCarUnload());
      await delay(1000);
      dispatch(fetchBulkCarUnloadSuccess(values));
    } catch (error) {
      dispatch(fetchBulkCarUnloadError());
    }
  };
};
