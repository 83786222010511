import { createReducer, createAction } from 'redux-act';
import toast from 'shared/utils/toast';

import {
  fetchData,
  createData,
  deleteDataById,
  updateDataById
} from 'services/integratedShipping/ContainerFlowProcessWaveSetup';

const initialState = {
  isFetching: false,
  data: [],
  meta: {},
  isUpdate: false,
  error: false,
  message: ''
};

const fetchDataActionStart = createAction(
  'start fetch container wave setup list'
);
const fetchDataActionSuccess = createAction(
  'fetch container wave setup list success'
);
const fetchDataActionError = createAction(
  'fetch container wave setup list error'
);

const createUpdateDataActionStart = createAction(
  'start create/update container wave setup'
);
const createUpdateDataActionSuccess = createAction(
  'create/update integrated container wave setup'
);
const createUpdateDataActionError = createAction(
  'create/update integrated container wave setup'
);

export default createReducer(
  {
    [fetchDataActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      data: payload.data,
      meta: payload.meta
    }),
    [fetchDataActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [createUpdateDataActionStart]: (state) => ({
      ...state,
      isUpdate: true
    }),
    [createUpdateDataActionSuccess]: (state) => ({
      ...state,
      isUpdate: false
    }),
    [createUpdateDataActionError]: (state) => ({
      ...state,
      isUpdate: false
    })
  },
  initialState
);

export const fetchContainerWaveSetupListAction = (companyId, param) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchData(companyId, param);
      dispatch(fetchDataActionSuccess({ data: res.data, meta: res.meta }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const createIContainerWaveSetupAction = (companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await createData(companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Container Wave Set-up has been created');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when creating Container Wave Set-up');
    }
  };
};

export const updateContainerWaveSetupSetupAction = (id, companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await updateDataById(id, companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Container Wave Set-up has been updated');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when updating Container Wave Set-up');
    }
  };
};

export const deleteContainerWaveSetupSetupAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await deleteDataById(id);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Container Wave Set-up has been deleted');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when deleting Container Wave Set-up');
    }
  };
};
