import http from 'shared/utils/http';

export const getAnnualVolume = (filter) => {
  return http.get('/netwila-core/demand/annual-contract-volume', filter);
};

export const updateAnnualVolume = (data) => {
  return http.post('/netwila-core/demand/annual-contract-volume', data);
};

export const getRemainingCaps = (filter) => {
  return http.get(
    '/netwila-core/demand/annual-contract-volume/remaining-caps',
    filter
  );
};

export const updateAnnualVolumes = (data) => {
  return http.post('/netwila-core/demand/annual-volume', data);
};

export const getAnnualVolumes = (params) => {
  return http.get('/netwila-core/demand/annual-volume', params);
};

export const deleteAnnualVolume = (params) => {
  return http.delete('/netwila-core/demand/annual-volume', params);
};

export const updateAnnualForecastVolume = (data) => {
  return http.post('/netwila-core/demand/annual-volume/forecast', data);
};
