import React from 'react';

import { Thead } from './Styles';

export const filterColumn = (colArr, colConfig) => {
  const output = [];

  const recur = (cols) => {
    if (!cols.length) return output;
    const col = cols[0];
    // eslint-disable-next-line
    if (!colConfig.hasOwnProperty(col.key) || colConfig[col.key]) {
      output.push({
        ...col,
        title: (
          <Thead bgColor={col.bgColor} className='child-header'>
            {col.title}
          </Thead>
        )
      });
    }
    return recur(cols.slice(1));
  };

  return recur(colArr);
};

export const filteredColumnGroup = (columnGroup, colConfig) => {
  const filtered = [];
  columnGroup.forEach((columns) => {
    const filteredColumns = columns.reduce((acc, cur) => {
      let col = {};
      if (cur.children) {
        const colChildren = filterColumn(cur.children, colConfig);
        if (colChildren.length) {
          col = {
            ...cur,
            title: (
              <Thead bgColor={cur.bgColor} className='parent-header'>
                {cur.title}
              </Thead>
            ),
            children: colChildren
          };
          acc.push(col);
        }
      } else {
        // eslint-disable-next-line
        if (!colConfig.hasOwnProperty(cur.key) || colConfig[cur.key]) {
          col = {
            ...cur,
            title: (
              <Thead
                bgColor={cur.bgColor}
                style={cur.style}
                className='child-header'
              >
                {cur.title}
              </Thead>
            )
          };
          acc.push(col);
        }
      }
      return acc;
    }, []);

    filtered.push(filteredColumns);
  });
  return filtered;
};
