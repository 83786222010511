import { createActionResources, createReducer } from 'redux-waiters';
import * as companyServices from 'services/company';

export const fetchCompanyAction = createActionResources('fetch company');

const initialState = {
  companies: []
};

export default createReducer(
  {
    [fetchCompanyAction.success]: (state, payload) => {
      return {
        ...state,
        companies: payload
      };
    }
  },
  initialState
);

export const fetchCompanyCreator = (companyId) =>
  fetchCompanyAction.waiterAction(async (dispatch) => {
    try {
      dispatch(fetchCompanyAction.start());
      const companies = await companyServices.getCompany(companyId);
      dispatch(fetchCompanyAction.success(companies.data[0]));
    } catch (err) {
      dispatch(fetchCompanyAction.error(err));
    }
  });
