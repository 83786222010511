/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form, Alert, Input, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import {
  SignUpPage,
  FormElement,
  FormHeading,
  Agreement,
  Actions,
  ActionButton
} from './Styles';

import CountrySelect from 'components/Commons/CountrySelect';
import { registerVendor, registerBuyer } from 'services/sourcing';
import { dispatchService } from 'shared/utils';
import { capitalize } from 'shared/utils/stringHelper';
import { routeConfig } from 'configs/route';

export default () => {
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const [state, setState] = useState([]);
  const { type } = useParams();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState();

  const onFinish = (data) => {
    const registerApi = type === 'seller' ? registerVendor : registerBuyer;
    dispatchService(
      () =>
        registerApi({
          ...data,
          country: countryCode
        }),
      () => {
        // Redirect to confirmation
        dispatch(push(routeConfig.SOURCING_SIGNUP_CONFIRM));
      },
      (err) => setError(err)
    );
  };

  const onCountryChange = (_, country) => {
    setState(country.state);
    setCountryCode(country.code);
  };

  return (
    <SignUpPage>
      <FormElement>
        <FormHeading>Join as a {capitalize(type)}</FormHeading>
        {error && <Alert type='error' message={error} />}
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item
            label='Company Name'
            name='companyName'
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'country'}
            label='Country'
            rules={[
              {
                required: true
              }
            ]}
          >
            <CountrySelect onChange={onCountryChange} />
          </Form.Item>
          <Form.Item
            name={'state'}
            label='State/Province'
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              options={state.map((s) => ({
                label: s.state,
                value: s.code
              }))}
            />
          </Form.Item>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input type='email' />
          </Form.Item>
          <Form.Item>
            <Form.Item
              label='First Name'
              name='firstName'
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Last Name'
              name='lastName'
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                margin: '0 8px'
              }}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input type='password' />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
              })
            ]}
          >
            <Input type='password' />
          </Form.Item>
          <Agreement>
            By signing up means that you agreeing to the Netwila{' '}
            <a
              href='https://app.netwila.ca/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              term &amp; conditions
            </a>{' '}
            and{' '}
            <a
              href='https://app.netwila.ca/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              privacy policy
            </a>
          </Agreement>
          <Actions>
            <ActionButton type='submit' variant='primary'>
              Sign Up
            </ActionButton>
          </Actions>
        </Form>
      </FormElement>
    </SignUpPage>
  );
};
