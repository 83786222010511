import { createReducer, createAction } from 'redux-act';
import toast from 'shared/utils/toast';

import {
  fetchData,
  createData,
  deleteDataById,
  updateDataById
} from 'services/integratedShipping/integratedYardSetup';

const initialState = {
  isFetching: true,
  data: [],
  isUpdate: false,
  error: false,
  message: ''
};

const fetchDataActionStart = createAction(
  'start fetch integrated yard setup list'
);
const fetchDataActionSuccess = createAction(
  'fetch integrated yard setup list success'
);
const fetchDataActionError = createAction(
  'fetch integrated yard setup list error'
);

const createUpdateDataActionStart = createAction(
  'start create/update integrated yard setup'
);
const createUpdateDataActionSuccess = createAction(
  'create/update integrated yard setup success'
);
const createUpdateDataActionError = createAction(
  'create/update integrated yard setup error'
);

export default createReducer(
  {
    [fetchDataActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      data: payload.data
    }),
    [fetchDataActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [createUpdateDataActionStart]: (state) => ({
      ...state,
      isUpdate: true
    }),
    [createUpdateDataActionSuccess]: (state) => ({
      ...state,
      isUpdate: false
    }),
    [createUpdateDataActionError]: (state) => ({
      ...state,
      isUpdate: false
    })
  },
  initialState
);

export const fetchIntegratedYardSetupList = (companyId, filter) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchData(companyId, filter);
      dispatch(fetchDataActionSuccess({ data: res.data }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const createIntegratedYardSetupAction = (companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await createData(companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Integrated yard setup has been created');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when creating integrated yard setup');
    }
  };
};

export const updateIntegratedYardSetupAction = (id, companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await updateDataById(id, companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Integrated yard setup has been updated');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when updating integrated yard setup');
    }
  };
};

export const deleteIntegratedYardSetupAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await deleteDataById(id);
      if (res) {
        dispatch(createUpdateDataActionSuccess());
        toast.success('Integrated yard setup has been deleted');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when deleting integrated yard setup');
    }
  };
};
