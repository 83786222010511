import { combineReducers } from 'redux';
import annualOceanCapacityAllocationReducer from './annualOceanCapacityAllocation';
import monthlyAllocationReducer from './monthlyAllocation';
import oceanRateAndFuelReducer from './oceanRateAndFuel';
import totalAllocationToSmartReducer from './totalAllocationToSmart';
import intermodalRateAndFuelReducer from './intermodalRateAndFuel';
import oceanSpotRateAndFuelReducer from './oceanSpotRateAndFuel';
import allWaterRatesReducer from './allWaterRates';
import totalProgramCapacityReducer from './totalProgramCapacity';
import annualVolumeReducer from './annualVolume';

export default combineReducers({
  annualOceanCapacityAllocation: annualOceanCapacityAllocationReducer,
  monthlyAllocation: monthlyAllocationReducer,
  oceanRateAndFuel: oceanRateAndFuelReducer,
  totalAllocationToSmart: totalAllocationToSmartReducer,
  intermodalRateAndFuel: intermodalRateAndFuelReducer,
  oceanSpotRateAndFuel: oceanSpotRateAndFuelReducer,
  allWaterRates: allWaterRatesReducer,
  totalProgramCapacity: totalProgramCapacityReducer,
  annualVolume: annualVolumeReducer
});
