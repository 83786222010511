import { createReducer, createAction } from 'redux-act';
import toast from 'shared/utils/toast';

import {
  fetchData,
  createData,
  deleteDataById,
  updateDataById
} from 'services/integratedShipping/containerWaveAssignment';

const initialState = {
  isFetching: false,
  data: [],
  meta: {},
  isUpdate: false,
  error: false,
  message: ''
};

const fetchDataActionStart = createAction(
  'start fetch container wave assignment list'
);
const fetchDataActionSuccess = createAction(
  'fetch container wave assignment list success'
);
const fetchDataActionError = createAction(
  'fetch container wave assignment list error'
);

const createUpdateDataActionStart = createAction(
  'start create/update container wave assignment'
);
const createUpdateDataActionSucess = createAction(
  'create/update container wave assignment success'
);
const createUpdateDataActionError = createAction(
  'create/update container wave assignment error'
);

export default createReducer(
  {
    [fetchDataActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      data: payload.data,
      meta: payload.meta
    }),
    [fetchDataActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [createUpdateDataActionStart]: (state) => ({
      ...state,
      isUpdate: true
    }),
    [createUpdateDataActionSucess]: (state) => ({
      ...state,
      isUpdate: false
    }),
    [createUpdateDataActionError]: (state) => ({
      ...state,
      isUpdate: false
    })
  },
  initialState
);

export const fetchContainerWaveAssignmentList = (companyId, param = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchData(companyId, param);
      dispatch(fetchDataActionSuccess({ data: res.data, meta: res.meta }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const createContainerWaveAssignmentAction = (companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await createData(companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Container Wave Assignmenthas been created');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when creating container wave assignment');
    }
  };
};

export const updateContainerWaveAssignmentAction = (id, companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await updateDataById(id, companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Container Wave Assignmenthas been updated');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when updating container wave assignment');
    }
  };
};

export const deleteContainerWaveAssignmentAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await deleteDataById(id);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Container Wave Assignmenthas been deleted');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when deleting container wave assignment');
    }
  };
};
