import { createAction, createReducer } from 'redux-act';
import { LOCATION_CHANGE } from 'connected-react-router';
import { fetchHazardousCode } from 'services/hCode';
import { getRateCardType, findMatchedRate } from 'services/contract';
import { getModes } from 'services/mode';
import { createOrderDray, getOrderDray } from 'services/oderDrayServices';

const DRAY_MODE = 'Dray';
const initialState = {
  orderEntry: {}
};

const fetchOrderSuccessAction = createAction('fetch order success');

export default createReducer(
  {
    [fetchOrderSuccessAction]: (state, orders) => ({
      ...state,
      orders
    }),
    [LOCATION_CHANGE]: () => ({})
  },
  initialState
);

export const getHzdCodeAction = (_companyId) => async () => {
  try {
    const response = await fetchHazardousCode();
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getDrayModesAction = () => async () => {
  try {
    const response = await getModes({ mode: DRAY_MODE });
    return response.data.find((mode) => mode.mode === DRAY_MODE);
  } catch (err) {
    return [];
  }
};

export const createOrderDrayAction = (data) => {
  return async () => {
    const response = await createOrderDray(data);
    return response.data;
  };
};

const rateMatch = (data) => ({
  [`Out Port Drayage Pick-up and Deliver`]: {
    [`Carrier`]: data.carrier.legalName,
    [`Origin City`]: data.oceanTerminalPickUpSite?.city,
    [`Origin State/Province`]: data.oceanTerminalPickUpSite?.state?.state,
    [`Destination City`]: data.railTerminalDropOffSite?.city,
    [`Destination Province/State`]: data.railTerminalDropOffSite?.state?.state
  },
  [`Into Port Drayage Deliver and Pick-up`]: {
    [`Carrier`]: data.carrier.legalName,
    [`Origin City`]: data.railTerminalPickUpSite?.city,
    [`Origin State/Province`]: data.railTerminalPickUpSite?.state?.state,
    [`Destination City`]: data.oceanTerminalDropOffSite?.city,
    [`Destination Province/State`]: data.oceanTerminalDropOffSite?.state?.state
  },
  [`Drayage WHS Deliver Full and Pick-up Empty`]: {
    [`Carrier`]: data.carrier.legalName,
    [`Origin City`]: data.railTerminalPickUpSite?.city,
    [`Origin State/Province`]: data.railTerminalPickUpSite?.state?.state,
    [`Destination City`]: data.consigneeLocation?.city,
    [`Destination Province/State`]: data.consigneeLocation?.state?.state
  },
  [`Drayage Yard Deliver Full and Pick-up Empty`]: {
    [`Carrier`]: data.carrier.legalName,
    [`Origin City`]: data.railTerminalPickUpSite?.city,
    [`Origin State/Province`]: data.railTerminalPickUpSite?.state?.state,
    [`Destination City`]: data.railTerminalDropOffSite?.city,
    [`Destination Province/State`]: data.railTerminalDropOffSite?.state?.state
  },
  [`Drayage WHS Pick-up empty`]: {
    [`Carrier`]: data.carrier.legalName,
    [`Origin City`]: data.shipperLocation?.city,
    [`Origin State/Province`]: data.shipperLocation?.state?.state,
    [`Destination City`]: data.emptyReturnAddress?.city,
    [`Destination Province/State`]: data.emptyReturnAddress?.state?.state
  },
  [`Drayage WHS Delivery no pick-up`]: {
    [`Carrier`]: data.carrier.legalName,
    [`Origin City`]: data.railTerminalPickUpSite?.city,
    [`Origin State/Province`]: data.railTerminalPickUpSite?.state?.state,
    [`Destination City`]: data.consigneeLocation?.city,
    [`Destination Province/State`]: data.consigneeLocation?.state?.state
  }
});
export const findMatchedRateAction = (data) => async () => {
  const rateMatchFilter = rateMatch(data);
  // Gets rate card type 130 is drayage rate card
  const rateCardTypes = await getRateCardType({ code: '130' });
  if (rateCardTypes.length === 0) return false;
  const rateCardType = rateCardTypes.data[0];

  const fieldsForMatching = rateMatchFilter[`${data.moveType}`] ?? {};
  // map field to value
  const mapData = rateCardType.feature
    .filter((field) => Object.keys(fieldsForMatching).includes(field.fieldName))
    .map((field) => {
      return {
        feature: field._id,
        value: fieldsForMatching[field.fieldName]
      };
    });

  const rate = await findMatchedRate({
    contractId: data?.contract._id,
    feature: [...mapData]
  });
  return rate.data;
};

export const getOrdersAction = () => async (dispatch, getState) => {
  const {
    company: { loggedCompany }
  } = getState();
  const response = await getOrderDray(loggedCompany._id);
  dispatch(fetchOrderSuccessAction(response.data));
};
