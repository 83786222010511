import store from 'store';
import http from 'shared/utils/http';
import { extractAccessToken } from 'shared/utils/authToken';

const LOGGED_COMPANY_KEY = 'loggedCompany';
const LOGGED_ACCOUNT_KEY = 'loggedAccount';
const CURRENT_SHIPPING_MODE_KEY = 'shippingMode';
const CURRENT_SHIPPING_TYPE_KEY = 'shippingType';
const IS_INTEGRATED_SHIPPING = 'isIntegratedShipping';

export const getCompanyType = () => {
  return http.get('/netwila/company/type');
};

export const getCompany = (companyId) => {
  return http.get(`/netwila/company?companyId=${companyId}`);
};

/**
 * Get current company of current logged in user
 */
export const getCurrentCompany = () => {
  const currentUser = extractAccessToken();
  //TODO: That is the best way to get company from BE
  return http.get(`/netwila/company?employee=${currentUser.username}`);
};

export const getAllCompany = () => {
  return http.get(`/netwila/company`);
};

export const getCompanies = (filter = {}) => {
  return http.get(`/netwila/company`, filter);
};

/**
 *
 * @param {Object} company
 */

export const createCompany = (company) => {
  return http.post('/netwila/company', company);
};

/**
 * Why is the same, because the backend likes that
 * @param {Object} company
 */
export const updateCompany = (company) => {
  return http.post('/netwila/company', company);
};

export const updateCompanyLogo = (id, logo) => {
  return http.put(`/netwila-core/company/${id}`, { logo });
};

export const updateCompanyContacts = (id, contacts) => {
  return http.put(`/netwila-core/company/${id}`, { contacts });
};

export const updateCompanyProductSegments = (id, productSegments) => {
  return http.put(`/netwila-core/company/${id}`, { productSegments });
};

export const getIndustrySegment = () => {
  return http.get('/netwila/company/category');
};

/**
 * Disable a company by Id
 * @param {Object} companyId
 */
export const disableCompany = (companyId) => {
  return http.delete('/netwila/company', companyId);
};

/**
 * Return list of departments
 * @return {[type]} [description]
 */
export const getDepartments = () => {
  return http.get('/netwila/department');
};

export const approveUserToCompany = (data) => {
  return http.put('/netwila/company/user', data);
};

/**
 * Gets all accounts by company id
 * @param {Integer} companyId The company id
 */
export const getAccounts = (companyId) => {
  return http.get(`/netwila/company/${companyId}/account`);
};

/**
 * Return list of account types
 * @return {[type]} [description]
 */
export const getAccountTypes = () => {
  return http.get('/netwila/company/accounttype');
};

/**
 * Return list of accounts by company
 * @param {string} companyId
 * @param {Object} params
 */
export const getAccountByCompany = (companyId, params = {}) => {
  return http.get('/netwila-core/account', {
    company: companyId,
    ...params
  });
};

/**
 * Create new account
 * @param {*} account
 */
export const createAccount = (data) => {
  // return http.post(`/netwila/company/${account.company}/account`, account);
  return http.post(`/netwila-core/account`, data);
};

export const disableAccount = (companyId, account) => {
  return http.put(`/netwila/company/${companyId}/account`, account);
};

export const getLoggedCompany = () => {
  return store.get(LOGGED_COMPANY_KEY);
};

export const setLoggedCompany = (company) => {
  return store.set(LOGGED_COMPANY_KEY, company);
};

export const removeLoggedCompany = () => {
  return store.remove(LOGGED_COMPANY_KEY);
};

export const getLoggedAccount = () => {
  return store.get(LOGGED_ACCOUNT_KEY);
};

export const setLoggedAccount = (account) => {
  return store.set(LOGGED_ACCOUNT_KEY, account);
};

export const removeLoggedAccount = () => {
  return store.remove(LOGGED_ACCOUNT_KEY);
};

export const getCurrentShippingMode = () => {
  return store.get(CURRENT_SHIPPING_MODE_KEY);
};

export const setCurrentShippingMode = (mode) => {
  return store.set(CURRENT_SHIPPING_MODE_KEY, mode);
};

export const getCurrentShippingType = () => {
  return store.get(CURRENT_SHIPPING_TYPE_KEY);
};

export const setCurrentShippingType = (type) => {
  return store.set(CURRENT_SHIPPING_TYPE_KEY, type);
};

export const setIsIntegratedShipping = (isIntegratedShipping) => {
  return store.set(IS_INTEGRATED_SHIPPING, isIntegratedShipping);
};

export const isIntegratedShipping = () => {
  return store.get(IS_INTEGRATED_SHIPPING, false);
};

/**
 * Normally the user have to pick a company & account which they
 * would like to work on
 * @param {String} companyName Company Legal Name
 * @param {String} accountNumber The account number
 */
export const checkCompany = (companyName, accountNumber) => {
  return http.put('/netwila/company/check', { companyName, accountNumber });
};

/**
 * Account service for admin call
 */

export const getAllAccounts = (filter) => {
  return http.get('netwila/company/account', filter);
};

export const getEmployees = (filter) => {
  return http.get('/netwila-core/company/employee', filter);
};

export const getAccountsByUser = () => {
  return http.get('/netwila-core/company/accounts');
};

export const resendConfirmEmail = () => {
  return http.get('/netwila/company/resend-confirm-email');
};

export const verifyConfirmEmail = (token, companyId, userId) => {
  return http.get('/netwila-core/company/verify', { token, companyId, userId });
};

export const getCompanyProfile = (id) => {
  return http.get(`/netwila-core/company/${id}`);
};

export const getCompanySubscription = () => {
  return http.get('/netwila-core/company/subscriptions');
};

export const getRequestedCompanies = (params) => {
  return http.get('/netwila-core/company/requests', params);
};

export const approveRequest = (id, data = {}) => {
  return http.post(`/netwila-core/company/request/${id}/approve`, data);
};

export const declineRequest = (id) => {
  return http.post(`/netwila-core/company/request/${id}/decline`);
};

export const getRequestedCompany = (token) => {
  return http.get(`/netwila-core/company/request/${token}`);
};

export const getCompanySurvey = () => {
  return http.get(`/netwila/survey/company`);
};

export const updateCompanySurvey = (data) => {
  return http.post('/netwila/survey/company', data);
};

export const getLocations = (params = {}) => {
  return http.get('/netwila-core/company/location', params);
};

export const getLocation = (id) => {
  return http.get(`/netwila-core/company/location/${id}`);
};

export const addLocation = (id) => {
  return http.post(`/netwila-core/company/location`, {
    id
  });
};

export const getStores4DC = (params = {}) => {
  return http.get('/netwila-core/company/location/4dc', params);
};

export const addStores4DC = (id, data) => {
  return http.post('/netwila-core/company/location/4dc', {
    id,
    ...data
  });
};

export const deleteStores4DC = (id, data) => {
  return http.delete('/netwila-core/company/location/4dc', {
    id,
    ...data
  });
};

export const deleteLocation = (id) => {
  return http.delete('/netwila-core/company/location', { id });
};

export const importPartners = (data) => {
  return http.post(`/netwila-core/company/import-partners`, data);
};

export const getCompanyAppServices = () => {
  return http.get(`/netwila-core/company/app-services`);
};

export const getCompanyAppService = (id) => {
  return http.get(`/netwila-core/company/app-services/${id}`);
};

export const connectAppService = (id, data) => {
  return http.post(`/netwila-core/company/app-services/${id}/connect`, data);
};

export const setupAppService = (data) => {
  return http.put(`/netwila-core/company/app-services/setup`, data);
};

export const mergeCompanyProfile = (params) => {
  return http.get('/netwila-core/company/merge', params);
};

export const searchCompany = (data) => {
  return http.post('/netwila-core/company/search', data);
};

export const searchCompanyPartner = (data) => {
  return http.post('/netwila-core/company/partner', data);
};
