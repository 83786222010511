import { createActionResources, createReducer } from 'redux-waiters';
import * as locationServices from 'services/location';

export const fetchLocationAction = createActionResources('fetch location');

const initialState = {
  locations: [],
  locationsFormater: {}
};

export default createReducer(
  {
    [fetchLocationAction.success]: (state, payload) => {
      return {
        ...state,
        locations: payload,
        locationsFormater: {
          ...state.locationsFormater,
          [payload.locationType]: payload.data
        }
      };
    }
  },
  initialState
);

export const fetchLocationCreator = (param = {}) =>
  fetchLocationAction.waiterAction(async (dispatch) => {
    try {
      dispatch(fetchLocationAction.start());
      const locations = await locationServices.getLocation(param);
      dispatch(
        fetchLocationAction.success({
          locationType: param.type || 'none',
          data: locations.data
        })
      );
    } catch (err) {
      dispatch(fetchLocationAction.error(err));
    }
  });
