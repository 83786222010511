import { createReducer, createAction } from 'redux-act';

import {
  createAutoYardSetup,
  updateAutoYardSetup,
  getAutoYardSetup,
  getAutoYardSetupDetail,
  createAutoYardManagement,
  updateAutoYardManagement,
  getAutoYardManagement,
  getAutoYardManagementDetail,
  findAutoYardAreas,
  createInboundFromYard,
  updateInboundFromYard,
  getInboundFromYard,
  getInboundFromYardDetail,
  createOutboundFromYard,
  getOutboundFromYard,
  getDailyReport,
  generateDailyReport,
  updateDailyReport,
  getYardAreas,
  getInventory,
  updateInventory,
  deleteInventory,
  getDownloadHistory,
  exportInventory
} from 'services/asset/automotiveYardPlanning';

const initialState = {
  isFetching: false,
  autoYardSetups: [],
  autoYardSetupsMeta: {},
  autoYardManagements: [],
  autoYardManagementsMeta: {},
  inboundFromYards: [],
  inboundFromYardsMeta: {},
  outboundFromYards: [],
  outboundFromYardsMeta: {},
  dailyReports: [],
  dailyReportsMeta: [],
  downloadHistory: [],
  downloadHistoryMeta: null,
  isWorking: false,
  yardInventory: null,
  error: false,
  message: ''
};

const fetchAutoYardSetupActionStart = createAction(
  'start fetch auto yard setup list'
);
const fetchAutoYardSetupActionSuccess = createAction(
  'fetch auto yard setup list success'
);
const fetchAutoYardSetupActionError = createAction(
  'fetch auto yard setup list error'
);

const fetchAutoYardManagementActionStart = createAction(
  'start fetch auto yard management list'
);
const fetchAutoYardManagementActionSuccess = createAction(
  'fetch auto yard management list success'
);
const fetchAutoYardManagementActionError = createAction(
  'fetch auto yard management list error'
);

//Inbound from yard
const fetchInboundFromYardActionStart = createAction(
  'start fetch inbound from yard list'
);
const fetchInboundFromYardActionSuccess = createAction(
  'fetch inbound from yard list success'
);
const fetchInboundFromYardActionError = createAction(
  'fetch inbound from yard list error'
);

//Outbound from yard
const fetchOutboundFromYardActionStart = createAction(
  'start fetch outbound from yard list'
);
const fetchOutboundFromYardActionSuccess = createAction(
  'fetch outbound from yard list success'
);
const fetchOutboundFromYardActionError = createAction(
  'fetch outbound from yard list error'
);

//Daily Report
const fetchDailyReportActionStart = createAction(
  'start fetch daily report list'
);
const fetchDailyReportActionSuccess = createAction(
  'fetch daily report list success'
);
const fetchDailyReportActionError = createAction(
  'fetch daily report list error'
);

//DetailData
const fetchDetailDataActionStart = createAction('start fetch detail data');
const fetchDetailDataActionSuccess = createAction('fetch detail data success');
const fetchDetailDataActionError = createAction('fetch detail data error');
const fetchYardAreasActionStart = createAction('fetchYardAreasActionStart');
const fetchYardAreasActionSuccess = createAction('fetchYardAreasActionSuccess');
const fetchYardInventoryActionSuccess = createAction(
  'fetchYardInventoryActionSuccess'
);
const fetchDownloadHistoryActionSuccess = createAction(
  'fetchDownloadHistoryActionSuccess'
);

export default createReducer(
  {
    [fetchAutoYardSetupActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchAutoYardSetupActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      autoYardSetups: data,
      autoYardSetupsMeta: meta
    }),
    [fetchAutoYardSetupActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),
    [fetchAutoYardManagementActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchAutoYardManagementActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      autoYardManagements: data,
      autoYardManagementsMeta: meta
    }),
    [fetchAutoYardManagementActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),

    //Inbound from Yard
    [fetchInboundFromYardActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchInboundFromYardActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      inboundFromYards: data,
      inboundFromYardsMeta: meta
    }),
    [fetchInboundFromYardActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),

    //Outbound from Yard
    [fetchOutboundFromYardActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchOutboundFromYardActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      outboundFromYards: data,
      outboundFromYardsMeta: meta
    }),
    [fetchOutboundFromYardActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),
    //Detail Data
    [fetchDetailDataActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchDetailDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      [`${payload.id}`]: payload
    }),
    [fetchDetailDataActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),

    //Daily Report
    [fetchDailyReportActionStart]: (state) => ({
      ...state,
      isFetching: true,
      error: false
    }),
    [fetchDailyReportActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      dailyReports: data,
      dailyReportsMeta: meta
    }),
    [fetchDailyReportActionError]: (state, message) => ({
      ...state,
      isFetching: false,
      error: true,
      message
    }),
    [fetchYardAreasActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchYardAreasActionSuccess]: (state, { data, meta }) => ({
      ...state,
      isFetching: false,
      yardAreas: data,
      yardAreasMeta: meta
    }),
    [fetchYardInventoryActionSuccess]: (state, { data }) => ({
      ...state,
      yardInventory: data
    }),
    [fetchDownloadHistoryActionSuccess]: (state, { data, meta }) => ({
      ...state,
      downloadHistory: data,
      downloadHistoryMeta: meta
    })
  },
  initialState
);

export const createAutoYardSetupAction = (companyId, data) => {
  return async () => {
    return createAutoYardSetup(companyId, data);
  };
};

export const updateAutoYardSetupAction = (id, companyId, data) => {
  return async () => {
    return updateAutoYardSetup(id, companyId, data);
  };
};

export const fetchAutoYardSetupAction = (companyId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAutoYardSetupActionStart());
      const resp = await getAutoYardSetup(companyId, params);
      if (resp) {
        dispatch(
          fetchAutoYardSetupActionSuccess({ data: resp.data, meta: resp.meta })
        );
      }
    } catch (error) {
      dispatch(fetchAutoYardSetupActionError('Failed to fetch data'));
    }
  };
};

export const fetchAutoYardSetupDetailAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDetailDataActionStart());
      const resp = await getAutoYardSetupDetail(id);
      if (resp.data) {
        dispatch(fetchDetailDataActionSuccess(resp.data));
      }
    } catch (error) {
      dispatch(fetchDetailDataActionError('Failed to fetch data'));
    }
  };
};

/**
 * Auto Yard Management
 */

export const createAutoYardManagementAction = (companyId, data) => {
  return async () => {
    return createAutoYardManagement(companyId, data);
  };
};

export const updateAutoYardManagementAction = (id, companyId, data) => {
  return async () => {
    return updateAutoYardManagement(id, companyId, data);
  };
};

export const fetchAutoYardManagementAction = (companyId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAutoYardManagementActionStart());
      const resp = await getAutoYardManagement(companyId, params);
      if (resp) {
        dispatch(
          fetchAutoYardManagementActionSuccess({
            data: resp.data,
            meta: resp.meta
          })
        );
      }
    } catch (error) {
      dispatch(fetchAutoYardManagementActionError('Failed to fetch data'));
    }
  };
};

export const fetchAutoYardManagementDetailAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDetailDataActionStart());
      const resp = await getAutoYardManagementDetail(id);
      if (resp.data) {
        dispatch(fetchDetailDataActionSuccess(resp.data));
      }
    } catch (error) {
      dispatch(fetchDetailDataActionError('Failed to fetch data'));
    }
  };
};

export const findYardAreasActions = (companyId, params = {}) => {
  return async () => {
    const resp = await findAutoYardAreas(companyId, params);
    return resp.data;
  };
};

/*
  Inbound from Yard
 */

export const createInboundFromYardAction = (companyId, data) => {
  return async () => {
    return createInboundFromYard(companyId, data);
  };
};

export const updateInboundFromYardAction = (id, companyId, data) => {
  return async () => {
    return updateInboundFromYard(id, companyId, data);
  };
};

export const fetchInboundFromYardAction = (companyId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchInboundFromYardActionStart());
      const resp = await getInboundFromYard(companyId, params);
      if (resp) {
        return dispatch(
          fetchInboundFromYardActionSuccess({
            data: resp.data,
            meta: resp.meta
          })
        );
      }
    } catch (error) {
      dispatch(fetchInboundFromYardActionError('Failed to fetch data'));
    }
  };
};

export const fetchInboundFromYardDetailAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDetailDataActionStart());
      const resp = await getInboundFromYardDetail(id);
      if (resp.data) {
        dispatch(fetchDetailDataActionSuccess(resp.data));
      }
    } catch (error) {
      dispatch(fetchDetailDataActionError('Failed to fetch data'));
    }
  };
};

/*
  Outbound from Yard
 */

export const createOutboundFromYardAction = (companyId, data) => {
  return async () => {
    return createOutboundFromYard(companyId, data);
  };
};

export const fetchOutboundFromYardAction = (companyId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOutboundFromYardActionStart());
      const resp = await getOutboundFromYard(companyId, params);
      if (resp) {
        dispatch(
          fetchOutboundFromYardActionSuccess({
            data: resp.data,
            meta: resp.meta
          })
        );
      }
    } catch (error) {
      dispatch(fetchOutboundFromYardActionError('Failed to fetch data'));
    }
  };
};

/*
  Daily Report
*/

export const fetchDailyReportAction = (companyId, params = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDailyReportActionStart());
      const resp = await getDailyReport(companyId, params);
      if (resp) {
        dispatch(
          fetchDailyReportActionSuccess({
            data: resp.data,
            meta: resp.meta
          })
        );
      }
    } catch (error) {
      dispatch(fetchDailyReportActionError('Failed to fetch data'));
    }
  };
};

export const generateDailyReportAction = (companyId, data) => {
  return async () => {
    return generateDailyReport(companyId, data);
  };
};

export const updateDailyReportAction = (id, companyId, data) => {
  return async () => {
    return updateDailyReport(id, companyId, data);
  };
};

export const fetchAutoYardAreaAction = (yardAddress, filter) => async (
  dispatch
) => {
  dispatch(fetchYardAreasActionStart());
  const response = await getYardAreas(yardAddress, filter);
  dispatch(fetchYardAreasActionSuccess(response));
};

export const fetchYardInventoryAction = (id) => async (dispatch) => {
  const response = await getInventory(id);
  dispatch(fetchYardInventoryActionSuccess(response));
};

export const updateYardInventoryAction = (id, data) => async () => {
  await updateInventory(id, data);
};

export const deleteYardInventoryAction = (id) => async () => {
  await deleteInventory(id);
  return true;
};

export const fetchDownloadHistoryAction = (params = {}) => async (dispatch) => {
  const response = await getDownloadHistory(params);
  dispatch(fetchDownloadHistoryActionSuccess(response));
};

export const exportYardInventoryAction = (params = {}) => async () => {
  await exportInventory(params);
  return true;
};
