import { createAction, createReducer } from 'redux-act';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading1: false,
  fetchLoading2: false,
  data1: [],
  data2: [],
  error1: false,
  error2: false,
  message1: '',
  message2: ''
};

const fetchIntermodalRateAndFuelTable1 = createAction(
  'fetch intermodal rate and fuel table 1'
);
const fetchIntermodalRateAndFuelTable1Success = createAction(
  'fetch intermodal rate and fuel table 1 success'
);
const fetchIntermodalRateAndFuelTable1Error = createAction(
  'fetch intermodal rate and fuel table 1 error'
);

const fetchIntermodalRateAndFuelTable2 = createAction(
  'fetch intermodal rate and fuel table 2'
);
const fetchIntermodalRateAndFuelTable2Success = createAction(
  'fetch intermodal rate and fuel table 2 success'
);
const fetchIntermodalRateAndFuelTable2Error = createAction(
  'fetch intermodal rate and fuel table 2 error'
);

export default createReducer(
  {
    [fetchIntermodalRateAndFuelTable1]: (state) => ({
      ...state,
      fetchLoading1: true,
      error1: false
    }),
    [fetchIntermodalRateAndFuelTable1Success]: (state, payload) => ({
      ...state,
      data1: payload,
      fetchLoading1: false,
      error1: false
    }),
    [fetchIntermodalRateAndFuelTable1Error]: (state, payload) => ({
      ...state,
      message1: payload,
      error1: true,
      data1: [],
      fetchLoading1: false
    }),
    [fetchIntermodalRateAndFuelTable2]: (state) => ({
      ...state,
      fetchLoading2: true,
      error2: false
    }),
    [fetchIntermodalRateAndFuelTable2Success]: (state, payload) => ({
      ...state,
      data2: payload,
      fetchLoading2: false,
      error2: false
    }),
    [fetchIntermodalRateAndFuelTable2Error]: (state, payload) => ({
      ...state,
      message2: payload,
      error2: true,
      data2: [],
      fetchLoading2: false
    })
  },
  initialState
);

export const fetchIntermodalRateAndFuelTable1Action = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchIntermodalRateAndFuelTable1());
      await delay(1000);
      dispatch(fetchIntermodalRateAndFuelTable1Success());
    } catch (error) {
      dispatch(fetchIntermodalRateAndFuelTable1Error());
    }
  };
};

export const fetchIntermodalRateAndFuelTable2Action = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchIntermodalRateAndFuelTable2());
      await delay(1000);
      dispatch(fetchIntermodalRateAndFuelTable2Success());
    } catch (error) {
      dispatch(fetchIntermodalRateAndFuelTable2Error());
    }
  };
};
