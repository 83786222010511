import styled, { css } from 'styled-components';

import { color, font } from 'shared/utils/styles';

export const StyledTextarea = styled.div`
  display: inline-block;
  width: 100%;
  textarea {
    overflow-y: hidden;
    width: 100%;
    padding: 8px 12px 9px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    color: ${color.textDarkest};
    background: #fff;
    ${font.regular}
    ${font.size(15)}
    &:focus {
      background: #fff;
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    ${(props) =>
      props.invalid &&
      css`
        &,
        &:focus {
          border: 1px solid ${color.danger};
        }
      `}
  }
`;
