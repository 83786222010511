import http from 'shared/utils/http';

/**
 *
 * @param {Object} filter
 * @example filter = { type: 'tarrif' }
 */
export const getContracts = (filter) => {
  return http.get('/netwila-core/contract', filter);
};

/**
 *
 * @param {Number} id
 * @param {Object} contract
 */
// export const updateContract = (id, contract) => {
//   return http.put(`netwila/contract/${id}`, contract);
// };

/**
 *
 * @param {String} id
 * @param {Object} data
 */
export const editContract = (id, data) => {
  const body = {
    contractId: id,
    ...data
  };
  return http.post(`netwila/contract`, body);
};

export const getContractType = () => {
  return http.get(`netwila/contract/type`);
};

export const getContractPart = () => {
  return http.get(`netwila/contract/part`);
};

export const createContract = (data) => {
  return http.post(`netwila/contract`, data);
};

export const updateContract = (id, data) => {
  return http.put(`netwila-core/contract/${id}`, data);
};

/**
 * Get all features of rate card
 */
export const getFeatureFields = () => {
  return http.get(`netwila/contract/feature`);
};

/**
 * Get all rates of rate card
 */
export const getRateFields = () => {
  return http.get(`netwila/contract/rate`);
};

/**
 * Create new rate account
 * @param {Object} data
 */
export const createRateCard = (contractId, data) => {
  return http.put(`/netwila/contract/${contractId}/ratecard`, data);
};

/**
 * Update Rate Card by contract id
 */
export const updateRateCard = (contractId, data) => {
  return http.put(`/netwila/contract/${contractId}/ratecard`, data);
};
/**
 * Get Rate Card Type
 */
export const getRateCardType = (filter = {}) => {
  return http.get('/netwila/contract/ratecardtype', filter);
};

/**
 * Validation rate card data of a contract
 */

export const validateRateCardData = (contractId, data) => {
  return http.put(`/netwila/contract/${contractId}/ratecard/validation`, data);
};

export const findMatchedRate = (data) => {
  return http.put('/netwila/contract/ratecard', data);
};

/**
 * Update Rate Card Group
 * @param {string} contractId
 * @param {string} rateGroupId
 * @param {Object} data
 */

export const updateRateCardGroup = (contractId, rateGroupId, data) => {
  const body = {
    rateGroupId,
    ...data
  };
  return http.post(`/netwila/contract/${contractId}/ratecardgroup`, body);
};

/**
 * Validate Formula Rate
 */

export const validateFormulaRate = (formula) => {
  return http.post(`/vrp/formulas/`, formula);
};

export const getInboundContracts = () => {
  return http.get('/netwila-core/contract/contract-link/inbound');
};

export const getOutboundContracts = () => {
  return http.get('/netwila-core/contract/contract-link/outbound');
};

/**
 * Delete Contract
 * @param {string} id
 */
export const deleteContract = (id) => {
  const body = { contractId: id };
  return http.delete(`netwila/contract`, body);
};

export const getRateCardByType = (type) => {
  return http.get('/netwila/contract/ratecardbytype', {
    modeContract: type
  });
};

export const getContractsV2 = (params) => {
  return http.get('/netwila-core/contract', params);
};

export const updateRoutingGuides = (id, data) => {
  return http.post(`/netwila-core/contract/routing-guides/${id}`, data);
};

export const getRoutingGuides = (id) => {
  return http.get(`/netwila-core/contract/routing-guide/${id}`);
};

export const deleteRoutingGuide = (id) => {
  return http.delete(`/netwila-core/contract/routing-guide/${id}`);
};

export const updateRoutingGuide = (id, data) => {
  return http.put(`/netwila-core/contract/routing-guide/${id}`, data);
};

export const getRates = (rateIds) => {
  return http.get('/netwila-core/contract/rates', { rateIds });
};

export const getContrackLinkRoutes = (linkId) => {
  return http.get(`/netwila-core/contract/contract-link/cmove/${linkId}`);
};

export const updateContractV2 = (id, data) => {
  return http.put(`/netwila-core/contract/${id}/accessorials`, data);
};
