import { createReducer, createAction } from 'redux-act';
import { LOCATION_CHANGE } from 'connected-react-router';

import { getModes } from 'services/mode';
import {
  fetchOceanOderTblData,
  createOrderOcean
} from 'services/oderOceanServices';
import {
  getOceanOrder,
  getRoadOrder,
  getRailOrder,
  getDrayOrder
} from 'services/orders';
import { getRateCardType, findMatchedRate } from 'services/contract';
import { OCEAN_RATE_TYPE } from 'configs/app';

const OCEAN_MODE = 'Ocean';
const initialState = {
  orders: [],
  mode: null,
  order: null
};

// TODO: Refactor
const featureFieldMapping = {
  carrier: 'Carrier',
  originCountry: 'Origin Country',
  originCity: 'Origin City',
  originState: 'Origin State/Province',
  originPostCode: 'Origin Postal Code/ Zip Code',
  originPort: 'Origin Port',
  destinationCountry: 'Destination Country',
  destinationPort: 'Destination Port',
  destinationCity: 'Destination City',
  destinationState: 'Destination Province/State',
  destinationPostCode: 'Destination Postal Code-Zip Code',
  equipmentType: 'Equipment Type',
  temperatureType: 'Temperature Type',
  moveType: 'Move Type', // Door-to-Door, Port-to-Port
  shippingMode: 'Mode', // Ocean
  shippingType: 'Service' // FCL,LCL,Consolidated
};

const fetchOrderSuccessAction = createAction('fetch order success');
const fetchOceanModeSuccessAction = createAction(
  'fetch equipment type success'
);
const fetchOceanOrderSuccessAction = createAction(
  'fetch ocean order detail success'
);

export default createReducer(
  {
    [fetchOrderSuccessAction]: (state, orders) => ({
      ...state,
      orders
    }),
    [fetchOceanModeSuccessAction]: (state, mode) => ({
      ...state,
      mode
    }),
    [fetchOceanOrderSuccessAction]: (state, order) => ({
      ...state,
      order
    }),
    [LOCATION_CHANGE]: () => initialState
  },
  initialState
);

export const fetchDataAction = (filter = {}) => async (dispatch) => {
  const response = await fetchOceanOderTblData(filter);
  dispatch(fetchOrderSuccessAction(response.data));
};

export const createOrderOceanAction = () => async (_, getState) => {
  const {
    order: { orderEntry }
  } = getState();

  delete orderEntry['matchedRates'];
  const orderData = {
    ...orderEntry,
    shippingType: orderEntry.shippingType?.service,
    moveType: orderEntry.moveType?.type,
    equipmentType: orderEntry.equipmentType
  };

  const respone = await createOrderOcean(orderData);
  return respone.data;
};

export const findMatchedRateAction = () => async (_, getState) => {
  const {
    order: { orderEntry }
  } = getState();
  const {
    shipperLocation,
    consigneeLocation,
    contract,
    moveType,
    shippingType,
    equipmentType
  } = orderEntry;

  // Gets rate card type
  const rateCardTypes = await getRateCardType();
  const rateCardType = rateCardTypes.data.find(
    (type) => type.code === OCEAN_RATE_TYPE
  );

  if (!rateCardType) return false;

  const orderData = {
    ...orderEntry,
    moveType: moveType.type,
    shippingType: shippingType.service,
    equipmentType: equipmentType.alias
  };

  orderData['carrier'] = orderData['carrier'].legalName;
  orderData['originCountry'] = orderData['originCountry'].name;
  orderData['destinationCountry'] = orderData['destinationCountry'].name;
  orderData['originPort'] = orderData['originPort'].name;
  orderData['destinationPort'] = orderData['destinationPort'].name;

  if (moveType === 'Door-to-Door' || moveType === 'Door-to-Port') {
    // Origin location should be shipper location
    orderData['originCity'] = shipperLocation?.city;
    orderData['originState'] = shipperLocation?.state?.state;
    orderData['originPostCode'] = shipperLocation?.postCode;
  }

  if (moveType === 'Port-to-Door' || moveType === 'Door-to-Door') {
    // Destination location shoulld be consignee location
    orderData['destinationCity'] = consigneeLocation?.city;
    orderData['destinationState'] = consigneeLocation?.state?.state;
    orderData['destinationPostCode'] = consigneeLocation?.postCode;
  }

  const featureFieldsValue = Object.keys(featureFieldMapping).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [featureFieldMapping[curr]]: orderData[curr]
      };
    },
    {}
  );

  const featureFields = rateCardType.feature;

  // map field to value
  const mapData = featureFields.map((field) => {
    return {
      feature: field._id,
      value: featureFieldsValue[field.fieldName]
    };
  });

  const rate = await findMatchedRate({
    contractId: contract._id,
    feature: mapData.filter((item) => item.value !== undefined)
  });

  return rate.data;
};

export const getOceanModesAction = () => async (dispatch) => {
  try {
    const response = await getModes({ mode: OCEAN_MODE });
    dispatch(
      fetchOceanModeSuccessAction(
        response.data.find((mode) => mode.mode === OCEAN_MODE)
      )
    );
  } catch (err) {
    /*SL*/
  }
};

export const getOceanOrderAction = (id) => async (dispatch) => {
  try {
    const response = await getOceanOrder(id);
    dispatch(fetchOceanOrderSuccessAction(response.data));
  } catch (err) {
    /*SL*/
  }
};

export const getRoadOrderAction = (id) => async (dispatch) => {
  try {
    const response = await getRoadOrder(id);
    dispatch(fetchOceanOrderSuccessAction(response.data));
  } catch (err) {
    /*SL*/
  }
};

export const getRailOrderAction = (id) => async (dispatch) => {
  try {
    const response = await getRailOrder(id);
    dispatch(fetchOceanOrderSuccessAction(response.data));
  } catch (err) {
    /*SL*/
  }
};

export const getDrayOrderAction = (id) => async (dispatch) => {
  try {
    const response = await getDrayOrder(id);
    dispatch(fetchOceanOrderSuccessAction(response.data));
  } catch (err) {
    /*SL*/
  }
};
