export const appConfig = {
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  ANWORK_APK_NAME: 'ANwork-mobile.7.7.20.apk'
};

export const NETWILA_COMPANY_NAME = 'Netwila Applications Corp.';
export const NETWILA_COMPANY_ID = '5ed94b1a23afd4001008cae9';
export const CONTRACT_SALE_TYPE = '5ec5b518c23ef25c50cb7e1b';
export const CONTRACT_PARTNER_TYPE = '';
export const CONTRACT_NON_PARTNER_TYPE = '';
export const CONTRACT_MODE_OCEAN = 'ocean';
export const CONTRACT_MODE_SMART_OCEAN = 'smart_ocean';
export const CONTRACT_MODE_ROAD = 'road';
export const CONTRACT_MODE_SMART_ROAD = 'smart_road';
export const CONTRACT_MODE_RAIL = 'rail';
export const CONTRACT_MODE_SMART_RAIL = 'smart_rail';
export const CONTRACT_MODE_AIR = 'air';
export const CONTRACT_MODE_DRAY = 'dray';
export const CONTRACT_MODE_SMART_DRAY = 'smart_dray';
export const CONTRACT_MODES = {
  [CONTRACT_MODE_OCEAN]: '5e9f6bce7fefda2b0e12e1d9',
  [CONTRACT_MODE_SMART_OCEAN]: '5e9f6bce7fefda2b0e12e1da',
  [CONTRACT_MODE_ROAD]: '5e9f6bce7fefda2b0e12e1d5',
  [CONTRACT_MODE_SMART_ROAD]: '5e9f6bce7fefda2b0e12e1d6',
  [CONTRACT_MODE_RAIL]: '5e9f6bce7fefda2b0e12e1d7',
  [CONTRACT_MODE_SMART_RAIL]: '5e9f6bce7fefda2b0e12e1d8',
  [CONTRACT_MODE_AIR]: '5ec7fee67d6d397e88a34244',
  [CONTRACT_MODE_DRAY]: '5f1201330eb8f21881067a2d',
  [CONTRACT_MODE_SMART_DRAY]: '5f12015f0eb8f21881067a2e'
};
export const CONTRACT_TYPE_SMART = '5ec5b6d49113ac4d70cf0c91';
export const TASK_TYPES = {
  truckDriver: {
    id: '5eb4537b81ebf00010b578a6',
    label: 'Truck Driver'
  },
  railWorker: {
    id: '5eb9b90d103f6576e069c738',
    label: 'Rail Dray Worker'
  },
  drayWorker: {
    id: 'drayWorker',
    label: 'Dray Worker'
  }
};
export const TASK_TYPE_INBOUND_OCEAN = 'inboundOcean';
export const TASK_TYPE_INBOUND_DRAYAGE = 'inboundDrayage';
export const TASK_TYPE_INBOUND_RAIL = 'inboundRail';
export const TASK_TYPE_OUTBOUND_OCEAN = 'outboundOcean';
export const TASK_TYPE_OUTBOUND_DRAYAGE = 'outboundDrayage';
export const TASK_TYPE_OUTBOUND_RAIL = 'outboundRail';
export const SMART_MODE_TASK_TYPES = [
  {
    label: 'Inbound Ocean',
    value: TASK_TYPE_INBOUND_OCEAN
  },
  {
    label: 'Inbound Drayage',
    value: TASK_TYPE_INBOUND_DRAYAGE
  },
  {
    label: 'Inbound Rail',
    value: TASK_TYPE_INBOUND_RAIL
  },
  {
    label: 'Outbound Ocean',
    value: TASK_TYPE_OUTBOUND_OCEAN
  },
  {
    label: 'Outbound Drayage',
    value: TASK_TYPE_OUTBOUND_DRAYAGE
  },
  {
    label: 'Outbound Rail',
    value: TASK_TYPE_OUTBOUND_RAIL
  }
];
export const UOM = ['Lbs', 'Kg'];
export const OCEAN_PORT_TYPE = '5ed931abc2cbb797742223bf';
export const OCEAN_RATE_TYPE = '110';
export const COMPANY_TYPE_OCEAN_CARRIER = 'Ocean Carrier';
export const COMPANY_TYPE_VENDOR = 'Vendor';
export const COMPANY_TYPE_SHIPPER = 'Shipper';
export const COMPANY_TYPE_NVOCC = 'NVOCC';
export const COMPANY_TYPE_ROAD_CARRIER = 'Road Carrier';
export const COMPANY_TYPE_RAIL_CARRIER = 'Rail Carrier';
export const COMPANY_TYPE_RAY_CARRIER = 'Dray Carrier';
export const RAIL_TERMINAL_TYPE = '5ed13b0f3ab655001013b6b2';
export const OCEAN_TERMINAL_TYPE = '5e9dcf1d63ceb57ed1bf8df2';
export const CUSTOM_BROKER_TYPE = '5efd51ee99b88b399e4c550c';
export const ROAD_WEIGHT_BREAK_LTL_RATE_TYPE = '10';
export const ROAD_PALLET_BREAK_LTL_RATE_TYPE = '20';
export const ROAD_LTL_REEFER_RATE_TYPE = '30';
export const ROAD_LTL_HEAT_RATE_TYPE = '40';
export const ROAD_TL_RATE_TYPE = '50';
export const ROAD_REEFER_TL_RATE_TYPE = '60';
export const ROAD_REEFER_TL_P_2_P_RATE_TYPE = '70';
export const ROAD_HEATER_TL_RATE_TYPE = '80';
export const ROAD_TL_PER_MILE_RATE_TYPE = '90';
export const ROAD_LTL_PER_MILE_RATE_TYPE = '100';
export const LOCATION_TYPE_WAREHOUSE = 'Warehouse / Distribution Center';
export const LOCATION_TYPE_AUTO_COMPOUND = 'Auto Compound';
export const LOCATION_TYPE_OCEAN_PORT = 'Ocean Port Terminal';

export const EQUIPMENT_CODE = {
  "20' Dry Container": '20 DRY',
  "20' NOR Container": '20 NOR',
  "20' Reefer Container": '20 REEFER',
  "40' HD Container": '40 HD',
  "40' Heat Container": '40 HEAT',
  "40' HQ Container": '40 HQ',
  "40' NOR Container": '40 NOR',
  "40' Reefer Container": '40 REEER',
  "40' Standard Container": '40 STD',
  "45' Container": '45',
  "53' Container": '53',
  "53' Heat Container": '53 Heat',
  "53' NOR Container": '53 NOR',
  "53' Reefer Container": '53 REEFER'
};

export const EQUIPMENT_SIZE_TEU_CONVERSION = {
  20: 1,
  40: 2,
  45: 2.25,
  48: 2.4,
  53: 2.65
};

export const EQUIPMENT_SIZE_FEU_CONVERSION = {
  20: 0.5,
  40: 1,
  45: 1.25,
  48: 1.4,
  53: 1.65
};

export const VEHICLE_TYPES = {
  1: 'Automobile',
  2: 'Truck',
  3: 'Others',
  4: 'Used Vehicles',
  5: 'Military'
};

export const CONTAINER_TYPE_SHORT = [
  '20 GP',
  '20 HC',
  '40 GP',
  '40 HC',
  '40 RF',
  '40 RH',
  '45 HC'
];

export const ROAD_SERVICE_COUNTRY_MAP = {
  CA: ['CA', 'US', 'MX'],
  IN: ['IN'],
  US: ['US', 'CA', 'MX'],
  MX: ['MX', 'US', 'CA']
};

export const SERVICE_MODE = {
  ROAD: 'road',
  OCEAN: 'ocean',
  RAIL: 'rail',
  DRAY: 'dray'
};

const MODE_MOVE_DOOR_2_DOOR = 'Door-to-Door';
const MODE_MOVE_DOOR_2_PORT = 'Door-to-Port';
const MODE_MOVE_PORT_2_DOOR = 'Port-to-Door';
const MODE_MOVE_PORT_2_PORT = 'Port-to-Port';
const MODE_MOVE_RAMP_2_RAMP = 'Ramp-to-Ramp';
const MODE_MOVE_DOOR_2_RAMP = 'Door-to-Ramp';
const MODE_MOVE_RAMP_2_DOOR = 'Ramp-to-Door';

export const MODE_MOVES = [
  MODE_MOVE_DOOR_2_DOOR,
  MODE_MOVE_DOOR_2_PORT,
  MODE_MOVE_PORT_2_DOOR,
  MODE_MOVE_PORT_2_PORT,
  MODE_MOVE_RAMP_2_RAMP,
  MODE_MOVE_DOOR_2_RAMP,
  MODE_MOVE_RAMP_2_DOOR
];

export const INVOICE_DRAFT_STATUS = 'draft';
export const INVOICE_UNPAID_STATUS = 'unpaid';
export const INVOICE_PAID_STATUS = 'paid';
export const INVOICE_CANCELLED_STATUS = 'cancelled';
export const INVOICE_STATUS = ['unpaid', 'paid', 'cancelled'];
export const PERU_PAYMENT_SERVICES = [
  'THC',
  'OCEAN FREIGHT',
  'ISSUE BL',
  'HANDLING',
  'CONTAINER DAMAGE*',
  'VISTO BUENO',
  'DISCHARGE',
  'USE AREA/STORAGE',
  'DEMURRAGE',
  'GATE IN',
  'PENALTY/TAX'
];

export const TRANSPORTATION_MODES = ['Intermodal', 'Domestic'];
export const LOCATION_TYPE_CODES = {
  BASE_PORT: '0013',
  AIR_PORT: '0010',
  PORT_TERMINAL: '0001'
};
export const SOC_TYPES = {
  // SOC_INLAND_RAIL: 'SOC_INLAND_RAIL',
  // SOC_PORT_2_PORT: 'SOC_PORT_2_PORT',
  // SOC_NON_NETWILA: 'SOC_NON_NETWILA',
  SOC_IPI: 'SOC_IPI',
  SOC_NETWILA_INLAND_RAIL: 'SOC_NETWILA_INLAND_RAIL'
  // SOC_NETWILA_PORT_2_PORT: 'SOC_NETWILA_PORT_2_PORT',
};
export const SOC_TYPE_LABELS = {
  // [SOC_TYPES.SOC_INLAND_RAIL]: 'SOC inland load to rail',
  // [SOC_TYPES.SOC_PORT_2_PORT]: 'SOC Port to port only',
  [SOC_TYPES.SOC_IPI]: 'IPI',
  // [SOC_TYPES.SOC_NON_NETWILA]: 'SOC other non-Netwila',
  [SOC_TYPES.SOC_NETWILA_INLAND_RAIL]: 'Load to rail'
  // [SOC_TYPES.SOC_NETWILA_PORT_2_PORT]: 'Netwila SOC Port to Port only'
};

export const INVOICE_PAYMENT_METHODS = [
  'Cash',
  'Bank Transfer',
  'Check',
  'Credit Card',
  'Visa',
  'MasterCard'
];

export const BILL_PAYMENT_METHODS = [
  'Cash',
  'Bank Transfer',
  'Check',
  'Credit Card',
  'Visa',
  'MasterCard'
];

export const APP_SERVICES = {
  FRESHBOOKS: 'Fresbooks',
  QUICKBOOKS: 'Quickbooks'
};

export const ShipmentStatus = {
  BOOKED: 0,
  COLLECTED: 1,
  NOT_ARRIVED: 2,
  WAITING_AT_PORT: 3,
  SHIPPED: 4,
  UNDERWAY: 5,
  UNDERWAY_2_TRANSHIPMENT_PORT: 6,
  ARRIVAL_AT_UNDERWAY_2_TRANSHIPMENT_PORT: 7,
  WAITING_AT_TRANSHIPMENT_PORT: 8,
  SHIPPED_AT_TRANSHIPMENT_PORT: 9,
  ARRIVED_AT_POD: 10,
  WAITING_AT_DESTINATION_PORT: 11,
  LEFT_DESTINATION_PORT: 12,
  COMPLETED: 13,
  WAITING_AT_RAMP: 14,
  ARRIVED_AT_DESTINATION_RAMP: 15
};

export const RailShipmentStatus = {
  BOOKED: 0,
  COLLECTED: 1,
  NOT_ARRIVED: 2,
  WAITING_AT_RAMP: 3,
  WAITING_PICKUP: 4,
  PENDING_RETURN: 5,
  COMPLETED: 13,
  ARRIVED_AT_DESTINATION_RAMP: 15
};

export const ShipmentStatusLabel = {
  [ShipmentStatus.BOOKED]: 'Booked',
  [ShipmentStatus.COLLECTED]: 'Collected from Shipper',
  [ShipmentStatus.NOT_ARRIVED]: 'Not arrived at Port of Load',
  [ShipmentStatus.WAITING_AT_PORT]: 'Waiting at Port of Load',
  [ShipmentStatus.SHIPPED]: 'Shipped on board',
  [ShipmentStatus.UNDERWAY]: 'Underway',
  [ShipmentStatus.UNDERWAY_2_TRANSHIPMENT_PORT]:
    'Underway to a Transhipment Port',
  [ShipmentStatus.ARRIVAL_AT_UNDERWAY_2_TRANSHIPMENT_PORT]:
    'Arrival at Transhipment Port',
  [ShipmentStatus.WAITING_AT_TRANSHIPMENT_PORT]: 'Waiting at Transhipment Port',
  [ShipmentStatus.SHIPPED_AT_TRANSHIPMENT_PORT]:
    'Shipped on board at Transhipment Port',
  [ShipmentStatus.ARRIVED_AT_POD]: 'Arrived at POD',
  [ShipmentStatus.WAITING_AT_DESTINATION_PORT]: 'Waiting at Destination Port',
  [ShipmentStatus.LEFT_DESTINATION_PORT]: 'Left the Destination Port',
  [ShipmentStatus.COMPLETED]: 'Shipment Completed',
  [ShipmentStatus.WAITING_AT_RAMP]: 'Waiting at Origin Ramp',
  [ShipmentStatus.ARRIVED_AT_DESTINATION_RAMP]: 'Arrived at Destination Ramp' // waiting for delivery
};

export const RailShipmentStatusLabel = {
  [RailShipmentStatus.BOOKED]: 'Booked',
  [RailShipmentStatus.COLLECTED]: 'Collected from Shipper',
  [RailShipmentStatus.NOT_ARRIVED]: 'Not arrived at Port of Load',
  [RailShipmentStatus.WAITING_AT_RAMP]: 'Waiting at terminal',
  [RailShipmentStatus.WAITING_PICKUP]: 'Waiting for pickup',
  [RailShipmentStatus.PENDING_RETURN]: 'Pending return',
  [RailShipmentStatus.COMPLETED]: 'Completed',
  [RailShipmentStatus.ARRIVED_AT_DESTINATION_RAMP]:
    'Arrived at destination ramp'
};

export const temperature = ['Heated', 'Ambient', 'Refridgerated', 'Frozen'];
export const temperatureType = ['C', 'F'];
export const dataTableLocationFields = [
  {
    label: 'Name',
    name: 'name',
    type: 'string',
    enable: true
  },
  {
    label: 'Address',
    name: 'address',
    type: 'string',
    enable: true
  },
  {
    label: 'PostCode',
    name: 'postCode',
    type: 'string',
    enable: true
  },
  {
    label: 'Code',
    name: 'code',
    type: 'string',
    enable: true
  },
  {
    label: 'Country',
    name: 'country',
    type: 'string',
    enable: true,
    fieldExpression: 'country.name'
  },
  {
    label: 'State',
    name: 'state',
    type: 'string',
    enable: true,
    fieldExpression: 'state.state'
  },
  {
    label: 'Type',
    name: 'type',
    type: 'string',
    enable: true,
    fieldExpression: 'type.type'
  }
];

export const dataTableProductFields = [
  {
    label: 'Product ID',
    name: 'productId',
    type: 'string',
    enable: true
  },
  {
    label: 'Name',
    name: 'name',
    type: 'string',
    enable: true
  },
  {
    label: 'Category',
    name: 'category',
    type: 'string',
    enable: true
  }
];

export const dataTableContractFields = [
  {
    label: 'Contract Number',
    name: 'contractNumber',
    type: 'string',
    enable: true
  },
  {
    label: 'Description',
    name: 'description',
    type: 'string',
    enable: true
  }
];

export const dataTablePurchaseOrderFields = [
  {
    label: 'PO No',
    name: 'poNo',
    type: 'string',
    enable: true
  },
  {
    label: 'Shipment',
    name: 'shipment',
    type: 'string',
    enable: true
  },
  {
    label: 'Customer',
    name: 'customer',
    type: 'string',
    enable: true,
    fieldExpression: 'customer.legalName'
  },
  {
    label: 'Vendor',
    name: 'vendor',
    type: 'string',
    enable: true,
    fieldExpression: 'vendor.legalName'
  }
];

export const dataTableLoadBoardFields = [
  {
    label: 'Shipper',
    name: 'shipper',
    type: 'string',
    enable: true,
    fieldExpression: 'company.legalName'
  },
  {
    label: 'Consignee',
    name: 'consignee',
    type: 'string',
    enable: true,
    fieldExpression: 'customer.legalName'
  }
];

export const dataTableOrderFields = [
  {
    label: 'Number',
    name: 'number',
    type: 'string',
    enable: true
  },
  {
    label: 'HBL',
    name: 'hbl',
    type: 'string',
    enable: true,
    fieldExpression: 'bol.bolNumber'
  },
  {
    label: 'Contract',
    name: 'contract',
    type: 'string',
    enable: true
  }
];

export const dataTableVolumeLaneFields = [
  {
    label: 'Mode',
    name: 'shippingMode',
    type: 'string',
    enable: true
  },
  {
    label: 'Origin',
    name: 'originLocation',
    type: 'string',
    enable: true,
    fieldExpression: 'originLocation.name'
  },
  {
    label: 'Destination',
    name: 'destinationLocation',
    type: 'string',
    enable: true,
    fieldExpression: 'destinationLocation.name'
  },
  {
    label: 'Shipping Type',
    name: 'shippingType',
    type: 'string',
    enable: true
  },
  {
    label: 'Equipment Type',
    name: 'equipmentType',
    type: 'string',
    enable: true
  },
  {
    label: 'Order Date',
    name: 'orderDate',
    type: 'date',
    enable: true
  },
  {
    label: 'Product Type',
    name: 'temperatureType',
    type: 'string',
    enable: true
  },
  {
    label: 'Count',
    name: 'count',
    type: 'number',
    enable: true
  }
];

export const dataTableTaskFields = [
  {
    label: 'No #',
    name: 'taskId',
    type: 'string',
    enable: true
  },
  {
    label: 'Type',
    name: 'taskType',
    type: 'string',
    enable: true
  },
  {
    label: 'Order Number',
    name: 'orderNumber',
    type: 'string',
    enable: true,
    fieldExpression: 'order.orderNumber'
  },
  {
    label: 'Status',
    name: 'status',
    type: 'string',
    enable: true
  }
];

export const dataTableRateFields = [
  {
    label: 'No #',
    name: 'rateId',
    type: 'string',
    enable: true
  },
  {
    label: 'Type',
    name: 'rateType',
    type: 'string',
    enable: true
  },
  {
    label: 'Shipping Mode',
    name: 'shippingMode',
    type: 'string',
    enable: true
  }
];

export const dataTableSupplyFields = [
  {
    label: 'Origin',
    name: 'originLocation',
    type: 'string',
    enable: true,
    fieldExpression: 'originLocation.name'
  },
  {
    label: 'Order Date',
    name: 'orderDate',
    type: 'date',
    enable: true
  },
  {
    label: 'Count',
    name: 'count',
    type: 'number',
    enable: true
  }
];

export const dataTableDemandFields = [
  {
    label: 'Origin',
    name: 'destinationLocation',
    type: 'string',
    enable: true,
    fieldExpression: 'destinationLocation.name'
  },
  {
    label: 'Order Date',
    name: 'orderDate',
    type: 'date',
    enable: true
  },
  {
    label: 'Count',
    name: 'count',
    type: 'number',
    enable: true
  }
];

export const ContainerSize = {
  '10GP': {
    L: 9.81,
    W: 8,
    H: 8.5
  },
  '20GP': {
    L: 19.875,
    W: 8,
    H: 8.5
  },
  '20HC': {
    L: 19.875,
    W: 8,
    H: 9.5
  },
  '40GP': {
    L: 40,
    W: 8,
    H: 8.5
  },
  '40HC': {
    L: 40,
    W: 8,
    H: 9.5
  }
};

export const CONTAINER_BOOKING_STATUS = {
  review: 'Under Review for 24 Hrs',
  accepted: 'Accepted and Charged',
  denied: 'Denied Due to Timing'
};

export const CUSTOMER_CONTAINER_TIERS = [
  'Platinum',
  'Gold',
  'Silver',
  'Bronze',
  'Brass',
  '500 Containers',
  '300 CTN',
  '200 CTN',
  '100 CTN',
  '<50',
  '<20'
];

export const ShipmentControlStatus = {
  PENDING: 'pending',
  TRACKING: 'tracking',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
  AT_ORIGIN: 'at_origin',
  ON_VESSEL: 'on_vessel',
  WAITING_RAIL: 'waiting_rail',
  LTR: 'LTR',
  WAITING_PICKUP: 'waiting_pickup',
  WAITING_EMPTY_RETURN: 'waiting_empty_return',
  ENDED: 'ended',
  D_D_INVOICE: 'd_d_invoice'
};

export const ShipmentControlStatusLabel = {
  [ShipmentControlStatus.PENDING]: 'Pending',
  [ShipmentControlStatus.TRACKING]: 'Tracking',
  [ShipmentControlStatus.AT_ORIGIN]: 'At origin',
  [ShipmentControlStatus.ON_VESSEL]: 'On vessel',
  [ShipmentControlStatus.WAITING_RAIL]: 'At rail yard',
  [ShipmentControlStatus.LTR]: 'LTR',
  [ShipmentControlStatus.WAITING_PICKUP]: 'Waiting pickup',
  [ShipmentControlStatus.WAITING_EMPTY_RETURN]: 'Pending return',
  [ShipmentControlStatus.D_D_INVOICE]: 'D&D invoice',
  [ShipmentControlStatus.ENDED]: 'Ended'
};

export const ROLES = {
  RAIL_YARD: ['Driver', 'Crane Operator', 'Lift Operator', 'Manager']
};
