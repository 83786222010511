import http from 'shared/utils/http';

/**
 * Gets public load boards
 * @param {} filter
 */
export const getLoadBoard = (filter) => {
  return http.get('/asset/automotive/loadboard/public', filter);
};

export const getPrivateLoadBoard = (filter) => {
  return http.get('/asset/automotive/loadboard', filter);
};

export const getLoadBoardDetail = (id) => {
  return http.get(`/asset/automotive/loadboard/${id}`);
};

export const updateLoadBoard = (loadBoard) => {
  return http.post('/netwila/loadboard', loadBoard);
};

export const deleteLoadBoard = (loadBoardId) => {
  return http.delete('/netwila/loadboard', { loadBoardId });
};

export const acceptBid = (loadBoardId, bidAccepted) => {
  return http.put(`/netwila/loadboard/${loadBoardId}/${bidAccepted}/accept`);
};

export const rejectBid = (loadBoardId, bidAccepted) => {
  return http.put(`/netwila/loadboard/${loadBoardId}/${bidAccepted}/reject`);
};

export const bidLoadBoard = (loadBoardId, bid) => {
  return http.post(`/netwila/loadboard/${loadBoardId}`, bid);
};

export const getUserBids = (params) => {
  return http.get('/asset/automotive/loadboard/user-bids', params);
};

export const cancelBid = (bidId) => {
  return http.post(`/asset/automotive/loadboard/bids/${bidId}/cancel`);
};
