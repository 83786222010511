import styled, { css } from 'styled-components';
import Button from '../Button';
import { color, font } from 'shared/utils/styles';

export const StyledUploadButton = styled.div`
  display: flex;
  justify-content: flex-start;
`;


const primarySize = css`
  height: 58px;
  ${font.size(14)}
`;

const secondarySize = css`
  height: 32px;
  ${font.size(12)}
`;

const uploadVariants = {
  primary: primarySize,
  secondary: secondarySize,
}

export const StyledButton = styled(Button)`
  ${(props) => uploadVariants[props.buttonSize]}
  margin-left: 10px;
`;

export const FieldLabel = styled.label`
  display: block;
  padding-bottom: 5px;
  color: ${color.textMedium};
  ${font.medium}
  ${font.size(12)}
`;

export const FieldTip = styled.div`
  padding-top: 6px;
  color: ${color.textMedium};
  ${font.size(12.5)}
`;

export const FieldError = styled.div`
  margin-top: 6px;
  line-height: 1;
  color: ${color.danger};
  ${font.medium}
  ${font.size(12.5)}
`;
