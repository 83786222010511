import http from 'shared/utils/http';

export const createVendor = async (data) => {
  const response = await http.api('/api/vendor/data', 'post', {
    ...data
  });
  /**
   * TODO: Response return status
   * How to handle for error
   */
  return response.data;
};

export const getVendor = async (companyId) => {
  const response = await http.api('/api/vendor/data', 'get', {
    companyId
  });

  return response.data;
};

export const getProductTypes = () => {
  return http.get('/netwila/item/producttype');
};

export const getProductCategory = () => {
  return http.get('/netwila/item/listcategories');
};

export const getProduct = () => {
  return http.get('/netwila/item/product');
};

export const createProduct = async (data) => {
  const response = await http.api('/netwila/item/createitemfull', 'post', {
    ...data
  });
  return response.data;
};
