import http from 'shared/utils/http';

export const fetchEquipmentTblData = (equipmentType, companyId) => {
  return http.get(`/api/equipment/${equipmentType}`, { companyId });
};

export const createEquipment = (equipmentType, data) => {
  return http.post(`/api/equipment/${equipmentType}`, data);
};

export const getEquipment = (equipmentType, equipmentId) => {
  return http.get(`/api/equipment/${equipmentType}/${equipmentId}`, {});
};

export const updateEquipment = (equipmentType, equipmentId, data) => {
  return http.put(`/api/equipment/${equipmentType}/${equipmentId}`, data);
};

export const deleteEquipment = (equipmentType, equipmentId) => {
  return http.delete(`/api/equipment/${equipmentType}/${equipmentId}`, {});
};

export const getEquipments = (params) => {
  return http.get('/netwila-core/equipment', params);
};

export const getEquipmentById = (id) => {
  return http.get(`/netwila-core/equipment/${id}`);
};

export const updateEquipmentById = (id, data) => {
  return http.put(`/netwila-core/equipment/${id}`, data);
};

export const createCompanyEquipment = (data) => {
  return http.post('/asset/equipment', data);
};
