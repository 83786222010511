import { combineReducers } from 'redux';
import { createAction, createReducer } from 'redux-act';
import { LOCATION_CHANGE } from 'connected-react-router';

import purchaseOrder from './purchaseOrder';
import oceanOrder from './oceanOrder';
import roadOrder from './roadOrder';
import railOrder from './railOrder';
import drayOrder from './drayOrder';
import { orderListReducer } from './orderList';

const updateOrderEntryAction = createAction('update order entry action');
const orderEntryReducer = createReducer(
  {
    [updateOrderEntryAction]: (state, data) => ({
      ...state,
      ...data
    }),
    [LOCATION_CHANGE]: (state) => ({
      isIntegratedShipping: state.isIntegratedShipping
    })
  },
  {}
);

export default combineReducers({
  purchaseOrder,
  oceanOrder,
  roadOrder,
  railOrder,
  drayOrder,
  orderList: orderListReducer,
  orderEntry: orderEntryReducer
});

export { updateOrderEntryAction };
