const initialState = {
  initialized: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'initializing':
      return {
        ...state,
        initialized: false
      };
    case 'initialized':
      return {
        ...state,
        initialized: true
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

export const initializingAction = () => ({ type: 'initializing' });
export const initializedAction = () => ({ type: 'initialized' });
