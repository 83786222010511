import toast from 'shared/utils/toast';
import { createReducer } from 'redux-act';
import { createActionCRUDResources } from 'shared/utils/reducerHelper';
import * as drayRateServices from 'services/drayRatesServices';

const initialState = {
  fetchLoading: false,
  createLoading: false,
  data: [],
  error: false,
  createError: false,
  message: '',
  createMessage: '',
  currentDrayRate: {}
};

const drayRateAction = createActionCRUDResources('dray rates');

export default createReducer(
  {
    [drayRateAction.fetch.start]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [drayRateAction.fetch.success]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [drayRateAction.fetch.error]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    }),
    [drayRateAction.create.start]: (state) => ({
      ...state,
      createLoading: true,
      createError: false,
      currentDrayRate: {}
    }),
    [drayRateAction.create.success]: (state, payload) => ({
      ...state,
      createLoading: false,
      currentDrayRate: payload
    }),
    [drayRateAction.create.error]: (state, payload) => ({
      ...state,
      createLoading: false,
      createError: true,
      createMessage: payload
    })
  },
  initialState
);

export const fetchDrayRatesTableCreator = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(drayRateAction.fetch.start());
      const response = await drayRateServices.fetchDrayRateTblData(companyId);
      dispatch(drayRateAction.fetch.success(response.data));
      return true;
    } catch (error) {
      dispatch(drayRateAction.fetch.error());
    }
    return false;
  };
};

export const createDrayRatesCreator = (companyId, data) => {
  return async (dispatch) => {
    try {
      dispatch(drayRateAction.create.start());
      const response = await drayRateServices.createDrayRate({
        ...data,
        companyId
      });
      toast.success('Dray rate has been created');
      dispatch(drayRateAction.create.success(response));
      return response;
    } catch (error) {
      dispatch(drayRateAction.create.error());
    }
    toast.error('Error occurred when creating dray rate');
    return false;
  };
};

export const updateDrayRatesCreator = (drayRateId, companyId, data) => {
  return async (dispatch) => {
    try {
      dispatch(drayRateAction.update.start());
      const response = await drayRateServices.updateDrayRate(drayRateId, {
        ...data,
        companyId
      });
      toast.success('Dray rate has been updated');
      dispatch(drayRateAction.update.success(response));
      return response;
    } catch (error) {
      dispatch(drayRateAction.update.error());
    }
    toast.error('Error occurred when updating dray rate');
    return false;
  };
};

export const deleteDrayRatesCreator = (drayRateId) => {
  return async (dispatch) => {
    try {
      dispatch(drayRateAction.delete.start());
      await drayRateServices.deleteDrayRate(drayRateId);
      toast.success('Dray rate has been deleted');
      dispatch(drayRateAction.delete.success());
      return true;
    } catch (error) {
      dispatch(drayRateAction.delete.error());
    }
    toast.error('Error occurred when deleting dray rate');
    return false;
  };
};
