import React from 'react';
import { Upload, message } from 'antd';

import { addDocument } from 'services/document';

const customRequest = async ({ data, file, onSuccess, onError }) => {
  try {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.set(key, data[key]);
    });
    formData.set('document', file);
    const response = await addDocument(formData);
    onSuccess(
      {
        ...response.data,
        name: file.name
      },
      file
    );
  } catch (err) {
    onError(err);
  }
};

export default ({ data, onSuccess, onRemove, mimeTypes, ...opts }) => {
  const props = {
    ...opts,
    data,
    beforeUpload: (file) => {
      if (!mimeTypes || mimeTypes.length === 0) return true;
      if (!mimeTypes.includes(file.type)) {
        message.error(`${file.name} is not supported file types`);
        return false;
      }
      return true;
    },
    customRequest: opts.customRequest ?? customRequest,
    onSuccess: (ret, file) => {
      message.success(`${file.name} file uploaded successfully`);
      onSuccess(ret, file);
    },
    onRemove
  };
  return React.forwardRef(({ children, fileList }, ref) => {
    return (
      <Upload ref={ref} {...props} fileList={fileList}>
        {children}
      </Upload>
    );
  });
};
