import * as Papa from 'papaparse';

const defaultCsvParserConfig = {
  skipEmptyLines: true,
  comments: true
};

export const csvParser = {
  /**
   * Parse CSV to an array
   * @param csv {string|File} a `string` of delimited text to be parsed. Or a `File` object obtained from the DOM.
   * @param config
   */
  parse: (csv, config = {}) => {
    const mergedConfig = {
      ...defaultCsvParserConfig,
      ...config
    };
    return Papa.parse(csv, mergedConfig);
  },
  /**
   * Convert an array into CSV
   * @param data {any} Data to unparse.
   * @param config
   * @returns {string}
   */
  unparse: (data, config = defaultCsvParserConfig) => {
    return Papa.unparse(data, config);
  }
};
