import http from 'shared/utils/http';
import { getLoggedCompany } from 'services/company';
import axios from 'axios';

export const fetchAIModals = () => {
  return http.get(`/netwila-core/ai/model`);
};

export const importLocationsData = async (data) => {
  const response = await http.post('/netwila/location/import', data);
  return response;
};

export const importItemProducts = async (data) => {
  const response = await http.post('/netwila/item/importitemproducts', data);
  return response;
};

export const bulkImportData = async (data) => {
  const response = await http.put('/netwila/bulkimport', data);
  return response;
};

export const downloadDocument = (s3Uri) => {
  return http.get('/netwila-core/etl/download-output', { s3Uri });
};

export const downloadDocumentData = async (url) => {
  const method = 'GET';
  return axios.request({ url, method, responseType: 'blob' });
};

export const downloadOrderTemplate = async (data) => {
  const response = await http.api('/netwila/order/csv/field', 'get', {
    ...data
  });
  return response.data;
};

export const addSupply = async (data) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/netwila/supply', 'post', {
    ...data,
    companyId: loggedCompany._id
  });
  return response.data;
};

export const addDemand = async (data) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/netwila/demand', 'post', {
    ...data,
    companyId: loggedCompany._id
  });
  return response.data;
};

export const addVolumeLane = async (data) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/netwila/volumelane', 'post', {
    ...data,
    companyId: loggedCompany._id
  });
  return response.data;
};

export const createPresentation = async (data) => {
  // const loggedCompany = getLoggedCompany();
  // const response = await http.api('/network/network/addPresentation', 'post', {
  //   ...data,
  //   companyId: loggedCompany._id
  // });
  // return response.data;
  return http.post('/netwila-core/network/presentations', data);
};

export const updatePresentation = async (id, data) => {
  // const loggedCompany = getLoggedCompany();
  // const response = await http.api(
  //   '/network/network/updatepresentation',
  //   'post',
  //   {
  //     ...data,
  //     companyId: loggedCompany._id
  //   }
  // );
  // return response.data;
  return http.put(`/netwila-core/network/presentations/${id}`, data);
};

export const deletePresentation = async (id) => {
  // const loggedCompany = getLoggedCompany();
  // const response = await http.api(
  //   '/network/network/deletepresentation',
  //   'post',
  //   {
  //     ...data,
  //     companyId: loggedCompany._id
  //   }
  // );
  // return response.data;
  return http.delete(`/netwila-core/network/presentations/${id}`);
};

export const getPresentations = (params) => {
  // const loggedCompany = getLoggedCompany();
  // const response = await http.api('/network/network', 'get', {
  //   companyId: loggedCompany._id
  // });
  // return response.data;
  return http.get('/netwila-core/network/presentations', params);
};

export const getGlossary = async () => {
  const response = await http.api('/netwila/glossary', 'get');
  return response.data;
};

export const getPresentation = async () => {
  return http.get(`/netwila-core/ai/model`);
};

export const getTemplates = async () => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/network/network/getTemplates', 'get', {
    companyId: loggedCompany._id
  });
  return response.data;
};

export const shareWith = async (data) => {
  const response = await http.api(
    '/network/network/sharepresentation',
    'post',
    {
      presentationId: data.presentationId,
      shareWith: data.shareWith
    }
  );
  return response.data;
};

export const shareWithExternal = async (data) => {
  const response = await http.api('/network/network/externalShare', 'post', {
    presentationId: data.presentationId,
    shareWithEmail: data.shareWithEmail
  });
  return response.data;
};

export const getExternalShares = async (data) => {
  const response = await http.api('/network/network/getexternalShare', 'get', {
    query: data
  });
  return response.data;
};

export const getCompanyLocations = () => {
  return http.get('/netwila-core/network/company-locations');
};

export const updateCompanyGeoLocations = () => {
  return http.put('/netwila-core/network/company-geo-locations');
};

export const getCompanyPartnerLocations = () => {
  return http.get('/netwila-core/network/company-partner-locations');
};

export const getProducts = () => {
  return http.get('/netwila-core/network/products');
};

export const getContracts = () => {
  return http.get('/netwila-core/network/contracts');
};

export const getPurchaseOrders = () => {
  return http.get('/netwila-core/network/purchase-orders');
};

export const getLoadBoards = () => {
  return http.get('/netwila-core/network/load-boards');
};

export const getOrders = () => {
  return http.get('/netwila-core/network/orders');
};

export const getVolumeLane = () => {
  return http.get('/netwila-core/network/volume-lane');
};

export const getTasks = () => {
  return http.get('/netwila-core/network/tasks');
};

export const getRates = () => {
  return http.get('/netwila-core/network/rates');
};

export const getSupply = () => {
  return http.get('/netwila-core/network/supply');
};

export const getDemand = () => {
  return http.get('/netwila-core/network/demand');
};

export const importCompanyLocations = (data) => {
  return http.post('/netwila-core/network/import-company-locations', data);
};

export const importProducts = (data) => {
  return http.post('/netwila-core/network/import-products', data);
};
