import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Typography,
  Spin,
  Alert
} from 'antd';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { routeConfig } from 'configs/route';
import {
  forgotPasswordAction,
  confirmForgotPasswordAction
} from 'reducers/user';
import { StyledLayout } from './Styles';

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState();
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  const passwordMatchValidation = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('The two passwords that you entered do not match!');
    }
  });

  const doLogin = () => {
    dispatch(push(routeConfig.LOGIN));
  };

  const doForgotPassword = (values) => {
    setEmail(values.email);
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => dispatch(forgotPasswordAction(values.email)))
      .then(() => {
        setHasSentEmail(true);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  const doPasswordConfirmation = (values) => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() =>
        dispatch(
          confirmForgotPasswordAction(
            email,
            values.confirmationCode,
            values.password
          )
        )
      )
      .then(() => {
        setHasConfirmed(true);
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Spin spinning={loading}>
      <StyledLayout>
        <Row justify='center' align='top'>
          <Col flex='376px'>
            <Card
              className='no-border'
              title={<b>Forgot Password</b>}
              extra={
                <Button type='link' onClick={doLogin}>
                  Login
                </Button>
              }
            >
              {error && <Alert message={error} type='error' />}
              {!hasSentEmail && (
                <>
                  <p>
                    Please enter your email & we will send a verification code
                  </p>
                  <Form onFinish={doForgotPassword}>
                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!'
                        }
                      ]}
                    >
                      <Input type='email' placeholder='Enter your email' />
                    </Form.Item>
                    <Form.Item>
                      <Button type='primary' block htmlType='submit'>
                        Send
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
              {hasSentEmail && !hasConfirmed && (
                <>
                  <p>
                    Please enter the verification code has been sent to your
                    email
                  </p>
                  <Form onFinish={doPasswordConfirmation}>
                    <Form.Item name='confirmationCode'>
                      <Input placeholder='Enter verfication code' />
                    </Form.Item>
                    <Form.Item
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!'
                        }
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder='Enter new password' />
                    </Form.Item>
                    <Form.Item
                      name='confirmPassword'
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!'
                        },
                        passwordMatchValidation
                      ]}
                    >
                      <Input.Password placeholder='Re-enter new password' />
                    </Form.Item>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' block>
                        Reset password
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}

              {hasConfirmed && (
                <>
                  <Typography.Title level={4}>
                    Your password was reset!
                  </Typography.Title>
                  <Form>
                    <Form.Item>
                      <Button type='primary' block onClick={doLogin}>
                        Go to login
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </StyledLayout>
    </Spin>
  );
};
