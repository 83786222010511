/* eslint-disable camelcase */
import {
  getHierarchys,
  getSelectedHierarchy,
  getDepartment,
  getRoles,
  create,
  getCodes,
  getCategories,
  getCompanyType,
  getAllUsers,
  createRole
} from 'services/hierarchy';

const initialState = {
  fetchingEmployees: false,
  fetchingHierarchy: false,
  fetchingDepartments: false,
  isCreating: false,
  isRolesUpdated: true,
  data: [],
  department: [],
  selectedDpt: '',
  roleMessageFail: '',
  roleMessageSuccess: '',
  selectedHierarchy: {},
  roles: [],
  responsiblities: {
    responsibility_center: [],
    cost_center: [],
    investment_cost_center: [],
    profit_center: [],
    revenue_center: []
  },
  categories: [],
  companyType: [],
  users: [],
  deptUsers: [],
  message: '',
  isCreated: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getHierarchys_start':
      return {
        ...state,
        fetchingHierarchy: true
      };
    case 'getHierarchys_success':
      return {
        ...state,
        data: action.data,
        deptUsers: action.data,
        fetchingHierarchy: false
      };
    case 'getHierarchys_error':
      return {
        ...state,
        fetchingHierarchy: false
      };
    case 'deptUsers_start':
      return {
        ...state,
        deptUsers: action.data
      };
    case 'getUsers_start':
      return {
        ...state,
        fetchingEmployees: true
      };
    case 'getUsers_success':
      return {
        ...state,
        users: action.data,
        fetchingEmployees: false
      };
    case 'getUsers_error':
      return {
        ...state,
        fetchingEmployees: false
      };
    case 'getDepartments_start':
      return {
        ...state,
        fetchingDepartments: true
      };
    case 'getDepartments_success':
      return {
        ...state,
        department: action.data,
        selectedDpt: action.data[0]?._id,
        fetchingDepartments: false
      };
    case 'getDepartments_error':
      return {
        ...state,
        fetchingDepartments: false
      };
    case 'filterHierarchyByStatus':
      return {
        ...state,
        filter: {
          status: action.data
        }
      };
    case 'filterHierarchyByType':
      return {
        ...state,
        filter: {
          ...state.filter, // keep filter status condition
          type: action.data
        }
      };
    case 'selectedHierarchy':
      return {
        ...state,
        selectedHierarchy: action.data
      };
    case 'getAllRoles':
      return {
        ...state,
        roles: action.data,
        isRolesUpdated: false
      };
    case 'selectedDpt':
      return {
        ...state,
        selectedDpt: action.id
      };
    case 'create_action_start':
      return {
        ...state,
        isCreating: action.data
      };
    case 'create_action':
      return {
        ...state,
        message: action.data,
        isCreating: false,
        isCreated: true
      };
    case 'create_Role_action_start':
      return {
        ...state,
        isCreating: action.data
      };
    case 'create_Role_action':
      return {
        ...state,
        isCreating: false,
        isRolesUpdated: true,
        roleMessageSuccess: `Role ${action.data} is created`
      };
    case 'create_Role_error':
      return {
        ...state,
        isCreating: false,
        roleMessageFail: action.data
          ? action.data
          : 'Failed to create new role.'
      };
    case 'get_code_action':
      return {
        ...state,
        responsiblities: { ...state.responsiblities, ...action }
      };
    case 'get_categories_action':
      return {
        ...state,
        categories: action.data
      };
    case 'get_company_type_action':
      return {
        ...state,
        companyType: action.data
      };
    case 'setCreateAction':
      return {
        ...state,
        isCreated: false
      };
    default:
      return state;
  }
};

export const getHierarchysAction = (id) => {
  return (dispatch) => {
    dispatch({ type: 'getHierarchys_start', data: true });
    getHierarchys(id)
      .then((res) => {
        dispatch({ type: 'getHierarchys_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getHierarchys_error', data: err });
      });
  };
};

export const getAllUsersAction = () => {
  return (dispatch) => {
    dispatch({ type: 'getUsers_start', data: true });
    getAllUsers()
      .then((res) => {
        dispatch({ type: 'getUsers_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getUsers_error', data: err });
      });
  };
};

export const getUsersOfDepAction = (id) => {
  return (dispatch) => {
    getHierarchys(id)
      .then((res) => {
        dispatch({ type: 'deptUsers_start', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'deptUsers_error', data: err });
      });
  };
};

export const selectHierarchyAction = (id) => {
  return (dispatch) => {
    getSelectedHierarchy(id)
      .then((res) => {
        dispatch({ type: 'selectedHierarchy', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'create_actions_error', data: err });
      });
  };
};

export const getDeprtmetAction = (id) => {
  return (dispatch) => {
    dispatch({ type: 'getDepartments_start', data: true });
    getDepartment(id)
      .then((res) => {
        dispatch({ type: 'getDepartments_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getDepartments_error', data: err });
      });
  };
};

export const getRolesAction = () => {
  return (dispatch) => {
    getRoles()
      .then((res) => {
        dispatch({ type: 'getAllRoles', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getHierarchys_error', data: err });
      });
  };
};

export const filterHierarchyByStatusAction = (status) => {
  return (dispatch) =>
    dispatch({ type: 'filterHierarchyByStatus', data: status });
};

export const filterHierarchyByTypeAction = (type) => {
  return (dispatch) => dispatch({ type: 'filterHierarchyByType', data: type });
};

export const loadingAction = (status) => {
  return (dispatch) => dispatch({ type: 'loadingStatus', status });
};

export const createAction = (type, jsonBody) => {
  return (dispatch) => {
    dispatch({ type: 'create_action_start', data: true });
    create(type, jsonBody)
      .then((res) => {
        dispatch({ type: 'create_action', data: res?.meta?.message });
      })
      .catch((res) => {
        dispatch({ type: 'create_action', data: res?.meta?.message });
      });
  };
};

export const createRoleAction = (jsonBody) => {
  return (dispatch) => {
    dispatch({ type: 'create_Role_action_start', data: true });
    createRole(jsonBody)
      .then((res) => {
        getRolesAction();
        dispatch({ type: 'create_Role_action', data: res?.data?.role_name });
      })
      .catch((res) => {
        dispatch({ type: 'create_Role_error', data: res?.meta?.message });
      });
  };
};

export const getCodesAction = (type) => {
  return (dispatch) =>
    getCodes(type)
      .then((res) => {
        const codes = res.data.map((loc) => {
          return { label: loc.code, value: loc._id };
        });
        switch (type) {
          case 'responsibility_center':
            return dispatch({
              type: 'get_code_action',
              responsibility_center: codes
            });
          case 'cost_center':
            return dispatch({ type: 'get_code_action', cost_center: codes });
          case 'investment_cost_center':
            return dispatch({
              type: 'get_code_action',
              investment_cost_center: codes
            });
          case 'profit_center':
            return dispatch({ type: 'get_code_action', profit_center: codes });
          case 'revenue_center':
            return dispatch({ type: 'get_code_action', revenue_center: codes });
          default:
            return 'state';
        }
      })
      .catch((err) => {
        dispatch({ type: 'get_code_err', id: type, data: err });
      });
};

export const getCategoriesAction = () => {
  return (dispatch) =>
    getCategories()
      .then((res) => {
        dispatch({ type: 'get_categories_action', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'get_categories_err', data: err });
      });
};

export const getCompanyTypeAction = () => {
  return (dispatch) =>
    getCompanyType()
      .then((res) => {
        dispatch({ type: 'get_company_type_action', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'get_company_type_err', data: err });
      });
};

export const selectDeptAction = (id) => {
  return (dispatch) => dispatch({ type: 'selectedDpt', id });
};

export const setCreateAction = () => {
  return (dispatch) => dispatch({ type: 'isCreatedNon', data: false });
};
