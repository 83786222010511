import React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';

import { routeConfig } from 'configs/route';
import { getLocale } from 'configs/locale';
import { PublicRoute } from 'components/Route/PublicRoute';
import { PrivateRoute } from 'components/Route/PrivateRoute';
import { Toast } from 'shared/components';

import 'assets/scss/style.scss';

import Login from 'pages/Login';
import OAuthLogin from 'pages/Login/OAuth';
import SignUp from 'pages/SignUp2';
import SignUpConfirmation from 'pages/SignUp/Confirm';
import Logout from 'pages/Logout';
import ForgotPassword from 'pages/ForgotPassword';
import AccountSelection from 'pages/Login/AccountSelection';
import Master from 'components/Master';
import ModalLot from 'components/ModalLot';
import CompanySignup from 'pages/Company/Signup';
import SignupVerification from 'pages/SignUp/Verification';
import SourcingSignup from 'pages/Sourcing/Signup';
import SourcingType from 'pages/Sourcing/Type';
import SourcingSignupConfirm from 'pages/Sourcing/SignupConfirm';
import SourcingVerification from 'pages/Sourcing/Verification';
import SourcingLogin from 'pages/Sourcing/Login';
import SourvingSurvey from 'pages/Sourcing/Survey';
import VendorSignup from 'pages/Vendor/pages/Signup';
import VendorSurvey from 'pages/Vendor/pages/Survey';
import VendorSignupConfirm from 'pages/Vendor/pages/SignupConfirm';

const App = () => {
  const locale = useSelector((state) => state.config.locale);

  return (
    <>
      <ConfigProvider locale={getLocale(locale)}>
        <Switch>
          <Route path={routeConfig.SIGNUP} component={SignUp} />
          <Route
            path={routeConfig.SOURCING_SIGNUP}
            component={SourcingSignup}
          />
          <Route
            path={routeConfig.SOURCING_SIGNUP_CONFIRM}
            component={SourcingSignupConfirm}
          />
          <Route
            path={routeConfig.SOURCING_SIGNUP_VIRIFY}
            component={SourcingVerification}
          />
          <Route
            path={routeConfig.SOURCING_ACCOUNT_TYPE}
            component={SourcingType}
          />
          <Route path={routeConfig.SOURCING_LOGIN} component={SourcingLogin} />
          <Route
            exact
            path={routeConfig.SIGNUP_CONFIRMATION}
            component={SignUpConfirmation}
          />
          <Route
            exact
            path={routeConfig.SIGNUP_VERIFICATION}
            component={SignupVerification}
          />
          <Route exact path={routeConfig.LOGIN} component={Login} />
          <Route exact path={routeConfig.VENDOR_LOGIN} component={Login} />
          <Route exact path={routeConfig.OAUTH_LOGIN} component={OAuthLogin} />
          <PublicRoute
            exact
            path={routeConfig.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <PrivateRoute exact path={routeConfig.LOGOUT} component={Logout} />
          <PrivateRoute
            exact
            path={routeConfig.COMPANY_SIGNUP}
            component={CompanySignup}
          />
          <PrivateRoute
            exact
            path={routeConfig.ACCOUNT_SELECTION}
            component={AccountSelection}
          />
          <PrivateRoute
            exact
            path={routeConfig.SOURCING_SURVEY}
            component={SourvingSurvey}
          />
          <PublicRoute
            exact
            path={routeConfig.VENDOR_SIGNUP}
            component={VendorSignup}
          />
          <PublicRoute
            exact
            path={routeConfig.VENDOR_SIGNUP_CONFIRM}
            component={VendorSignupConfirm}
          />
          <PrivateRoute
            exact
            path={routeConfig.VENDOR_SURVEY}
            component={VendorSurvey}
          />
          <PrivateRoute path='/' component={Master} />
        </Switch>
      </ConfigProvider>
      <Toast />
      <ModalLot />
    </>
  );
};

export default withRouter(App);
