import toast from 'shared/utils/toast';
import { createAction, createReducer } from 'redux-act';
import { fetchWayBillTblData } from 'services/waybill';

const fetchCmaStart = createAction('fetch CMA start');
const fetchCmaSuccess = createAction('fetch CMA success');
const fetchCmaError = createAction('fetch CMA error');

const initialState = {
  loading: false,
  message: '',
  error: false,
  data: []
};

export default createReducer(
  {
    [fetchCmaStart]: (state) => ({
      ...state,
      loading: true
    }),
    [fetchCmaSuccess]: (state, payload) => ({
      ...state,
      data: payload.data,
      loading: false
    }),
    [fetchCmaError]: (state, payload) => ({
      ...state,
      loading: false,
      error: true,
      message: payload.error.message
    })
  },
  initialState
);

export const fetchWayBillDataAction = ({ companyId, ...params }) => {
  return async (dispatch) => {
    dispatch(fetchCmaStart());
    try {
      const dataResponse = await fetchWayBillTblData({ companyId, ...params });
      dispatch(fetchCmaSuccess({ data: dataResponse.data }));
    } catch (error) {
      toast.error('Failed to fetch CMA data!');
      dispatch(fetchCmaError({ error }));
    }
  };
};
