import { createReducer, createApiAction } from 'shared/utils/redux';
import {
  getTasks,
  assignWorker,
  updateTaskStatus
} from 'services/asset/automotiveYardPlanning';

const initialState = {
  tasks: [],
  tasksMeta: {},
  error: null
};

export const getTaskStateSelector = (state) => state.asset.automotiveYardTask;

export const getTasksSelector = (state) => {
  const { tasks, tasksMeta } = getTaskStateSelector(state);
  return { tasks, tasksMeta };
};

export const getTasksAction = createApiAction(
  'getTasksAction',
  (params = {}) => ({
    callAPI: () => getTasks(params)
  })
);

export const assignWorkerAction = createApiAction(
  'assignWorkerAction',
  (params) => ({
    callAPI: () => assignWorker(params)
  })
);

export const updateTaskStatusAction = createApiAction(
  'updateTaskStatusAction',
  (params) => ({
    callAPI: () => updateTaskStatus(params)
  })
);

export default createReducer(initialState, {
  [getTasksAction.success]: (state, { response }) => ({
    ...state,
    tasks: response.data,
    tasksMeta: response.meta
  }),
  [assignWorkerAction.success]: (state, { payload }) => {
    const idx = state.tasks.findIndex((task) => task.id === payload.taskId);
    return {
      ...state,
      tasks: [
        ...state.tasks.slice(0, idx),
        {
          ...state.tasks[idx],
          worker: payload.worker
        },
        ...state.tasks.slice(idx + 1)
      ]
    };
  },
  [updateTaskStatusAction.success]: (state, { payload }) => {
    const idx = state.tasks.findIndex((task) => task.id === payload.taskId);
    return {
      ...state,
      tasks: [
        ...state.tasks.slice(0, idx),
        {
          ...state.tasks[idx],
          status: payload.status
        },
        ...state.tasks.slice(idx + 1)
      ]
    };
  },
  [updateTaskStatusAction.failure]: (state, { error }) => ({
    ...state,
    error: error.message
  })
});
