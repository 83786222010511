import { getAssignment } from 'services/drayageAssignment';

const initialState = {
  loading: true,
  error: false,
  message: '',
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'fetching_data':
      return {
        ...state,
        loading: true
      };
    case 'fetching_data_success':
      return {
        ...state,
        loading: false,
        data: action.data
      };
    case 'fetching_data_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      };
    default:
      return state;
  }
};

export const fetchDrayAssignmentAction = (param) => {
  return async (dispatch) => {
    try {
      const { data } = await getAssignment(param);
      dispatch({ type: 'fetching_data_success', data });
    } catch (e) {
      dispatch({ type: 'fetching_data_error', message: e.message });
    }
  };
};
