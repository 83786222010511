import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { waiterReducer } from 'redux-waiters';

import appReducer from './app';
import configReducer from './config';
import authReducer from './authentication';
import contractReducer from './contract';
import rateCardReducer from './contract/rateCard';
import modeReducer from './mode';
import orderReducer from './order';
import dashboardReducer from './dashboard';
import orderRailReducer from './orderRail';
import orderRoadReducer from './orderRoad';
import vendorReducer from './vendor';
import notificationReducer from './notification';
import userReducer from './user';
import companyReducer from './company/companyManagement';
import languageReducer from './language';
import locationReducer from './location';
import criticalMessageReducer from './criticalMessages';
import allMessagesReducer from './allMessages';
import hierarchyReducer from './hierarchy';
import roleReducer from './role';
import documentReducer from './document';
import settingReducer from './setting';
import orderEntryReducer from './orderEntry';
import equipmentReducer from './equipment';
import accountManagementReducer from './company/accountManagement';
import toggleVisibleNumberReducer from './toggleVisibleNumber';
import bulkVesselReducer from './bulkVessel';
import bulkCarUnloadReducer from './bulkCarUnload';
import railCapacityPlanningReducer from './railCapacityPlanning';
import ediReducer from './edi';
import oceanIntermodalReducer from './oceanIntermodal';
import drayRatesReducer from './drayRates';
import hsCodeReducer from './hCode/hsCode';
import hazardousCodeReducer from './hCode/hazardousCode';
import masterScheduleReducer from './masterSchedule';
import drayAppointmentBoard from './drayAppointmentBoard';
import drayAssignment from './drayAssignment';
import modalLotReducer from './modalLot';
import moveType from './moveType';
import railDeliveryTransitReducer from './railDeliveryTransit';
import commonReducer from './common';
import companyTaskReducer from './task/companyTask';
import wayBillReducer from './waybill';
import integratedShippingReducer from './integratedShipping';
import assetReducer from './asset';
import demandReducer from './demand';
import productReducer from './vendor/product';
import uaaReducer from './uaa';
import RoadReducer from './road';
import CustomerReducer from './customer';
import RevenueReducer from './revenue';
import AiReducer from './ai';
import EtlReducer from './etl';
import NetworkReducer from './network';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    waiter: waiterReducer,
    app: appReducer,
    config: configReducer,
    auth: authReducer,
    user: userReducer,
    contract: contractReducer,
    rateCard: rateCardReducer,
    mode: modeReducer,
    order: orderReducer,
    dashboard: dashboardReducer,
    orderRail: orderRailReducer,
    orderRoad: orderRoadReducer,
    vendor: vendorReducer,
    notification: notificationReducer,
    company: companyReducer,
    language: languageReducer,
    location: locationReducer,
    allMessages: allMessagesReducer,
    criticalMessages: criticalMessageReducer,
    hierarchy: hierarchyReducer,
    role: roleReducer,
    document: documentReducer,
    setting: settingReducer,
    orderEntry: orderEntryReducer,
    equipment: equipmentReducer,
    account: accountManagementReducer,
    visibleNumber: toggleVisibleNumberReducer,
    bulkVessel: bulkVesselReducer,
    bulkCarUnload: bulkCarUnloadReducer,
    railCapacityPlanning: railCapacityPlanningReducer,
    edi: ediReducer,
    oceanIntermodal: oceanIntermodalReducer,
    drayRates: drayRatesReducer,
    hsCode: hsCodeReducer,
    hazardousCode: hazardousCodeReducer,
    masterSchedule: masterScheduleReducer,
    drayAppointmentBoard,
    drayAssignment,
    modalLot: modalLotReducer,
    moveType,
    railDeliveryTransit: railDeliveryTransitReducer,
    common: commonReducer,
    companyTask: companyTaskReducer,
    wayBill: wayBillReducer,
    integratedShipping: integratedShippingReducer(),
    asset: assetReducer(),
    demand: demandReducer(),
    product: productReducer,
    uaa: uaaReducer,
    road: RoadReducer,
    customer: CustomerReducer,
    revenue: RevenueReducer,
    ai: AiReducer,
    etl: EtlReducer,
    network: NetworkReducer
  });
