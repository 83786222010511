import http from 'shared/utils/http';

export const fetchData = (companyId, param = {}) => {
  return http.get(`/api/integrated-shipping/integrated-shipping-setup`, {
    companyId,
    ...param
  });
};

export const createData = (companyId, data) => {
  return http.post(`/api/integrated-shipping/integrated-shipping-setup`, {
    companyId,
    ...data
  });
};

export const updateDataById = (id, companyId, data) => {
  return http.put(`/api/integrated-shipping/integrated-shipping-setup/${id}`, {
    companyId,
    ...data
  });
};

export const deleteDataById = (id) => {
  return http.delete(
    `/api/integrated-shipping/integrated-shipping-setup/${id}`
  );
};

export const createIntegratedShipping = (companyId, data) => {
  return http.post('/integrated-shipping/integrated-shipping-setup', {
    companyId,
    ...data
  });
};

export const fetchIntegratedShipping = (companyId, param = {}) => {
  return http.get(`/integrated-shipping/integrated-shipping-setup`, {
    companyId,
    ...param
  });
};

export const getIntegratedShippingWave = (param = {}) => {
  return http.get(`/integrated-shipping/wave-setup/find-wave-setup`, param);
};
