export const PO_DETAIL_FIELDS = [
  {
    label: 'PO Number',
    name: 'poNumber',
    type: 'Input',
    defaultValue: 'Purchase Order',
    default: true
  },
  {
    label: 'Load Number',
    name: 'customerLoadNumber',
    type: 'Input',
    defaultValue: 'Customer Load Tracking Number',
    default: true
  },
  {
    label: 'Customer',
    name: 'customer',
    type: 'Input',
    defaultValue: 'Netwila Applications Corp.',
    default: true
  },
  {
    label: 'Country',
    name: 'country',
    type: 'Select',
    defaultValue: 'Canada',
    options: [],
    default: false
  },
  {
    label: 'State',
    name: 'state',
    type: 'Select',
    defaultValue: 'ON',
    options: [],
    default: false
  },
  {
    label: 'Vendor Name',
    name: 'supplierName',
    type: 'Input',
    defaultValue: 'Ace Company',
    default: true
  },
  {
    label: 'Vendor Number',
    name: 'supplierNumber',
    type: 'Input',
    defaultValue: '60001',
    default: true
  },
  {
    label: 'Ship From',
    name: 'shipFrom',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Ship To',
    name: 'shipTo',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Planned Ready Date',
    name: 'plannedReadyDate',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Planned Pickup Date',
    name: 'plannedPickupDate',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Planned Receipt Date',
    name: 'plannedReceiptDate',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Actual Ready Date',
    name: 'actualReadyDate',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Actual Pickup Date',
    name: 'actualPickupDate',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Actual Receipt Date',
    name: 'actualReceiptDate',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Order Date',
    name: 'orderDate',
    type: 'Input', // it should be date picker
    defaultValue: 'Date of order',
    default: true
  },
  {
    label: 'Incoterm',
    name: 'incoterm',
    type: 'Input',
    defaultValue: 'Inco Term',
    default: true
  },
  {
    label: 'Order Status',
    name: 'orderStatus',
    type: 'Input',
    defaultValue: 'Order Status'
  },
  {
    label: 'Customer Ref Number',
    name: 'customerReferenceNumber',
    type: 'Input',
    defaultValue: 'Customer Ref Number'
  },
  {
    label: 'Forwarder',
    name: 'forwarder',
    type: 'Input',
    defaultValue: 'Forwarder'
  },
  {
    label: 'Container ETD',
    name: 'containerETD',
    type: 'Input',
    defaultValue: 'Container ETD'
  },
  {
    label: 'Vendor Address',
    name: 'vendorAddress',
    type: 'Input',
    defaultValue: 'Vendor Adress'
  },
  {
    label: 'Vendor Phone',
    name: 'vendorPhone',
    type: 'Input',
    defaultValue: 'Vendor Phone'
  },
  {
    label: 'Urgency',
    name: 'urgency',
    type: 'Input',
    defaultValue: 'Urgency'
  },
  {
    label: 'Equipment Type',
    name: 'equipmentType',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Temperature Type',
    name: 'temperatureType',
    type: 'Input',
    defaultValue: ''
  }
];

export const PO_ITEM_FIELDS = [
  {
    label: 'Item',
    name: 'item',
    type: 'Input',
    defaultValue: 'Product Name',
    default: true
  },
  {
    label: 'Description',
    name: 'description',
    type: 'Input',
    defaultValue: 'Product Description',
    default: true
  },
  {
    label: 'Category',
    name: 'category',
    type: 'Input',
    defaultValue: 'Product Category',
    default: true
  },
  {
    label: 'UoM',
    name: 'uom',
    type: 'Input',
    defaultValue: 'EA',
    default: true
  },
  {
    label: 'Quantity',
    name: 'quantity',
    type: 'Input',
    defaultValue: 'quantity of units',
    default: true,
    align: 'center'
  },
  {
    label: 'Weight Unit',
    name: 'weightUnit',
    type: 'Input',
    defaultValue: '',
    default: true
  },
  {
    label: 'Weight',
    name: 'weight',
    type: 'Input',
    defaultValue: '',
    default: true,
    align: 'center'
  },
  {
    label: 'Rate',
    name: 'rate',
    type: 'Input',
    defaultValue: '',
    default: true,
    align: 'center'
  },
  {
    label: 'Rate Currency',
    name: 'rateCurrency',
    type: 'Input',
    defaultValue: 'CAD',
    default: true
  },
  {
    label: 'UoV',
    name: 'uov',
    type: 'Input',
    defaultValue: 'CBM',
    default: true
  },
  {
    label: 'Volume',
    name: 'volume',
    type: 'Input',
    defaultValue: '',
    default: true,
    align: 'center'
  },
  {
    label: 'HS Code',
    name: 'hsCode',
    type: 'Input',
    defaultValue: 'HS code Number',
    default: true
  },
  {
    label: 'UN Code',
    name: 'unCode',
    type: 'Input',
    defaultValue: 'UN code for Hazourdous',
    default: true
  },
  {
    label: 'Hazardous',
    name: 'hazardous',
    type: 'Input',
    defaultValue: 'Hazardous description',
    default: true
  },
  {
    label: 'Temperature',
    name: 'temperature',
    type: 'Input',
    defaultValue: 'Temperature',
    default: true
  },
  {
    label: 'Origin Country',
    name: 'originCountry',
    type: 'Input',
    defaultValue: 'Origin Country'
  },
  {
    label: 'Container No.',
    name: 'containerNo',
    type: 'Input',
    defaultValue: 'Container No.',
    default: true
  },
  {
    label: 'Seal No.',
    name: 'sealNo',
    type: 'Input',
    defaultValue: 'Seal No.',
    default: true
  },
  {
    label: 'Collect',
    name: 'collect',
    type: 'Input',
    defaultValue: 'Collect'
  },
  {
    label: 'Prepaid To Destination',
    name: 'prepaidToDestination',
    type: 'Input',
    defaultValue: 'Prepaid To Destination'
  },
  {
    label: 'Prepaid To Store',
    name: 'prepaidToStore',
    type: 'Input',
    defaultValue: 'Prepaid To Store'
  },
  {
    label: 'Prepaid To Customer',
    name: 'prepaidToCustomer',
    type: 'Input',
    defaultValue: 'Prepaid To Customer'
  },
  {
    label: 'Freezable',
    name: 'freezable',
    type: 'Input',
    defaultValue: 'Freezable'
  },
  {
    label: 'Dangerous Goods',
    name: 'dangerousGoods',
    type: 'Input',
    defaultValue: 'Dangerous Goods'
  },
  {
    label: 'Product Segment',
    name: 'productSegment',
    type: 'Input',
    defaultValue: 'Product Segment'
  },
  {
    label: 'Fragile',
    name: 'fragile',
    type: 'Input',
    defaultValue: 'Fragile'
  },
  {
    label: 'Pallet Stackability',
    name: 'palletStackability',
    type: 'Input',
    defaultValue: 'Pallet Stackability'
  },
  {
    label: 'Article Nest Needed',
    name: 'articleNestNeeded',
    type: 'Input',
    defaultValue: 'Article Nest Needed'
  },
  {
    label: 'Current Shippable Layer',
    name: 'currentShippableLayer',
    type: 'Input',
    defaultValue: 'Current Shippable Layer'
  },
  // Packaging - Consumer Unit
  {
    label: 'Consumer Unit: Packaging Type',
    name: 'consumerUnit:packagingType',
    type: 'Input',
    defaultValue: 'Packaging Type'
  },
  {
    label: 'Consumer Unit: UPC Code',
    name: 'consumerUnit:upcCode',
    type: 'Input',
    defaultValue: 'UPC Code'
  },
  {
    label: 'Consumer Unit: Scannable',
    name: 'consumerUnit:scannable',
    type: 'Input',
    defaultValue: 'Scannable'
  },
  {
    label: 'Consumer Unit: Longest DIM (In MT)',
    name: 'consumerUnit:longestDIMInMT',
    type: 'Input',
    defaultValue: 'Longest DIM (In MT)'
  },
  {
    label: 'Consumer Unit: Longest DIM (In FT)',
    name: 'consumerUnit:longestDIMInFT',
    type: 'Input',
    defaultValue: 'Longest DIM (In FT)'
  },
  {
    label: 'Consumer Unit: Weight(In Kg)',
    name: 'consumerUnit:weightInKg',
    type: 'Input',
    defaultValue: 'Weight(In Kg)'
  },
  {
    label: 'Consumer Unit: Weight (In Lbs)',
    name: 'consumerUnit:weightInLb',
    type: 'Input',
    defaultValue: 'Weight (In Lbs)'
  },
  {
    label: 'Consumer Unit: Length (In MT)',
    name: 'consumerUnit:lengthInMT',
    type: 'Input',
    defaultValue: 'Length (In MT)'
  },
  {
    label: 'Consumer Unit: Length (In FT)',
    name: 'consumerUnit:lengthInFT',
    type: 'Input',
    defaultValue: 'Length (In FT)'
  },
  {
    label: 'Consumer Unit: Width (In MT)',
    name: 'consumerUnit:widthInMT',
    type: 'Input',
    defaultValue: 'Width (In MT)'
  },
  {
    label: 'Consumer Unit: Width (In FT)',
    name: 'consumerUnit:widthInFT',
    type: 'Input',
    defaultValue: 'Width (In FT)'
  },
  {
    label: 'Consumer Unit: Height (In MT)',
    name: 'consumerUnit:heightInMT',
    type: 'Input',
    defaultValue: 'Height (In MT)'
  },
  {
    label: 'Consumer Unit: Height (In FT)',
    name: 'consumerUnit:heightInFT',
    type: 'Input',
    defaultValue: 'Height (In FT)'
  },
  {
    label: 'Consumer Unit: Girth (In MT)',
    name: 'consumerUnit:girthInMT',
    type: 'Input',
    defaultValue: 'Girth (In MT)'
  },
  {
    label: 'Consumer Unit: Girth (In FT)',
    name: 'consumerUnit:girthInFT',
    type: 'Input',
    defaultValue: 'Girth (In FT)'
  },
  {
    label: 'Consumer Unit: Shipment Type',
    name: 'consumerUnit:shipmentType',
    type: 'Input',
    defaultValue: 'Shipment Type'
  },
  // Packaging - inner pack
  {
    label: 'Inner Pack: Packaging Type',
    name: 'innerPack:packagingType',
    type: 'Input',
    defaultValue: 'Packaging Type'
  },
  {
    label: 'Inner Pack: UPC Code',
    name: 'innerPack:upcCode',
    type: 'Input',
    defaultValue: 'UPC Code'
  },
  {
    label: 'Inner Pack: Scannable',
    name: 'innerPack:scannable',
    type: 'Input',
    defaultValue: 'Scannable'
  },
  {
    label: 'Inner Pack: Longest DIM (In MT)',
    name: 'innerPack:longestDIMInMT',
    type: 'Input',
    defaultValue: 'Longest DIM (In MT)'
  },
  {
    label: 'Inner Pack: Longest DIM (In FT)',
    name: 'innerPack:longestDIMInFT',
    type: 'Input',
    defaultValue: 'Longest DIM (In FT)'
  },
  {
    label: 'Inner Pack: Weight(In Kg)',
    name: 'innerPack:weightInKg',
    type: 'Input',
    defaultValue: 'Weight(In Kg)'
  },
  {
    label: 'Inner Pack: Weight (In Lbs)',
    name: 'innerPack:weightInLb',
    type: 'Input',
    defaultValue: 'Weight (In Lbs)'
  },
  {
    label: 'Inner Pack: Length (In MT)',
    name: 'innerPack:lengthInMT',
    type: 'Input',
    defaultValue: 'Length (In MT)'
  },
  {
    label: 'Inner Pack: Length (In FT)',
    name: 'innerPack:lengthInFT',
    type: 'Input',
    defaultValue: 'Length (In FT)'
  },
  {
    label: 'Inner Pack: Width (In MT)',
    name: 'innerPack:widthInMT',
    type: 'Input',
    defaultValue: 'Width (In MT)'
  },
  {
    label: 'Inner Pack: Width (In FT)',
    name: 'innerPack:widthInFT',
    type: 'Input',
    defaultValue: 'Width (In FT)'
  },
  {
    label: 'Inner Pack: Height (In MT)',
    name: 'innerPack:heightInMT',
    type: 'Input',
    defaultValue: 'Height (In MT)'
  },
  {
    label: 'Inner Pack: Height (In FT)',
    name: 'innerPack:heightInFT',
    type: 'Input',
    defaultValue: 'Height (In FT)'
  },
  {
    label: 'Inner Pack: Girth (In MT)',
    name: 'innerPack:girthInMT',
    type: 'Input',
    defaultValue: 'Girth (In MT)'
  },
  {
    label: 'Inner Pack: Girth (In FT)',
    name: 'innerPack:girthInFT',
    type: 'Input',
    defaultValue: 'Girth (In FT)'
  },
  {
    label: 'Inner Pack: Shipment Type',
    name: 'innerPack:shipmentType',
    type: 'Input',
    defaultValue: 'Shipment Type'
  },
  // Packaging - master pack
  {
    label: 'Master Pack: Packaging Type',
    name: 'masterPack:packagingType',
    type: 'Input',
    defaultValue: 'Packaging Type'
  },
  {
    label: 'Master Pack: UPC Code',
    name: 'masterPack:upcCode',
    type: 'Input',
    defaultValue: 'UPC Code'
  },
  {
    label: 'Master Pack: Scannable',
    name: 'masterPack:scannable',
    type: 'Input',
    defaultValue: 'Scannable'
  },
  {
    label: 'Master Pack: Longest DIM (In MT)',
    name: 'masterPack:longestDIMInMT',
    type: 'Input',
    defaultValue: 'Longest DIM (In MT)'
  },
  {
    label: 'Master Pack: Longest DIM (In FT)',
    name: 'masterPack:longestDIMInFT',
    type: 'Input',
    defaultValue: 'Longest DIM (In FT)'
  },
  {
    label: 'Master Pack: Weight(In Kg)',
    name: 'masterPack:weightInKg',
    type: 'Input',
    defaultValue: 'Weight(In Kg)'
  },
  {
    label: 'Master Pack: Weight (In Lbs)',
    name: 'masterPack:weightInLb',
    type: 'Input',
    defaultValue: 'Weight (In Lbs)'
  },
  {
    label: 'Master Pack: Length (In MT)',
    name: 'masterPack:lengthInMT',
    type: 'Input',
    defaultValue: 'Length (In MT)'
  },
  {
    label: 'Master Pack: Length (In FT)',
    name: 'masterPack:lengthInFT',
    type: 'Input',
    defaultValue: 'Length (In FT)'
  },
  {
    label: 'Master Pack: Width (In MT)',
    name: 'masterPack:widthInMT',
    type: 'Input',
    defaultValue: 'Width (In MT)'
  },
  {
    label: 'Master Pack: Width (In FT)',
    name: 'masterPack:widthInFT',
    type: 'Input',
    defaultValue: 'Width (In FT)'
  },
  {
    label: 'Master Pack: Height (In MT)',
    name: 'masterPack:heightInMT',
    type: 'Input',
    defaultValue: 'Height (In MT)'
  },
  {
    label: 'Master Pack: Height (In FT)',
    name: 'masterPack:heightInFT',
    type: 'Input',
    defaultValue: 'Height (In FT)'
  },
  {
    label: 'Master Pack: Girth (In MT)',
    name: 'masterPack:girthInMT',
    type: 'Input',
    defaultValue: 'Girth (In MT)'
  },
  {
    label: 'Master Pack: Girth (In FT)',
    name: 'masterPack:girthInFT',
    type: 'Input',
    defaultValue: 'Girth (In FT)'
  },
  {
    label: 'Master Pack: Shipment Type',
    name: 'masterPack:shipmentType',
    type: 'Input',
    defaultValue: ''
  },
  // Packaging - pallet pack
  {
    label: 'Pallet Type',
    name: 'pallet:palletType',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Outer Per Tier',
    name: 'pallet:outerPerTier',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Tier Per Pallet',
    name: 'pallet:tierPerPallet',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Consumer Units',
    name: 'pallet:consumerUnits',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Weight (In Kg)',
    name: 'pallet:weightInKg',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Weight (In Lb)',
    name: 'pallet:weightInLb',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Length (In MT)',
    name: 'pallet:lengthInMT',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Length (In FT)',
    name: 'pallet:lengthInFT',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Width (In MT)',
    name: 'pallet:widthInMT',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Width (In FT)',
    name: 'pallet:widthInFT',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Height (In MT)',
    name: 'pallet:heightInMT',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Height (In FT)',
    name: 'pallet:heightInFT',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: ECT Inner',
    name: 'pallet:ectInner',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: ECT Outer',
    name: 'pallet:ectOuter',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Strapping Used On Cartons',
    name: 'pallet:strappingUsedOnCartons',
    type: 'Input',
    defaultValue: ''
  },
  {
    label: 'Pallet: Plastic Wrap Used On Pallets',
    name: 'pallet:plasticWrapUsedOnPallets',
    type: 'Input',
    defaultValue: ''
  }
];
