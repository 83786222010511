const initState = {
  visible: true
};

export default (state = initState, { type }) => {
  switch (type) {
    case 'toggle_visible':
      return {
        visible: !state.visible
      };
    default:
      return state;
  }
};

export const toggleVisibleAction = () => ({
  type: 'toggle_visible'
});
