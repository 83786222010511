import React from 'react';
import { Select, DatePicker, Input, InputNumber, Form, Radio } from 'antd';

import SelectWithConfirm from 'shared/components/SelectWithConfirm';
import LocationDropdown from 'shared/components/CommonFields/LocationDropdown';
import CompanyDropdown from 'shared/components/CommonFields/CompanyDropdown';

import { FORMAT_DATE, FORMAT_TIME } from 'configs/date';

const EditableCell = (dataSelections = {}, childrenConfigs = {}) => ({
  editing,
  dataIndex,
  queryValue,
  inputType,
  children,
  index,
  title,
  titleOrigin,
  ...restProps
}) => {
  const options = dataSelections[dataIndex] || [];
  const childrenConfig = childrenConfigs[dataIndex] || {};

  const renderSelectOption = (selectOptions) => {
    return (selectOptions && selectOptions.length
      ? selectOptions
      : Array.from({ length: 10 }).map(
          (_, idx) => `${titleOrigin || title} ${idx + 1}`
        )
    ).map((optionItem) => {
      if (typeof optionItem === 'object') {
        return (
          <Select.Option key={optionItem.value} value={optionItem.value}>
            {optionItem.label}
          </Select.Option>
        );
      }
      return (
        <Select.Option key={optionItem} value={optionItem}>
          {optionItem}
        </Select.Option>
      );
    });
  };
  const renderCellField = () => {
    switch (inputType) {
      case 'select': {
        return <Select>{renderSelectOption(options)}</Select>;
      }
      case 'time':
        return (
          <DatePicker format={FORMAT_TIME} showTime style={{ width: '100%' }} />
        );
      case 'date':
        return <DatePicker format={FORMAT_DATE} style={{ width: '100%' }} />;
      case 'month':
        return (
          <DatePicker picker='month' format='MMMM' style={{ width: '100%' }} />
        );
      case 'year':
        return (
          <DatePicker picker='year' format='YYYY' style={{ width: '100%' }} />
        );
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'stt':
        children = index + 1;
        return <span>{children}</span>;
      case 'locationDropdown':
        return <LocationDropdown name={dataIndex} {...childrenConfig} />;
      case 'companyDropdown':
        return (
          <CompanyDropdown
            queryValue={queryValue}
            name={dataIndex}
            {...childrenConfigs}
          />
        );
      case 'radio':
        return (
          <Radio.Group>
            {options.map((v, i) => {
              if (typeof v === 'object') {
                return (
                  <Radio key={i} value={v.value}>
                    {v.label}
                  </Radio>
                );
              }
              return (
                <Radio key={i} value={v}>
                  {v}
                </Radio>
              );
            })}
          </Radio.Group>
        );
      default:
        return <Input />;
    }
  };

  const renderSelectConfirm = () => {
    return (
      <SelectWithConfirm dataIndex={dataIndex}>
        {renderSelectOption(options)}
      </SelectWithConfirm>
    );
  };
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          key={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          {inputType === 'select-confirm'
            ? renderSelectConfirm()
            : renderCellField()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableContext = React.createContext();

EditableCell.EditableContext = EditableContext;

export default EditableCell;
