import http from 'shared/utils/http';

export const getPolicies = (params = {}) => {
  return http.get('/uaa/manage/policy', params);
};

export const getUserPolicy = (userId) => {
  return http.get('/uaa/manage/user-policy', { userId });
};

export const getUnattachedPolicy = (userId) => {
  return http.get('/uaa/manage/user-policy/unattached', { userId });
};

export const addUserPolicy = (userPolicies) => {
  return http.post('/uaa/manage/user-policy', userPolicies);
};

export const deleteUserPolicy = (userId, policyId) => {
  return http.delete('/uaa/manage/user-policy', { userId, policyId });
};

export const getCompanyPolicies = (companyId, params) => {
  return http.get(`/uaa/manage/company-policy/${companyId}`, params);
};

export const updateCompanyPolicies = (companyId, policies) => {
  return http.post('/uaa/manage/company-policy', {
    companyId,
    policies
  });
};

export const deleteCompanyPolicy = (policyId) => {
  return http.delete(`/uaa/manage/company-policy/${policyId}`);
};

export const getUserPolicyById = (userId) => {
  return http.get(`/uaa/user-policy/${userId}`);
};

export const updateUserPermission = (userId, data) => {
  return http.put(`/uaa/user-policy/${userId}`, data);
};

export const getPolicyByCompany = () => {
  return http.get('/uaa/company-policy/');
};

export const getShipmentPolicy = (params) => {
  return http.get('/uaa/manage/user-policy/shipment', params);
};

export const updateShipmentPolicy = (data) => {
  return http.post('/uaa/manage/user-policy/shipment', data);
};
