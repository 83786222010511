import { getAllContacts } from 'services/allMessages';
import { contactsList } from 'pages/Messages/data';
import {
  GET_ALL_MESSAGES_SUCCESS,
  GET_ALL_MESSAGES_ERROR,
  TOGGLE_LOADING,
  FILTER_MESSAGES,
  SET_SELECTED_CONTACT
} from 'shared/constants/allMessages';

const initialState = {
  contactsList,
  filteredContactsList: contactsList,
  selectedContact: contactsList[0],
  skip: 0,
  limit: 10,
  total: 0,
  contactType: 'all',
  isLoading: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        contactsList: action.payload
      };
    case GET_ALL_MESSAGES_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading
      };
    case FILTER_MESSAGES:
      return {
        ...state,
        contactType: action.payload,
        filteredContactsList: state.contactsList.filter(
          (item) => action.payload === 'all' || item.type === 'action.payload'
        )
      };
    case SET_SELECTED_CONTACT:
      return {
        ...state,
        selectedContact: action.payload
      };
    default:
      return state;
  }
};

export const getAllContactsAction = (filter) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_LOADING });
    getAllContacts(filter)
      .then((res) => {
        dispatch({ type: GET_ALL_MESSAGES_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: GET_ALL_MESSAGES_ERROR, payload: err });
      })
      .finally(() => {
        dispatch({ type: TOGGLE_LOADING });
      });
  };
};

export const filterMessagesAction = (type) => {
  return (dispatch) => dispatch({ type: FILTER_MESSAGES, payload: type });
};

export const selectContactAction = (contact) => {
  return (dispatch) =>
    dispatch({ type: SET_SELECTED_CONTACT, payload: contact });
};
