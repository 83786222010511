import http from 'shared/utils/http';

export const createSmartModeList = async (data) => {
  const response = await http.post(
    '/api/integrated-shipping/smart-mode/list',
    data
  );

  return response.data;
};

export const fetchSmartModeTblData = async (companyId) => {
  const response = await http.api(
    '/api/integrated-shipping/smart-mode',
    'get',
    { companyId }
  );

  return response.data;
};

export const updateSmartMode = async (id, companyId, data = {}) => {
  const response = await http.api(
    `/api/integrated-shipping/smart-mode/${id}`,
    'put',
    { companyId, ...data }
  );

  return response.data;
};

export const deleteSmartMode = async (id) => {
  const response = await http.api(
    `/api/integrated-shipping/smart-mode/${id}`,
    'delete',
    {}
  );

  return response.data;
};
