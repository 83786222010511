import styled from 'styled-components';

import { Button } from 'shared/components';
import { mixin } from 'shared/utils/styles';

export const PopoverArea = styled.div`
  overflow-y: auto;
  width: 250px;
  max-height: 400px;
  ${mixin.customScrollbar}
`;

export const PopoverHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExpandBtn = styled(Button)`
  font-weight: bolder;
  text-align: center;
`;
