import {
  getWorkflows,
  getWorkflowconfigs,
  addWorkflow,
  addWorkflowConfig,
  getWorkflow,
  getWorkflowconfig
} from 'services/edi/workflow';
import {
  createAction,
  createApiAction,
  createReducer
} from 'shared/utils/redux';

const initialState = {
  fetchLoading: false,
  workflow: null,
  workflows: [],
  workflowMeta: null,
  workflowConfigs: [],
  workflowConfigMeta: [],
  workflowConfig: null,
  error: false
};

export const getEdiWorkflowSelector = (state) => state.edi.ediWorkflow;

export const setWorkflowAction = createAction('setWorkflowAction');
export const setWorkflowConfigAction = createAction('setWorkflowConfigAction');

export const fetchEdiWorkflowsAction = createApiAction(
  'fetchEdiWorkflowsAction',
  (params) => ({
    callAPI: () => getWorkflows(params)
  })
);

export const fetchEdiWorkflowConfigsAction = createApiAction(
  'fetchEdiWorkflowConfigsAction',
  () => ({
    callAPI: () => getWorkflowconfigs()
  })
);

export const addEdiWorkflowAction = createApiAction(
  'addEdiWorkflowAction',
  (data) => ({
    callAPI: () => addWorkflow(data)
  })
);

export const updateEdiWorkflowAction = createApiAction(
  'updateEdiWorkflowAction',
  () => ({
    callAPI: () => {}
  })
);

export const updateEdiWorkflowConfigAction = createApiAction(
  'updateEdiWorkflowConfigAction',
  () => ({
    callAPI: () => {}
  })
);

export const addEdiWorkflowConfigAction = createApiAction(
  'addEdiWorkflowConfigAction',
  (data) => ({
    callAPI: () => addWorkflowConfig(data)
  })
);

export const getWorkflowAction = createApiAction('getWorkflowAction', (id) => ({
  callAPI: () => getWorkflow(id),
  shouldCallAPI: (state) => !state.edi.ediWorkflow.workflow
}));

export const getWorkflowConfigAction = createApiAction(
  'getWorkflowConfigAction',
  (id) => ({
    callAPI: () => getWorkflowconfig(id),
    shouldCallAPI: (state) => !state.edi.ediWorkflow.workflowConfig
  })
);

export default createReducer(initialState, {
  [fetchEdiWorkflowsAction.success]: (state, { response }) => ({
    ...state,
    workflows: response.data,
    workflowMeta: response.meta
  }),
  [fetchEdiWorkflowConfigsAction.success]: (state, { response }) => ({
    ...state,
    workflowConfigs: response.data,
    workflowConfigMeta: response.meta
  }),
  [setWorkflowAction]: (state, { payload }) => ({
    ...state,
    workflow: payload
  }),
  [setWorkflowConfigAction]: (state, { payload }) => ({
    ...state,
    workflowConfig: payload
  }),
  [getWorkflowAction.success]: (state, { response }) => ({
    ...state,
    workflow: response.data
  }),
  [getWorkflowConfigAction.success]: (state, { response }) => ({
    ...state,
    workflowConfig: response.data
  })
});
