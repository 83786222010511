import { createReducer, createAction } from 'redux-act';
import toast from 'shared/utils/toast';

import {
  fetchData,
  createData,
  deleteDataById,
  updateDataById,
  createIntegratedShipping,
  fetchIntegratedShipping,
  getIntegratedShippingWave
} from 'services/integratedShipping/integratedShippingSetup';

const initialState = {
  isFetching: false,
  data: [],
  meta: {},
  isUpdate: false,
  error: false,
  message: '',
  waves: []
};

const fetchDataActionStart = createAction(
  'start fetch integrated shipping setup list'
);
const fetchDataActionSuccess = createAction(
  'fetch integrated shipping setup list success'
);
const fetchDataActionError = createAction(
  'fetch integrated shipping setup list error'
);

const fetchIntegratedShippingWaveActionStart = createAction(
  'start fetch integrated shipping wave list'
);
const fetchIntegratedShippingWaveActionSuccess = createAction(
  'fetch integrated shipping wave list success'
);
const fetchIntegratedShippingWaveActionError = createAction(
  'fetch integrated shipping wave list error'
);

const createUpdateDataActionStart = createAction(
  'start create/update integrated shipping setup'
);
const createUpdateDataActionSucess = createAction(
  'create/update integrated shipping setup success'
);
const createUpdateDataActionError = createAction(
  'create/update integrated shipping setup error'
);

export default createReducer(
  {
    [fetchDataActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchDataActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      data: payload.data,
      meta: payload.meta
    }),
    [fetchDataActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [fetchIntegratedShippingWaveActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchIntegratedShippingWaveActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      waves: payload.data
    }),
    [fetchIntegratedShippingWaveActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    }),
    [createUpdateDataActionStart]: (state) => ({
      ...state,
      isUpdate: true
    }),
    [createUpdateDataActionSucess]: (state) => ({
      ...state,
      isUpdate: false
    }),
    [createUpdateDataActionError]: (state) => ({
      ...state,
      isUpdate: false
    })
  },
  initialState
);

export const fetchIntegratedShippingSetupList = (companyId, param) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchData(companyId, param);
      dispatch(fetchDataActionSuccess({ data: res.data }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const fetchIntegratedShippingSetup = (companyId, param) => {
  return async (dispatch) => {
    try {
      dispatch(fetchDataActionStart());
      const res = await fetchIntegratedShipping(companyId, param);
      dispatch(fetchDataActionSuccess({ data: res.data, meta: res.meta }));
    } catch (e) {
      dispatch(fetchDataActionError());
    }
  };
};

export const createIntegratedShippingSetupAction = (companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await createData(companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Integrated Shipping Setup has been created');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when creating Integrated Shipping Setup');
    }
  };
};

export const createNewIntegratedShippingSetupAction = (companyId, data) => {
  return async () => {
    const res = await createIntegratedShipping(companyId, data);
    return res;
  };
};

export const updateIntegratedShippingSetupAction = (id, companyId, data) => {
  return async (dispatch) => {
    try {
      const res = await updateDataById(id, companyId, data);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Integrated Shipping Setup has been updated');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when updating Integrated Shipping Setup');
    }
  };
};

export const deleteIntegratedShippingSetupAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await deleteDataById(id);
      if (res) {
        dispatch(createUpdateDataActionSucess());
        toast.success('Integrated Shipping Setup has been deleted');
      }
    } catch (e) {
      dispatch(createUpdateDataActionError());
      toast.error('Error occurred when deleting Integrated Shipping Setup');
    }
  };
};

export const getIntegratedShippingWaveAction = (param) => {
  return async (dispatch) => {
    try {
      dispatch(fetchIntegratedShippingWaveActionStart());
      const res = await getIntegratedShippingWave(param);
      dispatch(fetchIntegratedShippingWaveActionSuccess({ data: res.data }));
    } catch (e) {
      dispatch(fetchIntegratedShippingWaveActionError());
    }
  };
};
