export const contactsList = [
  {
    id: 1,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Hooray! We are on track for the order ID 6332772.',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Hooray! We are on track for the order ID 6332772.',
        createdAt: 12345678,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      },
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 32345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 2,
    name: 'Lester Wilse',
    createdAt: 999999,
    lastMessage: 'No problem, Lester!',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'No problem, Lester!',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      },
      {
        id: 2,
        message: 'Great, thanks John!',
        createdAt: 12345678,
        sender: {
          id: 2,
          name: 'Lester Wilse'
        }
      },
      {
        id: 3,
        message:
          'We worked hard to make sure this order gets delivered on time. Hard work pays off.',
        createdAt: 12345678,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      },
      {
        id: 4,
        message: 'Yes, we are on track.',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      },
      {
        id: 5,
        message: 'Are we on track for the order ID 5332774?',
        createdAt: 12345678,
        sender: {
          id: 2,
          name: 'Lester Wilse'
        }
      },
      {
        id: 6,
        message: 'Hey Lester. Good morning!',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 3,
    name: 'Gabriel Osborne',
    createdAt: 999999,
    lastMessage:
      'The pick-up for order ID 6332772 is complete. We are on schedule.',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message:
          'The pick-up for order ID 6332772 is complete. We are on schedule.',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Gabriel Osborne'
        }
      },
      {
        id: 2,
        message: 'Hey Gabriel',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 4,
    name: 'Rose Saunders',
    createdAt: 999999,
    lastMessage: 'The order has been completed successfully. Cheers!',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'The order has been completed successfully. Cheers!',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Rose Saunders'
        }
      },
      {
        id: 2,
        message: 'Hey Rose, what is the status on the recent order?',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 5,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Are we on track for the order ID 6332772',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 6,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Are we on track for the order ID 6332772',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 7,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Are we on track for the order ID 6332772',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 8,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Are we on track for the order ID 6332772',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 9,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Are we on track for the order ID 6332772',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  },
  {
    id: 10,
    name: 'Cole Griffin',
    createdAt: 999999,
    lastMessage: 'Are we on track for the order ID 6332772',
    type: 'direct',
    profession: 'Manager',
    isOnline: true,
    profilePic: '',
    messages: [
      {
        id: 1,
        message: 'Are we on track for the order ID 6332772?',
        createdAt: 12345678,
        sender: {
          id: 1,
          name: 'Cole Griffin'
        }
      },
      {
        id: 2,
        message: 'Hey Cole',
        createdAt: 12345676,
        sender: {
          id: 'f495214e-de68-4c2d-89bb-302e6a66a5b7',
          name: 'John Doe'
        }
      }
    ]
  }
];
