import React from 'react';
import { Row, Col, Typography, notification } from 'antd';

import { ConfirmPage, StyledCard, Tip, LinkItem } from './Styles';
import SignUpImage from 'assets/images/group.svg';
import { resendConfirmEmail } from 'services/company';
import { dispatchService } from 'shared/utils/service';

export default () => {
  const rensendVerificationEmail = () => {
    dispatchService(
      resendConfirmEmail,
      () => {
        notification.success({
          message: 'An verification email has been sent to your inbox'
        });
      },
      (error) => {
        notification.error({ message: error });
      }
    );
  };

  return (
    <ConfirmPage>
      <Row justify='center' align='middle'>
        <Col flex='376px'>
          <StyledCard cover={<img src={SignUpImage} alt='' />}>
            <p>
              Thank you for submitting your credentials. We are processing and
              verifying your details, once we are done we will send a
              confirmation message with Login Link to your Email Address.
            </p>
            <p style={{ fontSize: '11px', color: '#999999', margin: '16px 0' }}>
              If you have not received a verification mail yet, you can resend
              the verification email.
              <Typography.Link onClick={rensendVerificationEmail}>
                {' '}
                Resend
              </Typography.Link>
            </p>
            <Tip>
              Already have an account?
              <LinkItem to='/login'>Login</LinkItem>
            </Tip>
          </StyledCard>
        </Col>
      </Row>
    </ConfirmPage>
  );
};
