import { createReducer, createApiAction } from 'shared/utils/redux';
import {
  getSarimaSessions,
  getSarimaSession,
  getRecentSarimaSession,
  stopSarimaSession
} from 'services/ai';

const initialState = {
  sarimaSessions: [],
  recentSession: null,
  session: null,
  error: null
};

export const getSarimaStateSelector = (state) => state.ai.sarima;

export const getSarimaSessionsSelector = (state) => {
  const { sarimaSessions } = getSarimaStateSelector(state);
  return sarimaSessions;
};

export const recentSarimaSessionSelector = (state) => {
  const { session } = getSarimaStateSelector(state);
  return session;
};

export const sarimaSessionSelector = (id) => (state) => {
  const { sarimaSessions } = getSarimaStateSelector(state);
  return sarimaSessions.find((session) => session.id === id);
};

export const getSarimaSessionsAction = createApiAction(
  'getSarimaSessionsAction',
  () => ({
    callAPI: () => getSarimaSessions()
  })
);

export const getRecentSarimaSessionAction = createApiAction(
  'getRecentSarimaSessionAction',
  () => ({
    callAPI: () => getRecentSarimaSession()
  })
);

export const stopSarimaSessionAction = createApiAction(
  'stopSarimaSessionAction',
  ({ id }) => ({
    callAPI: () => stopSarimaSession(id)
  })
);

export const getSarimaSessionAction = createApiAction(
  'getSarimaSessionAction',
  ({ id }) => ({
    callAPI: () => getSarimaSession(id)
  })
);

export default createReducer(initialState, {
  [getSarimaSessionsAction.success]: (state, { response }) => ({
    ...state,
    sarimaSessions: response.data
  }),
  [getRecentSarimaSessionAction.success]: (state, { response }) => ({
    ...state,
    session: response.data
  }),
  [getSarimaSessionAction.success]: (state, { response }) => ({
    ...state,
    session: response.data
  })
});
