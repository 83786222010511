import { routeConfig } from './route';

const dashboardMenuItems = [
  {
    icon: 'asset',
    title: 'ShipIntuit',
    to: '/demand-management/integrated-shipping',
    permission: 'integratedShipping.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'PBASM',
    to: '/demand-management/block',
    permission: 'block.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Forwarding',
    to: routeConfig.FORWARDING,
    permission: 'forwarding.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Container Tracking',
    to: routeConfig.CONTAINER_TRACKING,
    permission: 'container.view',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Audit & Pay',
    to: '/invoice-management',
    permission: 'invoice.*',
    role: ['user']
  },
  // {
  //   icon: 'asset',
  //   title: 'Asset Management',
  //   to: routeConfig.ASSET_MANAGEMENT,
  //   permission: 'asset.*',
  //   role: ['user']
  // },
  {
    icon: 'people',
    title: 'ANwork',
    to: '/dashboard/ANwork',
    permission: 'anwork.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Inventory Management',
    to: routeConfig.INVENTORY_MANAGEMENT,
    permission: 'asset.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Automotive',
    to: routeConfig.AUTOMOTIVE_MANAGEMENT,
    permission: 'automotive.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Aging Report',
    to: routeConfig.AGING_REPORT,
    permission: 'agingReport.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Bulk Shipping',
    to: routeConfig.BULK,
    permission: 'bulkShipping.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'IntelliDemand',
    to: routeConfig.DEMAND_MANAGEMENT,
    permission: 'demand.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Drayage Management',
    to: '/demand-management/drayage-management',
    permission: 'drayageMgt.*',
    role: ['user']
  },
  // {
  //   icon: 'asset',
  //   title: 'Plan/Monitor',
  //   to: '/asset-management/equipment-planning',
  //   permission: 'asset.*',
  //   role: ['user']
  // },

  {
    icon: 'logistics',
    title: 'Logistics',
    to: '/dashboard/logistics',
    permission: 'logistics.*',
    role: ['user']
  },
  {
    icon: 'asset',
    title: 'Ocean Management',
    to: '/demand-management/ocean-intermodal',
    // to: '/ocean-management',
    permission: 'oceanMgt.*',
    role: ['user']
  },

  {
    icon: 'asset',
    title: 'Ports Map',
    to: '/dashboard/asset-maps/ports-map',
    permission: 'portMgt.*',
    role: ['user']
  },
  // {
  //   icon: 'asset',
  //   title: 'Port Management',
  //   to: '/asset-management/port-management',
  //   permission: 'portMgt.*',
  //   role: ['user']
  // },
  {
    icon: 'overview',
    title: 'Rail Network Maps',
    to: '/dashboard/asset-maps/rail-map',
    permission: 'railMgt.railMaps',
    role: ['user']
  },
  {
    icon: 'overview',
    title: 'Rail Management',
    to: '/dashboard/rail-network-management',
    permission: 'railMgt.networkMgt',
    role: ['user']
  },
  {
    icon: 'overview',
    title: 'Road',
    to: '/demand-management/road',
    permission: 'roadMgt.*',
    role: ['user']
  },
  {
    icon: 'overview',
    title: 'Rate Management',
    to: '/contracts/rates/management',
    permission: 'rateMgt.*',
    role: ['user']
  },
  {
    icon: 'sys-admin',
    title: 'System Administrator',
    to: routeConfig.ADMIN,
    permission: 'adminMgt'
    // additionalCondition: (user) => user.isSystemAdmin
  }
];

const adminMenus = {
  module: 'admin',
  menus: [
    {
      icon: 'overview',
      title: 'Company Management',
      to: routeConfig.COMPANY_MANAGEMENT,
      permission: 'adminMgt.company'
    },
    {
      icon: 'overview',
      title: 'Account Management',
      to: routeConfig.ACCOUNT_MANAGEMENT,
      permission: 'adminMgt.account'
    },
    {
      icon: 'overview',
      title: 'User Management',
      to: routeConfig.USER_MANAGEMENT,
      permission: 'adminMgt.user'
    },
    {
      icon: 'overview',
      title: 'Requested Companies',
      to: routeConfig.REQUESTED_COMPANIES,
      permission: 'adminMgt.request-company'
    },
    {
      icon: 'overview',
      title: 'Subscriptions',
      to: routeConfig.SUBSCRIPTION_MAGAGEMENT,
      permission: 'adminMgt.subscription'
    },
    {
      icon: 'overview',
      title: 'Equipment Types',
      to: routeConfig.EQUIPMENT_MANAGEMENT,
      permission: 'adminMgt.equipment-type'
    },
    {
      icon: 'overview',
      title: 'Instructions',
      to: routeConfig.INSTRUCTION_MANAGEMENT,
      permission: 'adminMgt.instruction'
    },
    {
      icon: 'overview',
      title: 'App Configs',
      to: routeConfig.APP_CONFIG_MANAGEMENT,
      permission: 'adminMgt.app-config'
    },
    {
      icon: 'overview',
      title: 'Location Management',
      to: '/admin/location-management',
      permission: 'adminMgt.location'
    },
    {
      icon: 'overview',
      title: 'Product Management',
      to: routeConfig.PRODUCT_MANAGEMENT,
      permission: 'adminMgt.product'
    },
    {
      icon: 'overview',
      title: 'Quote Request Management',
      to: routeConfig.QUOTE_REQUEST_MANAGEMENT,
      permission: 'adminMgt.quote'
    },
    {
      icon: 'overview',
      title: 'Sourcing Management',
      to: routeConfig.SOURCING_MANAGEMENT,
      permission: 'adminMgt.sourcing'
    },
    {
      icon: 'overview',
      title: 'Order Change Logs',
      to: routeConfig.ORDER_MANAGEMENT,
      permission: 'adminMgt.order'
    },
    // {
    //   icon: 'overview',
    //   title: 'Network Management',
    //   to: routeConfig.NETWORK_MANAGEMENT,
    //   permission: 'adminMgt.network'
    // },
    {
      icon: 'overview',
      title: 'Data Events',
      to: routeConfig.DATA_EVENT_MANAGEMENT
    }
  ]
};

export const sidebarConfig = [
  {
    module: 'dashboard',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'orders',
    menus: [
      {
        icon: 'all',
        title: 'View Shipments',
        size: 10,
        to: routeConfig.ORDERS_LIST,
        permission: 'order.view'
      },
      {
        icon: 'all',
        title: 'Shipment Control',
        size: 10,
        to: routeConfig.ORDERS_DASHBOARD,
        permission: 'order.view'
      },
      {
        icon: 'right-arrow',
        title: 'PO Entry',
        size: 10,
        to: routeConfig.ORDERS_PO_ENTRY,
        permission: 'order.po'
      },
      {
        icon: 'right-arrow',
        title: "View PO's",
        size: 10,
        to: routeConfig.ORDERS_PO,
        permission: 'order.po'
      },
      {
        icon: 'right-arrow',
        title: 'Invoices',
        size: 10,
        to: routeConfig.ORDER_INVOICE,
        permission: 'order.invoice'
      },
      {
        icon: 'right-arrow',
        title: 'Invoice Payments',
        size: 10,
        to: routeConfig.ORDER_INVOICE_PAYMENT,
        permission: 'order.invoice-payment'
      },
      {
        icon: 'right-arrow',
        title: 'Bills',
        size: 10,
        to: routeConfig.ORDER_BILL,
        permission: 'order.bill'
      },
      {
        icon: 'right-arrow',
        title: 'Bill Payments',
        size: 10,
        to: routeConfig.ORDER_BILL_PAYMENT,
        permission: 'order.bill-payment'
      },
      {
        icon: 'right-arrow',
        title: 'Credit Notes',
        size: 10,
        to: routeConfig.ORDER_CREDIT_NOTE,
        permission: 'order.creditNote'
      },
      {
        icon: 'right-arrow',
        title: 'Supplier Credits',
        size: 10,
        to: routeConfig.ORDER_VENDOR_CREDIT,
        permission: 'order.vendorCredit'
      },
      {
        icon: 'right-arrow',
        title: 'Bank Accounts',
        size: 10,
        to: routeConfig.ORDER_BANK_ACCOUNT,
        permission: 'order.bankAccount'
      }
    ]
  },
  {
    module: 'company',
    menus: [
      {
        icon: 'sys-admin',
        title: 'My Company',
        subMenus: [
          {
            icon: 'all',
            title: 'Company \xa0 Details',
            to: routeConfig.COMPANY_DETAILS
          },
          {
            icon: 'hierarchy',
            title: 'Employee',
            to: routeConfig.COMPANY_EMPLOYEE
          },
          {
            icon: 'nw-file',
            title: 'Resources & Documents',
            to: routeConfig.COMPANY_RESOURCES_DOCUMENT
          },
          {
            icon: 'nw-file',
            title: 'Company Accounts',
            to: routeConfig.COMPANY_ACCOUNTS
          },
          {
            icon: 'nw-file',
            title: 'Product Segments',
            to: routeConfig.COMPANY_PRO_SEGMENTS
          },
          {
            icon: 'nw-file',
            title: 'Products',
            to: routeConfig.COMPANY_PRODUCT
          },
          {
            icon: 'nw-file',
            title: 'Distribution Center',
            to: routeConfig.COMPANY_DC_LOCATION
          }
        ]
      },
      // {
      //   icon: 'settings',
      //   title: 'Services',
      //   to: routeConfig.COMPANY_SERVICES,
      //   subMenus: [
      //     {
      //       icon: 'all',
      //       title: 'Service Area',
      //       to: routeConfig.COMPANY_SERVICE_AREA
      //     }
      //   ]
      // },
      {
        icon: 'shape',
        title: 'Assets',
        to: routeConfig.COMPANY_ASSETS,
        subMenus: [
          {
            icon: 'all',
            title: 'Equipment',
            to: routeConfig.COMPANY_EQUIPMENTS
          }
        ]
      },
      // {
      //   icon: 'asset',
      //   title: 'Locations',
      //   to: routeConfig.COMPANY_LOCATIONS,
      //   subMenus: [
      //     // {
      //     //   icon: 'all',
      //     //   title: 'Port Terminals',
      //     //   to: routeConfig.COMPANY_LOCATIONS_PORT
      //     // },
      //     // {
      //     //   icon: 'all',
      //     //   title: 'Rail Terminals',
      //     //   to: routeConfig.COMPANY_LOCATIONS_TERMINAL
      //     // },
      //     // {
      //     //   icon: 'all',
      //     //   title: 'Rail Yards',
      //     //   to: routeConfig.COMPANY_LOCATIONS_YARD
      //     // }
      //   ]
      // },
      {
        icon: 'people',
        title: 'ANwork',
        subMenus: [
          {
            icon: 'all',
            title: 'Load Board',
            to: routeConfig.COMPANY_ANWORK_LOAD_BOARD
          },
          {
            icon: 'shape',
            title: 'Mobile',
            to: routeConfig.COMPANY_ANWORK_MOBILE
          },
          {
            icon: 'shape',
            title: 'Yard Tasks',
            to: routeConfig.AUTO_YARD_TASK_LIST
          }
        ]
      }
    ]
  },
  {
    module: 'company-network',
    menus: [
      {
        icon: 'sys-admin',
        title: 'My Company',
        subMenus: [
          {
            icon: 'all',
            title: 'Company \xa0 Details',
            to: routeConfig.COMPANY_DETAILS
          },
          {
            icon: 'hierarchy',
            title: 'Company Hierarchy',
            to: routeConfig.COMPANY_HIERARCHY
          },
          {
            icon: 'nw-file',
            title: 'Resources & Documents',
            to: routeConfig.COMPANY_RESOURCES_DOCUMENT
          },
          {
            icon: 'nw-file',
            title: 'Company Accounts',
            to: routeConfig.COMPANY_ACCOUNTS
          }
        ]
      },
      {
        icon: 'settings',
        title: 'Services',
        to: routeConfig.COMPANY_SERVICES,
        subMenus: [
          {
            icon: 'all',
            title: 'Service Area',
            to: routeConfig.COMPANY_SERVICE_AREA
          }
        ]
      },
      {
        icon: 'shape',
        title: 'Assets',
        to: routeConfig.COMPANY_ASSETS,
        subMenus: [
          {
            icon: 'all',
            title: 'Equipment',
            to: routeConfig.COMPANY_ASSETS_EQUIPMENT
          }
        ]
      },
      {
        icon: 'asset',
        title: 'Locations',
        to: routeConfig.COMPANY_LOCATIONS,
        subMenus: [
          {
            icon: 'all',
            title: 'Port Terminals',
            to: routeConfig.COMPANY_LOCATIONS_PORT
          },
          {
            icon: 'all',
            title: 'Rail Terminals',
            to: routeConfig.COMPANY_LOCATIONS_TERMINAL
          },
          {
            icon: 'all',
            title: 'Rail Yards',
            to: routeConfig.COMPANY_LOCATIONS_YARD
          }
        ]
      },
      {
        icon: 'people',
        title: 'ANwork',
        subMenus: [
          {
            icon: 'all',
            title: 'Load Board',
            to: routeConfig.COMPANY_ANWORK_LOAD_BOARD
          },
          {
            icon: 'shape',
            title: 'Mobile',
            to: routeConfig.COMPANY_ANWORK_MOBILE
          }
        ]
      }
    ]
  },
  {
    module: 'contracts',
    menus: [
      {
        icon: 'nw-file',
        title: 'Contracts',
        subMenus: [
          {
            icon: 'all',
            title: 'All Contracts',
            to: routeConfig.CONTRACTS
          },
          {
            icon: 'plus',
            title: 'New Contracts',
            to: routeConfig.CONTRACTS_NEW
          }
          // {
          //   icon: 'reload',
          //   title: 'Contract History',
          //   to: routeConfig.CONTRACT_HISTORY
          // }
        ]
      }
    ]
  },
  {
    module: 'settings',
    menus: [
      {
        icon: 'key',
        title: 'Access Management',
        size: 8,
        subMenus: [
          {
            icon: 'people',
            title: 'Accounts',
            to: routeConfig.SETTINGS_ACCOUNTS,
            role: 'admin',
            permission: 'setting.account'
          },
          {
            icon: 'people',
            title: 'Users',
            to: routeConfig.SETTINGS_USERS,
            role: 'admin',
            permission: 'setting.account'
          }
        ],
        permission: 'setting.account'
      },
      {
        icon: 'archive',
        title: 'Profile',
        to: routeConfig.SETTINGS_PROFILE
      },
      {
        icon: 'settings',
        title: 'Configuration',
        to: routeConfig.SETTINGS_CONFIGURATION
      },
      {
        icon: 'settings',
        title: 'Subscription',
        to: routeConfig.SETTINGS_SUBSCRIPTIONS,
        role: 'admin'
      },
      {
        icon: 'settings',
        title: 'App Services',
        to: routeConfig.SETTINGS_APP_SERVICES,
        role: 'admin'
      }
    ]
  },
  {
    module: 'messages',
    menus: [
      {
        icon: 'people',
        title: 'Critical Messages',
        to: routeConfig.MESSAGES_CRITICAL
      },
      {
        icon: 'people',
        title: 'All Messages',
        to: routeConfig.MESSAGES_ALL
      }
    ]
  },
  {
    module: 'asset-management',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'aging-report',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'acquisition-management',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'asset-requirement-planning',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'integrated-shipping',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'route-asset-plans',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'port-management',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'customer-orders-manual',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'equipment-planning',
    menus: [
      {
        icon: 'operations',
        title: 'Equipment Planning',
        to: '/asset-management/equipment-planning'
      }
    ]
  },
  {
    module: 'route-timetable',
    menus: [
      {
        icon: 'operations',
        title: 'Route Timetable',
        to: '/asset-management/route-timetable'
      }
    ]
  },
  {
    module: 'maintenance-management',
    menus: [
      {
        icon: 'operations',
        title: 'Maintenance Management',
        to: '/asset-management/maintenance-management'
      }
    ]
  },
  {
    module: 'maintenance-schedule',
    menus: [
      {
        icon: 'operations',
        title: 'Maintenance Schedule',
        to: '/asset-management/maintenance-schedule'
      }
    ]
  },
  {
    module: 'staff-schedules',
    menus: [
      {
        icon: 'operations',
        title: 'Staff Schedules',
        to: '/asset-management/staff-schedules'
      }
    ]
  },
  {
    module: 'input-output-analysis',
    menus: [
      {
        icon: 'operations',
        title: 'Input/Output Analysis',
        to: '/asset-management/input-output-analysis'
      }
    ]
  },
  {
    module: 'internal-asset-audit',
    menus: [
      {
        icon: 'operations',
        title: 'Internal Asset Audit',
        to: '/asset-management/internal-asset-audit'
      }
    ]
  },
  {
    module: 'risk-management',
    menus: [
      {
        icon: 'operations',
        title: 'Risk Management',
        to: '/asset-management/risk-management'
      }
    ]
  },
  {
    module: 'external-rail',
    menus: [
      {
        icon: 'operations',
        title: 'External Rail Use Connected Rail Companies',
        to: '/asset-management/external-rail'
      }
    ]
  },
  {
    module: 'carbon-calculator',
    menus: [
      {
        icon: 'operations',
        title: 'Carbon Calculator',
        to: '/asset-management/carbon-calculator'
      }
    ]
  },
  {
    module: 'rail-master-capacity-plan',
    menus: [
      {
        icon: 'operations',
        title: 'Rail Master Capacity Plan',
        to: '/asset-management/rail-master-capacity-plan'
      }
    ]
  },
  {
    module: 'time-table-assessment',
    menus: [
      {
        icon: 'operations',
        title: 'Time Table Assessment',
        to: '/asset-management/time-table-assessment'
      }
    ]
  },
  {
    module: 'route-operational-plans',
    menus: [
      {
        icon: 'operations',
        title: 'Route Operational Plans',
        to: '/asset-management/route-operational-plans'
      }
    ]
  },
  {
    module: 'demand-management',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'road',
    menus: [
      {
        icon: 'people',
        title: 'ANwork',
        subMenus: [
          {
            icon: 'all',
            title: 'Load Board',
            to: routeConfig.COMPANY_ANWORK_LOAD_BOARD
          },
          {
            icon: 'shape',
            title: 'Mobile',
            to: routeConfig.COMPANY_ANWORK_MOBILE
          },
          {
            icon: 'all',
            title: 'Road',
            to: '/demand-management/road'
          },
          {
            icon: 'all',
            title: 'Create Loads for Bidding',
            to: routeConfig.ROAD_RATE_BOARD_ENTRY
          },
          {
            icon: 'all',
            title: 'Revenue',
            to: routeConfig.ROAD_REVENUE
          }
        ]
      }
    ]
  },
  {
    module: 'ocean-intermodal',
    menus: [
      {
        icon: 'all',
        title: 'Ocean Intermodal',
        to: '/demand-management/ocean-intermodal'
      }
    ]
  },
  {
    module: 'master-schedule',
    menus: [
      {
        icon: 'all',
        title: 'Master Schedule',
        to: '/demand-management/master-schedule'
      }
    ]
  },
  {
    module: 'dangerous-goods',
    menus: [
      {
        icon: 'all',
        title: 'Dangerous Goods',
        to: '/demand-management/dangerous-goods'
      }
    ]
  },
  {
    module: 'drayage-management',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'tasks',
    menus: [
      {
        icon: 'all',
        title: 'Tasks Management',
        to: '/tasks'
      }
    ]
  },
  {
    module: 'ocean-management',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'waybills',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'rail-delivery',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'revenue',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'edi',
    menus: [
      {
        icon: 'operations',
        title: 'EDI',
        to: '/edi'
      },
      {
        icon: 'operations',
        title: 'EDI Logs',
        to: '/edi/edi-logs'
      },
      {
        icon: 'operations',
        title: 'EDI Mapping',
        to: '/edi/edi-mapping'
      },
      {
        icon: 'operations',
        title: 'EDI Workflow',
        to: '/edi/workflow'
      },
      {
        icon: 'operations',
        title: 'EDI Workflow Configs',
        to: '/edi/workflow/config'
      }
    ]
  },
  {
    module: 'bulk-shipping',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'bulk-vessel',
    menus: [
      {
        icon: 'operations',
        title: 'Bulk Vessel',
        to: '/bulk-vessel'
      }
    ]
  },
  {
    module: 'input-bulk-carunload',
    menus: [
      {
        icon: 'operations',
        title: 'Input Bulk CarUnload',
        to: '/input-bulk-carunload'
      }
    ]
  },
  {
    module: 'auto-yard-planning',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'intermodal-yard-planning',
    menus: [...dashboardMenuItems]
  },
  {
    module: 'data-hub',
    menus: [
      {
        icon: 'operations',
        title: 'Data Table',
        to: routeConfig.DATA_TABLE
      },
      {
        icon: 'operations',
        title: 'Data Flow',
        to: routeConfig.DATA_FLOW
      }
    ]
  },
  {
    module: 'invoice-management',
    menus: [...dashboardMenuItems]
  },
  adminMenus
];

export const topbarConfig = [
  // {
  //   module: 'home',
  //   menus: [
  //     {
  //       title: 'Home',
  //       to: routeConfig.DASHBOARD
  //     }
  //   ]
  // },
  {
    module: 'company',
    menus: [
      {
        title: 'My Company',
        to: routeConfig.COMPANY,
        permission: 'company.*'
      }
    ]
  },
  {
    module: 'company-network',
    menus: [
      {
        title: 'Build My Network',
        to: routeConfig.NETWORK,
        permission: 'mynetwork.*'
      }
    ]
  },
  {
    module: 'messages',
    menus: [
      {
        title: 'AI Models',
        to: routeConfig.AI_MODEL,
        permission: 'ai.*'
      }
    ]
  },
  {
    module: 'data-hub',
    menus: [
      {
        title: 'DataHub',
        to: routeConfig.DATA_HUB,
        permission: 'dataHub.*'
      }
    ]
  },
  {
    module: 'orders',
    menus: [
      {
        title: 'My Orders',
        to: routeConfig.ORDERS_LIST,
        permission: 'order.*'
      }
    ]
  },
  {
    module: 'contracts',
    menus: [
      {
        title: 'Contracts',
        to: routeConfig.CONTRACTS,
        permission: 'order.*'
      }
    ]
  }
];
