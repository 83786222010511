import { createStore, applyMiddleware } from 'redux';
import waiter from 'redux-waiters';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import appReducer from 'reducers';
import { callAPIMiddleware, loggerMiddleware } from 'shared/utils/redux';

const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['config']
};

const middleware = [
  thunk,
  waiter,
  routerMiddleware(history),
  callAPIMiddleware,
  loggerMiddleware
];

const persistedReducer = persistReducer(persistConfig, appReducer(history));
const store = createStore(
  persistedReducer,
  {}, // default state
  // http://extension.remotedev.io/
  composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store);
export { history, persistor };
export default store;
