import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { StyledCollapsible } from './Styles';

const propTypes = {
  renderLink: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired
};

const defaultProps = {

};

const Collapsible = ({ renderLink, renderContent }) => {
  const [isOpen, setOpen] = useState(false);

  const $linkRef = useRef();
  const $collapseRef = useRef();

  const openCollapsible = () => setOpen(true);
  const closeCollapsible = () => setOpen(false);

  return (
    <>
      {renderLink(
        { ref: $linkRef, onClick: isOpen ? closeCollapsible : openCollapsible },
        isOpen
      )}
      <TransitionGroup component={StyledCollapsible}>
        {isOpen && (
          <CSSTransition timeout={300} classNames='collapse'>
            {renderContent({ onClose: closeCollapsible, ref: $collapseRef })}
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
}

Collapsible.propTypes = propTypes;
Collapsible.defaultProps = defaultProps;

export default Collapsible;
