export default {
  common: {
    "Anwork": "Anwork",
    "Asset Management": "Gestion d'actifs",
    "Bulk Shipping": "Expédition vrac",
    "Demand Change Control": "Contrôle changement de demande",
    "Drayage Management": "Gestion factage",
    "Equipment Planning": "Planification équipements",
    "Integrated Shipping": "Expédition intégrée",
    "Logistics": "Logistique",
    "Ocean Management": "Gestion fret maritime",
    "Ports Map": "Carte des ports",
    "Port Management": "Gestion port",
    "Rail Network Maps": "Cartes du réseau ferroviaire",
    "Rail Management": "Gestion ferroviaire",
    "Road": "Transport routier",
    "System Administrator": "Administrateur système",
    "Smart Mode": "Mode intelligent",
    "Container Flow Process Wave Set-up": "Mise en place-vague du processus de flux de conteneurs",
    "Smart In Mode": "Mode entrant intelligent",
    "Integrated Shipping Set-up": "Mise en place expédition intégrée",
    "Container Assignment": "Affectation de conteneurs",
    "Smart Out Mode": "Mode retour intelligent",
    "Integrated Shipping Contract Set-up": "Mise en place contrat d'expédition intégré",
    "Truck Flow Process Set-Up": "Mise en place-processus de flux des camions",
    "Total Smart": "Mode intelligent complet",
    "Integrated Shipping Kpi Set-up": "Mise en place-KPI d'expédition intégrée",
    "Truck Assignment Set-up": "Mise en place-affectation des camions",
    "Cycle Timing": "Chronométrage du cycle",
    "Integrated Daily Wave Set-up": "Mise en place d'une vague quotidienne intégrée",
    "Inbound Delivery Receiving Booking Site": "Site de réservation pour la réception des livraisons entrantes",
    "Integrated Shipments": "Expéditions intégrées",
    "Container Wave Assignment": "Affectation des vagues de conteneurs",
    "Integrated Yard Set-up": "Mise en place-chantier intégré",
    "Integrated Warehouse Wave Set-up": "Mise en place d'une vague d'entrepôts intégrés",
    "Integrated Yard Management": "Gestion intégrée des chantiers",
    "Smart Drayage Appointment Scheduler": "Planificateur de rendez-vous factage intelligent",
    "Drayage Assignment": "Affectation de factage",
    "Drayage rates": "Tarifs de factage",
    "Chassis maintenance": "Maintenance-châssis",
    "Annual Volume From Contracts": "Volume annuel des contrats",
    "Rail Delivery": "Livraison ferroviaire",
    "Planned Capacity Release": "Libération de capacité prévue",
    "Dangerous Goods": "Marchandises dangereuses",
    "Demand Statistics Contracts": "Contrats de statistiques de la demande",
    "Revenue": "Revenu",
    "Route Timeable": "Horaire de l'itinéraire",
    "Drayage Management__1": "Gestion factage",
    "Demand Order Statistics All EDI": "Statistiques des commandes à la demande TOUS EDI",
    "Rail-Fuel Management": "Gestion du carburant ferroviaire",
    "Demand Planning": "Planification de la demande",
    "Intermodal Yard Planning": "Planification intermodale des gares",
    "New Sales Forecast": "Nouvelles prévisions de ventes",
    "Intergrated Shipping": "Expédition intégrée",
    "Route Asset Plans": "Plans d'itinéraires des actifs",
    "Mode Alignment": "Alignement des modes",
    "Data Hub": "Centre de données",
    "Time Table Assessment": "Évaluation du calendrier",
    "Master Schedule": "Calendrier principal",
    "Customer Demand Manual/EDI": "Demande des clients Manuel/EDI",
    "Ocean Intermodal": "Intermodal océanique",
    "Projected Available Balance": "Solde disponible prévu",
    "Route Operational Plans": "Plans opérationnels d'itinéraires",
    "Ocean Intermodal Management": "Gestion intermodale maritime",
    "Ocean Contracts": "Contrats maritime",
    "All Water Service Rates": "taux de service - voyage maritime toutes eaux",
    "Inbound Intermodal Port": "Port intermodal entrant",
    "Ocean Manual Entry": "Saisie manuelle-maritime",
    "Total Control Planning": "Planification du contrôle total",
    "Outbound Intermodal Port": "Intermodal sortant port",
    "Waybills": "Lettres de transport",
    "Monthly Control Planning": "Planification mensuelle des contrôles",
    "Documentation": "Documentation",
    "String And Transit Information": "Informations sur la chaîne et le transit",
    "Ocean Rate & Fuel": "Tarifs et carburant maritime",
    "Shipment Tracking": "Suivi des expéditions",
    "Intermodal Rate & Fuel": "Taux intermodal et carburant",
    "Annual Control Planning": "Planification annuelle du contrôle",
    "Total Program Capacity": "Capacité totale du programme",
    "Ocean Bookings": "Réservations maritime",
    "Ocean Spot Rates": "Taux maritime au comptant",
    "High Service Profitable Loads for You! Bid Today!": "Des charges rentables pour vous ! Enchérissez aujourd'hui!",
    "Explore ANwork": "Explorer Anwork",
    "Download ANwork": "Télécharger Anwork",
    "For Android": "Pour Android",
    "Download Here": "Télécharger ici"
  }
};
