import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { FileInput, FileInputLabel, UploadIcon, StyledUpload } from './Styles';

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'form'])
};

const defaultProps = {
  className: undefined,
  placeholder: 'Browse Document',
  variant: 'primary',
  onChange: () => {},
  accept: undefined,
  invalid: true
};

const Upload = forwardRef(
  ({ className, variant, onChange, placeholder, accept }, ref) => {
    const [fileName, setFileName] = useState(placeholder);
    const uploadId = uniqueId('netwila-file-');
    const handleChange = (event) => {
      event.preventDefault();
      const { files } = event.target;
      if (files && files.length) {
        setFileName(files[0].name);
        onChange(files[0], event);
      }
    };

    const getIconSize = () => {
      if (['primary', 'form'].includes(variant)) return 30;
      return 16;
    };

    return (
      <StyledUpload className={className}>
        <FileInput
          type='file'
          name={uploadId}
          id={uploadId}
          onChange={handleChange}
          ref={ref}
          accept={accept}
        />
        <FileInputLabel htmlFor={uploadId} variant={variant}>
          <UploadIcon type='plus' size={getIconSize()} variant={variant} />
          {fileName}
        </FileInputLabel>
      </StyledUpload>
    );
  }
);

Upload.propTypes = propTypes;
Upload.defaultProps = defaultProps;

export default Upload;
