import store from 'store';
import { push } from 'connected-react-router';
import { setLoggedUser, getLoggedUser } from 'services/authentication';
import {
  getCurrentUser,
  updateCurrentUser,
  createUser,
  getUserById,
  updateUser,
  forgotPassword,
  confirmForgotPassword,
  getUserSetting,
  updateUserSetting,
  getPolicy,
  getUserByIds
} from 'services/user';
// import { removeStoredAccessToken } from 'shared/utils/authToken';
import {
  getCompany,
  approveUserToCompany,
  getCompanyAppServices
} from 'services/company';
import toast from 'shared/utils/toast';
import { routeConfig } from 'configs/route';

const initialState = {
  loading: false,
  message: '',
  currentUser: {},
  error: false,
  isWorking: false,
  permissions: [],
  isFetchingUserById: false,
  userSettings: null,
  users: {}
};

export const getUserSelector = (userId) => {
  return (state) => state.user[userId];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getCurrentUser_start':
      return {
        ...state,
        error: false,
        loading: true
      };
    case 'getCurrentUser_success':
      return {
        ...state,
        currentUser: action.data,
        loading: false
      };
    case 'getCurrentUser_error':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message
      };
    case 'updateCurrentUser_start':
      return {
        ...state,
        error: false,
        isWorking: true
      };
    case 'updateCurrentUser_success':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.data
        },
        isWorking: false
      };
    case 'updateCurrentUser_error':
      return {
        ...state,
        isWorking: false,
        error: true,
        message: action.message
      };
    case 'getUserById_start':
      return {
        ...state,
        isFetchingUserById: true,
        [action.data]: null
      };
    case 'getUserById_success':
      return {
        ...state,
        isFetchingUserById: false,
        ...action.data
      };
    case 'getUserById_error':
      return {
        ...state,
        isFetchingUserById: false
      };
    case 'getUserSettingStartAction':
      return {
        ...state,
        loading: true
      };
    case 'getUserSettingSuccessAction':
      return {
        ...state,
        userSettings: action.data,
        loading: false
      };
    case 'getUserPolicySuccess':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          roles: action.data.roles,
          permissions: action.data.permissions,
          isSystemAdmin: action.data.isSystemAdmin
        }
      };
    case 'getUserByIdsSuccess':
      return {
        ...state,
        users: {
          ...state.users,
          ...action.data.reduce((a, c) => ({ ...a, [c.userId]: c }), {})
        }
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

export const shouldFetchUser = () => {
  // 1. If there is no user information in localStorage then return `true`
  // 2. Otherwise return `false`
  return !getLoggedUser();
};

/**
 * Gets logged user formation
 * @param {Boolean} refresh For getting new user data fron server
 */
export const getCurrentUserAction = (refresh = false) => {
  return async (dispatch) => {
    if (!shouldFetchUser() && !refresh) {
      // Puts the user information into state
      dispatch({
        type: 'getCurrentUser_success',
        data: store.get('user')
      });
      return Promise.resolve();
    }

    try {
      dispatch({ type: 'getCurrentUser_start' });
      const resp = await getCurrentUser();
      if (resp.data.length === 0) {
        throw new Error('The user is not found');
      }
      const user = resp.data[0];
      if (!user.company) {
        dispatch(push(routeConfig.COMPANY_SIGNUP));
        throw new Error('No company profile was found');
      }
      if (user.company.pendingStatus === true) {
        // removeStoredAccessToken();
        dispatch(push(routeConfig.SIGNUP_CONFIRMATION));
        throw new Error('The company account has not been confirmed yet');
      }
      // Sets user information into local storage
      setLoggedUser(user);
      dispatch({
        type: 'getCurrentUser_success',
        data: user
      });

      //Get company profile of current user
      //The data of company in current user was populated
      if (user.company) {
        const companyResp = await getCompany(user.company._id);
        const appServicesResp = await getCompanyAppServices();
        dispatch({
          type: 'getCurrentCompany_success',
          data: { ...companyResp.data[0], appServices: appServicesResp.data }
        });
      }
      //When create company in the first time, it will be in wailistcompany
      // The user has this field, but it didn't populate the company
      // So we need to fetch data for the company id
      // This one will make high coupling this part
      // The BE designed api so bad for consuming
      else if (user.companyWaitList) {
        const companyResp = await getCompany(user.companyWaitList);
        if (companyResp.data.length > 0)
          dispatch({
            type: 'getCurrentCompany_success',
            data: companyResp.data[0]
          });
      }
    } catch (error) {
      dispatch({ type: 'getCurrentUser_error', message: error.message });
      throw error;
    }
  };
};

export const updateCurrentUserAction = (data) => {
  return (dispatch) => {
    dispatch({ type: 'updateCurrentUser_start' });
    updateCurrentUser(data)
      .then((res) => {
        if (res.data.length === 0) {
          throw new Error('Failed to update user');
        }
        dispatch({
          type: 'updateCurrentUser_success',
          data: res.data[0]
        });
        toast.success('Your user profile has been updated');
      })
      .catch((err) => {
        dispatch({ type: 'updateCurrentUser_error', message: err.message });
      });
  };
};

export const updateUserByIdAction = (userId, data) => {
  return async () => {
    const response = await updateUser(userId, data);
    return response.data[0];
  };
};

export const createUserAction = (data) => {
  return async () => {
    try {
      const response = await createUser(data);
      await approveUserToCompany({
        userId: response.data[0].userId,
        companyId: data.companyId
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.data.message);
    }
  };
};

export const addUserToCompanyAction = (data) => {
  return async () => {
    const response = await approveUserToCompany(data);
    return response.data;
  };
};

const shouldFetchUserById = (state, userId) => {
  return state.user[userId] !== null;
};

export const getUserByIdAction = (userId) => {
  return async (dispatch, getState) => {
    try {
      if (!shouldFetchUserById(getState(), userId)) {
        return;
      }
      dispatch({ type: 'getUserById_start', data: userId });
      const response = await getUserById(userId);
      dispatch({
        type: 'getUserById_success',
        data: response.data.reduce((a, c) => ({ ...a, [c.userId]: c }), {})
      });
    } catch (error) {
      dispatch({ type: 'getUserByID_error' });
    }
  };
};

export const forgotPasswordAction = (email) => {
  return () => {
    return forgotPassword(email);
  };
};

export const confirmForgotPasswordAction = (
  email,
  confirmationCode,
  password
) => {
  return () => {
    return confirmForgotPassword(email, confirmationCode, password);
  };
};

export const getUserSettingAction = () => async (dispatch) => {
  dispatch({
    type: 'getUserSettingStartAction'
  });
  const response = await getUserSetting();
  dispatch({
    type: 'getUserSettingSuccessAction',
    data: response.data
  });
};

export const confirmTermsAndConditions = () => async (dispatch) => {
  await updateUserSetting({
    confirmedTermsAndConditions: true
  });
  dispatch(getUserSettingAction());
};

export const getPermissionAction = () => async (dispatch, getState) => {
  const {
    account: { loggedAccount }
  } = getState();

  const response = await getPolicy(loggedAccount?._id);
  dispatch({ type: 'getUserPolicySuccess', data: response.data });
};

export const getUserByIdsAction = (userIds) => async (dispatch) => {
  const response = await getUserByIds(userIds);
  dispatch({
    type: 'getUserByIdsSuccess',
    data: response.data
  });
};
