import { combineReducers } from 'redux';

import truckFlowProcessReducer from './truckFlowProcess';
import truckAssignmentSetupReducer from './truckAssignmentSetup';
import totalSmartReducer from './totalSmart';
import smartOutModeReducer from './smartOutMode';
import smartInModeReducer from './smartInMode';
import integrateWareSetupReducer from './integrateWareSetup';
import containerWaveAssignmentReducer from './containerWaveAssignment';
import integratedShippingSetupReducer from './integratedShippingSetup';
import smartModeReducer from './smartMode';
import integratedShippingKPISetupReducer from './integratedShippingKPISetup';
import waveSetupReducer from './waveSetup';
import integratedWarehouseWaveSetupReducer from './integratedWHWaveSetup';
import containerWaveSetupReducer from './containerWaveSetup';
import containerAssignmentReducer from './containerAssignment';
import inboundDeliveryReceivingReducer from './inboundDeliveryReceiving';
import integratedYardSetupReducer from './integratedYardSetup';
import integratedYardManagementReducer from './integratedYardManagement';
import contractLinkReducer from './contractLink';

export default () =>
  combineReducers({
    truckFlowProcess: truckFlowProcessReducer,
    truckAssignmentSetup: truckAssignmentSetupReducer,
    totalSmart: totalSmartReducer,
    smartOutMode: smartOutModeReducer,
    smartInMode: smartInModeReducer,
    integrateWareSetup: integrateWareSetupReducer,
    containerWaveAssignment: containerWaveAssignmentReducer,
    integratedShippingSetup: integratedShippingSetupReducer,
    smartMode: smartModeReducer,
    integratedShippingKPISetup: integratedShippingKPISetupReducer,
    waveSetup: waveSetupReducer,
    warehouseWareSetup: integratedWarehouseWaveSetupReducer,
    containerWaveSetup: containerWaveSetupReducer,
    containerAssignment: containerAssignmentReducer,
    inboundDeliveryReceiving: inboundDeliveryReceivingReducer,
    integratedYardSetup: integratedYardSetupReducer,
    integratedYardManagement: integratedYardManagementReducer,
    contractLink: contractLinkReducer
  });
