import React from 'react';
import { Row, Col } from 'antd';

import { ConfirmPage, StyledCard, Tip, LinkItem } from './Styles';
import SignUpImage from 'assets/images/group.svg';

export default () => {
  return (
    <ConfirmPage>
      <Row justify='center' align='middle'>
        <Col flex='376px'>
          <StyledCard cover={<img src={SignUpImage} alt='' />}>
            <p>
              Thank you for submitting your information. Our team will reach out
              to you as soon as possible.
            </p>
            <Tip>
              Already have an account?
              <LinkItem to='/vendor/login'>Login</LinkItem>
            </Tip>
          </StyledCard>
        </Col>
      </Row>
    </ConfirmPage>
  );
};
