import styled, { css } from 'styled-components';
import { color, font } from 'shared/utils/styles';
import Icon from '../Icon';

export const StyledUpload = styled.div`
  width: 100%;
  display: inline-block;
`;

const primarySize = css`
  height: 58px;
  ${font.size(14)}
`;

const secondarySize = css`
  height: 32px;
  ${font.size(12)}
`;

const formSize = css`
  height: 40px;
  ${font.size(14)}
`;

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const uploadVariants = {
  primary: primarySize,
  secondary: secondarySize,
  form: formSize
};

export const FileInputLabel = styled.label`
  font-size: 1.25em;
  font-weight: 700;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${color.textLightBlue};
  ${(props) => uploadVariants[props.variant]}
  display: flex;
  align-items: center;
`;

const iconSizes = {
  primary: 48,
  secondary: 24
};

export const UploadIcon = styled(Icon)`
  background-color: #e6e6e6;
  border-radius: 4px;
  color: #fff;
  margin-right: 12px;
  margin-left: 5px;
  ${(props) =>
    `width: ${iconSizes[props.variant]}px; height: ${
      iconSizes[props.variant]
    }px;`}
  display: flex;
  justify-content: center;
  align-items: center;
`;
