import toast from 'shared/utils/toast';
import { push } from 'connected-react-router';
import { routeConfig } from 'configs/route';
import { sortByNewest } from 'shared/utils/javascript';

import {
  getContracts,
  getContractType,
  getContractPart,
  createContract,
  updateContract,
  getInboundContracts,
  getOutboundContracts,
  deleteContract
} from 'services/contract';

const initialState = {
  contracts: [],
  selectedContract: null,
  filteredType: null,
  isLoading: false,
  isFetchingContractType: false,
  isFetchingContractPart: false,
  contractTemplates: [],
  contractType: [],
  contractPart: [],
  isCreatingContract: false,
  filters: {},
  isFetchingContract: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getContracts_start':
      return {
        ...state,
        isLoading: true
      };
    case 'getContracts_success':
      return {
        ...state,
        isLoading: false,
        contracts: action.data,
        selectedContract:
          (action.data && action.data.length) > 0 ? action.data[0] : null
      };
    case 'getContracts_error':
      return {
        ...state,
        isLoading: false
      };
    case 'getContract_start':
      return {
        ...state,
        isFetchingContract: true
      };
    case 'getContract_success':
      return {
        ...state,
        isFetchingContract: false,
        ...action.data
      };
    case 'getContract_error':
      return {
        ...state,
        isFetchingContract: false
      };
    case 'getContractType_start':
      return {
        ...state,
        isFetchingContractType: true
      };
    case 'getContractType_success':
      return {
        ...state,
        isFetchingContractType: false,
        contractTemplates: action.data,
        contractType: action.data.map((contractType) => ({
          _id: contractType._id,
          type: contractType.type
        }))
      };
    case 'getContractType_error':
      return {
        ...state,
        isFetchingContractType: false
      };
    case 'filterContractByType':
      return {
        ...state,
        filteredType: action.data
      };
    case 'getContractPart_start':
      return {
        ...state,
        isFetchingContractPart: true
      };
    case 'getContractPart_success':
      return {
        ...state,
        isFetchingContractPart: false,
        contractPart: action.data
      };
    case 'getContractPart_error':
      return {
        ...state,
        isFetchingContractPart: false
      };
    case 'createContract_start':
      return {
        ...state,
        isCreatingContract: true
      };
    case 'createContract_success':
      return {
        ...state,
        isCreatingContract: false,
        contracts: action.data.concat(state.contracts)
      };
    case 'createContract_error':
      return {
        ...state,
        isCreatingContract: false
      };
    case 'updateContract_success':
      return {
        ...state,
        contracts: state.contracts.map((contract) => {
          if (contract._id === action.data._id) return action.data;
          return contract;
        }),
        selectedContract: action.data
      };
    case 'selectedContract':
      return {
        ...state,
        selectedContract: action.data
      };
    case 'addFilters_success':
      return {
        ...state,
        filters: { ...state.filters, ...action.filter }
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

const isContractsExisted = (state) => {
  const { contracts } = state.contract;
  if (contracts.length === 0) {
    return true;
  }
  return false;
};

export const getContractsAction = (
  filter,
  refresh = false,
  paging = { limit: 100 }
) => {
  return async (dispatch, getState) => {
    if (!isContractsExisted(getState()) && !refresh) {
      return Promise.resolve();
    }
    const loggedCompany = getState().company.loggedCompany;
    try {
      dispatch({ type: 'getContracts_start' });
      const query = {
        ...filter,
        ...paging,
        parties: [loggedCompany._id]
      };

      let response;
      switch (filter.mode) {
        case 'inbound':
          response = await getInboundContracts(query);
          break;
        case 'outbound':
          response = await getOutboundContracts(query);
          break;
        default:
          response = await getContracts(query);
      }

      return dispatch({
        type: 'getContracts_success',
        data: sortByNewest(response.data, 'updatedAt')
      });
    } catch (error) {
      dispatch({ type: 'getContracts_error' });
    }
  };
};

export const getContractAction = (contractId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'getContract_start' });
      const response = await getContracts({ contractId });
      dispatch({
        type: 'getContract_success',
        data: { [contractId]: response.data[0] }
      });
      return response.data[0];
    } catch (error) {
      dispatch({ type: 'getContract_error' });
    }
  };
};

export const filterContractByStatusAction = (status) => {
  return (dispatch) =>
    dispatch({ type: 'filterContractByStatus', data: status });
};

export const filterContractByTypeAction = (type) => {
  return (dispatch) => {
    dispatch({ type: 'filterContractByType', data: type });
    dispatch(getContractsAction({ type }));
  };
};

export const selectContractAction = (contract) => {
  return (dispatch) => dispatch({ type: 'selectedContract', data: contract });
};

const isContractTypeExisted = (state) => {
  const { contractTemplates, isFetchingContractType } = state.contract;
  if (contractTemplates.length !== 0) {
    return true;
  }
  if (isFetchingContractType) return true;

  return false;
};

export const getContractTypeAction = () => async (dispatch, getState) => {
  if (isContractTypeExisted(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch({ type: 'getContractType_start' });
    const response = await getContractType();

    dispatch({ type: 'getContractType_success', data: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: 'getContractType_error' });
  }
};

const isContractPartExisted = (state) => {
  const { contractPart } = state.contract;
  if (contractPart.length === 0) {
    return true;
  }
  return false;
};

export const getContractPartAction = () => async (dispatch, getState) => {
  if (!isContractPartExisted(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch({ type: 'getContractPart_start' });
    const response = await getContractPart();

    dispatch({ type: 'getContractPart_success', data: response.data });
  } catch (error) {
    dispatch({ type: 'getContractPart_error' });
  }
};

export const createContractAction = (
  data,
  createFromMyNetwork = false
) => async (dispatch) => {
  try {
    dispatch({ type: 'createContract_start' });
    const response = await createContract(data);
    dispatch({ type: 'createContract_success', data: response.data });
    toast.success('Your contract has been created successfully');
    if (!createFromMyNetwork) {
      dispatch(push(routeConfig.CONTRACTS));
    } else {
      return response.data;
    }
  } catch (error) {
    dispatch({ type: 'createContract_error', message: error.message });
  }
};

export const updateContractAction = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: 'updateContract_start' });
    const response = await updateContract(id, data);
    dispatch({ type: 'updateContract_success', data: response.data });
    toast.success('Your contract has been updated successfully');
    return response.data;
  } catch (error) {
    dispatch({ type: 'updateContract_error', message: error.message });
  }
};

export const deleteContractAction = (contractId) => async () => {
  try {
    const response = await deleteContract(contractId);
    toast.success('The contract has been deleted successfully');
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};
