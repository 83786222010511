import styled from 'styled-components';

export const StyledFormInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ col }) => col || 4}, 1fr);
  grid-gap: 5px 1rem;

  .ant-select {
    width: 100% !important;
  }
`;

export const FilterActionWrapper = styled.div`
  text-align: right;
  margin-top: 5px;

  button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:first-child {
      margin-right: 8px;
    }
  }
`;
