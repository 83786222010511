/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Steps,
  Typography,
  List,
  Card,
  Button,
  Upload,
  Select,
  Form,
  Row,
  Input,
  Col,
  message,
  Divider,
  Table,
  Space,
  Modal,
  Tooltip
} from 'antd';
import {
  InboxOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { csvParser } from 'shared/utils/parser';
import { saveFileAs } from 'shared/utils/fileHelper';
import CountrySelect from 'components/Commons/CountrySelect';
import Company from 'components/Commons/Company';
import Logo from 'assets/images/nscmglobal.png';
import Solar from 'assets/images/sourcing/solar.png';
import Pipe from 'assets/images/sourcing/pipes.png';
import Flooring from 'assets/images/sourcing/flooring.png';
import Plastics from 'assets/images/sourcing/plastics.png';
import Drill from 'assets/images/sourcing/drills.png';
import Machinery from 'assets/images/sourcing/machinery.png';
import Mirror from 'assets/images/sourcing/mirrors.png';
import Transportation from 'assets/images/sourcing/transportation.png';
import Trailer from 'assets/images/sourcing/trailers.png';
import TruckEquipment from 'assets/images/sourcing/truck-equipment.png';
import Gloves from 'assets/images/sourcing/gloves-for-truck-drivers.png';
import SafetyVest from 'assets/images/sourcing/safety-vests.png';
import SafetyBoot from 'assets/images/sourcing/safety-boots.png';
import HardHat from 'assets/images/sourcing/hard-hat.png';
import RatchetBar from 'assets/images/sourcing/ratchet-bars.png';
import Strapping from 'assets/images/sourcing/strapping.png';
import Chains from 'assets/images/sourcing/chains.png';
import ProductTemplate from './ProductTemplate';
import {
  uploadSrcFile,
  getSurvey,
  submitSurvey,
  updateSurvey
} from 'services/sourcing';
import { createCompany, updateCompany } from 'services/company';
import { getCurrentUser } from 'services/user';
import { getLocationType } from 'services/location';
import { dispatchService } from 'shared/utils';

const { Dragger } = Upload;
const data = [
  {
    title: 'Solar Power Energy',
    img: Solar
  },
  {
    title: 'Pipes',
    img: Pipe
  },
  {
    title: 'Flooring',
    img: Flooring
  },
  {
    title: 'Plastics',
    img: Plastics
  },
  {
    title: 'Drills',
    img: Drill
  },
  {
    title: 'Machinery',
    img: Machinery
  },
  {
    title: 'Home & Decor',
    img: Mirror
  },
  {
    title: 'Transportation Suppliers',
    img: Transportation
  },
  {
    title: 'Trailers',
    img: Trailer
  },
  {
    title: 'Truck Equipment',
    img: TruckEquipment
  },
  {
    title: 'Gloves for Truck Drivers',
    img: Gloves
  },
  {
    title: 'Safety Vests',
    img: SafetyVest
  },
  {
    title: 'Safety Boots',
    img: SafetyBoot
  },
  {
    title: 'Hard Hat',
    img: HardHat
  },
  {
    title: 'Ratchet Bars',
    img: RatchetBar
  },
  {
    title: 'Strapping',
    img: Strapping
  },
  {
    title: 'Truck Chains',
    img: Chains
  }
];

export default () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [state, setState] = useState([]);
  const [loadedSurvey, setLoadedSurvey] = useState();
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState(data);
  const [partners, setPartners] = useState([]);
  const [_, setCountryCode] = useState();
  const onCountryChange = (__, country) => {
    setState(country.state);
    setCountryCode(country.code);
  };
  const [newPartners, setNewPartners] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);

  useEffect(() => {
    dispatchService(getLocationType, setLocationTypes);
  }, []);

  const downloadLocationTemplate = () => {
    const header = [].concat([
      'name',
      'address',
      'postCode',
      'code',
      'city',
      'country',
      'state',
      'type'
    ]);
    const test = locationTypes.map((type) => type.type).join(', ');
    const templateDataAsCsv = csvParser.unparse(
      [
        header,
        ['# -------------------- NOTES --------------------'],
        ['# DO NOT ADD ANY ROW BEFORE THE HEADER'],
        ['# All rows with a leading hashtag or a double flash are ignored'],
        ['#'],
        ['#'],
        [
          '# -------------------- ALL AVAILABLE LOCATION TYPES ----------------'
        ],
        ...locationTypes.map((type) => [`# ${type.type}`]),
        ['#'],
        ['#'],
        ['# -------------------- SAMPLE LOCATIONS  --------------------'],
        [
          'North Warehouse',
          '4241 Buck Drive',
          '84104',
          '',
          'Salt Lake City',
          'US',
          'Utah',
          'Warehouse / Distribution Center'
        ]
      ],
      {
        skipEmptyLines: true,
        comments: true,
        escapeChar: ''
      }
    );
    saveFileAs(templateDataAsCsv, 'netwila_location.csv');
  };
  const downloadProductTemplate = () => {
    saveFileAs(ProductTemplate, 'seller_products.csv');
  };
  const viewProductImageCsvInstruction = (e) => {
    e.stopPropagation();
    Modal.info({
      width: 1000,
      title: <b>Vendor Image CSV</b>,
      content: (
        <>
          <p>
            <b>Product Line Number :</b> This is the line number of the product
            in correspondense to the product CSV file. Example : If the line
            number for product X is 2 in product CSV then the corresponding
            product line number is 2. IF product X has variants and now the line
            number for the next product Y is 6 in product CSV. So the product
            line number will also be 6.
          </p>
          <br />
          <p>
            <b>Image File Name (in JPG Format) :</b> This is the name of the
            image with .jpg extension. Example – image.jpg
          </p>
          <p>
            <b>Note :</b>
          </p>
          <ul style={{ listStyle: 'disc', padding: '0 30px' }}>
            <li>
              Image name should not be numeric. Example – 1.jpg or 1234.jpg
            </li>
            <li>
              The image ZIP file must contain all the images mentioned in the
              image CSV file.
            </li>
            <li>
              If products does not contain any image, then there is no need to
              upload image CSV file and image ZIP file.
            </li>
            <li>
              The folder containing all the images inside the ZIP file must have
              same name as the ZIP file. Lets say, there is folder named
              “Pictures” and it contains all the images uploaded in the image
              CSV file, then the corresponding ZIP file must also be named as
              “Pictures”.
            </li>
          </ul>
        </>
      )
    });
  };
  const loadLocation = (file) => {
    csvParser.parse(file, {
      header: true,
      complete: ({ data: loadLocations }) => {
        setLocations(
          loadLocations.map((loc, idx) => ({
            ...loc,
            id: idx
          }))
        );
      }
    });
  };
  const uploadSrcFileProps = (type) => ({
    multiple: false,
    name: 'file',
    showUploadList: false,
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        if (type === 'locationSrcFile') loadLocation(file);
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.set(key, data[key]);
        });
        formData.set('file', file);
        const response = await uploadSrcFile(type, formData);
        onSuccess(response.data);
        message.success(`File ${file.name} has been uploaded!`);
      } catch (err) {
        onError(err);
      }
    }
  });
  const submitSurveyAction = () => {
    dispatchService(
      () =>
        submitSurvey({
          sourcingCategories: categories
            .filter((category) => category.selected)
            .map((category) => category.title),
          partners
        }),
      () => {
        dispatch(push('/'));
      }
    );
  };

  const updateSurveyAction = () => {
    dispatchService(() =>
      updateSurvey({
        sourcingCategories: categories
          .filter((category) => category.selected)
          .map((category) => category.title),
        partners
      })
    );
  };
  const selectCategory = (item) => {
    const idx = categories.findIndex(
      (category) => category.title === item.title
    );
    setCategories([
      ...categories.slice(0, idx),
      {
        ...categories[idx],
        selected: !categories[idx].selected
      },
      ...categories.slice(idx + 1)
    ]);
  };
  const onChangePartner = (e) => {
    setPartners([...partners, ...e]);
  };

  const onFinish = (formValues) => {
    // Call API to add partner
    dispatchService(
      () => createCompany(formValues),
      (resp) => {
        const createdCompany = resp[0];
        setNewPartners([
          ...newPartners,
          {
            value: createdCompany._id,
            label: createdCompany.legalName
          }
        ]);
        form.resetFields();
        dispatchService(
          () =>
            updateCompany({
              companyId: loadedSurvey.company,
              partners: [...loadedSurvey.partners, createdCompany._id]
            }),
          () => {
            message.success(
              `Added ${createdCompany.legalName} as your partner`
            );
            setPartners([...partners, createdCompany._id]);
          }
        );
      },
      () => message.error('Cannot add partner')
    );
  };

  const steps = [
    {
      content: () => (
        <>
          <Typography.Title level={3} style={{ textAlign: 'center' }}>
            What are your sourcing categories?
          </Typography.Title>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={categories}
            renderItem={(item) => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => selectCategory(item)}
              >
                {item.selected && (
                  <CheckCircleOutlined
                    style={{
                      position: 'absolute',
                      top: 8,
                      right: 16,
                      zIndex: 999,
                      color: '#55a105'
                    }}
                  />
                )}
                <Card
                  style={{
                    textAlign: 'center',
                    backgroundColor: item.selected ? '#eaeaea' : '#fff'
                  }}
                >
                  <img
                    style={{
                      width: 72,
                      height: 72,
                      display: 'block',
                      margin: '0 auto'
                    }}
                    alt='example'
                    src={item.img}
                  />
                  {item.title}
                </Card>
              </List.Item>
            )}
          />
        </>
      )
    },
    {
      content: () => (
        <>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 'normal', textAlign: 'center' }}
          >
            Provide Your Manufacturing, Distribution, and Ship point Locations
            (bulk import or form option)
          </Typography.Title>
          <Dragger {...uploadSrcFileProps('locationSrcFile')}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload
            </p>
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                downloadLocationTemplate();
              }}
              style={{ marginTop: 8 }}
            >
              Download Template CSV
            </Typography.Link>
          </Dragger>
          {locations.length > 0 && (
            <Table
              size='small'
              rowKey={'id'}
              pagination={false}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name'
                },
                {
                  title: 'Address',
                  dataIndex: 'address'
                },
                {
                  title: 'Postal Code',
                  dataIndex: 'postCode'
                },
                {
                  title: 'City',
                  dataIndex: 'city'
                },
                {
                  title: 'Country',
                  dataIndex: 'country'
                },
                {
                  title: 'State',
                  dataIndex: 'state'
                }
              ]}
              dataSource={locations.slice(0, 10)}
            />
          )}
        </>
      )
    },
    {
      content: () => (
        <>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 'normal', textAlign: 'center' }}
          >
            Who are your current and prospective trading Partners?
          </Typography.Title>
          <Row>
            <Col span={12} offset={6}>
              <Form layout='vertical' form={form} onFinish={onFinish}>
                <Form.Item name='partners' label='Select your trading partners'>
                  <Company
                    mode='multiple'
                    placeholder='Search by company name'
                    onChange={onChangePartner}
                    partners={partners}
                  />
                </Form.Item>
                <Divider>
                  <b>Or enter your trading partner manually</b>
                </Divider>
                <Form.Item name='legalName' label='Trading Partner'>
                  <Input />
                </Form.Item>
                <Form.Item name={'country'} label='Country'>
                  <CountrySelect onChange={onCountryChange} />
                </Form.Item>
                <Form.Item name={'state'} label='State/Province'>
                  <Select
                    options={state.map((s) => ({
                      label: s.state,
                      value: s.code
                    }))}
                  />
                </Form.Item>
                <Form.Item name='city' label='City'>
                  <Input />
                </Form.Item>
                <Form.Item name='address' label='Address'>
                  <Input />
                </Form.Item>
                <Divider orientation='left'>Contact Information</Divider>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={['primaryContact', 'firstName']}
                      label='First Name'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['primaryContact', 'lastName']}
                      label='Last Name'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name={['primaryContact', 'email']} label='Email'>
                  <Input type='email' />
                </Form.Item>
                <Form.Item name={['primaryContact', 'phone']} label='Phone'>
                  <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                  <Button type='primary' htmlType='submit'>
                    Add Partner
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )
    },
    {
      content: () => (
        <>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 'normal', textAlign: 'center' }}
          >
            Upload Product Detail
          </Typography.Title>
          <Dragger {...uploadSrcFileProps('productSrcFile')}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag product file to this area to upload
            </p>
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                downloadProductTemplate();
              }}
              style={{ marginTop: 8 }}
            >
              Download Template CSV
            </Typography.Link>
          </Dragger>
          <Dragger
            {...uploadSrcFileProps('productImgCsvFile')}
            style={{ marginTop: 16 }}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag product image csv file to this area to upload
            </p>
            <Space>
              <Typography.Link
                onClick={(e) => {
                  e.stopPropagation();
                  downloadProductTemplate();
                }}
                style={{ marginTop: 8 }}
              >
                Download Template CSV
              </Typography.Link>
              <Typography.Link onClick={viewProductImageCsvInstruction}>
                <Tooltip title='View instruction'>
                  <InfoCircleOutlined />
                </Tooltip>
              </Typography.Link>
            </Space>
          </Dragger>
          <Dragger
            {...uploadSrcFileProps('productImgZipFile')}
            style={{ marginTop: 16 }}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag product image zip file to this area to upload
            </p>
          </Dragger>
        </>
      )
    }
  ];
  const next = () => {
    setCurrent(current + 1);
    updateSurveyAction();
  };
  const prev = () => {
    setCurrent(current - 1);
    updateSurveyAction();
  };

  useEffect(() => {
    dispatchService(
      () => getSurvey(),
      (_data) => {
        setCategories([
          ...categories.map((category) => {
            return {
              ...category,
              selected: _data.sourcingCategories.includes(category.title)
            };
          })
        ]);
        // Set partners
        setLoadedSurvey(_data);
      }
    );
  }, []);

  useEffect(() => {
    dispatchService(
      () => getCurrentUser(),
      (resp) => {
        if (!resp) return;
        const user = resp[0];
        dispatch({
          type: 'getCurrentCompany_success',
          data: user.company
        });
        if (user.company.completedSurvey) {
          dispatch(push('/'));
        }
      }
    );
  }, []);

  return (
    <Layout
      style={{ backgroundColor: '#fff', paddingBottom: '32px', flex: 'none' }}
    >
      <Layout.Header>
        <img src={Logo} alt='' />
      </Layout.Header>
      <Layout.Content style={{ marginTop: 32, padding: '0 32px' }}>
        <Typography.Title style={{ textAlign: 'center' }} level={3}>
          Survey
        </Typography.Title>
        <p style={{ textAlign: 'center' }}>Tell us more about your business</p>
        <Steps
          current={current}
          size='small'
          labelPlacement='vertical'
          style={{ marginTop: 32 }}
        >
          <Steps.Step title='Sourcing Categories' />
          <Steps.Step title='Locations' />
          <Steps.Step title='Trading Partner' />
          <Steps.Step title='Upload Products' />
        </Steps>
        <div className='steps-content' style={{ padding: '32px 0' }}>
          {steps[current].content()}
        </div>
        <div className='steps-action' style={{ textAlign: 'left' }}>
          {current < steps.length - 1 && (
            <Button type='primary' onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type='primary' onClick={submitSurveyAction}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px'
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};
