import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { SelectStyled } from './Styles';

const { confirm } = Modal;

export default ({ children, initialValue = undefined, dataIndex }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const [toggle, setToggle] = useState(true);
  const onSelectChange = (value) => {
    confirm({
      title: 'Are you sure select this item?',
      onOk: () => {
        setSelectedValue(value);
      },
      onCancel: () => {
        setToggle(!toggle);
      }
    });
  };
  return (
    <Form.Item
      name={dataIndex}
      key={dataIndex}
      style={{ margin: 0 }}
      rules={[
        {
          required: true,
          message: 'This field is required'
        }
      ]}
    >
      <SelectStyled value={selectedValue} onChange={onSelectChange}>
        {children}
      </SelectStyled>
    </Form.Item>
  );
};
