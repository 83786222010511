export const routeConfig = {
  // ROUTES FOR DASHBOARD MODULE
  DASHBOARD: '/dashboard', // for route sub page
  LOGIN: '/login',
  OAUTH_LOGIN: '/oauth/login',
  SIGNUP: '/signup/:token',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  ACCOUNT_SELECTION: '/account-selection',
  COMPANY_SIGNUP: '/company-sign-up',
  SIGNUP_CONFIRMATION: '/signup-confirmation',
  SIGNUP_VERIFICATION: '/signup-verification',
  // ROUTES FOR COMPANY MODULE
  ANWORK: '/dashboard/ANwork',
  COMPANY: '/company',
  NETWORK: '/company-network',
  COMPANY_DETAILS: '/company/company-details',
  COMPANY_PARTNER_DETAIL: '/company/company-details/:companyId',
  COMPANY_HIERARCHY: '/company/company-hierarchy',
  COMPANY_ACCOUNTS: '/company/company-accounts',
  COMPANY_ANWORK: '/company/anwork',
  COMPANY_ANWORK_LOAD_BOARD: '/company/anwork/load-board',
  COMPANY_ANWORK_MOBILE: '/company/anwork/mobile',
  COMPANY_ANWORK_HEALTH_CHECK: '/company/anwork/health-check',
  COMPANY_ANWORK_ATTENDANCE: '/company/anwork/attendance',
  COMPANY_ANWORK_CREW: '/company/anwork/crew-scheduling',
  COMPANY_SERVICES: '/company/services',
  COMPANY_SERVICE_AREA: '/company/services/service-area',
  COMPANY_ASSETS: '/company/assets',
  COMPANY_ASSETS_EQUIPMENT: '/company/assets/equipment',
  COMPANY_ASSETS_EQUIPMENT_EDIT:
    '/company/assets/equipment-:equipmentType/:id/edit',
  COMPANY_EQUIPMENTS: '/company/equipments',
  COMPANY_RESOURCES_DOCUMENT: '/company/resources-documents',
  COMPANY_ROLE: '/company/role',
  COMPANY_LOCATIONS: '/company/locations',
  COMPANY_LOCATIONS_PORT: '/company/locations/port-locations',
  COMPANY_LOCATIONS_YARD: '/company/locations/yard-locations',
  COMPANY_LOCATIONS_TERMINAL: '/company/locations/terminal-locations',
  COMPANY_ACCOUNtS: '/company/accounts',
  COMPANY_NEW_ACCOUNT: '/company/company-accounts/new-account',
  COMPANY_ACCOUNT_DETAIL: '/company/account-detail/:id',
  COMPANY_PRO_SEGMENTS: '/company/prod-segments',
  COMPANY_APPROVE: '/company/company-approve',
  COMPANY_APPROVE_DETAIL: '/company/company-approve/:companyId',
  COMPANY_ACCOUNT_APPROVE:
    '/company/company-approve/:companyId/account/:accountId',
  COMPANY_APPROVE_PARTNERS:
    '/company/company-approve/:companyId/company-partners',
  COMPANY_APPROVE_NEW_COMPANY: '/company/company-approve/new-company',
  COMPANY_PRODUCT: '/company/product',
  COMPANY_DC_LOCATION: '/company/dc-location',
  COMPANY_EMPLOYEE: '/company/employee',
  COMPANY_PARTNER_PRODUCT: '/company/product/partner/:id',
  // ROUTES FOR ORDER MODULE
  ORDERS: '/orders',
  ORDERS_LIST: '/orders/all',
  ORDERS_DASHBOARD: '/orders/dashboard',
  ORDERS_DETAIL: '/orders/order-detail/:id',
  OCEAN_ORDERS_LIST: '/orders/ocean-orders/order-list',
  ROAD_ORDERS_LIST: '/orders/road-orders/order-list',
  RAIL_ORDERS_LIST: '/orders/rail-orders/order-list',
  DRAYAGE_CHASSIS_ORDERS_LIST: '/orders/drayage-chassis-orders/order-list',
  INTEGRATED_SHIPPING_ORDERS_LIST:
    '/orders/integrated-shipping-orders/order-list',
  ORDERS_ENTRY: '/orders/order-entry',
  ORDERS_ENTRY_UPDATE: '/orders/order-entry/:orderType/:id',
  ORDERS_DESTINATION: '/orders/destination',
  ORDERS_START: '/orders/start',
  ORDERS_OCEAN: '/orders/ocean',
  ORDERS_PO: '/orders/purchase-order',
  ORDERS_PO_ENTRY: '/orders/purchase-order/entry',
  ORDERS_PO_DETAIL: '/orders/purchase-order/:id',
  ORDERS_PO_EDIT: '/orders/purchase-order/:id/edit',
  ORDERS_MANAGEMENT: '/orders/order-management',
  ORDERS_MANAGEMENT_CREATE: '/orders/order-management/create',
  // ROUTES FOR TASK MANAGEMENT
  TASKS: '/tasks',
  INTEGRATED_TASK_DETAIL: '/orders/task/:id',
  INTEGRATED_TASK_EDIT: '/orders/task/edit/:id',
  // ROUTES FOR CONTRACT MODULE
  CONTRACTS: '/contracts',
  CONTRACT_RATE: '/contracts/:contractId/rate/:rateId',
  CONTRACT_RATES: '/contracts/:contractId/rate',
  CONTRACT_NEW: '/contracts/new-contract/:contractTypeId',
  CONTRACTS_NEW: '/contracts/new-contracts',
  CONTRACT_HISTORY: '/contracts/contract-history',
  RATE_CARD_NEW: '/contracts/:contractId/new-rate-card/:rateCardTypeId',
  RATES: '/contracts/rates',
  // ROUTES FOR MESSAGES MODULE
  MESSAGES: '/messages',
  MESSAGES_ALL: '/messages/all',
  MESSAGES_CRITICAL: '/messages/critical',
  MESSAGES_CHANNELS: '/messages/channels',
  // SETTING
  SETTINGS: '/settings',
  SETTINGS_ACCESS_MANAGEMENT: '/settings/access',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_NOTIFICATIONS: '/settings/notifications',
  SETTINGS_CONFIGURATION: '/settings/configuration',
  SETTINGS_ACCOUNTS: '/settings/accounts',
  SETTINGS_ACCOUNTS_DETAIL: '/settings/accounts/:id',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_USERS_DETAIL: '/settings/users/:id',
  SETTINGS_SUBSCRIPTIONS: '/settings/subscriptions',
  SETTINGS_SUBSCRIPTIONS_SUCCESS: '/settings/subscriptions/success/:id',
  SETTINGS_SURVEY: '/settings/survey',
  SETTINGS_APP_SERVICES: '/settings/app-services',
  // PROFILE
  PROFILE: '/profile',
  // ASSET_MANAGEMENT
  ASSET_MANAGEMENT: '/asset-management',
  ACQUISITION_MANAGEMENT: '/acquisition-management',
  ASSET_REQUIREMENT_PLANNING: '/asset-requirement-planning',
  INTERMODAL_YARD_PLANNING: '/intermodal-yard-planning',
  INVENTORY_MANAGEMENT: '/asset-management/automotive-yard-planning',
  // AGING REPORT
  AGING_REPORT: '/aging-report',
  // AUTO YARD PLANNING
  AUTO_YARD_PLANNING: '/auto-yard-planning',
  AUTO_YARD_SETUP: '/auto-yard-planning/auto-yard-setup',
  AUTO_YARD_MANAGEMENT: '/auto-yard-planning/auto-yard-management',
  AUTO_YARD_INBOUND: '/auto-yard-planning/inbound-from-yard',
  AUTO_YARD_OUTBOUND: '/auto-yard-planning/outbound-from-yard',
  BLOCK_PLANNING: '/block-planning',
  ROUTE_ASSET_PLANS: '/route-asset-plans',
  PORT_MANAGEMENT: '/port-management',
  CUSTOMER_ORDERS_MANUAL: '/customer-orders-manual',
  EQUIPMENT_PLANNING: '/equipment-planning',
  ROUTE_TIMETABLE: '/route-timetable',
  MAINTENANCE_MANAGEMENT: '/maintenance-management',
  MAINTENANCE_SCHEDULE: '/maintenance-schedule',
  STAFF_SCHEDULES: '/staff-schedules',
  INPUT_OUTPUT_ANALYSIS: '/input-output-analysis',
  INTERNAL_ASSET_AUDIT: '/internal-asset-audit',
  RISK_MANAGEMENT: '/risk-management',
  EXTERNAL_RAIL: '/external-rail',
  CARBON_CALCULATOR: '/carbon-calculator',
  RAIL_MASTER_CAPACITY_PLAN: '/rail-master-capacity-plan',
  TIME_TABLE_ASSESSMENT: '/time-table-assessment',
  ROUTE_OPERATIONAL_PLANS: '/route-operational-plans',
  // DEMAND_MANAGEMENT
  DEMAND_MANAGEMENT: '/demand-management',
  DRAYAGE_MANAGEMENT: '/drayage-management',
  DEMAND_MANAGEMENT_REVENUE: '/demand-management/revenue',
  REVENUE: '/revenue',
  // ROAD MANAGEMENT
  ROAD: '/road',
  ROAD_LOAD_BOARD: '/road/load-board',
  ROAD_RATE_BOARD: '/road/rate-board',
  ROAD_RATE_BOARD_ENTRY: '/road/rate-board/entry',
  ROAD_RATE_BOARD_EDIT: '/road/rate-board/:id/edit',
  ROAD_RATE_BOARD_DETAIL: '/road/rate-board/:id/detail',
  ROAD_REVENUE: '/road/revenue',

  OCEAN_INTERMODAL: '/ocean-intermodal',
  MASTER_SCHEDULE: '/master-schedule',
  RAIL_DELIVERY: '/rail-delivery',
  DANGEROUS_GOODS: '/dangerous-goods',
  INTEGRATED_SHIPPING: '/integrated-shipping',
  INTEGRATED_SHIPPING_WAVE_SETUP:
    '/demand-management/integrated-shipping/wave-setup',
  // OCEAN MANAGEMENT
  OCEAN_MANAGEMENT: '/ocean-management',
  WAYBILLS: '/waybills',
  // EDI
  EDI: '/edi',
  EDI_LOGS: '/edi-logs',
  // BULK
  BULK: '/bulk-shipping',
  BULK_VESSEL: '/bulk-vessel',
  INPUT_BULK_CARULOAD: '/input-bulk-carunload',
  // ADMIN
  ADMIN: '/admin',
  USER_MANAGEMENT: '/admin/user-management',
  USER_MANAGEMENT_NEW: '/admin/user-management/new',
  USER_MANAGEMENT_DETAIL: '/admin/user-management/:userId',
  COMPANY_MANAGEMENT: '/admin/company-management',
  REQUESTED_COMPANIES: '/admin/requested-companies',
  SUBSCRIPTION_MAGAGEMENT: '/admin/subscriptions',
  COMPANY_MANAGEMENT_DETAIL: '/admin/company-management/:companyId',
  COMPANY_MANAGEMENT_ACCOUNT_DETAIL:
    '/admin/company-management/:companyId/account/:accountId',
  ACCOUNT_MANAGEMENT: '/admin/account-management',
  ACCOUNT_MANAGEMENT_DETAIL: '/admin/account-management/:accountId',
  CONTRACT_MANAGEMENT: '/admin/contract-management',
  EQUIPMENT_MANAGEMENT: '/admin/equipment-management',
  BASE_PORT_MANAGEMENT: '/admin/base-port-management',
  INSTRUCTION_MANAGEMENT: '/admin/instruction-management',
  ORDER_MANAGEMENT: '/admin/order-management',
  // TASK MANAGEMENT
  TASK_MANAGEMENT: '/tasks',
  TASK_MANAGEMENT_DETAIL: '/tasks/:id',
  CONTRACT_LINK_SETUP: '/integrated-shipping/link-smart-contract',
  APP_CONFIG_MANAGEMENT: '/admin/config-management',
  LOCATION_MANAGEMENT: '/admin/location-management',
  PRODUCT_MANAGEMENT: '/admin/product-management',
  QUOTE_REQUEST_MANAGEMENT: '/admin/quote-request',
  SOURCING_MANAGEMENT: '/admin/sourcing',
  NETWORK_MANAGEMENT: '/admin/network-management',

  DATA_EVENT_MANAGEMENT: '/admin/event-management',

  SMART_MODE: '/integrated-shipping/smart-mode',
  // Automotive Yard
  AUTO_YARD_TASK_LIST: '/auto-yard-planning/task',
  EDI_WORKFLOW: '/edi/workflow',
  ASSET_INVOICE_REPORT: '/auto-yard-planning/invoice-report',
  DATA_HUB: '/demand-management/data-hub',
  DATA_TABLE: '/data-hub/data-table',
  DATA_FLOW: '/data-hub/data-flow',
  AI_MODEL: '/ai',
  AI_MODEL_DETAIL: '/ai/:model',
  // Invoice
  INVOICE_MANAGEMENT: '/invoice-management',
  FORWARDING: '/demand-management/forwarding',
  ROAD_ORDERS: '/orders/road',
  RAIL_ORDERS: '/orders/rail',
  OCEAN_ORDERS: '/orders/ocean',
  ROAD_ORDER_DETAIL: '/orders/road/:id',
  RAIL_ORDER_DETAIL: '/orders/rail/:id',
  OCEAN_ORDER_DETAIL: '/orders/ocean/:id',
  AIR_ORDER_DETAIL: '/orders/air/:id',
  DRAYAGE_ORDER_DETAIL: '/orders/drayage/:id',
  ORDER_INVOICE: '/orders/invoice',
  ORDER_INVOICE_PAYMENT: '/orders/invoice-payment',
  ORDER_BILL: '/orders/bill',
  ORDER_BILL_PAYMENT: '/orders/bill-payment',
  ORDER_CREDIT_NOTE: '/orders/credit-note',
  ORDER_VENDOR_CREDIT: '/orders/vendor-credit',
  ORDER_BANK_ACCOUNT: '/orders/bank-account',
  BOL_DETAIL: '/demand-management/forwarding/bol/:id',
  CONTAINER_TRACKING: '/demand-management/forwarding/container-tracking',
  AUTOMOTIVE: '/automotive',
  AUTOMOTIVE_MANAGEMENT: '/demand-management/automotive',
  QUOTE: '/quote',
  DB_SHIPINTUIT: '/dashboard/shipintuit',
  SHIPINTUIT: '/demand-management/integrated-shipping',
  // Sourcing
  SOURCING: '/sourcing',
  SOURCING_SIGNUP: '/sourcing/:type/signup',
  SOURCING_LOGIN: '/sourcing/login',
  SOURCING_SIGNUP_CONFIRM: '/sourcing/confirmation',
  SOURCING_SIGNUP_VIRIFY: '/sourcing/verify/:type/:token',
  SOURCING_ACCOUNT_TYPE: '/sourcing/type',
  SOURCING_SURVEY: '/sourcing/survey',

  VENDOR: '/vendor',
  VENDOR_SIGNUP: '/vendor/signup',
  VENDOR_LOGIN: '/:vendor/login',
  VENDOR_SURVEY: '/vendor/survey',
  VENDOR_SIGNUP_CONFIRM: '/vendor/confirm'
};
