import { isNumber, isNaN, omitBy, isUndefined } from 'lodash';

export * from './service';
export * from './utilHelper';
export * from './stringHelper';
export * from './formHelper';
export * from './router';

const PKGS_TYPES = [
  ['Bag', 'Bags'],
  ['Box', 'Boxes'],
  ['Carton', 'Cartons'],
  ['Crate', 'Crates'],
  ['Drum', 'Drums'],
  ['Package', 'Packages'],
  ['Pallet', 'Pallets'],
  ['Pallet/Skid', 'Pallets/Skids'],
  ['Roll', 'Rolls'],
  ['Tube', 'Tubes']
];

const WEIGHT_UNITS = [
  ['kg', 'kgs'],
  ['lb', 'lbs']
];

const isEmpty = (val) => {
  if (val === undefined || val === null) return true;

  if (typeof val === 'string') {
    return val.trim() === '';
  }
  if (typeof val === 'object') {
    return Object.keys(val).length === 0;
  }

  return false;
};

export const formatPkg = (total, pkgType) => {
  if (!total || !pkgType) return;
  const type = PKGS_TYPES.find((item) => item[0] === pkgType);
  if (!type) return total;
  const formattedTotal = `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (total > 1) return `${formattedTotal} ${type[1]}`;
  return `${formattedTotal} ${type[0]}`;
};

export const formatWeight = (total, unit = 'kg') => {
  if (!total || !unit) return;
  const type = WEIGHT_UNITS.find((item) => item[0] === unit.toLowerCase());
  if (!type) return total;
  const _total = `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (total > 1) return `${_total} ${type[1]}`;
  return `${_total} ${type[0]}`;
};

export const formatNumber = (number, prefix = '') => {
  if (number === undefined) return `${prefix}0`;
  if (!isNumber(number)) return `${prefix}0`;
  if (isNaN(number)) return `${prefix}0`;
  return `${prefix}${String(number.toFixed(2))}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
};

export const parameterize = (obj) => {
  return omitBy(omitBy(obj, isUndefined), isEmpty);
};

export const formatAddress = (address) => {
  if (!address) return;
  return [
    address.address1,
    address.city,
    address.province,
    address.country,
    address.zip
  ]
    .filter((v) => !!v && v !== '')
    .join(', ');
};
