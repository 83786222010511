import { createApiAction, createReducer } from 'shared/utils/redux';

import { getRateCardByType, getRateCardType } from 'services/contract';

const initialState = {
  rateCards: [],
  rateCardType: null
};

export const rateCardsSelector = (state) =>
  state.oceanIntermodal.allWaterRates.rateCards;
export const rateCardTypeSelector = (state) =>
  state.oceanIntermodal.allWaterRates.rateCardType;

export const getAllWaterRatesAction = createApiAction(
  'getAllWaterRatesAction',
  () => ({
    callAPI: () => getRateCardByType('Ocean')
  })
);

export const getRateCardTypeAction = createApiAction(
  'getRateCardTypeAction',
  (code) => ({
    callAPI: () => getRateCardType({ code })
  })
);

export default createReducer(initialState, {
  [getAllWaterRatesAction.success]: (state, { response }) => ({
    ...state,
    rateCards: response.data
  }),
  [getRateCardTypeAction.success]: (state, { response }) => ({
    ...state,
    rateCardType: response.data?.[0]
  })
});
