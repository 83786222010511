import { getNotificationCount, getNotifications } from 'services/notification';

const initialState = {
  count: 0,
  notifications: [],
  isLoading: false,
  visible: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getNotificationCount_success':
      return {
        ...state,
        count: action.data
      };
    case 'getNotifications_success':
      return {
        ...state,
        notifications: action.data
      };
    case 'handleVisibleChange':
      return {
        ...state,
        visible: action.data
      };
    default:
      return state;
  }
};

export const getNotificationCountAction = () => {
  return (dispatch) => {
    getNotificationCount().then((res) =>
      dispatch({
        type: 'getNotificationCount_success',
        data: res.data
      })
    );
  };
};

export const getNotificationsAction = () => {
  return (dispatch) => {
    getNotifications().then((res) => {
      dispatch({
        type: 'getNotifications_success',
        data: res.data
      });
    });
  };
};

export const handleVisibleChangeAction = (visible) => {
  return (dispatch) => {
    dispatch({
      type: 'handleVisibleChange',
      data: visible
    });
  };
};
