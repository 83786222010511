import toast from 'shared/utils/toast';
import { createAction, createReducer } from 'redux-act';
import {
  fetchHazardousCode,
  createHazardousCode,
  deleteHazardousCode
} from 'services/hCode';

const initialState = {
  fetchLoading: false,
  createLoading: false,
  data: [],
  error: false,
  createError: false,
  deleteLoading: {},
  deleteError: {}
};

const fetchHazardousCodeStart = createAction('fetch hazardous code');
const fetchHazardousCodeSuccess = createAction('fetch hazardous code success');
const fetchHazardousCodeError = createAction('fetch hazardous code error');
const createHazardousCodeStart = createAction('create hazardous code');
const createHazardousCodeSuccess = createAction(
  'create hazardous code success'
);
const createHazardousCodeError = createAction('delete hazardous code error');

const deleteHazardousCodeStart = createAction('delete hazardous code');
const deleteHazardousCodeSuccess = createAction(
  'delete hazardous code success'
);
const deleteHazardousCodeError = createAction('delete hazardous code error');

export default createReducer(
  {
    [fetchHazardousCodeStart]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchHazardousCodeSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchHazardousCodeError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      fetchLoading: false
    }),
    [createHazardousCodeStart]: (state) => ({
      ...state,
      createLoading: true
    }),
    [createHazardousCodeSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      createLoading: false
    }),
    [createHazardousCodeError]: (state) => ({
      ...state,
      createError: true
    }),
    [deleteHazardousCodeStart]: (state, payload) => ({
      ...state,
      deleteLoading: {
        ...state.deleteLoading,
        [payload.id]: true
      }
    }),
    [deleteHazardousCodeSuccess]: (state, payload) => ({
      ...state,
      data: payload.data,
      deleteLoading: {
        ...state.deleteLoading,
        [payload.id]: false
      }
    }),
    [deleteHazardousCodeError]: (state, payload) => ({
      ...state,
      deleteError: {
        ...state.deleteError,
        [payload.id]: true
      },
      deleteLoading: {
        ...state.deleteLoading,
        [payload.id]: false
      }
    })
  },
  initialState
);

export const fetchHazardousCodeAction = (companyId) => {
  return async (dispatch) => {
    dispatch(fetchHazardousCodeStart());
    try {
      const data = await fetchHazardousCode(companyId);
      dispatch(fetchHazardousCodeSuccess(data.data));
      return data.data;
    } catch (error) {
      dispatch(fetchHazardousCodeError('Fetching error'));
      toast.error(`Error occurred when fetching Hazardous Code`);
    }
  };
};

export const createHazardousCodeAction = (data) => {
  return async (dispatch) => {
    dispatch(createHazardousCodeStart());
    try {
      const res = await createHazardousCode(data);
      if (res) {
        const refetchData = await fetchHazardousCode(data.companyId);
        dispatch(createHazardousCodeSuccess(refetchData.data));
      }
      return res.data;
    } catch (error) {
      dispatch(fetchHazardousCodeError('Fetching error'));
      toast.error(`Error occurred when create Hazardous Code`);
    }
  };
};

export const deleteHazardousCodeAction = (data) => {
  return async (dispatch) => {
    dispatch(deleteHazardousCodeStart({ id: data.id }));
    try {
      const res = await deleteHazardousCode(data.id);
      if (res) {
        const refetchData = await fetchHazardousCode(data.companyId);
        dispatch(
          deleteHazardousCodeSuccess({ data: refetchData.data, id: data.id })
        );
        toast.success('Delete Hazardous Code successfully');
        return true;
      }
    } catch (error) {
      dispatch(deleteHazardousCodeError('Fetching error'));
      toast.error(`Error occurred when delete Hazardous Code`);
    }
    return true;
  };
};
