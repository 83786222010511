import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group'; //TODO: have a bug in react-transition
import pubsub from 'sweet-pubsub';
import { uniqueId } from 'lodash';

import { Container, StyledToast, CloseIcon, Title, Message } from './Styles';

const Toast = () => {
  const [toasts, setToasts] = useState([]);
  const removeToast = (id) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  };

  useEffect(() => {
    const addToast = ({ type = 'success', title, message, duration = 5 }) => {
      const id = uniqueId('toast-');

      setToasts((currentToasts) => [
        ...currentToasts,
        { id, type, title, message }
      ]);

      if (duration) {
        setTimeout(() => removeToast(id), duration * 1000);
      }
    };

    pubsub.on('toast', addToast);

    return () => {
      pubsub.off('toast', addToast);
    };
  }, []);

  return (
    <Container>
      <TransitionGroup>
        {toasts.map((toast) => (
          <CSSTransition
            key={toast.id}
            classNames='netwila-toast'
            timeout={200}
          >
            <StyledToast
              key={toast.id}
              type={toast.type}
              onClick={() => removeToast(toast.id)}
            >
              <CloseIcon type='close' />
              {toast.title && <Title>{toast.title}</Title>}
              {toast.message && <Message>{toast.message}</Message>}
            </StyledToast>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Container>
  );
};

export default Toast;
