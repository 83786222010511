import http from 'shared/utils/http';

export const getOrders = () => {
  return http.get('/netwila/order');
};

export const createPurchaseOrder = (purchseOrders) => {
  return http.put('/netwila/item', purchseOrders);
};

export const deletePurchaseOrder = (itemPOId) => {
  return http.delete('/netwila/item/purchaseorder', { itemPOId });
};

export const getPurchaseOrder = (filter = {}) => {
  return http.get('/netwila/item', filter);
};

export const createRoadOrder = (order) => {
  return http.post('/api/order/road-order', order);
};

export const getOrderStatus = () => {
  return http.get('/netwila/order/status');
};

export const getLoadTypes = () => {
  return http.get('/netwila/item/potype');
};

export const getRoadOrders = (companyId, filter) => {
  return http.get('/api/order/road-order', { ...filter, companyId });
};

export const getOceanOrder = (id) => {
  return http.get(`/api/order/oceanorder/${id}`);
};

export const getRoadOrder = (id) => {
  return http.get(`/api/order/road-order/${id}`);
};

export const getRailOrder = (id) => {
  return http.get(`/api/order/rail-order/${id}`);
};

export const getDrayOrder = (id) => {
  return http.get(`/api/order/dray-order/${id}`);
};

export const updateOrder = (id, data) => {
  return http.put(`/netwila-core/order/${id}`, data);
};

export const updateAttachPo = (id, data) => {
  return http.post(`/netwila-core/order/${id}/po`, data);
};

export const unassignPo = (id, data) => {
  return http.delete(`/netwila-core/order/${id}/po`, data);
};

export const updateSocContainer = (id, data) => {
  return http.put(`/netwila-core/order/${id}/soc`, data);
};

export const addOrderDocument = (id, data) => {
  return http.put(`/netwila-core/order/${id}/documents`, data);
};

export const updateOrderDocument = (id, docId, data) => {
  return http.put(`/netwila-core/order/${id}/documents/${docId}`, data);
};

export const deleteOrderDocument = (id, docId) => {
  return http.delete(`/netwila-core/order/${id}/documents`, { docId });
};

export const updateOrders = (data) => {
  return http.put(`/netwila-core/order`, data);
};

export const createOrder = (data) => {
  return http.post(`/netwila-core/order`, data);
};

export const searchOrder = (params) => {
  return http.get('/netwila-core/order', params);
};

export const approvePo = (id) => {
  return http.post(`/netwila-core/po/${id}/approve`);
};

export const cancelPo = (id) => {
  return http.post(`/netwila-core/po/${id}/cancel`);
};

export const acceptPo = (id) => {
  return http.post(`/netwila-core/po/${id}/accept`);
};

export const rejectPo = (id) => {
  return http.post(`/netwila-core/po/${id}/reject`);
};

export const updatePo = (id, data) => {
  return http.put(`/netwila-core/po/${id}`, data);
};

export const updateItemPo = (id, data) => {
  return http.put(`/netwila-core/po/item-po/${id}`, data);
};

export const deleteItemPo = (id) => {
  return http.delete(`/netwila-core/po/item-po/${id}`);
};

export const createVesselOrder = (data) => {
  return http.post('/netwila-core/order/vessel', data);
};

export const getVesselOrder = (id) => {
  return http.get(`/netwila-core/order/vessel/${id}`);
};

export const getIntegratedOrders = (params = {}) => {
  return http.get(`/netwila-core/order/integrated`, params);
};

export const getForwardingOrders = (params = {}) => {
  return http.get(`/integrated-shipping/forwarding`, params);
};

export const getOrder = (id) => {
  return http.get(`/netwila-core/order/${id}`);
};

export const deleteOrder = (id) => {
  return http.delete(`/netwila-core/order/${id}`);
};

export const createRoadOrderFromPo = (poNumber) => {
  return http.post('/netwila-core/order/road', { poNumber });
};

export const createOceanOrderFromPo = (poNumber) => {
  return http.post('/netwila-core/order/ocean', { poNumber });
};

export const getChangeLogs = (id, params = {}) => {
  return http.get(`/netwila-core/order/${id}/changelogs`, params);
};

export const exportExcelChangeLogs = (id) => {
  return http.get(`/netwila-core/order/${id}/changelogs/export-excel`);
};

export const getAllChangeLogs = (params) => {
  return http.get(`/netwila-core/order/changelogs/all`, params);
};

export const getDownloadChangeLogLink = (id) => {
  return http.get(`/netwila-core/order/changelogs/download`, { id });
};

export const exportExcelAllChangeLogs = (params) => {
  return http.get(`/netwila-core/order/changelogs/all/export-excel`, params);
};

export const importOceanOrders = (data) => {
  return http.post('/netwila-core/order/ocean/import', data);
};

export const createFreightInvoice = (data) => {
  return http.post('/netwila-core/freight-invoice', data);
};

export const updateFreightInvoice = (id, data) => {
  return http.put(`/netwila-core/freight-invoice/${id}`, data);
};

export const getFreightInvoice = (freightId) => {
  return http.get(`/netwila-core/freight-invoice/${freightId}`);
};

export const getFreightInvoiceById = (id) => {
  return http.get(`/netwila-core/freight-invoice/by-id/${id}`);
};

export const resetFreightInvoice = (freightId) => {
  return http.put(`/netwila-core/freight-invoice/reset/${freightId}`);
};

export const deleteInvoice = (id) => {
  return http.delete(`/netwila-core/freight-invoice/${id}`);
};

export const getSubInvoices = (freightId) => {
  return http.get(`/netwila-core/freight-invoice/${freightId}/sub`);
};

export const getBol = (bolNumber) => {
  return http.get(`/netwila-core/bol/${bolNumber}`);
};

export const deleteBol = (id) => {
  return http.delete(`/netwila-core/bol/${id}`);
};

export const getBols = (params) => {
  return http.get(`/netwila-core/bol`, params);
};

export const createBol = (orderId) => {
  return http.post(`/netwila-core/bol/`, { orderId });
};

export const assignBol = (orderId, bolNumber) => {
  return http.post(`/netwila-core/bol/assign`, { orderId, bolNumber });
};

export const updateBol = (id, data) => {
  return http.put(`/netwila-core/bol/${id}`, data);
};

export const exportBol = (id, status) => {
  return http.get(`/netwila-core/bol/${id}/export`, { status });
};

export const exportInvoice = (id) => {
  return http.get(`/netwila-core/freight-invoice/${id}/export`);
};

export const updateShippingAlert = (id, data) => {
  return http.put(`/netwila-core/order/${id}/shipping-alert`, data);
};

export const exportShippingAlert = (id, containerNo) => {
  return http.get(`/netwila-core/order/${id}/shipping-alert`, { containerNo });
};

export const exportPickupNotice = (id, containerNo) => {
  return http.get(`/netwila-core/order/${id}/pickup-notice`, { containerNo });
};

export const updatePickupNotice = (id, data) => {
  return http.put(`/netwila-core/order/${id}/pickup-notice`, data);
};

export const getFreightInvoices = (params) => {
  return http.get('/netwila-core/freight-invoice', params);
};

export const getShipmentUnits = (id) => {
  return http.get(`/netwila-core/order/${id}/container`);
};

export const updateShippingInstruction = (id, data) => {
  return http.put(`/netwila-core/order/${id}/shipping-instruction`, data);
};

export const exportShippingInstructionPdf = (id) => {
  return http.get(`/netwila-core/order/${id}/shipping-instruction/export-pdf`);
};

export const updateContainerRelease = (id, data) => {
  return http.put(`/netwila-core/order/${id}/container-release`, data);
};

export const exportContainerRelease = (id, containerNo) => {
  return http.get(`/netwila-core/order/${id}/container-release`, {
    containerNo
  });
};

export const getBolImportStatus = (id) => {
  return http.get(`/netwila-core/bol/import/${id}`);
};

export const exportBolByLoadHistory = (id) => {
  return http.get(`/netwila-core/bol/export/${id}`);
};

export const exportBols = (params) => {
  return http.post(`/netwila-core/bol/export`, params);
};

export const bookBolNumber = (numberOfBol) => {
  return http.get(`/netwila-core/bol/book-number`, { numberOfBol });
};

export const createOrUpdatePo = (data) => {
  return http.post(`/netwila-core/po`, data);
};

export const getInvoiceCategories = () => {
  return http.get(`/netwila-core/invoice-category`);
};

export const getItemCategories = (params) => {
  return http.get(`/netwila-core/item-category`, params);
};

export const updateContainerInfo = (id, data) => {
  return http.post(`/netwila-core/order/${id}/update-container`, data);
};

export const updateOrderUnit = (id, data) => {
  return http.post(`/netwila-core/order/${id}/update-unit`, data);
};

export const calculateCarbonGenerated = (id) => {
  return http.post(`/netwila-core/order/${id}/calculate-carbon`);
};

export const getAccountingOrders = (params) => {
  return http.get(`/netwila-core/order/accounting`, params);
};

export const exportExcelAccountingOrders = (params) => {
  return http.get(`/netwila-core/order/accounting/export-excel`, params);
};

export const getProfitability = (id) => {
  return http.get(`/netwila-core/order/${id}/profitability`);
};

export const generateCustomBol = (data) => {
  return http.post(`/netwila-core/bol/custom`, data);
};
