import decoder from 'jwt-decode';
import store from 'store';
import Cookies from 'js-cookie';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const AUTH_TIME_KEY = 'authTime';
const APP_DOMAIN = process.env.REACT_APP_DOMAIN ?? 'localhost';

export const getStoredAccessToken = () => store.get(ACCESS_TOKEN_KEY);

export const updateAuthTime = () => {
  const now = Math.round(Date.now() / 1000);
  store.set(AUTH_TIME_KEY, now);
};

export const storeAccessToken = (token) => {
  store.set(ACCESS_TOKEN_KEY, token);
  Cookies.set(ACCESS_TOKEN_KEY, token, { domain: APP_DOMAIN });
  updateAuthTime();
};

export const removeStoredAccessToken = () => store.remove(ACCESS_TOKEN_KEY);

export const getStoredRefreshToken = () => store.get(REFRESH_TOKEN_KEY);

export const storeRefreshToken = (token) => store.set(REFRESH_TOKEN_KEY, token);

export const removeStoredRefreshToken = () => store.remove(REFRESH_TOKEN_KEY);

export const extractAccessToken = () => decoder(store.get(ACCESS_TOKEN_KEY));

export const getAuthTime = () => store.get(AUTH_TIME_KEY);
