import { createAction, createReducer } from 'redux-act';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {};

export const searchOrderAction = createAction('[order] search query changed');
export const orderListReducer = createReducer(
  {
    [searchOrderAction]: (state, query) => ({
      ...state,
      query
    }),
    [LOCATION_CHANGE]: () => initialState
  },
  initialState
);
