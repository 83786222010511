import { createReducer } from 'redux-act';
import { createActionCRUDResources } from 'shared/utils/reducerHelper';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading1: false,
  fetchLoading2: false,
  data1: [],
  data2: [],
  error1: false,
  error2: false,
  message1: '',
  message2: ''
};

const fetchSmartOutModeTable1 = createActionCRUDResources(
  'fetch smart out mode table 1'
);

const fetchSmartOutModeTable2 = createActionCRUDResources(
  'fetch smart out mode table 2'
);

export default createReducer(
  {
    [fetchSmartOutModeTable1.fetch.start]: (state) => ({
      ...state,
      fetchLoading1: true,
      error1: false
    }),
    [fetchSmartOutModeTable1.fetch.success]: (state, payload) => ({
      ...state,
      data1: payload,
      fetchLoading1: false,
      error1: false
    }),
    [fetchSmartOutModeTable1.fetch.error]: (state, payload) => ({
      ...state,
      message1: payload,
      error1: true,
      data1: [],
      fetchLoading1: false
    }),
    [fetchSmartOutModeTable2.fetch.start]: (state) => ({
      ...state,
      fetchLoading2: true,
      error2: false
    }),
    [fetchSmartOutModeTable2.fetch.success]: (state, payload) => ({
      ...state,
      data2: payload,
      fetchLoading2: false,
      error2: false
    }),
    [fetchSmartOutModeTable2.fetch.error]: (state, payload) => ({
      ...state,
      message2: payload,
      error2: true,
      data2: [],
      fetchLoading2: false
    })
  },
  initialState
);

export const fetchSmartOutModeTable1Action = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchSmartOutModeTable1.fetch.start());
      await delay(1000);
      dispatch(fetchSmartOutModeTable1.fetch.success());
    } catch (error) {
      dispatch(fetchSmartOutModeTable1.fetch.error());
    }
  };
};

export const fetchSmartOutModeTable2Action = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchSmartOutModeTable2.fetch.start());
      await delay(1000);
      dispatch(fetchSmartOutModeTable2.fetch.success());
    } catch (error) {
      dispatch(fetchSmartOutModeTable2.fetch.error());
    }
  };
};
