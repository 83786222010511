import styled from 'styled-components';
import { Select } from 'antd';

export const StyledCompanyDropdown = styled.div`
  .ant-select {
    width: calc(100% - 70px);
  }
  .ant-divider-vertical {
    margin: 0 2px;
  }
`;
export const StyledSelect = styled(Select)``;

export const StyledAction = styled.span`
  display: inline-block;
`;
