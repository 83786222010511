import { createAction, createReducer } from 'shared/utils/redux';

const initialState = {
  locale: 'en'
};

export const changeLocaleAction = createAction('changeLocaleAction');

export default createReducer(initialState, {
  [changeLocaleAction]: (state, { payload }) => ({
    ...state,
    locale: payload
  })
});
