import { createAction, createReducer } from 'redux-act';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading: false,
  data: [],
  error: false
};

const fetchTotalAllocationToSmart = createAction(
  'fetch total allocation to smart'
);
const fetchTotalAllocationToSmartSuccess = createAction(
  'fetch total allocation to smart success'
);
const fetchTotalAllocationToSmartError = createAction(
  'fetch total allocation to smart error'
);

export default createReducer(
  {
    [fetchTotalAllocationToSmart]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchTotalAllocationToSmartSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchTotalAllocationToSmartError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    })
  },
  initialState
);

export const fetchTotalAllocationToSmartAction = (values) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTotalAllocationToSmart());
      await delay(1000);
      dispatch(fetchTotalAllocationToSmartSuccess(values));
    } catch (error) {
      dispatch(fetchTotalAllocationToSmartError());
    }
  };
};
