/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Steps,
  Typography,
  Button,
  Upload,
  Select,
  Form,
  Row,
  Input,
  Col,
  message,
  Divider,
  Table
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { uniqueId, omitBy } from 'lodash';

import { csvParser } from 'shared/utils/parser';
import { saveFileAs } from 'shared/utils/fileHelper';
import CountrySelect from 'components/Commons/CountrySelect';
import Company from 'components/Commons/Company';
import Logo from 'assets/svg/logo.svg';
import { getSurvey, updateSurvey } from 'services/sourcing';
import { uploadSrcFile, submitSurvey } from 'services/vendor';
import {
  createCompany,
  updateCompany,
  getLoggedCompany
} from 'services/company';
import { getCurrentUser } from 'services/user';
import { getLocationType } from 'services/location';
import { dispatchService } from 'shared/utils';
import { PO_DETAIL_FIELDS, PO_ITEM_FIELDS } from '../config';
import {
  resetPurchaseOrder,
  loadPurchaseOrderAction,
  createPurchaseOrderAction
} from 'reducers/order/purchaseOrder';

const { Dragger } = Upload;

export default () => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [state, setState] = useState([]);
  const [loadedSurvey, setLoadedSurvey] = useState();
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [partners, setPartners] = useState([]);
  const [_, setCountryCode] = useState();
  const onCountryChange = (__, country) => {
    setState(country.state);
    setCountryCode(country.code);
  };
  const [newPartners, setNewPartners] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const poList = useSelector((s) => s.order.purchaseOrder.po);
  const loggedCompany = getLoggedCompany();
  const mapColumn = (col) => ({
    title: col.label,
    dataIndex: col.name,
    key: col.name,
    defaultValue: col.defaultValue,
    align: col.align ?? 'left'
  });
  const [detailColumns, setDetailColumns] = useState(
    PO_DETAIL_FIELDS.filter((col) => col.default).map(mapColumn)
  );
  const [itemColumns, setItemColumns] = useState(
    PO_ITEM_FIELDS.filter((col) => col.default).map(mapColumn)
  );
  const isPackagingItem = (field) => {
    return field.split(':').length > 1;
  };
  const getPoDetail = (data) => {
    return PO_DETAIL_FIELDS.reduce((acc, curr) => {
      acc[curr.name] = data[curr.name] ?? '';
      return acc;
    }, {});
  };
  const getItemDetail = (data) => {
    return PO_ITEM_FIELDS.reduce(
      (acc, curr) => {
        // is packaging item?
        if (isPackagingItem(curr.name)) {
          // using `{packageType}:{field}` for naming
          const fields = curr.name.split(':');
          if (!acc.packaging[fields[0]]) {
            acc.packaging[fields[0]] = {};
          }
          acc.packaging[fields[0]][fields[1]] = data[curr.name];
        } else {
          acc[curr.name] = data[curr.name] ?? '';
        }
        return acc;
      },
      {
        key: uniqueId(), // an unique key for antd table
        packaging: {} // initiate packaging object
      }
    );
  };

  useEffect(() => {
    dispatchService(getLocationType, setLocationTypes);
  }, []);

  const downloadLocationTemplate = () => {
    const header = [].concat([
      'name',
      'address',
      'postCode',
      'code',
      'city',
      'country',
      'state',
      'type'
    ]);
    const templateDataAsCsv = csvParser.unparse(
      [
        header,
        ['# -------------------- NOTES --------------------'],
        ['# DO NOT ADD ANY ROW BEFORE THE HEADER'],
        ['# All rows with a leading hashtag or a double flash are ignored'],
        ['#'],
        ['#'],
        [
          '# -------------------- ALL AVAILABLE LOCATION TYPES ----------------'
        ],
        ...locationTypes.map((type) => [`# ${type.type}`]),
        ['#'],
        ['#'],
        ['# -------------------- SAMPLE LOCATIONS  --------------------'],
        [
          'North Warehouse',
          '4241 Buck Drive',
          '84104',
          '',
          'Salt Lake City',
          'US',
          'Utah',
          'Warehouse / Distribution Center'
        ]
      ],
      {
        skipEmptyLines: true,
        comments: true,
        escapeChar: ''
      }
    );
    saveFileAs(templateDataAsCsv, 'netwila_location.csv');
  };
  const downloadPOTemplate = () => {
    const header = [].concat(
      detailColumns.map((column) => column.title),
      itemColumns.map((column) => column.title)
    );
    const sampleData = [].concat(
      detailColumns.map((column) => column.defaultValue),
      itemColumns.map((column) => column.defaultValue)
    );
    const templateDataAsCsv = csvParser.unparse([
      ['# -------------------- NOTES --------------------'],
      ['# All rows with a leading hashtag or a double flash are ignored'],
      ['#'],
      ['#'],
      ['# -------------------- EXAMPLE PURCHASE ORDERS ----------------'],
      [`#${header[0]}`, ...header.slice(1)],
      [`#${sampleData[0]}`, ...sampleData.slice(1)],
      ['#'],
      ['#'],
      ['# -------------------- PURCHASE ORDERS  --------------------'],
      header
    ]);
    saveFileAs(templateDataAsCsv, 'PoTemplate.csv');
  };
  const loadPO = (file) => {
    const poData = {};
    const splitPOs = {};
    dispatch(resetPurchaseOrder());
    csvParser.parse(file, {
      header: true,
      complete: ({ data }) => {
        // Mapping
        const mappingFields = [...detailColumns, ...itemColumns].reduce(
          (columns, column) => ({
            ...columns,
            [column.title]: column.key
          }),
          {}
        );
        data
          .map((item) => {
            return Object.keys(item).reduce(
              (obj, key) => ({
                ...obj,
                [mappingFields[key]]: item[key]
              }),
              {}
            );
          })
          .forEach((item) => {
            // getPoNumber
            const poEntryNumber = `${item['poNumber']}:${item['customerLoadNumber']}`;

            // init list of split purchase orders
            if (!splitPOs[item['poNumber']]) {
              splitPOs[item['poNumber']] = [];
            }

            // add the customer load number to the split purchase order
            if (
              splitPOs[item['poNumber']].indexOf(item['customerLoadNumber']) ===
              -1
            ) {
              splitPOs[item['poNumber']].push(item['customerLoadNumber']);
            }

            if (!poData[poEntryNumber]) {
              // get po detail
              const poDetail = getPoDetail(item);
              poData[poEntryNumber] = {
                ...poDetail,
                itemDetails: [],
                lineItems: [],
                key: uniqueId() // an unique key for antd table
              };
            }

            // get item detail
            const itemDetail = getItemDetail(item);
            poData[poEntryNumber].lineItems.push(itemDetail);
          });

        // filter slit purchase orders with multple load number
        // then modify po number with a sequence letter
        Object.keys(omitBy(splitPOs, (items) => items.length === 1)).forEach(
          (poNumber) => {
            splitPOs[poNumber].forEach((loadNumber, idx) => {
              poData[`${poNumber}:${loadNumber}`][
                'poNumber'
              ] = `${poNumber}-${String(idx).padStart(2, '0')}`;
            });
          }
        );

        dispatch(loadPurchaseOrderAction(Object.values(poData)));
      },
      error: () => {}
    });
  };
  const expandedRowRender = ({ lineItems }) => {
    return (
      <Table columns={itemColumns} dataSource={lineItems} pagination={false} />
    );
  };
  const loadLocation = (file) => {
    csvParser.parse(file, {
      header: true,
      complete: ({ data: loadLocations }) => {
        setLocations(
          loadLocations.map((loc, idx) => ({
            ...loc,
            id: idx
          }))
        );
      }
    });
  };
  const uploadSrcFileProps = (type) => ({
    multiple: false,
    name: 'file',
    showUploadList: false,
    customRequest: async ({ onSuccess, onError, file }) => {
      try {
        if (type === 'locationSrcFile') loadLocation(file);
        if (type === 'poSrcFile') loadPO(file);
        const formData = new FormData();
        formData.set('file', file);
        const response = await uploadSrcFile(type, formData);
        onSuccess(response.data);
        message.success(`File ${file.name} has been uploaded!`);
      } catch (err) {
        onError(err);
      }
    }
  });
  const submitSurveyAction = () => {
    dispatchService(
      () => {
        // Submit purchase orders
        const mappedPOList = poList.map((po) => {
          return {
            ...po,
            company: loggedCompany._id
          };
        });
        dispatch(createPurchaseOrderAction(mappedPOList));
        return submitSurvey({
          partners
        });
      },
      () => {
        dispatch(push('/orders/purchase-order'));
      }
    );
  };

  const updateSurveyAction = () => {
    dispatchService(() =>
      updateSurvey({
        partners
      })
    );
  };
  const onChangePartner = (e) => {
    setPartners([...partners, ...e]);
  };

  const onFinish = (formValues) => {
    // Call API to add partner
    dispatchService(
      () => createCompany(formValues),
      (resp) => {
        const createdCompany = resp[0];
        setNewPartners([
          ...newPartners,
          {
            value: createdCompany._id,
            label: createdCompany.legalName
          }
        ]);
        form.resetFields();
        dispatchService(
          () =>
            updateCompany({
              companyId: loadedSurvey.company,
              partners: [...loadedSurvey.partners, createdCompany._id]
            }),
          () => {
            message.success(
              `Added ${createdCompany.legalName} as your partner`
            );
            setPartners([...partners, createdCompany._id]);
          }
        );
      },
      () => message.error('Cannot add partner')
    );
  };

  const steps = [
    {
      content: () => (
        <>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 'normal', textAlign: 'center' }}
          >
            Provide Your Manufacturing, Distribution, and Ship point Locations
            (bulk import or form option)
          </Typography.Title>
          <Dragger {...uploadSrcFileProps('locationSrcFile')}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload
            </p>
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                downloadLocationTemplate();
              }}
              style={{ marginTop: 8 }}
            >
              Download Template CSV
            </Typography.Link>
          </Dragger>
          {locations.length > 0 && (
            <Table
              size='small'
              rowKey={'id'}
              pagination={false}
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name'
                },
                {
                  title: 'Address',
                  dataIndex: 'address'
                },
                {
                  title: 'Postal Code',
                  dataIndex: 'postCode'
                },
                {
                  title: 'City',
                  dataIndex: 'city'
                },
                {
                  title: 'Country',
                  dataIndex: 'country'
                },
                {
                  title: 'State',
                  dataIndex: 'state'
                }
              ]}
              dataSource={locations.slice(0, 10)}
            />
          )}
        </>
      )
    },
    {
      content: () => (
        <>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 'normal', textAlign: 'center' }}
          >
            Who are your current and prospective trading Partners?
          </Typography.Title>
          <Row>
            <Col span={12} offset={6}>
              <Form layout='vertical' form={form} onFinish={onFinish}>
                <Form.Item name='partners' label='Select your trading partners'>
                  <Company
                    mode='multiple'
                    placeholder='Search by company name'
                    onChange={onChangePartner}
                    partners={partners}
                    type='Shipper'
                  />
                </Form.Item>
                <Divider>
                  <b>Or enter your trading partner manually</b>
                </Divider>
                <Form.Item name='legalName' label='Trading Partner'>
                  <Input />
                </Form.Item>
                <Form.Item name={'country'} label='Country'>
                  <CountrySelect onChange={onCountryChange} />
                </Form.Item>
                <Form.Item name={'state'} label='State/Province'>
                  <Select
                    options={state.map((s) => ({
                      label: s.state,
                      value: s.code
                    }))}
                  />
                </Form.Item>
                <Form.Item name='city' label='City'>
                  <Input />
                </Form.Item>
                <Form.Item name='address' label='Address'>
                  <Input />
                </Form.Item>
                <Divider orientation='left'>Contact Information</Divider>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={['primaryContact', 'firstName']}
                      label='First Name'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['primaryContact', 'lastName']}
                      label='Last Name'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name={['primaryContact', 'email']} label='Email'>
                  <Input type='email' />
                </Form.Item>
                <Form.Item name={['primaryContact', 'phone']} label='Phone'>
                  <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                  <Button type='primary' htmlType='submit'>
                    Add Partner
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )
    },
    {
      content: () => (
        <>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 'normal', textAlign: 'center' }}
          >
            Upload Your Purchase Orders
          </Typography.Title>
          <Dragger {...uploadSrcFileProps('poSrcFile')}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag purchase order to this area to upload
            </p>
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                downloadPOTemplate();
              }}
              style={{ marginTop: 8 }}
            >
              Download Template CSV
            </Typography.Link>
          </Dragger>
          {poList && (
            <Table
              scroll={{ x: '100vw' }}
              rowClassName={() => 'editable-row'}
              columns={detailColumns}
              dataSource={poList}
              expandable={{ expandedRowRender }}
            />
          )}
        </>
      )
    }
  ];
  const next = () => {
    setCurrent(current + 1);
    updateSurveyAction();
  };
  const prev = () => {
    setCurrent(current - 1);
    updateSurveyAction();
  };

  useEffect(() => {
    dispatchService(
      () => getSurvey(),
      (_data) => {
        setLoadedSurvey(_data);
      }
    );
  }, []);

  useEffect(() => {
    dispatchService(
      () => getCurrentUser(),
      (resp) => {
        if (!resp) return;
        const user = resp[0];
        dispatch({
          type: 'getCurrentCompany_success',
          data: user.company
        });
        if (user.company.completedSurvey) {
          dispatch(push('/'));
        }
      }
    );
  }, []);

  return (
    <Layout
      style={{ backgroundColor: '#fff', paddingBottom: '32px', flex: 'none' }}
    >
      <Layout.Header>
        <img src={Logo} alt='' />
      </Layout.Header>
      <Layout.Content style={{ marginTop: 32, padding: '0 32px' }}>
        <Typography.Title style={{ textAlign: 'center' }} level={3}>
          Survey
        </Typography.Title>
        <p style={{ textAlign: 'center' }}>Tell us more about your business</p>
        <Steps
          current={current}
          size='small'
          labelPlacement='vertical'
          style={{ marginTop: 32 }}
        >
          <Steps.Step title='Locations' />
          <Steps.Step title='Trading Partner' />
          <Steps.Step title='Upload PO' />
        </Steps>
        <div className='steps-content' style={{ padding: '32px 0' }}>
          {steps[current].content()}
        </div>
        <div className='steps-action' style={{ textAlign: 'left' }}>
          {current < steps.length - 1 && (
            <Button type='primary' onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type='primary' onClick={submitSurveyAction}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px'
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};
