import { CardWrapper, CardTitle, CardBody, CardItem } from './Styles';

const CardLink = {
  CardWrapper,
  CardTitle,
  CardBody,
  CardItem
};

export default CardLink;
