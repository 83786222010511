import moment from 'moment-timezone';

const DEFAULT_TIMEZONE = 'America/Toronto';
export const formatDate = (
  date,
  format = 'MMMM D, YYYY',
  tz = DEFAULT_TIMEZONE
) => (date ? moment(date).tz(tz).format(format) : date);

export const formatUtcDate = (
  date,
  format = 'MMMM D, YYYY',
  tz = DEFAULT_TIMEZONE
) => (date ? moment.utc(date).tz(tz).format(format) : date);

/**
 * Convert utc date time to local date time
 * @param {*} date UTC Date Time
 * @param {*} format
 * @returns
 */
export const formatDateTime = (
  date,
  format = 'YYYY-MM-DD h:mm A'
  // tz = DEFAULT_TIMEZONE
) => (date ? moment(date).format(format) : date);

export const formatUtcDateTime = (
  date,
  format = 'YYYY-MM-DD h:mm a',
  tz = DEFAULT_TIMEZONE
) => (date ? moment.utc(date).tz(tz).format(format) : date);

export const formatDateTimeForAPI = (date) =>
  date ? moment(date).utc().format() : date;

export const formatDateTimeConversational = (date) =>
  date ? moment(date).fromNow() : date;

export const shortDate = (date) => {
  return date ? moment(date).format('MMM DD') : date;
};

export const isExpired = (date) => {
  if (!date) return false;
  const now = moment();
  const inputDate = moment(date);
  return now.diff(inputDate) >= 0;
};

export const timeFromX = (date) => {
  if (!date) return false;
  return moment(date).fromNow();
};
