import http from 'shared/utils/http';

export const getRole = async (companyId, count) => {
  const response = await http.api(
    '/api/role/data',
    'get',
    {
      companyId, count
    },
    //'http://localhost:3030'
  )
  return response.data;
};

export const createRole = async (data) => {
  const response = await http.api(
    '/api/role/data',
    'post',
    {
      ...data
    },
    //'http://localhost:3030'
  );
  return response.data;
};

export const updateRole = async (data) => {
  const response = await http.api(
    `/api/role/data/${data._id}`,
    'put',
    {
      name: data.name,
      description: data.description,
      features: data.features,
      companyId: data.companyId
    },
    //'http://localhost:3030'
  );
  return response.data;
};


/**
 * Get all access groups
 */
export const getAccessGroup = () => {
  return http.get('/netwila/access/group');
};
