// FOR COMPANY ACCOUNT REDUCER
import { orderBy } from 'lodash';
import { createAction, createReducer } from 'redux-act';
import { push } from 'connected-react-router';
import { routeConfig } from 'configs/route';

import { addNewLocation } from 'services/location';
import {
  getAccountTypes,
  createAccount,
  getAccounts,
  getLoggedAccount,
  getAllAccounts,
  getAccountByCompany
} from 'services/company';

const initialState = {
  isInitialized: false,
  isLoading: false,
  isCreatingAccount: false,
  isFetchingAccounts: false,
  isFetchingAccountsByCompany: false,
  accounts: [],
  accountTypes: [],
  loggedAccount: null,
  allAccounts: [], //For admin management
  isFetchingAllAccounts: false,
  isFetchingAccountById: false
};

const initializeAction = createAction('initiate account page');
const fetchHttpErrorAction = createAction('fetch http error');

const fetchAccountsStartAction = createAction('fetch accounts');
const fetchAccountsSuccessAction = createAction('fetch accounts success');
const fetchAccountsErrorAction = createAction('fetch account error');

const fetchAccountByIdStartAction = createAction('fetch account by id');
const fetchAccountByIdSuccessAction = createAction(
  'fetch account by id success'
);
const fetchAccountByIdErrorAction = createAction('fetch account by id error');

const fetchAllAccountsStartAction = createAction('fetch all accounts');
const fetchAllAccountsSuccessAction = createAction(
  'fetch all accounts success'
);
const fetchAllAccountsErrorAction = createAction('fetch all account error');

const fetchAccountsByStartAction = createAction('fetch accounts by company id');
const fetchAccountsBySuccessAction = createAction(
  'fetch accounts by company id success'
);
const fetchAccountsByErrorAction = createAction(
  'fetch accounts by company id error'
);

const createAccountAction = createAction('create new account');
const cancelCreatingAccountAction = createAction('cancel creating account');
const saveAccountStartAction = createAction('save new account start');
const saveAccountSuccessAction = createAction('save new account success');
const saveAccountErrorAction = createAction('save new account error');

const fetchAccountTypesSuccessAction = createAction(
  'fetch account type success'
);
const verifyLoggedAccountSuccessAction = createAction(
  'verify logged account success'
);

export default createReducer(
  {
    [initializeAction]: (state, isInitialized) => ({ ...state, isInitialized }),
    [fetchAccountsStartAction]: (state) => ({
      ...state,
      isLoading: true,
      isFetchingAccounts: true,
      accounts: []
    }),
    [fetchAccountsSuccessAction]: (state, accounts) => ({
      ...state,
      isLoading: false,
      isFetchingAccounts: false,
      accounts
    }),
    [fetchAccountsErrorAction]: (state) => ({
      ...state,
      isLoading: false,
      isFetchingAccounts: false
    }),
    [fetchAccountsByStartAction]: (state) => ({
      ...state,
      isFetchingAccountsByCompany: true
    }),
    [fetchAccountsBySuccessAction]: (state, { companyId, accounts }) => ({
      ...state,
      isFetchingAccountsByCompany: false,
      [companyId]: accounts
    }),
    [fetchAccountsByErrorAction]: (state) => ({
      ...state,
      isFetchingAccountsByCompany: false
    }),
    [createAccountAction]: (state) => ({ ...state, isCreatingAccount: true }),
    [cancelCreatingAccountAction]: (state) => ({
      ...state,
      isCreatingAccount: false
    }),
    [fetchAccountTypesSuccessAction]: (state, accountTypes) => ({
      ...state,
      accountTypes
    }),
    [saveAccountStartAction]: (state) => ({ ...state, isLoading: true }),
    [saveAccountSuccessAction]: (state) => ({
      ...state,
      isLoading: false,
      isCreatingAccount: false
    }),
    [saveAccountErrorAction]: (state) => ({ ...state, isLoading: false }),
    [verifyLoggedAccountSuccessAction]: (state, loggedAccount) => ({
      ...state,
      loggedAccount
    }),
    [fetchAllAccountsStartAction]: (state) => ({
      ...state,
      isFetchingAllAccounts: true
    }),
    [fetchAllAccountsSuccessAction]: (state, allAccounts) => ({
      ...state,
      isFetchingAllAccounts: false,
      allAccounts
    }),
    [fetchAllAccountsErrorAction]: (state) => ({
      ...state,
      isFetchingAllAccounts: false
    }),
    [fetchAccountByIdStartAction]: (state) => ({
      ...state,
      isFetchingAllAccounts: true
    }),
    [fetchAccountByIdSuccessAction]: (state, account) => ({
      ...state,
      isFetchingAllAccounts: false,
      ...account
    }),
    [fetchAccountByIdErrorAction]: (state) => ({
      ...state,
      isFetchingAllAccounts: false
    })
  },
  initialState
);

const fetchAccountsAction = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAccountsStartAction());
      const response = await getAccounts(companyId);
      dispatch(
        fetchAccountsSuccessAction(
          orderBy(response.data, ['updatedAt'], ['desc'])
        )
      );
    } catch (err) {
      dispatch(fetchAccountsErrorAction());
    }
  };
};

export const fetchAccountsByAction = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAccountsByStartAction());
      const response = await getAccounts(companyId);
      const accounts = orderBy(response.data, ['updatedAt'], ['desc']);
      dispatch(fetchAccountsBySuccessAction({ companyId, accounts }));
    } catch (error) {
      dispatch(fetchAccountsByErrorAction());
    }
  };
};

export const fetchAllAccountsAction = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchAllAccountsStartAction());
      const response = await getAllAccounts({ limit: 1000 });
      const accounts = orderBy(response.data, ['updatedAt'], ['desc']);
      dispatch(fetchAllAccountsSuccessAction(accounts));
    } catch (error) {
      dispatch(fetchAllAccountsErrorAction());
    }
  };
};

export const fetchAccountByIdAction = (accountId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAccountByIdStartAction());
      const response = await getAllAccounts({ accountId });
      dispatch(
        fetchAccountByIdSuccessAction({ [accountId]: response.data[0] })
      );
    } catch (error) {
      dispatch(fetchAccountByIdErrorAction());
    }
  };
};

const fetchAccountTypesAction = () => {
  return async (dispatch) => {
    try {
      const response = await getAccountTypes();
      dispatch(fetchAccountTypesSuccessAction(response.data));
    } catch (err) {
      dispatch(fetchHttpErrorAction());
    }
  };
};

const saveAccountAction = (data) => {
  return async (dispatch) => {
    dispatch(saveAccountStartAction());
    try {
      //1. Create new location
      const location = await addNewLocation({
        address: data.address,
        city: data.city,
        postCode: data.postCode,
        state: data.state,
        country: data.country
      });
      const locationId = location.data[0]._id;
      //2. Add account
      await createAccount({
        enable: true,
        accountType: data.accountType,
        company: data.company,
        companyType: data.companyType,
        mode: data.mode,
        location: locationId,
        manageBy: data.manageBy
      });
      dispatch(saveAccountSuccessAction());
      // Then back to the account page
    } catch (err) {
      dispatch(saveAccountErrorAction());
    }
  };
};

export const addNewAccountAction = (account) => {
  return async () => {
    const response = await createAccount(account);
    return response.data;
  };
};

export const fetchAccountsByCompanyAction = (companyId, params = {}) => {
  return async () => {
    try {
      const response = await getAccountByCompany(companyId, params);
      return response.data;
    } catch (error) {
      return [];
    }
  };
};

const verifyAccountSelectionAction = () => {
  return async (dispatch) => {
    const loggedAccount = getLoggedAccount();
    if (loggedAccount) {
      dispatch(verifyLoggedAccountSuccessAction(loggedAccount));
      return Promise.resolve();
    }

    return dispatch(push(routeConfig.ACCOUNT_SELECTION));
  };
};

export {
  fetchAccountsAction,
  fetchAccountTypesAction,
  saveAccountAction,
  verifyAccountSelectionAction,
  initializeAction,
  createAccountAction,
  cancelCreatingAccountAction
};
