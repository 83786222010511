import React from 'react';
import PropTypes from 'prop-types';
import ProgressBarItem from './ProgressBarItem';
import { Container, Label, ProgressBarGroupContainer } from './Styles';

const propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string
};

const defaultProps = {
  max: 100,
  color: '#19466e',
  background: '#989898'
};

const ProgressBar = (props) => {
  const { value, max, color, background, width } = props;
  const maxForBarItem = max / 4;

  const getBarScores = (valueString) => {
    const quotient = Math.floor(valueString / maxForBarItem);
    const remainder = valueString % maxForBarItem;
    let values = [];
    for (let i = 0; i < 4; i++) {
      if (i < quotient) {
        values.push(maxForBarItem);
      } else if (i === quotient) {
        values.push(remainder);
      } else {
        values.push(0);
      }
    }
    return values;
  };

  return (
    <Container>
      <ProgressBarGroupContainer width={width}>
        {getBarScores(value).map((eachValue, index) => {
          return (
            <ProgressBarItem
              key={index}
              value={eachValue}
              max={maxForBarItem}
              color={color}
              background={background}
            />
          );
        })}
      </ProgressBarGroupContainer>
      <Label>{(value / max) * 100}% Completed</Label>
    </Container>
  );
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;
export default ProgressBar;
