import { createAction, createReducer } from 'redux-act';

import {
  getProductTypes,
  getProductCategory,
  createProduct,
  getProduct
} from 'services/vendorServices';

const initialState = {
  productTypes: [],
  productCategory: [],
  productData: [],
  newProductData: null
};
const getProductTypesSuccess = createAction('getProductTypesSuccess');
const getProductCategorySuccess = createAction('getProductCategorySuccess');
const addProductSuccess = createAction('addProductSuccess');
const getProductSuccess = createAction('getProductSuccess');

export default createReducer(
  {
    [getProductTypesSuccess]: (state, productTypes) => ({
      ...state,
      productTypes
    }),
    [getProductCategorySuccess]: (state, productCategory) => ({
      ...state,
      productCategory
    }),
    [addProductSuccess]: (state, newProductData) => ({
      ...state,
      newProductData
    }),
    [getProductSuccess]: (state, productData) => ({
      ...state,
      productData
    })
  },
  initialState
);

export const getProductTypesAction = () => async (dispatch) => {
  try {
    const response = await getProductTypes();
    dispatch(getProductTypesSuccess(response.data));
  } catch (err) {
    /** */
  }
};

export const getProductCategoryAction = () => async (dispatch) => {
  try {
    const response = await getProductCategory();
    dispatch(getProductCategorySuccess(response.data));
  } catch (err) {
    /** */
  }
};

export const getProductAction = () => async (dispatch) => {
  try {
    const response = await getProduct();
    dispatch(getProductSuccess(response.data));
  } catch (err) {
    /** */
  }
};

export const addProductAction = (data) => async (dispatch) => {
  try {
    const response = await createProduct(data);
    dispatch(addProductSuccess(response.data));
  } catch (err) {
    /** */
  }
};
