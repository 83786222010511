import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// DO NOT KNOW WHY ESLINT DOES NOT UNDERSTAND THIS IMPORT
// I WANNA USE THIS ACTION NAME, THEN I HAVE TO DISABLE ESLINE FOR NEXT LINE
// eslint-disable-next-line import/named
import { logoutAction } from 'reducers/authentication';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'reset' });
    dispatch(logoutAction());
  }, [dispatch]);

  return <></>;
};
