import {
  getModes,
  getModeMoves,
  getLoadType,
  getTemperatureType
} from 'services/mode';
import { uniqBy } from 'lodash';

const initialState = {
  modes: [],
  modeServices: [],
  modeEquipments: [],
  isFetchingModes: false,
  modeMoves: [],
  isFetchingModeMoves: false,
  loadType: [],
  isFetchingLoadType: false,
  temperatureType: [],
  isFetchingTemperatureType: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getModes_start':
      return {
        ...state,
        isFetchingModes: true
      };
    case 'getModes_success':
      return {
        ...state,
        modes: action.modes,
        modeServices: action.modeServices,
        modeEquipments: action.modeEquipments,
        isFetchingModes: false
      };
    case 'getModes_error':
      return {
        ...state,
        isFetchingModes: false
      };
    case 'getModeMoves_start':
      return {
        ...state,
        isFetchingModeMoves: true
      };
    case 'getModeMoves_success':
      return {
        ...state,
        modeMoves: action.modeMoves,
        isFetchingModeMoves: false
      };
    case 'getModeMoves_error':
      return {
        ...state,
        isFetchingModeMoves: false
      };
    case 'getLoadType_start':
      return {
        ...state,
        isFetchingLoadType: true
      };
    case 'getLoadType_success':
      return {
        ...state,
        loadType: action.loadType,
        isFetchingLoadType: false
      };
    case 'getLoadType_error':
      return {
        ...state,
        isFetchingLoadType: false
      };
    case 'getTemperatureType_start':
      return {
        ...state,
        isFetchingTemperatureType: true
      };
    case 'getTemperatureType_success':
      return {
        ...state,
        temperatureType: action.temperatureType,
        isFetchingTemperatureType: false
      };
    case 'getTemperatureType_error':
      return {
        ...state,
        isFetchingTemperatureType: false
      };
    default:
      return state;
  }
};

const isModeServicesExisted = (state) => {
  const { modes } = state.mode;
  if (modes.length === 0) {
    return true;
  }
  return false;
};

export const getModeAction = () => async (dispatch, getState) => {
  if (!isModeServicesExisted(getState())) {
    return Promise.resolve();
  }
  try {
    dispatch({ type: 'getModes_start' });
    const response = await getModes();
    let modeServices = [];
    let modeEquipments = [];
    response.data.forEach((mode) => {
      modeServices.push(...mode.modeService);
      mode.modeType.forEach((type) => {
        modeEquipments.push(...type.modeEquipment);
      });
    });

    dispatch({
      type: 'getModes_success',
      modes: response.data,
      modeServices: uniqBy(modeServices, '_id'),
      modeEquipments: uniqBy(modeEquipments, '_id')
    });
  } catch (error) {
    dispatch({ type: 'getModes_error' });
  }
};

export const getModeMoveAction = () => async (dispatch) => {
  try {
    dispatch({ type: 'getModeMoves_start' });
    const response = await getModeMoves();
    dispatch({ type: 'getModeMoves_success', modeMoves: response.data });
  } catch (error) {
    dispatch({ type: 'getModeMoves_error' });
  }
};

export const getLoadTypeAction = () => async (dispatch) => {
  try {
    dispatch({ type: 'getLoadType_start' });
    const response = await getLoadType();
    dispatch({ type: 'getLoadType_success', loadType: response.data });
  } catch (error) {
    dispatch({ type: 'getLoadType_error' });
  }
};

export const getTemperatureTypeAction = () => async (dispatch) => {
  try {
    dispatch({ type: 'getTemperatureType_start' });
    const response = await getTemperatureType();
    dispatch({
      type: 'getTemperatureType_success',
      temperatureType: response.data
    });
  } catch (error) {
    dispatch({ type: 'getTemperatureType_error' });
  }
};
