import http from '../shared/utils/http';

export const loginOrder = async (_data) => {
  // const response = await http.api('netwila/company', 'post', { ...data });
  return {
    message: 'success'
  };
  // return response.data;
};

export const fetchCompanyWithGroupDivision = async (params = {}) => {
  const response = await http.api('netwila/company', 'get', { ...params });

  return response.data;
};

export const fetchCompanyType = async () => {
  const response = await http.api('netwila/company/type');

  return response.data;
};

export const fetchVendors = async () => {
  //TODO get type vendor
  const response = await http.api('netwila/company');

  return response.data;
};

export const fetchCarriers = async (companyType) => {
  //Road | Rail | Ocean Carriers get id from fetchCompanyType
  const response = await http.api(`netwila/company`, 'get', {
    companyType
  });

  return response.data;
};

export const createOrder = async (data, shippingMode) => {
  const relatedShippingModeAPIType = {
    ocean: 'oceanorder',
    road: 'externalnonrailtruck',
    rail: 'externalrailorderentry'
  };
  const response = await http.api(
    `/api/order/${relatedShippingModeAPIType[shippingMode]}`,
    'post',
    {
      ...data,
      companyId: '0'
    }
  );

  return response.data;
};

export const fetchOrderData = async (id, shippingMode) => {
  const relatedShippingModeAPIType = {
    ocean: 'oceanorder',
    road: 'externalnonrailtruck',
    rail: 'externalrailorderentry'
  };
  const response = await http.api(
    `api/order/${relatedShippingModeAPIType[shippingMode]}/${id}`,
    'get'
  );

  return response.data;
};

export const updateOrder = async (id, data, shippingMode) => {
  const relatedShippingModeAPIType = {
    ocean: 'oceanorder',
    road: 'externalnonrailtruck',
    rail: 'externalrailorderentry'
  };
  const response = await http.api(
    `api/order/${relatedShippingModeAPIType[shippingMode]}/${id}`,
    'put',
    {
      ...data
    }
  );

  return response.data;
};

export const fetchGroupDivision = async (params = {}) => {
  const response = await http.api('hr-hierarchy/division', 'get', {
    ...params
  });
  return response.data;
};
