import http from 'shared/utils/http';

/**
 *
 * @param {Object} filter
 * @example filter = { status: 'draft', type: 'tarrif' }
 */
export const getHierarchys = (dptId) => {
  return http.get(`hr-hierarchy/user-hierarchy?department_id=${dptId}`);
};

/**
 *
 * @param {Object} filter
 * @example filter = { status: 'draft', type: 'tarrif' }
 */
export const getAllUsers = () => {
  return http.get(`hr-hierarchy/user-hierarchy`);
};

/**
 *
 * @param userId
 * @example userId = string
 */
export const getSelectedHierarchy = (dptId) => {
  return http.get(`hr-hierarchy/user-hierarchy/tree?department_id=${dptId}`);
};

/**
 *
 * @param {Object} filter
 * @example filter = { status: 'draft', type: 'tarrif' }
 */
export const getDepartment = (companyId) => {
  return http.get(`hr-hierarchy/department?company_id=${companyId}`);
};

export const getRoles = () => {
  return http.get(`hr-hierarchy/user-role`);
};

export const getCompanyType = () => {
  return http.get(`hr-hierarchy/company-type`);
};

export const getCategories = () => {
  return http.get(`hr-hierarchy/industry-categories`);
};

export const getCodes = (type) => {
  return http.get(`hr-hierarchy/codes?code_type=${type}`);
};


/**
 *
 * @param {Number} id
 * @param {Object} Hierarchy
 */
export const create = (type, jsonBody) => {
  return http.post(`hr-hierarchy/${type}`, jsonBody);
};

export const createRole = (jsonBody) => {
  return http.post(`hr-hierarchy/user-role`, jsonBody);
};
