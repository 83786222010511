import toast from 'shared/utils/toast';
import { createAction, createReducer } from 'redux-act';
import { fetchHSCode, createHSCode, deleteHSCode } from 'services/hCode';

const initialState = {
  fetchLoading: false,
  createLoading: false,
  data: [],
  error: false,
  createError: false,
  deleteError: {},
  deleteLoading: {}
};

const fetchHSCodeStart = createAction('fetch hsCode');
const fetchHSCodeSuccess = createAction('fetch hsCode success');
const fetchHSCodeError = createAction('fetch hsCode error');
const createHSCodeStart = createAction('create hsCode');
const createHSCodeSuccess = createAction('create hsCode success');
const createHSCodeError = createAction('create hsCode error');

const deleteHSCodeStart = createAction('delete hsCode');
const deleteHSCodeSuccess = createAction('delete hsCode success');
const deleteHSCodeError = createAction('delete hsCode error');

export default createReducer(
  {
    [fetchHSCodeStart]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchHSCodeSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchHSCodeError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      fetchLoading: false
    }),
    [createHSCodeStart]: (state) => ({
      ...state,
      createLoading: true
    }),
    [createHSCodeSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      createLoading: false
    }),
    [createHSCodeError]: (state) => ({
      ...state,
      createError: true
    }),
    [deleteHSCodeStart]: (state, payload) => ({
      ...state,
      deleteLoading: {
        ...state.deleteLoading,
        [payload.id]: true
      }
    }),
    [deleteHSCodeSuccess]: (state, payload) => ({
      ...state,
      data: payload.data,
      deleteLoading: {
        ...state.deleteLoading,
        [payload.id]: false
      }
    }),
    [deleteHSCodeError]: (state, payload) => ({
      ...state,
      deleteError: {
        ...state.deleteError,
        [payload.id]: true
      },
      deleteLoading: {
        ...state.deleteLoading,
        [payload.id]: false
      }
    })
  },
  initialState
);

export const fetchHSCodeAction = (companyId) => {
  return async (dispatch) => {
    dispatch(fetchHSCodeStart());
    try {
      const data = await fetchHSCode(companyId);
      dispatch(fetchHSCodeSuccess(data.data));
      return data.data;
    } catch (error) {
      dispatch(fetchHSCodeError('Fetching error'));
      toast.error(`Error occurred when fetching HS Code`);
    }
  };
};

export const createHSCodeAction = (data) => {
  return async (dispatch) => {
    dispatch(createHSCodeStart());
    try {
      const res = await createHSCode(data);
      if (res) {
        const refetchData = await fetchHSCode(data.companyId);
        dispatch(createHSCodeSuccess(refetchData.data));
      }
      return res.data;
    } catch (error) {
      dispatch(fetchHSCodeError('Fetching error'));
      toast.error(`Error occurred when create HS Code`);
    }
  };
};

export const deleteHSCodeAction = (data) => {
  return async (dispatch) => {
    dispatch(deleteHSCodeStart({ id: data.id }));
    try {
      const res = await deleteHSCode(data.id);
      if (res) {
        const refetchData = await fetchHSCode(data.companyId);
        dispatch(deleteHSCodeSuccess({ id: data.id, data: refetchData.data }));
        toast.success('Delete HS Code successfully');
        return true;
      }
    } catch (error) {
      dispatch(deleteHSCodeError('Fetching error'));
      toast.error(`Error occurred when delete HS Code`);
    }
    return false;
  };
};
