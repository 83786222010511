import http from 'shared/utils/http';

export const fetchData = (companyId, param = {}) => {
  return http.get(`/integrated-shipping/kpi-setup`, {
    companyId,
    ...param
  });
};

export const createData = (companyId, data) => {
  return http.post(`/integrated-shipping/kpi-setup`, {
    companyId,
    ...data
  });
};

export const updateDataById = (id, companyId, data) => {
  return http.put(`/integrated-shipping/kpi-setup/${id}`, {
    companyId,
    ...data
  });
};

export const deleteDataById = (id) => {
  return http.delete(`/integrated-shipping/kpi-setup/${id}`);
};
