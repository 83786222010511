import http from 'shared/utils/http';

export const getPreUploadUrl = (filename, model) => {
  return http.get(`/netwila-core/ai/pre-upload-url`, { filename, model });
};

export const getSessions = (params) => {
  return http.get('/netwila-core/ai/sessions', params);
};

export const runSarimaModel = (data) => {
  return http.post('/netwila-core/ai/sarima', data);
};

export const getSarimaSessions = () => {
  return http.get('/netwila-core/ai/sarima/sessions');
};

export const getSarimaSession = (id) => {
  return http.get(`/netwila-core/ai/sarima/session/${id}`);
};

export const getRecentSarimaSession = () => {
  return http.get('/netwila-core/ai/sarima/session/recent');
};

export const stopSarimaSession = (id) => {
  return http.delete(`/netwila-core/ai/sarima/session/${id}`);
};

export const getOutputLink = (s3Uri) => {
  return http.get('/netwila-core/ai/output-link', { s3Uri });
};

export const runCmoveModel = (data) => {
  return http.post('/netwila-core/ai/cmove', data);
};

export const runBlockModel = (data) => {
  return http.post('/netwila-core/ai/block', data);
};

export const runSgVrpModel = (data) => {
  return http.post('/netwila-core/ai/sgvrp', data);
};

export const runAcsModel = (data) => {
  return http.post('/netwila-core/ai/acs', data);
};

export const runTransloadingModel = (data) => {
  return http.post('/netwila-core/ai/transloading', data);
};

export const runCraneSchedulingModel = (data) => {
  return http.post('/netwila-core/ai/crane-scheduling', data);
};

export const runCrewSchedulingModel = (data) => {
  return http.post('/netwila-core/ai/crew-scheduling', data);
};

export const runCarbonModel = (data) => {
  return http.post('/netwila-core/ai/carbon', data);
};

export const runMultiPeriodModel = (data) => {
  return http.post('/netwila-core/ai/multi-period', data);
};

export const deleteSession = (id) => {
  return http.delete(`/netwila-core/ai/session/${id}`);
};

export const getTransloads = () => {
  return http.get(`/netwila-core/transloading/transloads`);
};
