import { saveAs } from 'file-saver';
import { csvParser } from './parser';

const FileMIMEType = {
  PLAIN: 'text/plain',
  HTML: 'text/html',
  XML: 'application/xml',
  JSON: 'application/json',
  OCTET: 'application/octet-stream'
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
const Ext2MimeTypeMap = {
  aac: 'audio/aac',
  abw: 'application/x-abiword',
  arc: 'application/x-freearc',
  avi: 'video/x-msvideo',
  azw: 'application/vnd.amazon.ebook',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  bz: 'application/x-bzip',
  bz2: 'application/x-bzip2',
  csh: 'application/x-csh',
  css: 'text/css',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg'
};

export const getFileUrl = (path) =>
  `${process.env.REACT_APP_CDN_BASE_URL}${path}`;
export const getDocumentUrl = (path) =>
  `${process.env.REACT_APP_FILE_BASE_URL}${path}`;
export const saveFileAs = (data, fileName, fileType = FileMIMEType.PLAIN) => {
  const blob = new Blob([data], {
    type: fileType
  });
  saveAs(blob, fileName);
};
export const saveFileFromUrl = (url, fileType) => {
  saveAs(url, fileType);
};

export const genDocumentPath = (whereId, where, fileName) =>
  `/document/${where}/${whereId}/${fileName}`.toLowerCase();

export const genFileName = (fileName) =>
  fileName.replace(/[^a-zA-Z0-9-.]+/g, '-').toLowerCase();

export const getFileExtension = (fileName) => {
  const lastIdxOfDot = fileName.lastIndexOf('.');
  if (lastIdxOfDot === -1) return fileName;
  return fileName.slice(lastIdxOfDot + 1);
};

export const getMimeType = (fileName) => {
  const fileExt = getFileExtension(fileName);
  return Ext2MimeTypeMap[fileExt] ?? FileMIMEType.PLAIN;
};

export const getFileName = (filePath) => {
  const lastIdxOfFlash = filePath.lastIndexOf('/');
  if (lastIdxOfFlash === -1) return filePath;
  return filePath.slice(lastIdxOfFlash + 1);
};

export const genTemplateFile = (filename, header, data) => {
  const templateDataAsCsv = csvParser.unparse([
    ['# -------------------- NOTES --------------------'],
    ['# All rows with a leading hashtag or a double flash are ignored'],
    ['#'],
    ['#'],
    ['# -------------------- START EXAMPLE ----------------'],
    [`#${header[0]}`, ...header.slice(1)],
    [`#${data[0]}`, ...data.slice(1)],
    ['#'],
    ['#'],
    ['# -------------------- END EXAMPLE  --------------------'],
    header
  ]);
  saveFileAs(templateDataAsCsv, filename);
};
