import styled from 'styled-components';
import { Layout, Card } from 'antd';

import { color } from 'shared/utils/styles';

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  justify-content: center;
  background-color: ${color.primary};
`;

export const ShippingMode = styled(Card)`
  margin-top: 5px;
  border-radius: 4px;

  .ant-card-cover {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .ant-card-meta-title {
    text-transform: uppercase;
  }

  .ant-card-body {
    height: 30px;
    padding: 4px 0;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .ant-card-meta {
      margin: 0;
    }

    .ant-card-meta-title {
      overflow: auto;
      text-overflow: inherit;
      white-space: normal;
      font-size: 12px;
      font-weight: bold;
      color: #666666;
    }

    .integrated_shipping,
    .ports {
      padding: 0 4px;
    }
  }

  &.active {
    border-color: transparent;
    color: white;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    background-color: #55a105;

    .ant-card-meta-title {
      color: white;
    }
  }

  &.integrated_shipping,
  &.ports {
    display: flex;
    align-items: center;

    img {
      width: 153px;
    }
  }
`;

export const ShippingType = styled(Card)`
  .ant-card-body {
    padding: 0;
    height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: #666666;
  }

  &.active {
    .ant-card-body {
      background-color: #55a105;
      color: white;
    }
  }
`;
