/* eslint-disable no-unused-vars */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Select, Typography, AutoComplete } from 'antd';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { dispatchService } from 'shared/utils/service';
import { searchCompany, searchCompanyPartner } from 'services/company';

/**
 * This component should not be controlled by Antd Form component
 * The location should be getting via onSelect event
 * @param {*} param0
 * @param {*} ref
 * @returns
 */
const CompanyPicker = (
  {
    initialValue,
    type,
    onSelect,
    onChange,
    partner = false,
    disabled = false,
    ...props
  },
  ref
) => {
  const [companies, setCompanies] = useState(
    initialValue
      ? [
          {
            value: initialValue?.id,
            label: initialValue?.legalName
          }
        ]
      : []
  );
  const [loading, setLoading] = useState(false);

  const getCompanyAction = (q) => {
    setLoading(true);
    const fetchApi = partner ? searchCompanyPartner : searchCompany;
    return dispatchService(
      () =>
        fetchApi({
          name: q,
          companyType: type && [type]
        }),
      (data) =>
        setCompanies(
          data.map((company) => ({
            value: company.id,
            label: company.legalName,
            key: company.id,
            company
          }))
        ),
      () => {},
      () => setLoading(false)
    );
  };

  const onSearch = () => {
    const updateFilter = (q) => {
      getCompanyAction(q);
    };
    return debounce(updateFilter, 300);
  };

  useEffect(getCompanyAction, []);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      optionFilterProp='label'
      options={companies}
      onSelect={(e) => {
        if (onSelect) {
          const company = companies.find((p) => p.value === e);
          onSelect(company);
        }
      }}
      defaultValue={initialValue?.id}
      ref={ref}
      onSearch={onSearch()}
      onChange={onChange}
    />
  );
};

export default React.forwardRef(CompanyPicker);
