/* eslint-disable quotes */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { FontStyled } from './Styles';

// Please check the netwila.svg file in assets to change the code
// for matching the icon

const codes = {
  [`check-circle`]: '\\e92d',
  [`check-fat`]: '\\f00c',
  [`arrow-left`]: '\\e912',
  [`arrow-right`]: '\\e923',
  [`add-file`]: '\\e92f',
  [`upload-thin`]: '\\e91f',
  [`bell`]: '\\e913',
  [`bid`]: '\\e9a8',
  [`calendar`]: '\\e915',
  [`check`]: '\\e916',
  [`chevron-down`]: '\\e917',
  [`chevron-left`]: '\\e918',
  [`chevron-right`]: '\\e919',
  [`chevron-up`]: '\\e91a',
  [`clock`]: '\\e91b',
  [`watch`]: '\\e92e',
  [`download`]: '\\e91c',
  [`plus`]: '\\e91e',
  [`refresh`]: '\\e91f',
  [`search`]: '\\e906',
  [`upload`]: '\\e930',
  [`close`]: '\\e921',
  [`archive`]: '\\e926',
  [`briefcase`]: '\\e927',
  [`settings`]: '\\e914',
  [`email`]: '\\e925',
  [`email-open`]: '\\f2b7',
  [`lock`]: '\\e924',
  [`dashboard`]: '\\e928',
  [`alert`]: '\\e922',
  [`edit`]: '\\e929',
  [`delete`]: '\\e92a',
  [`sort`]: '\\f0dc',
  [`sort-up`]: '\\f0d8',
  [`sort-down`]: '\\f0d7',
  [`euro`]: '\\f153',
  [`eye`]: '\\e934',
  [`folder-plus`]: '\\e932',
  [`folder-minus`]: '\\e931',
  [`file`]: '\\e91d',
  [`like`]: '\\e92b',
  [`dislike`]: '\\e92c',
  [`file-text`]: '\\e935',
  [`logistics`]: '\\e90d',
  [`sys-admin`]: '\\e901',
  [`operations`]: '\\e903',
  [`asset`]: '\\e909',
  [`people`]: '\\e904',
  [`overview`]: '\\e90a',
  [`netwila-logo`]: '\\e90f',
  [`up-arrow-triple`]: '\\e900',
  [`hamburger-menu`]: '\\e905',
  [`shape`]: '\\e907',
  [`caret-up`]: '\\e90b',
  [`caret-down`]: '\\e90c',
  [`yard`]: '\\e90e',
  [`all`]: '\\e911',
  [`document`]: '\\e937',
  [`nw-download`]: '\\e938',
  [`nw-file`]: '\\e93a',
  [`high-priority`]: '\\e93b',
  [`hierarchy`]: '\\e93c',
  [`information`]: '\\e93d',
  [`key`]: '\\e93e',
  [`low-priority`]: '\\e93f',
  [`medium-priority`]: '\\e940',
  [`new`]: '\\e941',
  [`reload`]: '\\e942',
  [`right-arrow`]: '\\e943',
  [`status-button`]: '\\e944',
  [`destination`]: '\\e946',
  [`start`]: '\\e947'
};

const propTypes = {
  type: PropTypes.oneOf(Object.keys(codes)).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  left: PropTypes.number,
  top: PropTypes.number
};

const defaultProps = {
  className: undefined,
  size: 16,
  left: 0,
  top: 0,
  color: 'inherit'
};

const Icon = forwardRef(({ type, ...iconProps }, ref) => {
  return <FontStyled ref={ref} {...iconProps} code={codes[type]} />;
});

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
