import { combineReducers } from 'redux';
import locationReducer from './location';
import companyReducer from './company';
import locationTypeReducer from './locationType';

export default combineReducers({
  location: locationReducer,
  company: companyReducer,
  locationType: locationTypeReducer
});
