/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icon, Modal } from 'shared/components';
import { sidebarConfig } from 'configs/menu';
import { StyledLogo, LogoLink, Bottom } from './Styles';
import { useModuleMatch } from 'shared/hooks/useModuleMatch';

import QuickAccessModal from '../QuickAccessModal';

const Sidebar = ({ onCollapse }) => {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isBreakPoint, setBreakPoint] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const { moduleName } = useModuleMatch();
  const { location } = useSelector((state) => state.router);
  const path = useLocation();

  const collapsed = useMemo(() => {
    // Hard coding for build my network
    if (
      path.pathname === '/company-network' ||
      path.pathname === '/dashboard/forwarding' ||
      path.pathname === '/dashboard/forwarding/all'
    ) {
      return true;
    }
    return isBreakPoint;
  }, [path, isBreakPoint]);

  const onBreakPoint = (breakpoint) => {
    setBreakPoint(breakpoint);
  };

  useEffect(() => {
    onCollapse(collapsed);
    setCollapsed(collapsed);
  }, [onCollapse, collapsed]);

  const onSidebarCollapsed = (_collapsed) => {
    setCollapsed(_collapsed);
    onCollapse(_collapsed);
  };

  const hasRole = (role) => {
    if (!role) return true;
    if (typeof role === 'string') return currentUser.role === role;
    if (typeof role === 'object' && role.length > 0)
      return role.indexOf(role) !== -1;
    return false;
  };

  const hasPermission = (menuPermission) => {
    if (!menuPermission) return true;
    return currentUser.permissions.some((permission) => {
      const [access, subAccess] = permission.split('.');
      const [_access, _subAccess] = menuPermission.split('.');
      if (access === _access && subAccess === '*') return true;
      if (access === _access && subAccess === _subAccess) return true;
      return false;
    });
  };

  const getUserMenus = (menus) => {
    return menus.filter((menu) => {
      /**
       * - Menu will be displayed if function was not configured
       * - Menu will be displayed if logged user if right permissions
       */
      const hasRoleAccess = hasRole(menu.role);
      const hasMenuAccess =
        currentUser.isSystemAdmin || hasPermission(menu.permission);
      /**
       * - Menu will be displayed if additional condition was not configured
       * - Menu will be displayed if additional condition return true
       */
      const hasAdditionalAccess =
        !menu.additionalCondition || menu.additionalCondition?.(currentUser);

      if (menu.role !== undefined) {
        if (hasRoleAccess) return true;
      }

      if (hasMenuAccess && hasAdditionalAccess) {
        return true;
      }

      return false;
    });
  };

  return (
    <Layout.Sider
      width={160}
      breakpoint='md'
      collapsedWidth='70'
      collapsed={collapsed}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 999
      }}
      onCollapse={onSidebarCollapsed}
      onBreakpoint={onBreakPoint}
    >
      <LogoLink to='/'>
        <StyledLogo hidden={collapsed} type='netwila-logo' />
      </LogoLink>
      <Menu
        mode='inline'
        theme='dark'
        selectable={false}
        inlineIndent={16}
        selectedKeys={[location.pathname]}
      >
        {sidebarConfig
          .filter((sidebar) => sidebar.module === moduleName)
          .map((sidebar) => getUserMenus(sidebar.menus))
          .map((menus) => {
            return menus.map((menu) =>
              menu.subMenus ? (
                <Menu.SubMenu
                  key={menu.to}
                  icon={
                    <Icon
                      className='menu-icon'
                      type={menu.icon}
                      size={menu.size}
                    />
                  }
                  title={
                    menu.to ? (
                      <Link to={menu.to}>
                        <span>{t(menu.title)}</span>
                      </Link>
                    ) : (
                      <span>{t(menu.title)}</span>
                    )
                  }
                >
                  {menu.subMenus
                    ? getUserMenus(menu.subMenus).map((subMenu) => (
                        <Menu.Item
                          key={subMenu.title}
                          icon={
                            <Icon
                              className='menu-icon'
                              type={subMenu.icon}
                              size={subMenu.size}
                            />
                          }
                        >
                          <Link to={`${subMenu.to}`}>{t(subMenu.title)}</Link>
                        </Menu.Item>
                      ))
                    : ''}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={menu.to}
                  icon={<Icon className='menu-icon' type={menu.icon} />}
                >
                  <Link to={`${menu.to}`}>{t(menu.title)}</Link>
                </Menu.Item>
              )
            );
          })}
      </Menu>
      <Bottom>
        <Menu
          mode='inline'
          selectable={false}
          onClick={() => setOpenModal(true)}
          theme='dark'
          inlineIndent={22}
        >
          <Menu.Item
            key='quick-access'
            icon={<Icon className='menu-icon' type='settings' />}
          >
            Quick Access
          </Menu.Item>
        </Menu>
      </Bottom>
      {isOpenModal && (
        <Modal
          isOpen
          variant='aside'
          width={400}
          onClose={() => setOpenModal(false)}
          renderContent={(modal) => <QuickAccessModal modal={modal} />}
        />
      )}
    </Layout.Sider>
  );
};

export default Sidebar;
