export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s
    .toLowerCase()
    .split(' ')
    .reduce((acc, curr) => {
      acc = acc !== null ? acc + ' ' : '';
      acc += curr.charAt(0).toUpperCase() + curr.slice(1);
      return acc;
    }, null);
};

/**
 * Return sequence string by n-th index
 * @param {Number} idx The sequence of n-th string
 * @example
 *  sequenceOfLetter(0) should returned 'a'
 *  sequenceOfLetter(1) should returned 'b'
 *  sequenceOfLetter(26) should returned 'aa'
 *  sequenceOfLetter(27) should returned 'ab'
 */
export const sequenceOfLetter = (idx) => {
  const aCharCode = 'a'.charCodeAt(0);
  let b = [idx],
    sp = 0,
    out = '',
    i = 0,
    div;

  sp = 0;
  while (sp < b.length) {
    if (b[sp] > 25) {
      div = Math.floor(b[sp] / 26);
      b[sp + 1] = div - 1;
      b[sp] %= 26;
    }
    sp += 1;
  }

  for (; i < b.length; i++) {
    out = String.fromCharCode(aCharCode + b[i]) + out;
  }

  return out;
};

export const findAndReplace = (pattern, obj) => {
  return Object.keys(obj).reduce((result, key) => {
    return result.replace(`{${key}}`, obj[key]);
  }, pattern);
};
