import http from 'shared/utils/http';

export const getModes = (filter = {}) => {
  return http.get('/netwila/mode', filter);
};

export const getModeMoves = () => {
  return http.get('/netwila/mode/move');
};

export const getLoadType = () => {
  return http.get('/netwila/item/poType');
};

export const getTemperatureType = () => {
  return http.get('/netwila/item/temperaturetype');
};
