import { groupBy } from 'lodash';
import { getUsers } from 'services/user';
import { getAccessGroup } from 'services/role';
import { getDepartments, getCompany } from 'services/company';
import { getCountries, getRegions } from 'services/location';
import { getMenus, getAccessFunctions } from 'services/setting';

const initialState = {
  users: [],
  userMeta: {},
  isFetchingUsers: false,
  roles: [],
  isFetchingRoles: false,
  departments: [],
  isFetchingDepartment: false,
  countries: [],
  isFetchingCountry: false,
  regions: {},
  isFetchingRegion: false,
  states: [],
  isFetchingMenu: false,
  menus: [],
  isFetchingAccessFunction: false,
  accessFunctions: [],
  selectedUser: null,
  editingUserPermission: false,
  isFetchingAccounts: false,
  accounts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'getUsers_start':
      return {
        ...state,
        isFetchingUsers: true,
        users: []
      };
    case 'getUsers_success':
      return {
        ...state,
        isFetchingUsers: false,
        users: action.data,
        userMeta: action.meta
      };
    case 'getUsers_error':
      return {
        ...state,
        isFetchingUsers: false
      };
    case 'editUserPermission':
      return {
        ...state,
        selectedUser: action.data,
        editingUserPermission: true
      };
    case 'cancelEditUserPermission':
      return {
        ...state,
        editingUserPermission: false,
        selectedUser: null
      };
    case 'updateUserPermission_start':
      return {
        ...state,
        selectedUser: action.data
      };
    case 'updateUserPermission_end':
      return {
        ...state,
        selectedUser: null,
        editingUserPermission: false
      };
    case 'viewUserPermission_start':
      return {
        ...state,
        selectedUser: action.data
      };
    case 'viewUserPermission_end':
      return {
        ...state,
        selectedUser: null
      };
    case 'fetchAccessGroup_start':
      return {
        ...state,
        roles: [],
        isFetchingRoles: true
      };
    case 'fetchAccessGroup_success':
      return {
        ...state,
        roles: action.data,
        isFetchingRoles: false
      };
    case 'fetchDepartments_start':
      return {
        ...state,
        isFetchingDepartment: true
      };
    case 'fetchDepartments_success':
      return {
        ...state,
        isFetchingDepartment: false,
        departments: action.data
      };
    case 'fetchDepartments_error':
      return {
        ...state,
        isFetchingDepartment: false
      };
    case 'fetchCountries_start':
      return {
        ...state,
        isFetchingCountry: true
      };
    case 'fetchCountries_success':
      return {
        ...state,
        isFetchingCountry: false,
        countries: action.data
      };
    case 'fetchCountries_error':
      return {
        ...state,
        isFetchingCountry: false
      };
    case 'fetchRegions_start':
      return {
        ...state,
        isFetchingRegion: true
      };
    case 'fetchRegions_success':
      return {
        ...state,
        isFetchingRegion: false,
        regions: {
          ...state.regions,
          [action.code]: action.data
        }
      };
    case 'fetchMenus_start':
      return {
        ...state,
        isFetchingMenu: true
      };
    case 'fetchMenus_success':
      return {
        ...state,
        isFetchingMenu: false,
        menus: action.data
      };
    case 'fetchMenus_error':
      return {
        ...state,
        isFetchingMenu: false
      };
    case 'fetchAccessFunctions_start':
      return {
        ...state,
        isFetchingAccessFunction: true
      };
    case 'fetchAccessFunctions_success':
      return {
        ...state,
        isFetchingAccessFunction: false,
        accessFunctions: action.data
      };
    case 'fetchAccessFunctions_error':
      return {
        ...state,
        isFetchingAccessFunction: false
      };
    case 'fetchAccounts_start':
      return {
        ...state,
        isFetchingAccounts: true
      };
    case 'fetchAccounts_success':
      return {
        ...state,
        isFetchingAccounts: false,
        accounts: action.data
      };
    case 'fetchAccounts_error':
      return {
        ...state,
        isFetchingAccounts: false
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

export const getUsersAction = (filter) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'getUsers_start' });
      const response = await getUsers(filter);
      dispatch({
        type: 'getUsers_success',
        data: response.data,
        meta: response.meta
        // data: orderBy(response.data, ['updatedAt'], ['desc'])
      });
    } catch (err) {
      dispatch({ type: 'getUsers_error' });
    }
  };
};

export const editUserPermission = (user) => {
  return (dispatch) => dispatch({ type: 'editUserPermission', data: user });
};

export const cancelEditingUserPermission = () => {
  return (dispatch) => dispatch({ type: 'cancelEditUserPermission' });
};

export const updateUserPermissionAction = (data) => {
  return (dispatch) => dispatch({ type: 'updateUserPermission_start', data });
};

export const viewedUserPermission = (user) => {
  return (dispatch) =>
    dispatch({ type: 'viewUserPermission_start', data: user });
};

export const shouldFetchAccessGroup = (state) => {
  const { roles, isFetchingRoles } = state.setting;

  if (roles.length === 0 && !isFetchingRoles) {
    return true;
  }

  return false;
};

export const fetchAccessGroupIfNeeded = () => {
  return async (dispatch, getState) => {
    if (!shouldFetchAccessGroup(getState())) {
      return Promise.resolve();
    }

    try {
      dispatch({ type: 'fetchAccessGroup_start' });
      const response = await getAccessGroup();
      dispatch({ type: 'fetchAccessGroup_success', data: response.data });
    } catch (err) {
      dispatch({ type: 'fetchAccessGroup_error' });
    }
  };
};

export const shouldFetchDepartments = (state) => {
  const { departments, isFetchingDepartment } = state.setting;

  if (departments.length === 0 && !isFetchingDepartment) {
    return true;
  }

  return false;
};

export const fetchDepartmentsIfNeeded = () => async (dispatch, getState) => {
  if (!shouldFetchDepartments(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'fetchDepartments_start' });
    const response = await getDepartments();
    dispatch({ type: 'fetchDepartments_success', data: response.data });
  } catch (err) {
    dispatch({ type: 'fetchDepartments_error' });
  }
};

export const shouldFetchCountries = (state) => {
  const { countries, isFetchingCountry } = state.setting;

  if (countries.length === 0 && !isFetchingCountry) {
    return true;
  }

  return false;
};

export const fetchCountriesIfNeeded = () => async (dispatch, getState) => {
  if (!shouldFetchCountries(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'fetchCountries_start' });
    const response = await getCountries();
    dispatch({ type: 'fetchCountries_success', data: response.data });
  } catch (err) {
    dispatch({ type: 'fetchCountries_error' });
  }
};

export const shouldFetchRegions = (code, state) => {
  const { regions, isFetchingRegion } = state.setting;

  if (!regions[code] && !isFetchingRegion) {
    return true;
  }

  return false;
};

export const fetchRegionsIfNeeded = (code) => async (dispatch, getState) => {
  if (!shouldFetchRegions(code, getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'fetchRegions_start' });
    const response = await getRegions(code);
    dispatch({ type: 'fetchRegions_success', data: response.data, code });
  } catch (err) {
    dispatch({ type: 'fetchRegions_error' });
  }
};

export const selectCountry = (code) => (dispatch) => {
  if (code === undefined || code === '') {
    return;
  }
  dispatch(fetchRegionsIfNeeded(code));
};

export const shouldFetchMenus = (state) => {
  const { menus } = state.setting;

  if (menus.length === 0) {
    return true;
  }

  return false;
};

export const fetchMenusIfNeeded = () => async (dispatch, getState) => {
  if (!shouldFetchMenus(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'fetchMenus_start' });
    const response = await getMenus();
    dispatch({ type: 'fetchMenus_success', data: response.data });
  } catch (err) {
    dispatch({ type: 'fetchMenus_error' });
  }
};

const shouldFetchAccessFunctions = (state) => {
  const { accessFunctions } = state.setting;

  if (accessFunctions.length === 0) {
    return true;
  }

  return false;
};

export const fetchAccessFunctions = () => async (dispatch, getState) => {
  if (!shouldFetchAccessFunctions(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'fetchAccessFunctions_start' });
    const response = await getAccessFunctions();
    const funcs = response.data.filter((func) => !func.netwilaAdmin);
    const groupFuncs = groupBy(funcs, 'route');
    const data = [];
    Object.keys(groupFuncs).forEach((group) => {
      data.push({
        _id: group,
        function: group,
        children: groupFuncs[group]
      });
    });
    dispatch({ type: 'fetchAccessFunctions_success', data });
  } catch (err) {
    dispatch({ type: 'fetchAccessFunctions_error' });
  }
};

export const shouldFetchAccounts = (state) => {
  const { accounts } = state.setting;

  if (accounts.length === 0) {
    return true;
  }

  return false;
};

export const fetchAccounts = (companyId) => async (dispatch, getState) => {
  if (!shouldFetchAccounts(getState())) {
    return Promise.resolve();
  }

  try {
    dispatch({ type: 'fetchAccounts_start' });
    const response = await getCompany(companyId);
    dispatch({
      type: 'fetchAccounts_success',
      data: response.data.length !== 0 ? response.data[0].account : []
    });
  } catch (err) {
    dispatch({ type: 'fetchAccounts_error' });
  }
};
