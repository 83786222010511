/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getCountriesAction } from 'reducers/location';

const CountrySelect = ({ onChange, filter, valuePropName, ...props }, ref) => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const countries = useSelector((state) => {
    return state.location.countries.map(
      ({ _id, name, code2, state: _state }) => ({
        value: _id,
        label: name,
        code: code2,
        state: _state
      })
    );
  });
  const _onChange = (id) => {
    const country = countries.find((c) => c.value === id);
    if (valuePropName) {
      onChange(country[valuePropName]);
    } else {
      onChange(id, country);
    }
  };

  useEffect(() => {
    dispatch(getCountriesAction());
  }, [dispatch]);

  return (
    <Select
      {...props}
      ref={ref}
      showSearch
      optionFilterProp='label'
      open={opened}
      onChange={_onChange}
      options={countries.filter(
        (country) => filter?.includes(country.code) ?? true
      )}
      onFocus={() => setOpened(true)}
      onBlur={() => setOpened(false)}
    />
  );
};

export default React.forwardRef(CountrySelect);
