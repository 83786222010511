import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  Spin,
  Alert,
  AutoComplete
} from 'antd';
// eslint-disable-next-line no-unused-vars
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';

import { routeConfig } from 'configs/route';
import { StyledLayout, ShippingMode, ShippingType } from './Styles';
import OceanImg from 'assets/images/ocean.png';
import RailImg from 'assets/images/rail.png';
import RoadImg from 'assets/images/road.png';
// import AirImg from 'assets/images/air.png';
// import DroneImg from 'assets/images/drone.png';
// import ParcelImg from 'assets/images/parcel.png';
import IntegratedShippingImg from 'assets/images/integrated_shipping.png';
import DrayageChassisImg from 'assets/images/drayage_chassis.JPG';
import PortImg from 'assets/images/port.jpg';
import { doCompanyCheckAction } from 'reducers/company/companyManagement';
import {
  setCurrentShippingMode,
  setCurrentShippingType,
  setIsIntegratedShipping,
  getAccountsByUser
} from 'services/company';
import { getCurrentUser } from 'services/user';
import { dispatchService } from 'shared/utils/service';

export default () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [accounts, setAccounts] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    setLoading(true);
    dispatchService(
      () => getCurrentUser(),
      (data) => setCurrentUser(data[0]),
      () => {},
      () => setLoading(false)
    );
  }, []);

  const shippingModes = [
    {
      title: 'ShipIntuit',
      imgSrc: IntegratedShippingImg,
      mode: 'integrated_shipping',
      location: routeConfig.DB_SHIPINTUIT,
      // location: '/demand-management/integrated-shipping',
      loginType: ['netwila'],
      types: [
        // {
        //   title: 'Smart Mode',
        //   type: 'smart_mode'
        // }
      ],
      layout: { span: 12 }
    },
    {
      title: 'Ports',
      imgSrc: PortImg,
      mode: 'ports',
      location: '/dashboard/asset-maps/ports-map',
      loginType: ['netwila'],
      types: [],
      layout: { span: 12 }
    },
    {
      title: 'Ocean',
      imgSrc: OceanImg,
      mode: 'ocean',
      location: routeConfig.OCEAN_MANAGEMENT,
      layout: { span: 6 },
      loginType: ['netwila'],
      types: [
        {
          title: 'FCL',
          type: 'FCL'
        },
        {
          title: 'LCL',
          type: 'LCL'
        },
        {
          title: 'CONSOLIDATED',
          type: 'CONSOLIDATED'
        }
      ]
    },
    {
      title: 'Rail',
      imgSrc: RailImg,
      mode: 'rail',
      // TODO: Missing route config for rail network management
      location: '/dashboard/asset-maps/rail-map',
      layout: { span: 6 },
      loginType: ['netwila'],
      types: [
        {
          title: 'Intermodal/Domestic',
          type: 'intermodal/domestic'
        },
        {
          title: 'Bulk',
          type: 'bulk'
        },
        {
          title: 'Automotive',
          type: 'automotive'
        }
      ]
    },
    {
      title: 'Road',
      imgSrc: RoadImg,
      mode: 'road',
      // TODO: Missing route config for logistics
      location: '/demand-management/road',
      layout: { span: 6 },
      loginType: ['netwila', 'anwork'],
      types: [
        {
          title: 'FTL',
          type: 'FTL'
        },
        {
          title: 'LTL',
          type: 'LTL'
        },
        {
          title: 'CONSOLIDATED',
          type: 'CONSOLIDATED'
        }
      ]
    },
    {
      title: 'Drayage',
      imgSrc: DrayageChassisImg,
      mode: 'drayage_chassis',
      location: '/demand-management/drayage-management',
      layout: { span: 6 },
      loginType: ['netwila', 'anwork'],
      types: [
        {
          title: 'Non-Ocean Carrier Chassis',
          type: 'non_ocean_carrier_chassis'
        },
        {
          title: 'Co-op Chassis',
          type: 'co_op_chassis'
        },
        {
          title: '3PL Chassis Provider',
          type: '3PL_chassis_provider'
        },
        {
          title: 'Power Tractor Provider',
          type: 'power_tractor_provider'
        }
      ]
    }
    /*
    {
      title: 'Air',
      imgSrc: AirImg,
      mode: 'air',
      // TODO: Missing route config for logistics
      location: '/dashboard',
      layout: { span: 6 },
      loginType: ['netwila'],
      types: [
        {
          title: 'LTL',
          type: 'LTL'
        },
        {
          title: 'TL',
          type: 'TL'
        }
      ]
    },
    {
      title: 'RDI',
      imgSrc: DroneImg,
      mode: 'RDI',
      // TODO: Missing route config for logistics
      location: '/dashboard',
      layout: { span: 6 },
      loginType: ['netwila'],
      types: [
        {
          title: 'IntelliDemand',
          type: 'IntelliDemand',
          location: '/demand-management'
        },
        {
          title: 'Plan/Monitor',
          type: 'Plan/Monitor',
          location: '/asset-management/equipment-planning'
        },
        {
          title: 'Video',
          type: 'Video'
        }
      ]
    },
    {
      title: 'Parcel',
      imgSrc: ParcelImg,
      mode: 'parcel',
      // TODO: Missing route config for logistics
      location: '/dashboard',
      layout: { span: 6 },
      loginType: ['netwila'],
      types: [
        {
          title: 'Ground',
          type: 'Ground'
        },
        {
          title: 'Express',
          type: 'Express'
        }
      ]
    },
    */
  ];

  const loginType = location.state?.app ?? 'netwila';
  const defaultMode =
    shippingModes.find((m) => m.mode === location.state?.mode) ??
    shippingModes[loginType === 'netwila' ? 0 : 2];
  const [shippingMode, setShippingMode] = useState(defaultMode);
  const [shippingType, setShippingType] = useState(shippingMode.types[0]);

  const doAccountCheck = () => {
    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => form.validateFields())
      .then(({ companyName, accountNumber }) =>
        dispatch(doCompanyCheckAction(companyName, accountNumber))
      );
  };

  const doEmailTracking = () => {
    const oribiObj = window.ORIBI || null;
    if (oribiObj) {
      oribiObj.api('setUserEmail', currentUser.email);
    }
  };

  const doEnter = () => {
    const redirectPath =
      shippingType?.location || shippingMode?.location || '/';
    Promise.resolve()
      .then(() => doAccountCheck())
      .then(() => doEmailTracking())
      .then(() => {
        setCurrentShippingMode(shippingMode?.mode);
        setCurrentShippingType(shippingType?.type);
        setIsIntegratedShipping(shippingMode.mode === 'integrated_shipping');
      })
      .then(() => dispatch(push(redirectPath)))
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const doOrder = () => {
    Promise.resolve()
      .then(() => doAccountCheck())
      .then(() => doEmailTracking())
      .then(() => {
        setCurrentShippingMode(shippingMode?.mode);
        setCurrentShippingType(shippingType?.type);
        setIsIntegratedShipping(shippingMode.mode === 'integrated_shipping');
      })
      .then(() => dispatch(push(routeConfig.ORDERS_LIST)))
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  /**
   * That is a tricky function to help the user
   * back to the login screen by redirect them to logout function
   */
  const doBack = () => {
    dispatch(push(routeConfig.LOGOUT));
  };

  useEffect(() => dispatchService(getAccountsByUser, setAccounts), []);

  return (
    <Spin spinning={loading}>
      <StyledLayout style={{ minHeight: '100vh', justifyContent: 'center' }}>
        {currentUser && (
          <Row justify='center' align='middle'>
            <Col flex='544px'>
              <Card
                className='no-border'
                title={<b>Account Information</b>}
                extra={
                  <Button type='link' onClick={doBack}>
                    Back
                  </Button>
                }
                style={{ borderRadius: 10 }}
              >
                {error && <Alert message={error} type='error' />}
                <Form name='account_selection' layout='vertical' form={form}>
                  <Form.Item
                    label='Account Number'
                    name='accountNumber'
                    rules={[{ required: true }]}
                  >
                    <AutoComplete
                      options={accounts.map((account) => ({
                        label: account.number,
                        value: account.number
                      }))}
                    >
                      <Input
                        allowClear
                        placeholder='Select or enter account number'
                      />
                    </AutoComplete>
                  </Form.Item>
                  <Form.Item label='Select Shipping Mode'>
                    <Row gutter={4}>
                      {shippingModes
                        .filter((mode) => mode.loginType.includes(loginType))
                        .map((mode) => (
                          <Col {...mode.layout} key={mode.mode}>
                            <ShippingMode
                              hoverable
                              cover={<img alt='example' src={mode.imgSrc} />}
                              className={
                                shippingMode?.mode === mode.mode
                                  ? `active ${mode.mode}`
                                  : mode.mode
                              }
                              onClick={() => {
                                setShippingMode(mode);
                                setShippingType(mode.types?.[0]);
                              }}
                            >
                              <Card.Meta
                                className={mode.mode}
                                title={mode.title}
                              />
                            </ShippingMode>
                          </Col>
                        ))}
                    </Row>
                  </Form.Item>
                  {shippingMode?.types.length > 0 && (
                    <Form.Item label='Select Shipping Type'>
                      <Row gutter={4}>
                        {shippingMode.types.map((type) => (
                          <Col
                            span={8}
                            key={`${shippingMode.mode}${type.type}`}
                          >
                            <ShippingType
                              hoverable
                              className={
                                shippingType?.type === type.type ? 'active' : ''
                              }
                              onClick={() => setShippingType(type)}
                            >
                              {type.title}
                            </ShippingType>
                          </Col>
                        ))}
                      </Row>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Button
                          style={{ width: '100%' }}
                          type='primary'
                          onClick={() => doEnter()}
                        >
                          <b>Enter</b>
                        </Button>
                      </Col>
                      <Col span={12}>
                        <Button
                          style={{ width: '100%' }}
                          type='primary'
                          onClick={() => doOrder()}
                        >
                          <b>Order</b>
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        )}
      </StyledLayout>
    </Spin>
  );
};
