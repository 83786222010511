import React from 'react';
import { Select, Form, Col, Input, DatePicker } from 'antd';
import { forEach } from 'lodash';
import moment from 'moment';

export const fillForm = (form, columns) => {
  forEach(columns, (column) => {
    form.setFieldsValue({
      [column.dataIndex]:
        column.type && column.type === 'date' ? moment() : column.title
    });
  });
};

export const tableRender = (columns, columnsRender) => {
  return columns.map((column) => {
    if (
      columnsRender &&
      columnsRender[column.dataIndex] &&
      typeof columnsRender[column.dataIndex].render === 'function'
    ) {
      return { ...column, render: columnsRender[column.dataIndex].render };
    }

    return column;
  });
};

const shouldBeCapitalizeAllChars = [
  /\b(vat|Vat)\b/g,
  /\b(baf|Baf)\b/g,
  /\b(lss|Lss)\b/g,
  /\b(lmo|Lmo)\b/g,
  /\b(vat|Vat)\b/g,
  /\b(bol|Bol)\b/g,
  /\b(bol|Bol)\b/g,
  /\b(eta|Eta)\b/g,
  /\b(ata|Ata)\b/g,
  /\b(dg|Dg)\b/g,
  /\b(od|Od)\b/g,
  /\b(ls|Ls)\b/g,
  /\b(bc|Bc)\b/g,
  /\b(imo|Imo)\b/g,
  /\b(id|Id)\b/g,
  /\b(whs|Whs)\b/g,
  /\b(ssl|Ssl)\b/g,
  /\b(po|Po)\b/g
];

export const column2Title = (
  val,
  lastNumberWithSpace = true,
  allCaptitalizeChar = []
) => {
  let newTitle = val
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map((v) => (v && v[0].toUpperCase() + v.substr(1)) || '')
    .join(' ');
  if (lastNumberWithSpace) {
    newTitle = newTitle.replace(/([^\s+\d])(\d+$)/, '$1 $2');
  }
  [...shouldBeCapitalizeAllChars, ...allCaptitalizeChar].forEach((capRex) => {
    newTitle = newTitle.replace(capRex, (v) => v.toUpperCase());
  });
  return newTitle;
};

export const formFilterRender = (formGroup) => {
  return formGroup.map(({ name, dataIndex, type }) => {
    switch (type) {
      case 'select':
        return (
          <Col key={dataIndex} span={8}>
            <Form.Item
              key={dataIndex}
              name={dataIndex}
              label={`Select ${name}`}
            >
              <Select name={dataIndex} key={dataIndex}>
                {Array.from({ length: 5 }).map((formItem, idx) => (
                  <Select.Option key={idx}>{`${name} ${
                    idx + 1
                  }`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        );
      case 'date':
        return (
          <Col key={dataIndex} span={8}>
            <Form.Item key={dataIndex} name={dataIndex} label={`Input ${name}`}>
              <DatePicker />
            </Form.Item>
          </Col>
        );
      default:
        return (
          <Col key={dataIndex} span={8}>
            <Form.Item key={dataIndex} name={dataIndex} label={`Input ${name}`}>
              <Input />
            </Form.Item>
          </Col>
        );
    }
  });
};

export const mapSelectOptions = (labelProp, valueProp) => {
  return (obj) => ({
    label: typeof labelProp === 'function' ? labelProp(obj) : obj[labelProp],
    value: typeof valueProp === 'function' ? valueProp(obj) : obj[valueProp]
  });
};

export const resetFieldValues = (form, changedValues, fieldDeps) => {
  const resetFields = Object.keys(changedValues).reduce(
    (a, c) => [...a, ...(fieldDeps[c] ?? [])],
    []
  );

  form.resetFields(resetFields);
};

export const prepFormData = (obj) => {
  return Object.keys(obj).reduce((f, k) => {
    if (/Date$/.test(k)) {
      return {
        ...f,
        [k]: obj[k] && moment(obj[k])
      };
    }
    if (/Time$/.test(k)) {
      return {
        ...f,
        [k]: obj[k] && moment(obj[k], 'HH:mm:ss')
      };
    }
    if (typeof obj[k] === 'object') {
      return {
        ...f,
        [k]: obj[k]?.id ?? obj[k]
      };
    }
    return {
      ...f,
      [k]: obj[k]
    };
  }, {});
};
