/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { signupAction } from 'reducers/authentication';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Alert, Spin, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';

import { routeConfig } from 'configs/route';
import {
  storeAccessToken,
  removeStoredAccessToken
} from 'shared/utils/authToken';
import {
  SignUpPage,
  FormElement,
  FormHeading,
  Agreement,
  Actions,
  ActionButton
} from './Styles';
import { dispatchService } from 'shared/utils/service';
import { getRequestedCompany } from 'services/company';
import { signup2 } from 'services/authentication';

const SignUp = () => {
  const [form] = Form.useForm();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const getRequestedCompanyAction = () => {
    return dispatchService(
      () => getRequestedCompany(token),
      (data) => {
        form.setFieldsValue({
          companyName: data.companyName,
          firstName: data.contactName,
          email: data.contactEmail
        });
      }
    );
  };
  const onFinish = (values) => {
    dispatchService(
      () =>
        signup2(
          values.firstName,
          values.lastName,
          values.email,
          values.password,
          token
        ),
      (data) => {
        dispatch(push(routeConfig.LOGIN));
      },
      (err) => {
        if (typeof err === 'string') {
          setError(err);
        } else {
          setError(err.join(', '));
        }
      }
    );
  };

  useEffect(getRequestedCompanyAction, [token]);

  return (
    <SignUpPage>
      <FormElement>
        <FormHeading>Netwila</FormHeading>
        {error && <Alert type='error' message={error} />}
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item label='Company Name' name='companyName'>
            <Input disabled />
          </Form.Item>
          <Form.Item label='Email' name='email'>
            <Input type='email' disabled />
          </Form.Item>
          <Form.Item label='First Name' name='firstName'>
            <Input />
          </Form.Item>
          <Form.Item label='Last Name' name='lastName'>
            <Input />
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input type='password' />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                }
              })
            ]}
          >
            <Input type='password' />
          </Form.Item>
          <Agreement>
            By signing up means that you agreeing to the Netwila{' '}
            <a
              href='https://app.netwila.ca/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              term &amp; conditions
            </a>{' '}
            and{' '}
            <a
              href='https://app.netwila.ca/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              privacy policy
            </a>
          </Agreement>
          <Actions>
            <ActionButton type='submit' variant='primary' isWorking={isLoading}>
              Sign Up
            </ActionButton>
          </Actions>
        </Form>
      </FormElement>
    </SignUpPage>
  );
};

export default SignUp;
