import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarContainer } from './Styles';

const propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string
};

const defaultProps = {
  value: 0,
  max: 25,
  width: '25%',
  color: '#19466e'
};

const ProgressBarItem = (props) => {
  const { value, max, color, width, background } = props;

  return (
    <ProgressBarContainer color={color} width={width} background={background}>
      <progress value={value} max={max} />
    </ProgressBarContainer>
  );
};

ProgressBarItem.propTypes = propTypes;
ProgressBarItem.defaultProps = defaultProps;
export default ProgressBarItem;
