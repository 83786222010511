/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { routeConfig } from 'configs/route';
import { Form } from 'shared/components';

import { Row, Col } from 'antd';

import {
  StyledLayout,
  Heading,
  FormHeading,
  FormElement,
  Actions,
  ActionButton,
  Tip,
  LinkItem,
  Error
} from './Styles';
import { oauthLogin } from 'services/authentication';
import { dispatchService } from 'shared/utils';
import { useQuery } from 'shared/utils/router';

const NetwilaLogin = ({ login, auth, app }) => {
  const query = useQuery();

  const handleLogin = (values) => {
    login(values.email, values.password, query.toString());
  };

  const actionUrl =
    process.env.REACT_APP_API_BASE_URL || 'http://localhost:6008';

  return (
    <form
      method='post'
      action={`${actionUrl}/netwila/oauth2/login?${query.toString()}`}
    >
      <input name='username' type='input' />
      <input name='password' type='password' />
      <input type='submit' value='Submit' />
    </form>
  );
};

const Login = ({ login, auth }) => {
  const [app] = useState('oauth');

  return (
    <StyledLayout>
      <Row justify='center' align='middle'>
        <Col flex='400px'>
          <NetwilaLogin login={login} auth={auth} app={app} />
        </Col>
      </Row>
    </StyledLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password, query) => {}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
