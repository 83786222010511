import React, { useState, useEffect, useMemo } from 'react';
import {
  Popover,
  Dropdown,
  Button,
  Row,
  Col,
  Input,
  Checkbox,
  Space
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  StyledTable,
  ColumnFilterHeader,
  ColumnFilterContent,
  ColumnFilterFooter
} from './Styles';

import {
  calculateTableWidth,
  getSettingColumns,
  updateSettingColumns,
  getDefaultColumns,
  DEFAULT_ITEM_PER_PAGE
} from './tableHelper';

const ColumnFilter = ({ columns, name, onChange }) => {
  const [filter, setFilter] = useState(null);
  const [visible, setVisible] = useState(false);
  const defaultColumns = useMemo(() => {
    if (columns.length === 0) return [];
    return getDefaultColumns(columns);
  }, [columns]);
  const [settingColumns, setSettingColumns] = useState(
    new Set(getSettingColumns(name) ?? [])
  );
  const mergedColumns = useMemo(() => {
    return columns
      .filter((column) => !column.fixed)
      .filter(
        (column) =>
          filter === null ||
          column.key.toLowerCase().includes(filter.toLowerCase())
      )
      .map((column) => ({
        ...column,
        checked: settingColumns.has(column.key)
      }));
  }, [columns, settingColumns, filter]);

  const addSettingColumn = (column) => {
    settingColumns.add(column);
    setSettingColumns(new Set(settingColumns.values()));
  };

  const removeSettingColumn = (column) => {
    settingColumns.delete(column);
    setSettingColumns(new Set(settingColumns.values()));
  };

  const updateColumnFilter = () => {
    updateSettingColumns(name, Array.from(settingColumns));
    setVisible(false);
    onChange();
  };

  const cancelColumnFilter = () => {
    setSettingColumns(new Set(getSettingColumns(name) ?? defaultColumns));
    setVisible(false);
  };

  const selectAllColumn = () => {
    setSettingColumns(new Set(columns.map((column) => column.key)));
  };

  const handleClickChange = (_visible) => {
    if (!_visible) {
      setSettingColumns(new Set(getSettingColumns(name) ?? defaultColumns));
    } else {
      setFilter('');
    }
    setVisible(_visible);
  };

  const restoreDefaultColumns = () => {
    updateSettingColumns(name, Array.from(defaultColumns));
    setSettingColumns(new Set(defaultColumns));
    setVisible(false);
    onChange();
  };

  const header = () => (
    <ColumnFilterHeader>
      <h3>Columns</h3>
      <Input.Search
        value={filter}
        autoFocus={true}
        onChange={(e) => setFilter(e.target.value)}
      />
    </ColumnFilterHeader>
  );

  const footer = () => (
    <ColumnFilterFooter>
      <Button type='primary' onClick={updateColumnFilter}>
        Done
      </Button>
      <Button type='link' onClick={cancelColumnFilter}>
        Cancel
      </Button>
    </ColumnFilterFooter>
  );

  const content = () => (
    <>
      <Space>
        <Button type='link' onClick={selectAllColumn}>
          Select all
        </Button>
        <Button type='link' onClick={restoreDefaultColumns}>
          Restore defaults
        </Button>
      </Space>
      <ColumnFilterContent>
        {mergedColumns.map((item) => {
          return (
            <Checkbox
              defaultChecked={item.checked}
              key={item.key}
              checked={item.checked}
              onChange={(e) =>
                e.target.checked
                  ? addSettingColumn(item.key)
                  : removeSettingColumn(item.key)
              }
            >
              {item.title}
            </Checkbox>
          );
        })}
      </ColumnFilterContent>
      {footer()}
    </>
  );

  useEffect(() => {
    if (columns.length === 0) return;
    if (!getSettingColumns(name)) {
      setSettingColumns(new Set(getDefaultColumns(columns)));
    }
  }, [name, columns]);

  return (
    <Popover
      title={header}
      content={content}
      trigger='click'
      placement='bottomRight'
      visible={visible}
      onVisibleChange={handleClickChange}
      destroyTooltipOnHide={{ keepParent: true }}
    >
      <Button type='text' onClick={() => setVisible(!visible)}>
        Columns
        <DownOutlined />
      </Button>
    </Popover>
  );
};

export default ({
  columns,
  name,
  pagination,
  showInfo = false,
  showFilter = true,
  selection = false,
  scroll = {},
  onSettingColumnsChanged,
  ...rest
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [settingColumns, setSettingColumns] = useState(
    new Set(getSettingColumns(name) ?? getDefaultColumns(columns))
  );
  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: DEFAULT_ITEM_PER_PAGE
  });

  const onColumnChange = () => {
    const updatedColumns = getSettingColumns(name);
    setSettingColumns(new Set(updatedColumns));
    if (onSettingColumnsChanged) {
      onSettingColumnsChanged(updatedColumns);
    }
  };

  const tableTitle = (currentPageData) => {
    const currentPageItemCount = currentPageData.length;
    const skip =
      (currentPagination.current - 1) * currentPagination.pageSize + 1;
    return (
      <Row justify='space-between' align='middle'>
        <Col>
          {showInfo && (
            <>
              <b>{skip}</b>-<b>{skip + currentPageItemCount - 1}</b> of{' '}
              <b>{pagination?.total ?? 0}</b>
            </>
          )}
          {rest.title && rest.title()}
        </Col>
        {showFilter && (
          <Col>
            <Space>
              {rest.extra}
              {selection && (
                <Dropdown.Button
                  menu={
                    rest.bulkActions
                      ? {
                          items: rest.bulkActions.items,
                          onClick: (e) =>
                            rest.bulkActions.onClick(e, selectedRowKeys)
                        }
                      : {}
                  }
                  disabled={selectedRowKeys.length === 0}
                >
                  Bulk actions
                </Dropdown.Button>
              )}
              <ColumnFilter
                columns={columns.filter((column) => column.title !== '')}
                name={name}
                onChange={onColumnChange}
              />
            </Space>
          </Col>
        )}
      </Row>
    );
  };

  const onChange = (page, pageSize) => {
    setCurrentPagination({
      current: page,
      pageSize
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = selection
    ? {
        selectedRowKeys,
        onChange: onSelectChange
      }
    : rest.rowSelection;

  useEffect(() => {
    if (columns.length === 0) return;
    setSettingColumns(
      new Set(getSettingColumns(name) ?? getDefaultColumns(columns))
    );
  }, [name, columns]);

  useEffect(() => {
    const fixedLeftColumns = columns.filter(
      (column) => column.fixed === 'left'
    );
    const fixedRightColumns = columns.filter(
      (column) => column.fixed === 'right'
    );

    setDisplayColumns([
      ...fixedLeftColumns,
      ...columns
        .filter((column) => !column.fixed)
        .filter((column) => settingColumns.has(column.key)),
      ...fixedRightColumns
    ]);
  }, [name, columns, settingColumns]);

  return (
    <StyledTable
      {...rest}
      title={tableTitle}
      scroll={{ x: calculateTableWidth(displayColumns), ...scroll }}
      columns={displayColumns}
      rowSelection={rowSelection}
      pagination={
        pagination
          ? {
              ...pagination,
              onChange,
              defaultPageSize: DEFAULT_ITEM_PER_PAGE
            }
          : false
      }
    />
  );
};
