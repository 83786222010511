import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
    background-color: #fff;
    color: #000;
  }

  .ant-menu-inline-collapsed-tooltip a {
    color: #000;
  }

  .ant-tooltip-arrow-content {
    background-color: #fff !important;
  }

  input.ant-input, div.ant-select-selector, div.ant-picker, div.ant-input-number  {
    border-radius: 4px;
  }

  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #666666 !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #666666 !important;
    &::after {
      background-color: #666666 !important;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08) !important;
  }

  .location-option div.ant-select-item-option-content {
    white-space: normal;
    text-overflow: unset;
  }
`;

export default GlobalStyle;
