import { createAction, createReducer } from 'redux-act';

export const createActionResources = (actionName) => {
  return {
    start: createAction(actionName),
    success: createAction(`${actionName} success`),
    error: createAction(`${actionName} error`)
  };
};

export const createActionCRUDResources = (actionName) => {
  return {
    fetch: createActionResources(`fetch ${actionName}`),
    create: createActionResources(`create ${actionName}`),
    update: createActionResources(`update ${actionName}`),
    delete: createActionResources(`delete ${actionName}`)
  };
};

export { createReducer };
