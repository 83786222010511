import styled from 'styled-components';
import { Button } from 'shared/components';
import { Link } from 'react-router-dom';
import { Layout, Card } from 'antd';

import { color } from 'shared/utils/styles';

export const FormHeading = styled.div`
  padding-bottom: 15px;
  font-size: 21px;
  font-weight: bold;
`;

export const FormElement = styled.div`
  padding: 30px 20px;
  width: 400px;
  margin: 0 auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 3px #fff;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
`;

export const SignUpPage = styled.div`
  background-color: ${color.primary};
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const Agreement = styled.div`
  margin: 10px 5px 0;
  font-size: 12px;
  color: #000;
  a {
    color: #1f4370;
    font-weight: bold;
    &:active {
      color: #1f4370;
    }
  }
`;

export const LinkItem = styled(Link)`
  margin-left: 5px;
  color: #1f4370;
  font-weight: bold;
  &:active {
    color: #1f4370;
  }
`;

export const Tip = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 12px;
`;

export const Error = styled.div`
  margin-top: 5px;
  color: #8a0303;
  font-size: 12px;
  text-align: center;
`;

export const ConfirmPage = styled(Layout)`
  min-height: 100vh;
  justify-content: center;
  background-color: ${color.primary};
`;

export const StyledCard = styled(Card)`
  .ant-card-cover {
    padding: 30px;
    background-color: rgba(229, 229, 229, 0.5);
  }
`;

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  justify-content: center;
  background-color: ${color.primary};

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #fff;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background-color: #dedede;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 63px;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:first-child {
    margin-right: 2px;
  }

  form {
    // border-radius: 0;
  }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: center;
`;
