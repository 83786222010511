import React from 'react';
import { Spin } from 'antd';

/**
 * A custom block ui component with default loader
 * @param  {[type]} options.blocking [description]
 * @param  {[type]} options.children [description]
 * @return {[type]}                  [description]
 */
export default ({ blocking, children }) => {
  return <Spin spinning={blocking}>{children}</Spin>;
};
