import http from 'shared/utils/http';

export const fetchModels = (companyId) => {
  return http.get('/api/edi/config/models', { companyId });
};

export const fetchEdiTypes = (companyId) => {
  return http.get('/api/edi/config', { companyId });
};

export const fetchDbFields = (companyId, selectedDb) => {
  return http.get(`/api/edi/config/models/${selectedDb}/fields`, {
    companyId
  });
};

export const fetchEdiFields = (companyId, selectedEdiType) => {
  return http.get(`/api/edi/config/${selectedEdiType}/fields`, {
    companyId
  });
};
