import {
  groupBy,
  toPairs,
  flatten,
  isEmpty,
  isNil,
  pickBy,
  pick
} from 'lodash';
import moment from 'moment';

function genrows(groups, groupKey) {
  return toPairs(groups).map(([key, data]) => ({ [groupKey]: key, data }));
}

function gengroups(arr, iteratee, key) {
  const grouped = groupBy(arr, iteratee);
  return genrows(grouped, key);
}

/**
 * @typedef {Object} GroupByProp
 * @prop {String=} key Grouping key
 * @prop {String|Function} iteratee An iteratee for Lodash's groupBy
 */

/**
 * Group collection's data by multiple iteratees
 * @param data
 * @param {Array<String|GroupByProp>} props Array of group by objects or property names
 *   This parameter also can contain both property names and group by objects together
 * @returns {Array}
 */

export const nestedGroup = (data, props) => {
  let result = [{ data }];
  props.forEach((prop, i) => {
    const key = prop.key || `k${i + 1}`;
    const iteratee = prop.iteratee || prop;

    result = flatten(
      result.map((row) => {
        return gengroups(row.data, iteratee, key).map(
          (group) =>
            // start
            ({
              ...row,

              [key]: group[key],
              data: group.data
            })
          //end
        );
      })
    );
  });

  return flatten(result);
};

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms, ms);
  });
};

/**
 * Get and parse current querystring
 *
 * @returns {Dictionary<string,string>} parsed querystring
 */
export const getQueryString = () =>
  window.location.search
    .split(/[?&]/)
    .map((param) => param.split('='))
    .filter((p) => p.length === 2)
    .reduce(
      (params, [k, v]) => ({
        ...params,
        [decodeURIComponent(k)]: decodeURIComponent(v)
      }),
      {}
    );

/**
 * Get Radio Label
 *
 * @returns {Dictionary<string>} radio label string
 */
export const getRadioFieldLabel = (val, options = []) => {
  const selectedOption = options.find((option) => option.value === val);
  return selectedOption ? selectedOption.label : val;
};

/**
 * Format Table Cell Input Value
 */
export const formatCellFieldValue = (record, inputTypes) => {
  return Object.keys(record).reduce((reduceData, key) => {
    const fieldValue = record[key];
    const type = inputTypes[key];

    switch (type) {
      case 'year':
      case 'month':
      case 'date':
      case 'time':
        return {
          ...reduceData,
          [key]: moment(fieldValue)
        };
      default:
        return {
          ...reduceData,
          [key]: fieldValue
        };
    }
  }, {});
};

export const formatLocation = (loc) => {
  if (isEmpty(loc)) return;
  return `${loc.address} - ${loc.city} - ${loc.state?.state} - ${loc.country?.name} ${loc.postCode}`;
};

export const pickObj = (obj, paths) => {
  return pickBy(
    pick(obj, paths),
    (value) => typeof value === 'boolean' || !isNil(value)
  );
};

export const possibilities = (unit) => {
  const mass = {
    lbs: 'lb',
    kgs: 'kg'
  };

  if (isEmpty(unit)) return 'lb';

  const _possibilities = { ...mass };
  return _possibilities[unit] ?? unit;
};

export const getLocationDisplayName = (location) => {
  if (!location) return '';
  return !isEmpty(location.name) ? location.name : location.address;
};

export const getLocationDisplayAddress = (location) => {
  if (!location) return '';
  return !isEmpty(location.name)
    ? `${location.address ?? 'N/A'} (${location.name})`
    : location.address;
};

export const getLocationDisplayContact = (location) => {
  if (!location) return;
  if (!location.contact) return;
  const contact = location.contact;
  if (!contact) return;
  const _contactInfo = [];
  const _contactName = [];
  if (!isEmpty(contact.firstName) && !isNil(contact.firstName)) {
    _contactName.push(contact.firstName);
  }
  if (!isEmpty(contact.lastName) && !isNil(contact.lastName)) {
    _contactName.push(contact.lastName);
  }
  if (_contactName.length > 1) {
    _contactInfo.push(`ATTN: ${_contactName.join(' ')}`);
  }
  if (!isEmpty(contact.email) && !isNil(contact.email)) {
    _contactInfo.push(`EMAIL: ${contact.email}`);
  }
  if (!isEmpty(contact.phone) && !isNil(contact.phone)) {
    _contactInfo.push(`TEL: ${contact.phone}`);
  }
  if (!isEmpty(contact.fax) && !isNil(contact.fax)) {
    _contactInfo.push(`FAX: ${contact.fax}`);
  }
  return _contactInfo.join(' / ');
};

export default {
  pickObj,
  possibilities,
  getLocationDisplayName,
  getLocationDisplayAddress,
  getLocationDisplayContact
};
