/* eslint-disable no-unused-vars */
import { notification } from 'antd';
import _ from 'lodash';
import { csvParser } from 'shared/utils/parser';
import LoadableComponent from 'components/LoadableComponent';
import {
  addCompanyLocationAction,
  updateCompanyAction
} from 'reducers/company/companyManagement';
import { addLocationDropModalAction, openModal } from 'reducers/modalLot';
import { getDataTables } from 'services/etl/data-table';
import {
  addDemand,
  addSupply,
  addVolumeLane,
  bulkImportData,
  downloadDocument,
  downloadDocumentData,
  downloadOrderTemplate,
  fetchAIModals,
  getGlossary,
  getPresentation,
  getPresentations,
  getTemplates,
  importItemProducts,
  importLocationsData,
  createPresentation,
  updatePresentation,
  deletePresentation,
  getCompanyLocations,
  getCompanyPartnerLocations,
  getProducts,
  getContracts,
  getPurchaseOrders,
  getLoadBoards,
  getOrders,
  getVolumeLane,
  getTasks,
  getRates,
  getSupply,
  getDemand
} from 'services/network';
import {
  dataTableLocationFields,
  dataTableProductFields,
  dataTableContractFields,
  dataTablePurchaseOrderFields,
  dataTableLoadBoardFields,
  dataTableOrderFields,
  dataTableVolumeLaneFields,
  dataTableTaskFields,
  dataTableRateFields,
  dataTableSupplyFields,
  dataTableDemandFields
} from '../configs/app';

const initialState = {
  selectedElementId: 0,
  selectedElementData: null,
  //elementDatas: [],
  aiModels: [],
  error: false,
  message: '',
  loading: false,
  showDrawer: false,
  slides: [],
  currentSlideIndex: 0,
  currentSlideData: null,
  currentPresenationData: {
    title: 'Untitled',
    slides: [],
    isPublished: false,
    isTemplate: false
  },
  allPresentationData: [],
  allPresentationMeta: {},
  allTemplateData: {},
  isUploadingLocation: false,
  locationsData: null,
  isFetchingDataTable: false,
  isDownloadingDataTable: false,
  dataTables: null,
  workareaSize: {
    height: 0,
    width: 0
  },
  history: [],
  isAutoSave: true,
  glosssary: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'changePresentationData': {
      return {
        ...state,
        currentSlideIndex: 0,
        selectedElementId: 0,
        slides:
          action.data.presentationData && action.data.presentationData.slides
            ? action.data.presentationData.slides
            : [],
        currentPresenationData: action.data.presentationData
      };
    }
    case 'changeSelection': {
      let selectedElementData = {};
      state.slides.forEach((slide) => {
        if (slide.index === state.currentSlideIndex) {
          selectedElementData = slide.elements.filter(
            (ele) => ele.id === action.data.selectedElementId
          )[0];
        }
      });
      return {
        ...state,
        selectedElementId: action.data.selectedElementId,
        selectedElementData
      };
    }
    case 'add_element': {
      let updatedSlideDataArr = state.slides.map((slide) => {
        if (slide.index === state.currentSlideIndex) {
          let data = {
            ...slide,
            elements: [...slide.elements, action.data]
          };
          return data;
        }
        return slide;
      });
      return {
        ...state,
        slides: updatedSlideDataArr
      };
    }
    case 'update_element': {
      const updatedSlideDataArr = state.slides.map((slide) => {
        if (slide.index === state.currentSlideIndex) {
          const updatedElementDataArr = slide.elements.map((elem) => {
            if (elem.id === action.data.id) {
              return action.data;
            }
            return elem;
          });
          const data = {
            ...slide,
            elements: updatedElementDataArr
          };
          return data;
        }
        return slide;
      });
      return {
        ...state,
        selectedElementData: action.data,
        slides: updatedSlideDataArr
      };
    }
    case 'remove_element': {
      const updatedSlideDataArr = state.slides.map((slide) => {
        if (slide.index === state.currentSlideIndex) {
          const updatedElementDataArr = slide.elements.filter((elem) => {
            return elem.id !== state.selectedElementId;
          });
          const data = {
            ...slide,
            elements: updatedElementDataArr
          };
          return data;
        }
        return slide;
      });

      return {
        ...state,
        slides: updatedSlideDataArr
      };
    }
    case 'add_slide': {
      return {
        ...state,
        slides: [...state.slides, action.data],
        currentSlideIndex: action.data.index
      };
    }
    case 'update_slide': {
      if (action.data && action.data.slideData) {
        let updatedSlideDataArr = state.slides.map((slide) => {
          if (slide.index === state.currentSlideIndex) {
            return action.data.slideData;
          }
          return slide;
        });
        return {
          ...state,
          slides: updatedSlideDataArr,
          currentSlideData: state.slides.filter(
            (slide) => slide.index === state.currentSlideIndex
          )[0]
        };
      }
      return {
        ...state,
        currentSlideData: state.slides.filter(
          (slide) => slide.index === state.currentSlideIndex
        )[0]
      };
    }
    case 'change_slide': {
      return {
        ...state,
        currentSlideIndex: action.data.currentSlideIndex
      };
    }
    case 'remove_slide': {
      let updatedSlideDataArr = state.slides
        .filter((slide) => {
          return slide.index !== state.currentSlideIndex;
        })
        .map((slide, index) => {
          return {
            ...slide,
            index
          };
        });
      return {
        ...state,
        slides: updatedSlideDataArr,
        currentSlideIndex:
          state.currentSlideIndex - 1 >= 0 ? state.currentSlideIndex - 1 : 0
      };
    }
    case 'createPresentation_success': {
      return {
        ...state,
        // slides: action.data.slides,
        currentPresenationData: action.data
      };
    }
    case 'createPresentation_error': {
      return {
        ...state
      };
    }
    case 'updatePresentation_success': {
      return {
        ...state,
        // slides: action.data.slides,
        currentPresenationData: action.data
      };
    }
    case 'updatePresentation_error': {
      return {
        ...state
      };
    }
    case 'getAllTemplate_success': {
      return {
        ...state,
        allTemplateData: action.data
      };
    }
    case 'getAllTemplate_error': {
      return {
        ...state
      };
    }
    case 'getAllPresentation_success': {
      return {
        ...state,
        allPresentationData: action.data.data,
        allPresentationMeta: action.data.meta
      };
    }
    case 'getAllPresentation_error': {
      return {
        ...state
      };
    }
    case 'getPresentationData_success': {
      return {
        ...state
      };
    }
    case 'getPresentationData_error': {
      return {
        ...state
      };
    }
    case 'fetchGlossary_success': {
      return {
        ...state,
        glosssary: action.data
      };
    }
    case 'fetchGlossary_error': {
      return {
        ...state,
        glosssary: []
      };
    }
    case 'getAIModels_start':
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      };
    case 'getAIModels_success': {
      return {
        ...state,
        aiModels: action.data
      };
    }
    case 'handle_drawer': {
      return {
        ...state,
        showDrawer: action.data.showDrawer
      };
    }
    case 'importLocations_start': {
      return {
        ...state,
        isUploadingLocation: true
      };
    }
    case 'importLocations_success': {
      return {
        ...state,
        locationsData: action.data,
        isUploadingLocation: false
      };
    }
    case 'importLocations_error': {
      return {
        ...state,
        locationsData: {},
        error: true,
        message: action.data,
        isUploadingLocation: false
      };
    }
    case 'getDataTable_start': {
      return {
        ...state,
        isFetchingDataTable: true
      };
    }
    case 'getDataTable_success': {
      return {
        ...state,
        isFetchingDataTable: false,
        dataTables: action.data
      };
    }
    case 'getDataTable_error': {
      return {
        ...state,
        error: true,
        message: action.data
      };
    }
    case 'downloadDataTable_start': {
      return {
        ...state,
        isDownloadingDataTable: true
      };
    }
    case 'downloadDataTable_completed': {
      return {
        ...state,
        isDownloadingDataTable: false
      };
    }
    case 'set_workarea_size': {
      return {
        ...state,
        workareaSize: {
          height: action.data.height,
          width: action.data.width
        }
      };
    }
    case 'register_history': {
      return {
        ...state,
        history: [...state.history, action.data]
      };
    }
    case 'undo_history': {
      if (state.history.length === 0) return state;
      return {
        ...state,
        history: state.history.slice(0, -1)
      };
    }
    default:
      return state;
  }
};

export const changePresentationData = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'changePresentationData',
      data: { presentationData: data }
    });
    dispatch({
      type: 'update_slide'
    });
  };
};

export const changeSelectedElementt = (changeTo) => {
  return (dispatch) => {
    dispatch({
      type: 'changeSelection',
      data: { selectedElementId: changeTo }
    });
  };
};

export const addElement = (data) => {
  const newUniqueID = Date.now().toString();
  return (dispatch) => {
    dispatch({
      type: 'add_element',
      data: { ...data, id: newUniqueID, key: newUniqueID }
    });
    dispatch({
      type: 'changeSelection',
      data: { selectedElementId: newUniqueID }
    });
    dispatch({ type: 'update_slide' });
  };
};

export const updateElement = (data) => {
  return (dispatch) => {
    Promise.resolve()
      .then(() => dispatch({ type: 'update_element', data }))
      .then(() => dispatch({ type: 'update_slide' }));
  };
};

export const deleteElement = () => {
  return (dispatch) => {
    dispatch({ type: 'remove_element' });
    dispatch({ type: 'changeSelection', data: { selectedElementId: 0 } });
    dispatch({ type: 'update_slide' });
  };
};

export const addSlide = (data) => {
  let newUniqueID = Date.now().toString();
  return (dispatch) => {
    dispatch({
      type: 'add_slide',
      data: { ...data, id: newUniqueID, key: newUniqueID }
    });
    dispatch({
      type: 'vvvz',
      data: { currentSlideIndex: data.index }
    });
    dispatch({ type: 'update_slide' });
  };
};

export const changeSlide = (changeTo) => {
  return (dispatch) => {
    dispatch({
      type: 'change_slide',
      data: { currentSlideIndex: changeTo }
    });

    dispatch({ type: 'update_slide' });
  };
};

export const updateSlide = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'update_slide',
      data: { slideData: data }
    });
  };
};

export const deleteSlide = () => {
  return (dispatch) => {
    dispatch({ type: 'remove_slide' });
    dispatch({ type: 'update_slide' });
  };
};

export const handleDrawer = (visibility) => {
  return (dispatch) => {
    dispatch({ type: 'handle_drawer', data: { showDrawer: visibility } });
  };
};

export const getAIModels = () => {
  return (dispatch) => {
    dispatch({ type: 'getAIModels_start' });
    fetchAIModals()
      .then((res) => {
        dispatch({ type: 'getAIModels_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getAIModels_error', message: err });
      });
  };
};

export const getAllPresentation = (params = {}) => {
  return (dispatch) => {
    dispatch({ type: 'getAllPresentation' });
    getPresentations(params)
      .then((res) => {
        dispatch({ type: 'getAllPresentation_success', data: res });
      })
      .catch((err) => {
        dispatch({ type: 'getAllPresentation_error', message: err });
      });
  };
};

export const getAllTemplate = () => {
  return getTemplates();
};

export const getPresentationData = (data) => {
  return (dispatch) => {
    dispatch({ type: 'getPresentationData' });
    getPresentation(data)
      .then((res) => {
        dispatch({ type: 'getPresentationData_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'getPresentationData_error', message: err });
      });
  };
};

export const createPresentationAction = (data) => {
  return (dispatch) => {
    dispatch({ type: 'createPresentation_start' });
    createPresentation(data)
      .then((res) => {
        dispatch(
          getAllPresentation({
            isTemplate: false,
            page: 1,
            take: 12
          })
        );
        dispatch({ type: 'createPresentation_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'createPresentation_error', message: err });
      });
  };
};

export const updatePresentationAction = (id, data) => {
  return (dispatch) => {
    dispatch({ type: 'updatePresentation_start' });
    updatePresentation(id, data)
      .then((res) => {
        dispatch(
          getAllPresentation({
            isTemplate: false,
            page: 1,
            take: 12
          })
        );
        dispatch({ type: 'updatePresentation_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'updatePresentation_error', message: err });
      });
  };
};

export const deletePresentationAction = (id) => {
  return (dispatch) => {
    dispatch({ type: 'deletePresentation_start' });
    deletePresentation(id)
      .then((res) => {
        dispatch(
          getAllPresentation({
            isTemplate: false,
            page: 1,
            take: 12
          })
        );
        dispatch({ type: 'deletePresentation_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'deletePresentation_error', message: err });
      });
  };
};

export const fetchGlossary = (data) => {
  return (dispatch) => {
    dispatch({ type: 'fetchGlossary_start' });
    getGlossary(data)
      .then((res) => {
        dispatch({ type: 'fetchGlossary_success', data: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'fetchGlossary_error', message: err });
      });
  };
};

export const createSupply = (data) => {
  return addSupply(data);
};

export const createDemand = (data) => {
  return addDemand(data);
};

export const createVolumeLane = (data) => {
  return addVolumeLane(data);
};

export const importLocations = (data) => {
  return importLocationsData(data);
};

export const importProducts = (data) => {
  return importItemProducts(data);
};

export const bulkImport = (data) => {
  return bulkImportData(data);
};

export const fetchDataTables = (reqData) => {
  return getDataTables(reqData);
};

export const getOrderTemplateData = (tData, fileName) => {
  downloadOrderTemplate(tData).then((data) => {
    const element = document.createElement('a');
    const file = new Blob([data], { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = fileName + '.csv';
    document.body.appendChild(element);
    element.click();
  });
};

export const downloadDataTable = (fData) => {
  return (dispatch) => {
    dispatch({ type: 'downloadDataTable_start' });
    return new Promise((resolve, reject) => {
      try {
        downloadDocument(fData).then((res) => {
          downloadDocumentData(res.data).then((fileData) => {
            csvParser.parse(fileData.data, {
              header: true,
              complete: ({ data }) => {
                if (data.length > 0) {
                  let fields = Object.keys(data[0]).map((fieldData) => ({
                    label: fieldData,
                    name: fieldData,
                    type: 'Input',
                    enable: true
                  }));

                  let compData = {
                    type: 'DataTable',
                    vType: 'table',
                    data,
                    availableFields: fields
                  };
                  dispatch(addElement(compData));
                  notification.success({
                    message: 'File imported succesfully',
                    description: ''
                  });
                  dispatch({ type: 'downloadDataTable_completed' });
                  resolve('Promise resolved successfully');
                } else {
                  dispatch({ type: 'downloadDataTable_completed' });
                  reject(Error('File is empty'));
                  notification.error({
                    message: 'File is empty',
                    description: ''
                  });
                }
              }
            });
          });
        });
      } catch {
        dispatch({ type: 'downloadDataTable_completed' });
        notification.success({
          message: 'Something went wrong!!',
          description: ''
        });
        reject(Error('Something went wrong!!'));
      }
    });
  };
};

export const addIndustrySegmentElement = (
  currentCompany,
  segmentId,
  segmentName,
  xPos,
  yPos,
  icon
) => {
  return async (dispatch) => {
    const segmentFilterArray = currentCompany.industryCategory.filter(
      (segment) => {
        return segment._id === segmentId;
      }
    );

    if (segmentFilterArray.length === 0) {
      const segments = [
        ...currentCompany.industryCategory.map((s) => s._id),
        segmentId
      ];
      dispatch(
        await updateCompanyAction(currentCompany._id, {
          industryCategory: segments
        })
      );
    }

    let compData = {
      type: 'Business',
      vType: 'icon',
      data: segmentName,
      image: icon,
      x: xPos,
      y: yPos
    };
    dispatch(addElement(compData));
  };
};

export const addBusinessTypeElement = (
  currentCompany,
  segmentId,
  segmentName,
  xPos,
  yPos,
  icon
) => {
  return (dispatch) => {
    let compData = {
      type: 'Business Type',
      vType: 'icon',
      data: segmentName,
      image: icon,
      x: xPos,
      y: yPos
    };
    dispatch(addElement(compData));
  };
};

export const addLocationElement = (
  currentCompany,
  locationTypeId,
  locationTypeName,
  xPos,
  yPos,
  icon
) => {
  return async (dispatch) => {
    const location = await dispatch(
      addLocationDropModalAction(
        currentCompany,
        locationTypeId,
        locationTypeName
      )
    );
    if (!location) {
      return;
    }

    if (location.type === 'file') {
      csvParser.parse(location.file, {
        header: true,
        complete: ({ data }) => {
          if (data.length > 0) {
            let fields = Object.keys(data[0]).map((fieldData) => ({
              label: fieldData,
              name: fieldData,
              type: 'Input',
              enable: true
            }));
            let compData = {
              type: 'Business Partner',
              vType: 'table',
              data,
              x: xPos,
              y: yPos,
              availableFields: fields
            };
            dispatch(addElement(compData));
            notification.success({
              message: 'File imported succesfully',
              description: ''
            });
          } else {
            notification.success({
              message: 'File is empty',
              description: ''
            });
          }
        },
        error: () => {}
      });
    } else if (location.type === 'data') {
      try {
        // if (location.isNew) {
        //   const locations = [
        //     ...currentCompany.location.map((l) => l?._id),
        //     location.data._id
        //   ];
        //   await dispatch(
        //     updateCompanyAction(currentCompany._id, { location: locations })
        //   );
        //   await dispatch(addCompanyLocationAction(location.data));
        // }

        let compData = {
          type: 'Location',
          vType: 'icon',
          data: location.data.name,
          image: icon,
          x: xPos,
          y: yPos
        };
        dispatch(addElement(compData));
      } catch (err) {
        notification.error({
          message: 'Failed to add company location',
          description: err.message
        });
      }
    }
  };
};

export const addBusinessPartnerElement = (
  currentCompany,
  typeId,
  type,
  xPos,
  yPos,
  icon
) => {
  return async (dispatch) => {
    if (type === 'Customer') {
      const companyPartnerModal = LoadableComponent(() =>
        import('components/Commons/AddCustomerPartnerModal')
      );
      let defaultCompanyType = { companyType: type, _id: typeId };
      const companyPartner = await dispatch(
        openModal(companyPartnerModal, { currentCompany, defaultCompanyType })
      );

      if (companyPartner) {
        if (companyPartner.type === 'data') {
          if (companyPartner.newCompany.length > 0) {
            companyPartner.newCompany.forEach((cPartner) => {
              let compData = {
                type: 'Customers',
                vType: 'icon',
                data: cPartner,
                image: icon,
                x: xPos,
                y: yPos
              };
              dispatch(addElement(compData));
            });
          }
        } else {
          csvParser.parse(companyPartner.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Customers',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        }
      }
    } else {
      const companyPartnerModal = LoadableComponent(() =>
        import('components/Commons/AddCompanyPartnerModal')
      );
      let defaultCompanyType = { companyType: type, _id: typeId };
      const companyPartner = await dispatch(
        openModal(companyPartnerModal, {
          currentCompany,
          defaultCompanyType,
          isNetwork: true
        })
      );
      if (companyPartner) {
        if (companyPartner.type === 'data') {
          if (companyPartner.newCompany.length > 0) {
            companyPartner.newCompany.forEach((cPartner) => {
              let compData = {
                type: 'Business Partner',
                vType: 'icon',
                data: cPartner,
                image: icon,
                x: xPos,
                y: yPos
              };
              dispatch(addElement(compData));
            });
          }
        } else {
          csvParser.parse(companyPartner.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Business Partner',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        }
      }
    }
  };
};

export const addCustomerBaseElement = (
  currentCompany,
  baseId,
  baseName,
  xPos,
  yPos,
  icon
) => {
  return async (dispatch) => {
    if (baseName === 'Customer') {
      const companyPartnerModal = LoadableComponent(() =>
        import('components/Commons/AddCustomerPartnerModal')
      );
      let defaultCompanyType = { companyType: baseName, _id: baseId };
      const companyPartner = await dispatch(
        openModal(companyPartnerModal, { currentCompany, defaultCompanyType })
      );
      if (companyPartner) {
        if (companyPartner.type === 'data') {
          if (companyPartner.newCompany.length > 0) {
            companyPartner.newCompany.forEach((cPartner) => {
              let compData = {
                type: 'Customers',
                vType: 'icon',
                data: cPartner,
                image: icon,
                x: xPos,
                y: yPos
              };
              dispatch(addElement(compData));
            });
          }
        } else {
          csvParser.parse(companyPartner.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Customers',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        }
      }
    } else if (baseName === 'Product Segments') {
      const customerBaseModel = LoadableComponent(() =>
        import('components/Commons/CustomerBaseModel')
      );
      const productType = await dispatch(
        openModal(customerBaseModel, { type: 'segment' })
      );
      if (productType) {
        if (productType.type === 'file') {
          csvParser.parse(productType.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Product Segments',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            }
          });
        } else if (productType.type === 'data') {
          if (productType.data.length > 0) {
            productType.data.forEach((pType) => {
              let compData = {
                type: 'Product Segment',
                vType: 'icon',
                data: pType.type,
                image: icon,
                x: xPos,
                y: yPos
              };
              dispatch(addElement(compData));
            });
          }
        }
      }
    } else if (baseName === 'Products') {
      const customerBaseModel = LoadableComponent(() =>
        import('components/Commons/CustomerBaseModel')
      );
      const productType = await dispatch(
        openModal(customerBaseModel, { type: 'product' })
      );
      if (productType) {
        if (productType.type === 'file') {
          csvParser.parse(productType.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Products',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        } else if (productType.type === 'data') {
          if (productType.data.length > 0) {
            productType.data.forEach((pType) => {
              let compData = {
                type: 'Products',
                vType: 'icon',
                data: pType.productName,
                image: icon,
                x: xPos,
                y: yPos
              };
              dispatch(addElement(compData));
            });
          }
        }
      }
    } else if (baseName === 'Demand') {
      const companyPartnerModal = LoadableComponent(() =>
        import('components/Commons/AddDemandModal')
      );
      const demand = await dispatch(
        openModal(companyPartnerModal, { type: 'demand' })
      );
      if (demand) {
        if (demand.type === 'file') {
          csvParser.parse(demand.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Demand',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        } else if (demand.type === 'data') {
          let compData = {
            type: 'Demand',
            vType: 'icon',
            data: demand.values.originNode,
            image: icon,
            x: xPos,
            y: yPos
          };
          dispatch(addElement(compData));
        }
      }
    } else if (baseName === 'Supply') {
      const companyPartnerModal = LoadableComponent(() =>
        import('components/Commons/AddDemandModal')
      );
      const supply = await dispatch(
        openModal(companyPartnerModal, { type: 'supply' })
      );
      if (supply) {
        if (supply.type === 'file') {
          csvParser.parse(supply.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Supply',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        } else if (supply.type === 'data') {
          let compData = {
            type: 'Supply',
            vType: 'icon',
            data: supply.values.originNode,
            image: icon,
            x: xPos,
            y: yPos
          };
          dispatch(addElement(compData));
        }
      }
    }
  };
};

export const addTransportationMapElement = (
  currentCompany,
  typeId,
  subMenuName,
  xPos,
  yPos,
  icon
) => {
  return async (dispatch) => {
    if (subMenuName === 'Contracts') {
      const addContractsModal = LoadableComponent(() =>
        import('components/Commons/AddContractsModal')
      );

      const contracts = await dispatch(
        openModal(addContractsModal, { type: 'contracts' })
      );
      if (contracts) {
        if (contracts.type === 'file') {
          csvParser.parse(contracts.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Contract',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        } else if (contracts.data && contracts.data.length > 0) {
          contracts.data.forEach((contract) => {
            let data =
              contract.contractNumber +
              ' - ' +
              (contract.contract3Party.length > 0
                ? contract.contract3Party[0].legalName
                : '') +
              ' - ' +
              contract.contractType.type;
            let compData = {
              type: 'Contracts',
              vType: 'icon',
              data,
              image: icon,
              x: xPos,
              y: yPos
            };
            dispatch(addElement(compData));
          });
        }
      }
    } else if (subMenuName === 'Rates') {
      const addContractsModal = LoadableComponent(() =>
        import('components/Commons/AddContractsModal')
      );

      const contracts = await dispatch(
        openModal(addContractsModal, { type: 'rates' })
      );
      if (contracts) {
        if (contracts.rateCards && contracts.rateCards.length > 0) {
          let fields = Object.keys(contracts.rateCards[0]).map((fieldData) => ({
            label: fieldData,
            name: fieldData,
            type: 'Input',
            enable: true
          }));
          let compData = {
            type: 'rate',
            vType: 'table',
            data: contracts.rateCards,
            x: xPos,
            y: yPos,
            availableFields: fields
          };
          dispatch(addElement(compData));
        }
      }
    } else if (subMenuName === 'Volume By Lane') {
      const frequencyModal = LoadableComponent(() =>
        import('components/Commons/AddFrequencyModal')
      );
      const frequncy = await dispatch(openModal(frequencyModal));

      if (frequncy) {
        if (frequncy.type === 'file') {
          csvParser.parse(frequncy.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'Volume By Lane',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        } else if (frequncy.type === 'data') {
          let compData = {
            type: 'Volume By Lane',
            vType: 'icon',
            data: subMenuName,
            image: icon,
            x: xPos,
            y: yPos
          };
          dispatch(addElement(compData));
        }
      }
    } else if (subMenuName === 'Cross-docks') {
      const location = await dispatch(
        //addLocationDropModalAction({ locationTypeId: typeId, locationTypeName: 'Cross-dock' })
        addLocationDropModalAction(currentCompany, typeId, 'Cross_dock')
      );
      if (!location) {
        return;
      }

      if (location.type === 'file') {
        let fields = Object.keys(location.data[0]).map((data) => ({
          label: data,
          name: data,
          type: 'Input',
          enable: true
        }));
        let compData = {
          type: 'Cross-Docks',
          vType: 'table',
          data: location.data,
          x: xPos,
          y: yPos,
          availableFields: fields
        };
        dispatch(addElement(compData));
      } else if (location.type === 'data') {
        try {
          if (location.isNew) {
            const locations = [
              ...currentCompany.location.map((l) => l._id),
              location.data._id
            ];
            await dispatch(
              updateCompanyAction(currentCompany._id, { location: locations })
            );
            await dispatch(addCompanyLocationAction(location.data));
          }

          let compData = {
            type: 'Cross-Docks',
            vType: 'icon',
            data: location.data.name,
            image: icon,
            x: xPos,
            y: yPos
          };
          dispatch(addElement(compData));
        } catch (err) {
          notification.error({
            message: 'Failed to add company location',
            description: err.message
          });
        }
      }
    }
  };
};

export const addCostByLaneElement = (
  currentCompany,
  typeId,
  subMenuName,
  xPos,
  yPos,
  icon
) => {
  return async (dispatch) => {
    if (subMenuName === 'Shipment Order') {
      const orderModal = LoadableComponent(() =>
        import('components/Commons/AddOrderModal')
      );
      const shipmentOrder = await dispatch(openModal(orderModal));
      if (shipmentOrder) {
        if (shipmentOrder.type === 'file') {
          csvParser.parse(shipmentOrder.file, {
            header: true,
            complete: ({ data }) => {
              if (data.length > 0) {
                let fields = Object.keys(data[0]).map((fieldData) => ({
                  label: fieldData,
                  name: fieldData,
                  type: 'Input',
                  enable: true
                }));
                let compData = {
                  type: 'ShipmentOrder',
                  vType: 'table',
                  data,
                  x: xPos,
                  y: yPos,
                  availableFields: fields
                };
                dispatch(addElement(compData));
                notification.success({
                  message: 'File imported succesfully',
                  description: ''
                });
              } else {
                notification.success({
                  message: 'File is empty',
                  description: ''
                });
              }
            },
            error: () => {}
          });
        } else if (shipmentOrder.type === 'data') {
          let compData = {
            type: 'ShipmentOrder',
            vType: 'icon',
            data: subMenuName,
            image: icon,
            x: xPos,
            y: yPos
          };
          dispatch(addElement(compData));
        }
      }
    } else {
      const orderModal = LoadableComponent(() =>
        import('components/Commons/AddPurchaseOrder')
      );
      const purchaseOrder = await dispatch(openModal(orderModal));
      if (purchaseOrder) {
        if (purchaseOrder.length > 0) {
          let fields = Object.keys(purchaseOrder[0]).map((fieldData) => ({
            label: fieldData,
            name: fieldData,
            type: 'Input',
            enable: true
          }));
          let filteredData = purchaseOrder.map((pOrder) => {
            delete pOrder.itemDetails;
            return pOrder;
          });
          let compData = {
            type: 'Purchase Order',
            vType: 'table',
            data: filteredData,
            x: xPos,
            y: yPos,
            availableFields: fields
          };
          dispatch(addElement(compData));
        }
      }
    }
  };
};

export const addAIModalElement = (currentCompany, typeId, subMenuName) => {
  return async (dispatch) => {
    const aiModal = LoadableComponent(() =>
      import('components/Commons/AddAIModal')
    );
    await dispatch(openModal(aiModal, { code: typeId, subMenu: subMenuName }));
  };
};

export const openDataTable = () => {
  return async (dispatch) => {
    const dataModal = LoadableComponent(() =>
      import('components/Commons/AddDataModal/AddDataTable')
    );
    await dispatch(openModal(dataModal));
  };
};

export const setWorkareaSize = (size) => {
  return (dispatch) => {
    dispatch({
      type: 'set_workarea_size',
      data: { height: size.height, width: size.width }
    });
  };
};

export const registerHistory = (elementData) => {
  return (dispatch) => {
    dispatch({ type: 'register_history', data: elementData });
  };
};

export const undoFromHistory = (elementData) => {
  return (dispatch) => {
    dispatch({ type: 'update_element', data: elementData });
    dispatch({ type: 'undo_history' });
    dispatch({
      type: 'update_slide'
    });
  };
};

export const importDataTableAction = (params) => {
  return async (dispatch) => {
    // dispatch({ type: 'downloadDataTable_start' });
    try {
      const { name } = params;
      const element = {
        type: 'DataTable',
        vType: 'table',
        refetch: false,
        allowImport: false,
        dataSource: name,
        data: [],
        meta: {},
        availableFields: []
      };
      dispatch(addElement(element));
      // notification.success({ message: 'File imported succesfully' });
      // dispatch({ type: 'downloadDataTable_completed' });
    } catch {
      // dispatch({ type: 'downloadDataTable_completed' });
      notification.error({ message: 'Something went wrong!' });
    }
  };
};

export const getDataTableAction = (element) => {
  return async (dispatch) => {
    try {
      let resp = null,
        moduleUrl = null,
        availableFields = [];
      switch (element.dataSource) {
        case 'Company Locations':
          resp = await getCompanyLocations();
          moduleUrl = '/company/company-details';
          availableFields = dataTableLocationFields;
          element.allowImport = true;
          break;
        case 'Company Partner Locations':
          resp = await getCompanyPartnerLocations();
          availableFields = dataTableLocationFields;
          break;
        case 'Master Items':
          resp = await getProducts();
          moduleUrl = '/company/product';
          availableFields = dataTableProductFields;
          element.allowImport = true;
          break;
        case 'Contracts':
          resp = await getContracts();
          moduleUrl = '/contracts';
          availableFields = dataTableContractFields;
          break;
        case 'Purchase Orders':
          resp = await getPurchaseOrders();
          moduleUrl = '//orders/purchase-order/entry';
          availableFields = dataTablePurchaseOrderFields;
          break;
        case 'Load Boards':
          resp = await getLoadBoards();
          moduleUrl = '/company/anwork/load-board';
          availableFields = dataTableLoadBoardFields;
          break;
        case 'Shipment Orders':
          resp = await getOrders();
          moduleUrl = '/orders/all';
          availableFields = dataTableOrderFields;
          break;
        case 'Volume Lane':
          resp = await getVolumeLane();
          availableFields = dataTableVolumeLaneFields;
          break;
        case 'Order Tasks':
          resp = await getTasks();
          availableFields = dataTableTaskFields;
          break;
        case 'Contract with Rates':
          resp = await getRates();
          moduleUrl = '/contracts';
          availableFields = dataTableRateFields;
          break;
        case 'Data Supply':
          resp = await getSupply();
          availableFields = dataTableSupplyFields;
          break;
        case 'Data Demand':
          resp = await getDemand();
          availableFields = dataTableDemandFields;
          break;
        default:
          break;
      }

      const tempAvailableFields = availableFields.filter(
        (f) => f.fieldExpression
      );
      const data = resp.data.map((row) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of tempAvailableFields) {
          if (field.fieldExpression)
            row[field.name] = _.get(row, field.fieldExpression);
        }
        return row;
      });

      const tempElement = {
        ...element,
        data,
        meta: resp?.meta || {},
        moduleUrl,
        availableFields
      };
      dispatch(updateElement(tempElement));
      notification.success({
        message: `${element.dataSource} Loaded Succesfully`
      });
    } catch {
      notification.error({ message: 'Something went wrong!' });
    }
  };
};
