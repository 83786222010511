import React from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const { modals } = useSelector((state) => state.modalLot);
  return (
    <>
      {modals.map(({ modal: Modal, onClose, key, ...props }) => (
        <Modal onClose={onClose} key={key} {...props} />
      ))}
    </>
  );
};
