import http from 'shared/utils/http';

export const getDataTables = (params = {}) => {
  return http.get(`/netwila-core/etl/data-table`, params);
};

export const getPresignUrl = (data) => {
  return http.post('/netwila/report/getsignedurl', data);
};

export const processFile = (data) => {
  return http.post('/netwila/report/processFile', data);
};

export const updateDataTable = (id, data) => {
  return http.put(`/netwila-core/etl/data-table/${id}`, data);
};

export const importDataTable = (data) => {
  return http.post(`/netwila/report/data-table`, data);
};

export const deleteDataTable = (id) => {
  return http.delete(`/netwila-core/etl/data-table/${id}`);
};

export const getDataTable = (id) => {
  return http.get(`/netwila-core/etl/data-table/${id}`);
};

export const refreshData = (companyId, reportId) => {
  return http.get(`/netwila/report/${companyId}/${reportId}`);
};
