import http from 'shared/utils/http';

/**
 *
 * @param {String} menuId
 * @param {String} menuTitle
 */
export const getMenu = (menuId, menuTitle) => {
  return http.get(`/netwila/menu?menuId=${menuId}&title=${menuTitle}`);
};

export const getInprocessOrders = (params = {}) => {
  return http.get('/netwila-core/dashboard/order/ipi', params);
};

export const getLoadToRailOrders = (params = {}) => {
  return http.get('/netwila-core/dashboard/order/load-2-rail', params);
};

export const getDailyReport = (param = {}) => {
  return http.get('/netwila-core/dashboard/report/daily', param);
};

export const getTrackedShipmentStatus = (param = {}) => {
  return http.get('/netwila-core/dashboard/report/shipment-status', param);
};

export const getDepartureVessels = () => {
  return http.get('/netwila-core/dashboard/report/departure-vessels');
};

export const getUpcomingVessels = () => {
  return http.get('/netwila-core/dashboard/report/upcoming-vessels');
};

export const getShipmentTrackingReport = () => {
  return http.get('/netwila-core/dashboard/report/shipment-tracking');
};

export const getDelayVesselDeparture = () => {
  return http.get('/netwila-core/dashboard/report/delay-departure');
};

export const getDelayVesselArrival = () => {
  return http.get('/netwila-core/dashboard/report/delay-arrival');
};

export const exportLTRAsExcel = (params = {}) => {
  return http.get('/netwila-core/dashboard/order/export/load-2-rail', params);
};

export const exportIPIAsExcel = (params = {}) => {
  return http.get('/netwila-core/dashboard/order/export/ipi', params);
};

export const getShipmentSummary = () => {
  return http.get(`/netwila-core/dashboard/report/shipment/summary`);
};

export const getShipmentByStatus = (status, params = {}) => {
  return http.get(`/netwila-core/dashboard/report/shipment/${status}`, params);
};

export const exportShipmentByStatus = (status, params = {}) => {
  return http.get(
    `/netwila-core/dashboard/report/shipment/${status}/export`,
    params
  );
};

export const markShipmentAsCompleted = (id) => {
  return http.put(`/netwila-core/order_shipment/${id}/ended`);
};

export const getShipmentAtOrigin = () => {
  return http.get('/netwila-core/dashboard/report/origin');
};

export const assignPIC = (id, userId) => {
  return http.post(`/netwila-core/order_shipment/${id}/assign`, { userId });
};

export const getShipmentOnVessel = () => {
  return http.get('/netwila-core/dashboard/report/on-vessel');
};

export const getShipmentWaitingRail = () => {
  return http.get('/netwila-core/dashboard/report/waiting-rail');
};

export const getShipmentLTL = () => {
  return http.get('/netwila-core/dashboard/report/ltl');
};

export const getShipmentWaitingPickup = () => {
  return http.get('/netwila-core/dashboard/report/waiting-pickup');
};

export const getShipmentWaitingEmptyReturn = () => {
  return http.get('/netwila-core/dashboard/report/waiting-empty-return');
};

export const getShipmentDDInvoice = () => {
  return http.get('/netwila-core/dashboard/report/d-d-invoice');
};
