import { createAction, createReducer } from 'redux-act';
import { uniqueId } from 'lodash';

import LoadableComponent from 'components/LoadableComponent';

const initialState = {
  // A stack of modal
  modals: []
};

const addModalAction = createAction('add modal action', (modal) => ({
  ...modal,
  key: uniqueId()
}));
const removeModalAction = createAction('remove modal action');

export default createReducer(
  {
    [addModalAction]: (state, modal) => ({
      ...state,
      modals: [...state.modals, modal]
    }),
    [removeModalAction]: (state) => ({
      ...state,
      modals: state.modals.slice(0, -1)
    })
  },
  initialState
);

export const addLocationDropModalAction = (
  currentCompany,
  locationTypeId,
  locationTypeName
) => {
  const Modal = LoadableComponent(() =>
    import('components/Commons/AddLocationDropModal')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver,
        currentCompany,
        locationTypeId, //TODO: rename according to API field name
        locationTypeName
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const addLocationModalAction = (params) => {
  const Modal = LoadableComponent(() =>
    import('components/Commons/AddLocationModal')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver,
        ...params
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const addProductSegmentModalAction = () => {
  const Modal = LoadableComponent(() =>
    import('components/Commons/ProductType')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const addProductModalAction = () => {
  const Modal = LoadableComponent(() =>
    import('components/Commons/AddProduct')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const addOrderModalAction = (values) => {
  const Modal = LoadableComponent(() =>
    import('components/Commons/AddOrderModal/OrderCreate')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver,
        ...values
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const addCompanyModalAction = (initialValues) => {
  const Modal = LoadableComponent(() =>
    import('components/Commons/AddCompanyModal')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver,
        initialValues
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const viewContractDetailModalAction = (id) => {
  const Modal = LoadableComponent(() =>
    import('pages/Contracts/ContractDetailModal')
  );
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal: Modal,
        onClose: resolver,
        id
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export const openModal = (modal, props) => {
  return (dispatch) => {
    const promise = new Promise((resolver) => {
      const data = {
        modal,
        onClose: resolver,
        ...props
      };
      dispatch(addModalAction(data));
    });
    return promise.finally(() => {
      dispatch(removeModalAction());
    });
  };
};

export { removeModalAction, addModalAction };
