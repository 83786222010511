import toast from 'shared/utils/toast';
import { createAction, createReducer } from 'redux-act';
import {
  fetchEdiTypes,
  fetchDbFields,
  fetchModels,
  fetchEdiFields
} from 'services/edi/ediMapping';

const initialState = {
  error: false,
  fetchLoading: false,
  //DB
  arrModelName: [],
  selectedDb: '',
  selectedDbFields: [],
  fetchDbFieldLoading: false,
  fetchDbFieldError: false,
  //EDI
  ediTypes: [],
  selectedEdiType: '',
  selectedEdiFields: [],
  fetchEdiFieldLoading: false,
  fetchEdiFieldError: false
};

const fetchEdiMappingDataStart = createAction('fetch edi data mapping');
const fetchEdiMappingDataSuccess = createAction(
  'fetch edi data mapping success'
);
const fetchEdiMappingDataError = createAction('fetch edi data mapping error');

const handleChangeSelectedDb = createAction(
  'fetch DbFields when change selected db'
);
const handleChangeSelectedDbSucess = createAction(
  'fetch DbFields when change selected db success'
);
const handleChangeSelectedDbError = createAction(
  'fetch DbFields when change selected db error'
);

const handleChangeSelectedEdiType = createAction(
  'fetch EdiType when change selected ediType'
);
const handleChangeSelectedEdiTypeSucess = createAction(
  'fetch EdiType when selected ediType success'
);
const handleChangeSelectedEdiTypeError = createAction(
  'fetch EdiType when selected ediType error'
);

export default createReducer(
  {
    [fetchEdiMappingDataStart]: (state) => ({
      ...state,
      fetchLoading: true
    }),
    [fetchEdiMappingDataSuccess]: (state, payload) => ({
      ...state,
      ediTypes: payload.ediTypes,
      arrModelName: payload.arrModelName,
      fetchLoading: false,
      error: false
    }),
    [fetchEdiMappingDataError]: (state) => ({
      ...state,
      error: true
    }),
    //onChange Db selected-> refetch DB fields
    [handleChangeSelectedDb]: (state, payload) => ({
      ...state,
      fetchDbFieldLoading: true,
      selectedDb: payload.selectedDb
    }),
    [handleChangeSelectedDbSucess]: (state, payload) => ({
      ...state,
      fetchDbFieldLoading: false,
      selectedDbFields: payload.selectedDbFields
    }),
    [handleChangeSelectedDbError]: (state) => ({
      ...state,
      fetchDbFieldLoading: false,
      fetchDbFieldError: true
    }),
    //onChange Edi Type selected -> refetch EDI fields
    [handleChangeSelectedEdiType]: (state, payload) => ({
      ...state,
      fetchEdiFieldLoading: true,
      selectedEdiType: payload.selectedEdiType
    }),
    [handleChangeSelectedEdiTypeSucess]: (state, payload) => ({
      ...state,
      fetchEdiFieldLoading: false,
      selectedEdiFields: payload.selectedEdiFields
    }),
    [handleChangeSelectedEdiTypeError]: (state) => ({
      ...state,
      fetchEdiFieldLoading: false,
      fetchEdiFieldError: true
    })
  },
  initialState
);

export const fetchEdiMappingDataAction = (companyId) => {
  return async (dispatch) => {
    dispatch(fetchEdiMappingDataStart());
    try {
      const ediTypesResponse = await fetchEdiTypes(companyId);
      const arrModelNameResponse = await fetchModels(companyId);
      dispatch(
        fetchEdiMappingDataSuccess({
          ediTypes: ediTypesResponse.data,
          arrModelName: arrModelNameResponse.data
        })
      );
    } catch (error) {
      dispatch(fetchEdiMappingDataError('Fetching error'));
      toast.error(`Error occurred when fetching edi mapping data`);
    }
  };
};

export const handleChangeSelectedDbAction = (companyId, dbName) => {
  if (dbName === '') return;
  return async (dispatch) => {
    dispatch(handleChangeSelectedDb({ selectedDb: dbName }));
    try {
      const dbFieldsResponse = await fetchDbFields(companyId, dbName);
      dispatch(
        handleChangeSelectedDbSucess({
          selectedDbFields: dbFieldsResponse.data
        })
      );
    } catch (error) {
      dispatch(
        handleChangeSelectedDbError('Fetching error. Please try again!')
      );
      toast.error(`Error occurred when fetching database fields data`);
    }
  };
};

export const handleChangeEdiTypeAction = (companyId, selectedEdiType) => {
  if (selectedEdiType === '') return;
  return async (dispatch) => {
    dispatch(handleChangeSelectedEdiType({ selectedEdiType }));
    try {
      const ediFieldsResponse = await fetchEdiFields(
        companyId,
        selectedEdiType
      );
      dispatch(
        handleChangeSelectedEdiTypeSucess({
          selectedEdiFields: ediFieldsResponse.data
        })
      );
    } catch (error) {
      dispatch(
        handleChangeSelectedEdiTypeError('Fetching error. Please try again!')
      );
      toast.error(`Error occurred when fetching EDI fields types data`);
    }
  };
};
