import { createAction, createReducer } from 'redux-act';
import { delay } from 'shared/utils/utilHelper';

const initialState = {
  fetchLoading: false,
  data: [],
  error: false
};

const fetchRailCapacityPlanning = createAction('fetch rail capacity planning');
const fetchRailCapacityPlanningSuccess = createAction(
  'fetch rail capacity planning success'
);
const fetchRailCapacityPlanningError = createAction(
  'fetch rail capacity planning error'
);

export default createReducer(
  {
    [fetchRailCapacityPlanning]: (state) => ({
      ...state,
      fetchLoading: true,
      error: false
    }),
    [fetchRailCapacityPlanningSuccess]: (state, payload) => ({
      ...state,
      data: payload,
      fetchLoading: false,
      error: false
    }),
    [fetchRailCapacityPlanningError]: (state, payload) => ({
      ...state,
      message: payload,
      error: true,
      data: [],
      fetchLoading: false
    })
  },
  initialState
);

export const fetchRailCapacityPlanningAction = (values) => {
  return async (dispatch) => {
    try {
      dispatch(fetchRailCapacityPlanning());
      await delay(1000);
      dispatch(fetchRailCapacityPlanningSuccess(values));
    } catch (error) {
      dispatch(fetchRailCapacityPlanningError());
    }
  };
};
