import http from 'shared/utils/http';

export const registerVendor = (data) => {
  return http.post('/netwila-core/company/vendor', data);
};

export const uploadSrcFile = (type, data) => {
  return http.post(`/netwila-core/company/vendor/survey/upload/${type}`, data);
};

export const submitSurvey = (data) => {
  return http.post('/netwila-core/company/vendor/survey', data);
};
