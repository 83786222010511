import styled from 'styled-components';

export const StyledCollapsible = styled.div`
  .collapse-enter {
    opacity: 0.01;
    transform: translate(0, -10px);
  }

  .collapse-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: all 300ms ease-in;
  }

  .collapse-exit {
    opacity: 1;
    transform: translate(0, 0);
  }

  .collapse-exit-active {
    opacity: 0.01;
    transform: translate(0, 10px);
    transition: all 300ms ease-in;
  }
`;
