import http, { Http } from 'shared/utils/http';
import { extractAccessToken } from 'shared/utils/authToken';
import { NETWILA_COMPANY_ID } from 'configs/app';

export const getCurrentUser = async () => {
  const currentUser = extractAccessToken();
  return http.get(`/netwila/user?userId=${currentUser.username}`);
};

export const getUserById = async (userId) => {
  // return http.get('/netwila/user', { userId });
  return http.post('/netwila-core/users', {
    userIds: [userId]
  });
};

export const updateUser = async (userId, data) => {
  return http.post('/netwila/user', {
    userId,
    ...data
  });
};

export const updateCurrentUser = async (data) => {
  return http.post(`/netwila/user`, data);
};

export const getUsers = (params) => {
  return http.get('/netwila-core/users', params);
};

export const createUser = (data) => {
  const httpX = new Http();
  httpX.setHeader('x-api-key', process.env.REACT_APP_API_KEY);
  return Promise.resolve()
    .then(() => httpX.put('/netwila/user', data))
    .then((response) => {
      httpX.setHeader('Authorization', response.accessToken);
      return httpX.post('/netwila/user', {
        company: data.companyId,
        account: data.account
      });
    });
};

export const deleteUser = (userId) => {
  return http.delete(`/netwila-core/users/${userId}`);
};

export const isNetwilaUser = (user) => {
  return user?.company?._id === NETWILA_COMPANY_ID;
};

export const forgotPassword = (email) => {
  return http.post('/netwila/user/forgotpassword', { email });
};

export const confirmForgotPassword = (email, confirmationCode, password) => {
  return http.put('/netwila/user/forgotpassword', {
    email,
    confirmationCode,
    password
  });
};

export const changePassword = (userId, data) => {
  return http.put(`/netwila-core/users/${userId}/change-password`, data);
};

export const getUserSetting = () => {
  return http.get('/netwila-core/user/me');
};

export const updateUserSetting = (settings) => {
  return http.post('/netwila-core/user/me', settings);
};

export const getPolicy = (accountId) => {
  return http.get('/uaa/user-policy', { accountId });
};

export const getUserByIds = (userIds) => {
  return http.post('/netwila-core/users', { userIds });
};

export const updateUserById = (id, data) => {
  return http.post(`/netwila-core/user/${id}`, data);
};
