import http from 'shared/utils/http';

const URL = '/integrated-shipping/container-wave-assignment';

export const fetchData = (companyId, param = {}) => {
  return http.get(`${URL}`, {
    companyId,
    ...param
  });
};

export const createData = (companyId, data) => {
  return http.post(`${URL}`, {
    companyId,
    ...data
  });
};

export const updateDataById = (id, companyId, data) => {
  return http.put(`${URL}/${id}`, {
    companyId,
    ...data
  });
};

export const deleteDataById = (id) => {
  return http.delete(`${URL}/${id}`);
};
