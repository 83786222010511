import React, { useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isWaiting } from 'redux-waiters';
import { Select } from 'antd';
import {
  fetchLocationCreator,
  fetchLocationAction
} from 'reducers/common/location';

import {
  fetchLocationTypeCreator,
  fetchLocationTypeAction
} from 'reducers/common/locationType';

import EditableCell from 'shared/components/EditableCell';

const { Option } = Select;

const isFetchingSelector = isWaiting(fetchLocationAction.id);
const isFetchingLocationTypeSelector = isWaiting(fetchLocationTypeAction.id);

export default React.forwardRef(({ name, formProps, locationType }, ref) => {
  const dispatch = useDispatch();
  const form = formProps || useContext(EditableCell.EditableContext);
  const { locationsFormater } = useSelector((state) => state.common.location);
  const locationTypeData = useSelector(
    (state) => state.common.locationType.data
  );

  const locationTypeId = useMemo(
    () =>
      (
        locationTypeData.find((item) => item.type.indexOf(locationType) > -1) ||
        {}
      )._id,
    [locationType, locationTypeData]
  );

  const data = useMemo(() => locationsFormater[locationTypeId || 'none'], [
    locationTypeId,
    locationsFormater
  ]);

  const waiter = useSelector((state) => state.waiter);

  const fetching =
    isFetchingSelector(waiter) ||
    (locationType ? isFetchingLocationTypeSelector(waiter) : false);

  useEffect(() => {
    if (locationType && !locationTypeData.length) {
      dispatch(fetchLocationTypeCreator());
    } else if (locationType && !locationsFormater[locationTypeId]) {
      dispatch(fetchLocationCreator({ type: locationTypeId }));
    } else if (!locationType && !locationsFormater['none']) {
      dispatch(fetchLocationCreator());
    }
  }, [
    dispatch,
    locationsFormater,
    locationTypeId,
    locationType,
    locationTypeData
  ]);

  const onSelectChange = (value) => {
    if (!value) {
      form.resetFields([name]);
    } else {
      form.setFieldsValue({ [name]: value });
    }
  };
  return (
    <Select
      filterOption
      optionFilterProp='children'
      showSearch
      allowClear
      onChange={onSelectChange}
      loading={fetching}
      defaultValue={form.getFieldValue(name)}
      ref={ref}
    >
      {data &&
        data.map((location) => (
          <Option
            value={JSON.stringify({ _id: location._id, name: location.name })}
            key={location._id}
          >
            {location.name}
          </Option>
        ))}
    </Select>
  );
});
