import http from 'shared/utils/http';

import { getLoggedCompany } from 'services/company';

export const createOrderDray = async (data) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/api/order/dray-order', 'post', {
    ...data,
    companyId: loggedCompany._id
  });
  return response.data;
};

export const getOrderDray = async () => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/api/order/dray-order', 'get', {
    companyId: loggedCompany._id
  });
  return response.data;
};

export const getOrderDrayById = async (id) => {
  const response = await http.api('/api/order/dray-order', 'get', {
    id
  });
  return response.data;
};
