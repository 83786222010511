export default `
Unique Id,Title,Description,Vendor Email,Type,Tags,Option Name1,Option Value1,Option Name2,Option Value2,Option Name3,Option Value3,Variant SKU,Variant Grams,Variant Inventory Traking,Variant Qty,Variant Price,Variant Compare At Price,Variant Requires Shipping,Variant Barcode,Charge Taxes,Collection,Inventory Policy,Product Handle,Product Metafield Title,Metafield Description
925,verum shoe,shoe test for styoply seller beta version,john-doe@gmail.com,womens flat shoes and sandals,"testshoe,red",Color,red,Size,5,,,,1100,,80,525.00,,FALSE,,TRUE,,continue,,,
925,,,john-doe@gmail.com,womens flat shoes and sandals,,Color,Red,Size,6,,,,9071,,44,450.00,,FALSE,,TRUE,,continue,,,
88,Super Handbag,Simply choose your colour and you will receive a full matching set containing:,john-doe@gmail.com,"womens handbagsmn,b","testshoe,red",Color,red,Size,5,,,,1100,,80,452.00,,FALSE,,FALSE,,continue,,,
172,mega handbag,this is a green handbag and is a beta test product,john-doe@gmail.com,womens handbags,"handbags,womens,green",Color,Green,,,,,,2000,,882,50.00,,FALSE,,FALSE,,continue,,,
405,Super Handbag,Simply choose your colour and you will receive a full matching set containing:,john-doe@gmail.com,"womens handbagsmn,b","testshoe,red",Color,black / Red,Size,4,,,,100,,20,499.00,,FALSE,,TRUE,,continue,,,
405,,,john-doe@gmail.com,,"testshoe,red",Color,black / Red,Size,5,,,,1000,,20,499.00,,FALSE,,TRUE,,continue,,,
405,,,john-doe@gmail.com,,"testshoe,red",Color,black / Red,Size,6,,,,1000,,20,499.00,,FALSE,,FALSE,,deny,,,
`;
