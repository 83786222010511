import { createAction, createReducer } from 'redux-act';
import { LOCATION_CHANGE } from 'connected-react-router';

import { getModes, getTemperatureType } from 'services/mode';
import { createRoadOrder, getRoadOrders } from 'services/orders';
import { fetchHazardousCode } from 'services/hCode';
import { getRateCardType, findMatchedRate } from 'services/contract';

const ROAD_MODE = 'Road';
const ROAD_RATE_FEATURES = {
  carrier: 'Carrier',
  originCountry: 'Origin Country',
  originCity: 'Origin City',
  originState: 'Origin State/Province',
  // originPostCode: 'Origin Postal Code/ Zip Code',
  destinationCountry: 'Destination Country',
  destinationCity: 'Destination City',
  destinationState: 'Destination  Province/State',
  // destinationPostCode: 'Destination Postal Code-Zip Code',
  equipmentType: 'Equipment Type',
  temperatureType: 'Temperature Type',
  moveType: 'Move Type',
  shippingMode: 'Mode',
  shippingType: 'Service'
};
const initialState = {
  orderEntry: {}
};

const fetchOrderSuccessAction = createAction('fetch order success');

export default createReducer(
  {
    [fetchOrderSuccessAction]: (state, orders) => ({
      ...state,
      orders
    }),
    [LOCATION_CHANGE]: () => ({})
  },
  initialState
);

export const findMatchedRateAction = () => async (_, getState) => {
  const {
    order: { orderEntry }
  } = getState();
  const { shipperLocation, consigneeLocation, contract, uom } = orderEntry;

  // Gets rate card type
  const rateCardTypes = await getRateCardType();
  const rateCardType = rateCardTypes.data.find((rate) => rate.code === uom);

  if (!rateCardType) return [];

  const orderData = {
    ...orderEntry,
    carrier: orderEntry['carrier'].legalName,
    originCountry: orderEntry['originCountry'].name,
    originCity: shipperLocation?.city,
    originState: shipperLocation?.state?.state,
    originPostCode: shipperLocation?.postCode,
    destinationCountry: orderEntry['destinationCountry'].name,
    destinationCity: consigneeLocation?.city,
    destinationState: consigneeLocation?.state?.state,
    destinationPostCode: consigneeLocation?.postCode,
    temperatureType: orderEntry['temperatureType'].type
  };

  const featureFieldsValue = Object.keys(ROAD_RATE_FEATURES).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [ROAD_RATE_FEATURES[curr]]: orderData[curr]
      };
    },
    {} // default
  );

  // map with feature id
  const featureFields = rateCardType.feature;
  const mapData = featureFields.map((field) => {
    return {
      feature: field._id,
      value: featureFieldsValue[field.fieldName]
    };
  });

  const rate = await findMatchedRate({
    contractId: contract._id,
    feature: mapData.filter((item) => item.value !== undefined)
  });

  return rate.data;
};

export const createOrderAction = () => async (_, getState) => {
  const {
    order: { orderEntry },
    company: { loggedCompany }
  } = getState();
  const respone = await createRoadOrder({
    ...orderEntry,
    companyId: loggedCompany._id
  });
  return respone.data;
};

export const getRoadModesAction = () => async () => {
  try {
    const response = await getModes({ mode: ROAD_MODE });
    return response.data.find((mode) => mode.mode === ROAD_MODE);
  } catch (err) {
    return [];
  }
};

export const getHzdCodeAction = (_companyId) => async () => {
  try {
    const response = await fetchHazardousCode();
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getTemperatureTypeAction = () => async () => {
  try {
    const response = await getTemperatureType();
    return response.data;
  } catch (err) {
    return [];
  }
};

export const getOrdersAction = (filter = {}) => async (dispatch, getState) => {
  const {
    company: { loggedCompany }
  } = getState();
  const response = await getRoadOrders(loggedCompany._id, filter);
  dispatch(fetchOrderSuccessAction(response.data));
};
