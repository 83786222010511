import React from 'react';
import PropTypes from 'prop-types';

import { ProgressStyle, InfoStyle, StepStyleContainer } from './Styles';
import Step from './Step';

const propTypes = {
  percent: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
  paddingTop: PropTypes.string,
  showInfo: PropTypes.bool
};

const defaultProps = {
  percent: 0,
  size: 'small',
  paddingTop: '0px',
  showInfo: true
};

const computeStepPercent = (steps, percent, currentStep) => {
  const stepWidth = 100 / steps;
  const completedStep = Math.floor(percent / stepWidth);
  if (currentStep > completedStep) {
    return 0;
  }
  if (currentStep < completedStep) {
    return 100;
  }
  if (currentStep === completedStep) {
    return ((percent - completedStep * stepWidth) * 100) / stepWidth;
  }
  return 0;
};

const Progress = (props) => {
  const stepCount = React.Children.count(props.children);
  const stepWidth = 100 / stepCount;
  return (
    <ProgressStyle size={props.size}>
      <StepStyleContainer paddingTop={props.paddingTop}>
        {React.Children.map(props.children, (child, step) => {
          return React.cloneElement(child, {
            width: stepWidth,
            percent: computeStepPercent(stepCount, props.percent, step)
          });
        })}
      </StepStyleContainer>
      {props.showInfo && <InfoStyle>{`${props.percent}% completed`}</InfoStyle>}
    </ProgressStyle>
  );
};

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;
Progress.Step = Step;

export default Progress;
