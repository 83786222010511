import { getRole, createRole, updateRole } from '../services/role';

const initialState = {
  fetchingLoading: true,
  arrRole: [],
  error: false,
  createLoading: false,
  updateLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'fetching_role':
      return {
        ...state,
        fetchingLoading: true,
        error: false
      };
    case 'fetching_role_success':
      return {
        ...state,
        arrRole: action.data,
        fetchingLoading: false,
        error: false
      };
    case 'creating_role':
      return {
        ...state,
        createLoading: true,
        error: false
      };
    case 'creating_role_success':
      return {
        ...state,
        arrRole: [...state.arrRole, action.data],
        createLoading: false,
        error: false
      };
    case 'updating_role':
      return {
        ...state,
        updateLoading: true,
        error: false
      };
    case 'updating_role_success':
      return {
        ...state,
        arrRole: [...state.arrRole, action.data],
        updateLoading: false,
        error: false
      };
    case 'fetching_error':
      return {
        ...state,
        message: action.message,
        error: true,
        loading: false
      };
    default:
      return state;
  }
};

export const fetchRoleAction = () => {
  return async (dispatch) => {
    dispatch({ type: 'fetching_role' });
    //TODO get companyId (set temp companyId = '0')
    try {
      const data = await getRole('0', true);
      dispatch({
        type: 'fetching_role_success',
        data: data.data
      });
    } catch (error) {
      dispatch({
        type: 'fetching_error',
        message: 'Fetching error'
      });
    }
  };
};

export const createRoleAction = (body) => {
  return async (dispatch) => {
    dispatch({ type: 'creating_role' });
    try {
      //TODO add companyId to body
      const data = await createRole({ ...body, companyId: '0' });
      dispatch({
        type: 'creating_role_success',
        data: data.data
      });
    } catch (error) {
      dispatch({
        type: 'fetching_error',
        message: 'Fetching error'
      });
    }
  };
};

export const updateRoleAction = (body) => {
  return async (dispatch) => {
    dispatch({ type: 'updating_role' });
    try {
      const data = await updateRole(body);
      dispatch({
        type: 'updating_role_success',
        data: data.data
      });
    } catch (error) {
      dispatch({
        type: 'fetching_error',
        message: 'Fetching error'
      });
    }
  };
}
