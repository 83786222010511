import styled, { css } from 'styled-components';

import { color, font } from 'shared/utils/styles';
import Icon from 'shared/components/Icon';

export const StyledInput = styled.div`
  position: relative;
  display: inline-block;
  height: ${(props) => props.height}px;
  width: 100%;
`;

export const InputElement = styled.input`
  height: 100%;
  width: 100%;
  padding: 10px 10px;
  border-radius: 4px;
  border: 1px solid ${color.borderDark};
  color: ${color.textDarkest};
  background: #ffffff;
  transition: background 0.1s;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  :-ms-input-placeholder {
     color: #999999;
  }
  ${font.regular}
  ${font.size(15)}
  ${(props) => props.hasIcon && 'padding-left: 32px;'}
  &:hover {
    background: ${color.backgroundLight};
  }
  &:focus {
    background: #fff;
    border: 1px solid ${color.borderInputFocus};
    box-shadow: 0 0 0 1px ${color.borderInputFocus};
  }
  ${(props) =>
    props.invalid &&
    css`
      &,
      &:focus {
        border: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 8px;
  pointer-events: none;
  color: ${color.textMedium};
`;
