import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { RadioGroupContainer, StyledRadioButton, CheckBox } from './Styles';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  data: PropTypes.array.isRequired,
  toggleSelect: PropTypes.func,
  toggleSelectAll: PropTypes.func
};

const defaultProps = {
  className: undefined,
  children: undefined,
  data: [],
  toggleSelect: () => {},
  toggleSelectAll: () => {}
};

const SelectRadioGroup = forwardRef(
  ({ data, label, toggleSelect, toggleSelectAll, ...buttonProps }, ref) => {
    const [isAllSelected, setAllSelected] = useState(false);

    const handleToggleSelect = (item) => {
      if (isAllSelected && item.selected === true) {
        setAllSelected(!isAllSelected);
      }
      toggleSelect(item);
    };

    const handleToggleSelectAll = () => {
      setAllSelected(!isAllSelected);
      toggleSelectAll(!isAllSelected);
    };

    return (
      <RadioGroupContainer {...buttonProps} ref={ref}>
        <StyledRadioButton
          background='#e4e4e4'
          onClick={() => handleToggleSelectAll()}
        >
          <CheckBox checked={isAllSelected} background='#e4e4e4' />
          {label}
        </StyledRadioButton>
        {data.map((item) => (
          <StyledRadioButton
            key={item.id}
            onClick={() => handleToggleSelect(item)}
          >
            <CheckBox checked={item.selected} background='#ffffff' />
            {item.name}
          </StyledRadioButton>
        ))}
      </RadioGroupContainer>
    );
  }
);

SelectRadioGroup.propTypes = propTypes;
SelectRadioGroup.defaultProps = defaultProps;

export default SelectRadioGroup;
