import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { color, mixin } from 'shared/utils/styles';

export const CardWrapper = styled.div`
  padding: 1rem;
  margin-top: 25px;
  border-radius: 4px;
  ${mixin.boxShadowMedium}

  &.mt-0 {
    margin-top: 0 !important;
  }
`;

export const CardTitle = styled.h3`
  font-size: 19px;
  color: ${color.textDarkest};
  letter-spacing: 0.1px;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(90px, auto);

  @media (max-width: 1199.98px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 767.98px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 575.98px) {
    grid-template-columns: 1fr;
  }
`;

export const CardItem = styled(Link)`
  border: 1px solid ${color.borderLight};
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.3px;
  font-size: 15px;
  padding: 1rem;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff;
    border: 1px solid ${color.borderDark};
    opacity: 0.9;
  }

  span:not(:first-child) {
    padding-top: 2px;
  }
`;
