import { createAction, createReducer } from 'redux-act';

import {
  getLoadBoardRevenue,
  getLoadBoardCost,
  updateLoadBoardCost,
  deleteLoadBoardCost
} from 'services/revenue/loadBoard';

const initialState = {
  loadBoardRevenue: [],
  loadBoardCost: [],
  cost: null
};

const getLoadBoardRevenueSuccessAction = createAction(
  'getLoadBoardRevenueSuccessAction'
);
const getLoadBoardCostSuccessAction = createAction(
  'getLoadBoardCostSuccessAction'
);

const getLoadBoardCostByIdSuccessAction = createAction(
  'getLoadBoardCostByIdSuccessAction'
);

export default createReducer(
  {
    [getLoadBoardRevenueSuccessAction]: (state, loadBoardRevenue) => ({
      ...state,
      loadBoardRevenue
    }),
    [getLoadBoardCostSuccessAction]: (state, loadBoardCost) => ({
      ...state,
      loadBoardCost
    }),
    [getLoadBoardCostByIdSuccessAction]: (state, cost) => ({
      ...state,
      cost
    })
  },
  initialState
);

export const getLoadBoardRevenueAction = () => async (dispatch) => {
  const response = await getLoadBoardRevenue();
  dispatch(getLoadBoardRevenueSuccessAction(response.data));
};

export const getLoadBoardCostAction = () => async (dispatch) => {
  const response = await getLoadBoardCost();
  dispatch(getLoadBoardCostSuccessAction(response.data));
};

export const updateLoadBoardCostAction = (data) => async (dispatch) => {
  const response = await updateLoadBoardCost(data);
  dispatch(getLoadBoardCostAction());
  return response.data;
};

export const getLoadBoardCostByIdAction = (costId) => async (dispatch) => {
  const response = await getLoadBoardCost({ costId });
  dispatch(getLoadBoardCostByIdSuccessAction(response.data?.[0]));
};

export const deleteLoadBoardCostAction = (costId) => async (dispatch) => {
  await deleteLoadBoardCost(costId);
  dispatch(getLoadBoardCostAction());
};
