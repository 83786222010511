import http from 'shared/utils/http';
import axios from 'axios';

export const getLocations = (params) => {
  return http.get('/netwila-core/location', params);
};

export const getLocationById = (id) => {
  return http.get(`/netwila-core/location/${id}`);
};

export const getLocation = (filter = {}) => {
  const addedLimitation = { ...filter, limit: 10000 };
  return http.get('/netwila/location', addedLimitation);
};

/**
 * To update location
 * you need pass location id in location object
 * @param {Object} location
 */
export const addNewLocation = (data) => {
  return http.post('/netwila-core/location', data);
};

export const updateLocation = ({ id, data }) => {
  // return http.post('/netwila/location', location);
  return http.put(`/netwila-core/location/${id}`, data);
};

export const deleteLocation = (id) => {
  return http.delete(`/netwila-core/location/${id}`);
};

export const getCountries = () => {
  return http.get('/netwila/location/country');
};

export const getLocationType = () => {
  return http.get('/netwila/location/type');
};

/**
 * Gets regions by country code
 * @param  {[type]} code [description]
 * @return {[type]}      [description]
 */
export const getRegions = (code) => {
  return http.get('/netwila/location/city', { code });
};

export const getLatLongByAddress = (address) => {
  const url = encodeURI(
    'https://maps.google.com/maps/api/geocode/json?key=' +
      process.env.REACT_APP_GOOGLE_API_KEY +
      '&address=' +
      address
  );
  const method = 'GET';
  return axios.request({ url, method });
};

export const formatLocation = (location) => {
  if (!location) return location;
  return [
    location.name ?? '',
    location.address ?? '',
    location.city ?? '',
    location.state?.state ?? '',
    location.country?.name,
    location.postCode
  ]
    .filter((value) => value !== '')
    .join(' - ');
};

export const findLocation = (data) => {
  return http.get('/netwila/location/search/', { ...data });
};

export const getUploadUrl = () => {
  return http.get('/netwila-core/company/location/prep');
};

export const runImport = (data) => {
  return http.post('/netwila-core/company/location/import', data);
};

export const getUploadHistory = () => {
  return http.get('/netwila-core/company/location/upload-history');
};

export const importStore4DC = (data) => {
  return http.post('/netwila-core/company/location/import-store-4-dc', data);
};

export const searchLocations = (locIds, typeCode = undefined) => {
  return http.post('/netwila-core/location/search', { locIds, typeCode });
};

export const createBasePort = (data) => {
  return http.post('/netwila-core/location/base-port', data);
};

export const searchLocations2 = (params) => {
  return http.post('/netwila-core/location/search', params);
};

export const getDistance = (origin, destination) => {
  return http.get('/netwila-core/location/distance', {
    origin,
    destination
  });
};

export const addVariances = (data) => {
  return http.post('/netwila-core/location/add-variances', data);
};
