import { createApiAction, createReducer } from 'shared/utils/redux';

import {
  getAnnualVolume,
  updateAnnualVolume
} from 'services/demand/planning/annualVolume';

export const annualVolumeSelector = (state) =>
  state.oceanIntermodal.annualVolume.allocations;

export const getAnnualVolumeAction = createApiAction(
  'getAnnualVolume',
  (filter) => ({
    callAPI: () => getAnnualVolume(filter)
  })
);

export const updateAnnualVolumeAction = createApiAction(
  'updateAnnualVolume',
  (data) => ({
    callAPI: () => updateAnnualVolume(data)
  })
);

export default createReducer(
  {
    allocations: []
  },
  {
    [getAnnualVolumeAction.success]: (state, { response }) => ({
      ...state,
      allocations: response.data
    })
  }
);
