import { createAction, createReducer } from 'redux-act';

import {
  getContractLinks,
  addContractLinks
} from 'services/integratedShipping/contractLink';

const initialState = {
  contractLinks: []
};

const getContractLinksSuccess = createAction('getContractLinksSuccess');

export default createReducer(
  {
    [getContractLinksSuccess]: (state, contractLinks) => ({
      ...state,
      contractLinks
    })
  },
  initialState
);

export const getContractLinksAction = () => async (dispatch) => {
  const response = await getContractLinks();
  dispatch(getContractLinksSuccess(response.data));
};

export const addContractLinksAction = (
  companyId,
  inbounds,
  outbounds
) => async () => {
  const response = await addContractLinks({ companyId, inbounds, outbounds });
  return response.data;
};
