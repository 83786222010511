import { createReducer, createAction } from 'redux-act';

import { fetchCustomerType } from 'services/customer';

const initialState = {
  isFetching: false,
  customerType: [],
  error: false,
  message: ''
};

const fetchCustomerTypeActionStart = createAction(
  'start fetch customer type list'
);
const fetchCustomerTypeActionSuccess = createAction(
  'fetch customer type list success'
);
const fetchCustomerTypeActionError = createAction(
  'fetch customer type list error'
);

export default createReducer(
  {
    [fetchCustomerTypeActionStart]: (state) => ({
      ...state,
      isFetching: true
    }),
    [fetchCustomerTypeActionSuccess]: (state, payload) => ({
      ...state,
      isFetching: false,
      customerType: payload.data
    }),
    [fetchCustomerTypeActionError]: (state) => ({
      ...state,
      isFetching: false,
      error: true,
      message: 'Have some errors while fetch data'
    })
  },
  initialState
);

export const fetchCustomerTypeListAction = (companyId, param = {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCustomerTypeActionStart());
      const res = await fetchCustomerType(companyId, param);
      dispatch(
        fetchCustomerTypeActionSuccess({ data: res.data, meta: res.meta })
      );
    } catch (e) {
      dispatch(fetchCustomerTypeActionError());
    }
  };
};
