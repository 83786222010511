import http from 'shared/utils/http';

export const getLoadBoardRevenue = () => {
  return http.get('/netwila/loadboard/revenue');
};

export const getLoadBoardCost = (filter = {}) => {
  return http.get('/netwila/loadboard/cost', filter);
};

export const updateLoadBoardCost = (data) => {
  return http.post('/netwila/loadboard/cost', data);
};

export const deleteLoadBoardCost = (costId) => {
  return http.delete(`/netwila/loadboard/cost/${costId}`);
};
