import {
  fetchRoadOderTblData,
  createOrderRoad
} from 'services/oderRoadServices';

const initialState = {
  createLoading: false,
  fetchLoading: true,
  data: [],
  error: false,
  createError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'fetching_road_order':
      return {
        ...state,
        fetchLoading: true,
        error: false
      };
    case 'fetching_road_order_success':
      return {
        ...state,
        data: action.data,
        fetchLoading: false,
        error: false
      };
    case 'creating_road_order':
      return {
        ...state,
        createLoading: true,
        createError: true
      };
    case 'creating_road_order_success':
      return {
        ...state,
        data: [...state.data, action.data],
        createLoading: false,
        createError: false
      };
    case 'creating_road_order_error':
      return {
        ...state,
        createLoading: false,
        createError: true,
        message: action.message
      };
    case 'fetching_error':
      return {
        ...state,
        message: action.message,
        error: true,
        fetchLoading: false
      };
    default:
      return state;
  }
};

export const fetchDataAction = () => {
  return async (dispatch) => {
    dispatch({ type: 'fetching_road_order' });
    try {
      const data = await fetchRoadOderTblData();
      dispatch({
        type: 'fetching_road_order_success',
        data: data.data
      });
    } catch (error) {
      dispatch({
        type: 'fetching_error',
        message: 'Fetching error'
      });
    }
  };
};

export const createOrderRoadActionA = (data) => {
  return async (dispatch) => {
    dispatch({ type: 'creating_road_order' });
    try {
      //TODO add companyId to body
      const respone = await createOrderRoad(data);
      dispatch({
        type: 'creating_road_order_success',
        data: respone.data
      });
      return true;
    } catch (error) {
      dispatch({
        type: 'creating_road_order_error',
        message: 'Creating error'
      });
      return false;
    }
  };
};
