/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import LoadableComponent from 'components/LoadableComponent';
import BlockUi from 'components/BlockUi';
import { PrivateRoute } from 'components/Route/PrivateRoute';
import { routeConfig } from 'configs/route';
import Topbar from 'components/Topbar';
import Sidebar from 'components/Sidebar';

import { verifyAccessTokenAction } from 'reducers/authentication';
import { getCurrentUserAction, getPermissionAction } from 'reducers/user';
import { initializingAction, initializedAction } from 'reducers/app';
import {
  getCurrentCompanyAction,
  verifyCompanySelectionAction,
  verifyCompanySubscriptionAction,
  checkSurveyAction
} from 'reducers/company/companyManagement';
import { verifyAccountSelectionAction } from 'reducers/company/accountManagement';

const Dashboard = LoadableComponent(() => import('pages/Dashboard'));
const Company = LoadableComponent(() => import('pages/Company'));
const CompanyNetwork = LoadableComponent(() => import('pages/Network'));
const Orders = LoadableComponent(() => import('pages/Orders'));
const Contracts = LoadableComponent(() => import('pages/Contracts'));
const Settings = LoadableComponent(() => import('pages/Settings'));
const Profile = LoadableComponent(() => import('pages/Profile'));
const Messages = LoadableComponent(() => import('pages/Messages'));
const EDI = LoadableComponent(() => import('pages/Edi'));
const Bulk = LoadableComponent(() => import('pages/Bulk'));

const Asset = LoadableComponent(() => import('pages/Asset'));
const AcquisitionManagement = LoadableComponent(() =>
  import('pages/Asset/AcquisitionManagement/pages')
);
const AssetRequirementPlanning = LoadableComponent(() =>
  import('pages/Asset/AssetRequirementPlanning/pages')
);
const AutoYardPlanning = LoadableComponent(() =>
  import('pages/Asset/AutomotiveYardPlanning/pages')
);
const RouteAssetPlans = LoadableComponent(() =>
  import('pages/Asset/RouteAssetPlans/pages')
);
const PortManagement = LoadableComponent(() =>
  import('pages/Asset/PortManagement/pages')
);
const CustomerOrdersManual = LoadableComponent(() =>
  import('pages/Asset/CustomerOrdersManual/pages')
);
const EquipmentPlanning = LoadableComponent(() =>
  import('pages/Asset/EquipmentPlanning/pages')
);
const RouteTimetable = LoadableComponent(() =>
  import('pages/Asset/RouteTimetable/pages')
);
const MaintenanceManagement = LoadableComponent(() =>
  import('pages/Asset/MaintenanceManagement/pages')
);
const MaintenanceSchedule = LoadableComponent(() =>
  import('pages/Asset/MaintenanceSchedule/pages')
);
const StaffSchedules = LoadableComponent(() =>
  import('pages/Asset/StaffSchedules/pages')
);
const InputOutputAnalysis = LoadableComponent(() =>
  import('pages/Asset/InputOutputAnalysis/pages')
);
const InternalAssetAudit = LoadableComponent(() =>
  import('pages/Asset/InternalAssetAudit/pages')
);
const RiskManagement = LoadableComponent(() =>
  import('pages/Asset/RiskManagement/pages')
);
const ExternalRail = LoadableComponent(() =>
  import('pages/Asset/ExternalRail/pages')
);
const CarbonCalculator = LoadableComponent(() =>
  import('pages/Asset/CarbonCalculator/pages')
);
const RailMasterCapacityPlan = LoadableComponent(() =>
  import('pages/Asset/RailMasterCapacityPlan/pages')
);
const TimeTableAssessment = LoadableComponent(() =>
  import('pages/Asset/TimeTableAssessment/pages')
);
const RouteOperationalPlans = LoadableComponent(() =>
  import('pages/Asset/RouteOperationalPlans/pages')
);

const Demand = LoadableComponent(() => import('pages/Demand'));
const Road = LoadableComponent(() => import('pages/Demand/Road/pages'));
const OceanIntermodal = LoadableComponent(() =>
  import('pages/Demand/OceanIntermodal/pages')
);
const MasterSchedule = LoadableComponent(() =>
  import('pages/Demand/MasterSchedule/pages')
);
const RailDelivery = LoadableComponent(() =>
  import('pages/Demand/RailDelivery/pages')
);
const Revenue = LoadableComponent(() => import('pages/Demand/Revenue/pages'));
const DrayageManagement = LoadableComponent(() =>
  import('pages/Demand/DrayageManagement/pages')
);
const IntermodalYardPlanning = LoadableComponent(() =>
  import('pages/Demand/IntermodalYardPlanning/pages')
);

const Ocean = LoadableComponent(() => import('pages/Ocean'));
const Waybills = LoadableComponent(() => import('pages/Ocean/Waybills/pages'));

const AdminPage = LoadableComponent(() => import('pages/Admin'));
const TasksPage = LoadableComponent(() => import('pages/Tasks'));
const IntegratedShipping = LoadableComponent(() =>
  import('pages/Demand/IntegratedShipping/pages')
);
const DataTablePage = LoadableComponent(() => import('pages/DataTable'));
const DataFlowPage = LoadableComponent(() => import('pages/DataFlow'));
const AiPage = LoadableComponent(() => import('pages/Ai'));
const Invoice = LoadableComponent(() => import('pages/Invoice'));
const Automotive = LoadableComponent(() =>
  import('pages/Demand/Automotive/pages')
);
const AgingReport = LoadableComponent(() => import('pages/AgingReport'));
const Quote = LoadableComponent(() => import('pages/Quote'));

const Master = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { initialized } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = () => {
      return (
        Promise.resolve()
          .then(() => dispatch(initializingAction()))
          .then(() => dispatch(verifyAccessTokenAction()))
          // Verfiy account
          .then(() => dispatch(getCurrentUserAction()))
          .then(() => dispatch(verifyCompanySelectionAction()))
          .then(() => dispatch(verifyAccountSelectionAction()))
          .then(() => dispatch(getCurrentCompanyAction()))
          // Verify subscriptions
          .then(() => dispatch(verifyCompanySubscriptionAction()))
          // TODO: Get the access management
          .then(() => dispatch(getPermissionAction()))
          .then(() => dispatch(initializedAction()))
          .then(() => dispatch(checkSurveyAction()))
          .catch((err) => {
            if (err.name === 'AuthError') {
              dispatch(push(routeConfig.LOGOUT));
            }
            if (err.name === 'SurveyError') {
              let redirectUrl = '/';
              switch (err.type) {
                case 'sourcing':
                  redirectUrl = routeConfig.SOURCING_SURVEY;
                case 'vendor':
                  redirectUrl = routeConfig.VENDOR_SURVEY;
              }
              dispatch(push(redirectUrl));
            }
          })
      );
    };
    initialize();
  }, [dispatch]);

  return (
    <BlockUi blocking={!initialized}>
      <Layout style={{ minHeight: '100vh' }}>
        {initialized && (
          <>
            <Sidebar onCollapse={setSidebarCollapsed} />
            <Layout
              style={{
                marginLeft: sidebarCollapsed ? 70 : 160,
                paddingTop: 64
              }}
            >
              <Layout.Header
                style={{
                  position: 'fixed',
                  top: 0,
                  zIndex: 1,
                  width: `calc(100% - ${sidebarCollapsed ? '70px' : '160px'})`
                }}
              >
                <Topbar />
              </Layout.Header>
              <Switch>
                <PrivateRoute
                  path={routeConfig.DASHBOARD}
                  component={Dashboard}
                />
                <PrivateRoute path={routeConfig.COMPANY} component={Company} />
                <PrivateRoute
                  path={routeConfig.NETWORK}
                  component={CompanyNetwork}
                />
                <PrivateRoute path={routeConfig.ORDERS} component={Orders} />
                <PrivateRoute
                  path={routeConfig.CONTRACTS}
                  component={Contracts}
                />
                <PrivateRoute
                  path={routeConfig.SETTINGS}
                  component={Settings}
                />
                <PrivateRoute path={routeConfig.PROFILE} component={Profile} />
                <PrivateRoute
                  path={routeConfig.MESSAGES}
                  component={Messages}
                />
                <PrivateRoute
                  path={routeConfig.OCEAN_MANAGEMENT}
                  component={Ocean}
                />
                <PrivateRoute
                  path={routeConfig.WAYBILLS}
                  component={Waybills}
                />
                <PrivateRoute path={routeConfig.EDI} component={EDI} />
                <PrivateRoute path={routeConfig.BULK} component={Bulk} />
                <PrivateRoute
                  path={routeConfig.ASSET_MANAGEMENT}
                  component={Asset}
                />
                <PrivateRoute
                  path={routeConfig.ACQUISITION_MANAGEMENT}
                  component={AcquisitionManagement}
                />
                <PrivateRoute
                  path={routeConfig.ASSET_REQUIREMENT_PLANNING}
                  component={AssetRequirementPlanning}
                />
                <PrivateRoute
                  path={routeConfig.INTERMODAL_YARD_PLANNING}
                  component={IntermodalYardPlanning}
                />
                <PrivateRoute
                  path={routeConfig.AUTO_YARD_PLANNING}
                  component={AutoYardPlanning}
                />
                <PrivateRoute
                  path={routeConfig.AGING_REPORT}
                  component={AgingReport}
                />
                <PrivateRoute
                  path={routeConfig.ROUTE_ASSET_PLANS}
                  component={RouteAssetPlans}
                />
                <PrivateRoute
                  path={routeConfig.PORT_MANAGEMENT}
                  component={PortManagement}
                />
                <PrivateRoute
                  path={routeConfig.CUSTOMER_ORDERS_MANUAL}
                  component={CustomerOrdersManual}
                />
                <PrivateRoute
                  path={routeConfig.EQUIPMENT_PLANNING}
                  component={EquipmentPlanning}
                />
                <PrivateRoute
                  path={routeConfig.ROUTE_TIMETABLE}
                  component={RouteTimetable}
                />
                <PrivateRoute
                  path={routeConfig.MAINTENANCE_MANAGEMENT}
                  component={MaintenanceManagement}
                />
                <PrivateRoute
                  path={routeConfig.MAINTENANCE_SCHEDULE}
                  component={MaintenanceSchedule}
                />
                <PrivateRoute
                  path={routeConfig.STAFF_SCHEDULES}
                  component={StaffSchedules}
                />
                <PrivateRoute
                  path={routeConfig.INPUT_OUTPUT_ANALYSIS}
                  component={InputOutputAnalysis}
                />
                <PrivateRoute
                  path={routeConfig.INTERNAL_ASSET_AUDIT}
                  component={InternalAssetAudit}
                />
                <PrivateRoute
                  path={routeConfig.RISK_MANAGEMENT}
                  component={RiskManagement}
                />
                <PrivateRoute
                  path={routeConfig.CARBON_CALCULATOR}
                  component={CarbonCalculator}
                />
                <PrivateRoute
                  path={routeConfig.RAIL_MASTER_CAPACITY_PLAN}
                  component={RailMasterCapacityPlan}
                />
                <PrivateRoute
                  path={routeConfig.TIME_TABLE_ASSESSMENT}
                  component={TimeTableAssessment}
                />
                <PrivateRoute
                  path={routeConfig.ROUTE_OPERATIONAL_PLANS}
                  component={RouteOperationalPlans}
                />
                <PrivateRoute
                  path={routeConfig.DEMAND_MANAGEMENT}
                  component={Demand}
                />
                <PrivateRoute path={routeConfig.ROAD} component={Road} />
                <PrivateRoute
                  path={routeConfig.OCEAN_INTERMODAL}
                  component={OceanIntermodal}
                />
                <PrivateRoute
                  path={routeConfig.MASTER_SCHEDULE}
                  component={MasterSchedule}
                />
                <PrivateRoute
                  path={routeConfig.RAIL_DELIVERY}
                  component={RailDelivery}
                />
                <PrivateRoute path={routeConfig.REVENUE} component={Revenue} />
                <PrivateRoute
                  path={routeConfig.DRAYAGE_MANAGEMENT}
                  component={DrayageManagement}
                />
                <PrivateRoute path={routeConfig.ADMIN} component={AdminPage} />
                <PrivateRoute
                  path={routeConfig.TASK_MANAGEMENT}
                  component={TasksPage}
                />
                <PrivateRoute
                  path={routeConfig.INTEGRATED_SHIPPING}
                  component={IntegratedShipping}
                />
                <PrivateRoute
                  path={routeConfig.DATA_TABLE}
                  component={DataTablePage}
                />
                <PrivateRoute
                  path={routeConfig.DATA_FLOW}
                  component={DataFlowPage}
                />
                <PrivateRoute path={routeConfig.AI_MODEL} component={AiPage} />
                <PrivateRoute
                  path={routeConfig.INVOICE_MANAGEMENT}
                  component={Invoice}
                />
                <PrivateRoute
                  path={routeConfig.AUTOMOTIVE}
                  component={Automotive}
                />
                <PrivateRoute path={routeConfig.QUOTE} component={Quote} />
                <Redirect to={routeConfig.DASHBOARD} />
              </Switch>
            </Layout>
          </>
        )}
      </Layout>
    </BlockUi>
  );
};

export default Master;
