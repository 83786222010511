import { combineReducers } from 'redux';
import ediReducer from './edi';
import ediLogReducer from './ediLog';
import ediMappingReducer from './ediMapping';
import ediWorkflowReducer from './workflow';

export default combineReducers({
  edi: ediReducer,
  ediLog: ediLogReducer,
  ediMapping: ediMappingReducer,
  ediWorkflow: ediWorkflowReducer
});
