import React from 'react';
import PropTypes from 'prop-types';

import { StepStyle, StepProgressStyle, StepInfoStyle } from './Styles';

const propTypes = {
  width: PropTypes.number,
  percent: PropTypes.number,
  bgColor: PropTypes.string,
  strokeColor: PropTypes.string
};

const defaultProps = {
  percent: 0,
  bgColor: '#999999',
  strokeColor: '#1f4370'
};

const Step = ({ width, percent, bgColor, strokeColor, children }) => {
  return (
    <StepStyle
      width={`${width}%`}
      bgColor={bgColor}
      paddingTop={!!children}
    >
      <StepProgressStyle width={`${percent}%`} strokeColor={strokeColor} />
      {children && <StepInfoStyle>{children}</StepInfoStyle>}
    </StepStyle>
  );
};

Step.propTypes = propTypes;
Step.defaultProps = defaultProps;

export default Step;
