import http from 'shared/utils/http';

import { getLoggedCompany } from 'services/company';

export const fetchRailOderTblData = async () => {
  const response = await http.api('/api/order/externalrailorderentry', 'get', {
    companyId: '0'
  });

  return response.data;
};

export const createOrderRailA = async (data) => {
  const response = await http.api('/api/order/externalrailorderentry', 'post', {
    ...data,
    companyId: '0'
  });
  /**
   * TODO: get columns define from server
   * How to handle for error
   */

  return response.data;
};

export const fetchOrderRailDataById = async ({ id }) => {
  const response = await http.api('/api/order/externalrailorderentry', 'get', {
    id
  });

  return response.data;
};

export const createOrderRail = async (data) => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/api/order/rail-order', 'post', {
    ...data,
    companyId: loggedCompany._id
  });
  return response.data;
};

export const getOrderRail = async () => {
  const loggedCompany = getLoggedCompany();
  const response = await http.api('/api/order/rail-order', 'get', {
    companyId: loggedCompany._id
  });
  return response.data;
};

export const getOrderRailById = async (id) => {
  const response = await http.api('/api/order/rail-order', 'get', {
    id
  });
  return response.data;
};
